import React from "react";
// reactstrap components
import { Col, Container, Nav, NavItem, NavLink, Row } from "reactstrap";


const LandingFooter = () => {
  return (
    <>
      <footer className="py-3">
        <Container>
          <Row className="align-items-center justify-content-xl-between">
            <Col xl="6">
              <div className="copyright text-center text-xl-left text-muted">
                © 2023
                <a
                  className="font-weight-bold ml-1"
                  href="/"
                  target="_blank"
                >
                  Mo-Buzz+
                </a>
              </div>
            </Col>
            <Col xl="6" className="text-right">
              
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default LandingFooter;
