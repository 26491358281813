import * as api from '../api/index.js';
import {
  CALENDAR_FETCH_ALL,
  CALENDAR_FETCH_USER,
  CALENDAR_FETCH_PUB,
  CALENDAR_CREATE,
  CALENDAR_UPDATE,
  CALENDAR_DELETE,
  SHOW_LOADING
} from 'constants/actionTypes';

export const fetchCalendarEventAll = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchCalendarEventAll(formData);
    if (data?.status === 'success') {
      dispatch({ type: CALENDAR_FETCH_ALL, payload: data?.data });
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error);
    alert(error.response.data.message);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const fetchCalendarEventByUser = (formData) => async (dispatch) => {
  console.log("formData: ", formData);

  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchCalendarEventByUser(formData);
    if (data?.status === 'success') {
      dispatch({ type: CALENDAR_FETCH_USER, payload: data?.data });
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error);
    alert(error.response.data.message);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const fetchCalendarEventPub = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchCalendarEventPub(formData);
    if (data?.status === 'success') {
      dispatch({ type: CALENDAR_FETCH_PUB, payload: data?.data });
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error);
    alert(error.response.data.message);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const createCalendarEvent = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    console.log("formData: ", formData);

    const { data } = await api.createCalendarEvent(formData);
    if (data?.status === 'success') {
      dispatch({ type: CALENDAR_CREATE, payload: data?.data });
      alert(data?.message);
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error);
    alert(error.response.data.message);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const updateCalendarEvent = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    console.log("formData: ", formData);

    const { data } = await api.updateCalendarEvent(formData);
    if (data?.status === 'success') {
      dispatch({ type: CALENDAR_UPDATE, payload: data?.data });
      alert(data?.message);
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error);
    alert(error.response.data.message);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const deleteCalendarEvent = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.deleteCalendarEvent(formData);
    if (data?.status === 'success') {
      dispatch({ type: CALENDAR_DELETE, payload: data?.data });
      dispatch(fetchCalendarEventByUser(formData)); // to reload the page
      alert(data?.message);
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error);
    alert(error.response.data.message);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};