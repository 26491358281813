import { combineReducers } from 'redux';
import analytics from './analytics';
import auth from './auth';
import breedingsite from './breedingsite';
import calendar from './calendar';
import contact from './contact';
import education from './education';
import forum from './forum';
import user from './user';
import resource from './resource';
import map from './map';
import notification from './notification';
import dashboard from './dashboard';
import mbpCase from './mbpCase';

export const reducers = combineReducers({
  auth,
  user,
  forum,
  resource,
  analytics,
  breedingsite,
  calendar,
  contact,
  education,
  map,
  notification,
  dashboard,
  mbpCase,
});
