import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import PageHeader from "components/Headers/PageHeader.js"; // core components
import { MbppStyles } from "constants/globalStyles";
import Lodash from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Row } from "reactstrap"; // reactstrap components


const MapsLocationDetails = (props) => {
  const history = useHistory();

  // {
  //   color: "#FF0000"
  //   coordinate: { longitude: 79.92667771875858, latitude: 7.033965622914534 }
  //   description: ""
  //   key: 0
  //   title: ""
  // }
  const [mapLocations, setMapLocations] = useState([]);
  const [activeMarker, setActiveMarker] = useState(null);

  const handleOnLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    mapLocations.forEach(({ coordinate }) => bounds.extend({ lat: coordinate.latitude, lng: coordinate.longitude }));
    map.fitBounds(bounds);
  };

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  useEffect(() => {
    let locations = props.location.state?.mapLocations;
    console.log("map locations: ", locations);
    if (!Lodash.isEmpty(locations)) {
      console.log("map locations 1: ", locations);
      setMapLocations(locations);
    }
  }, [])

  return (
    <>
      <PageHeader />

      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow border-0">

              <CardHeader className="border-0">
                <div className="row">
                  <div className="mb-xl-0 col-10">
                    <h3 className="mb-0">{props.location.state?.title}</h3>
                    <h6 className="mb-0">Map Locations</h6>
                  </div>
                </div>
              </CardHeader>

              <div
                style={{ height: `600px` }}
                className="map-canvas"
                id="map-canvas"
              >
                <GoogleMap
                  onLoad={handleOnLoad}
                  onClick={() => setActiveMarker(null)}
                  mapContainerStyle={{ width: "100%", height: "100%" }}
                >
                  {mapLocations && mapLocations.map((item, position) => (
                    <Marker
                      key={position}
                      position={(item.coordinate && Lodash.isNumber(item.coordinate.latitude) && Lodash.isNumber(item.coordinate.longitude)) ? { lat: item.coordinate.latitude, lng: item.coordinate.longitude } : null}
                      onClick={() => handleActiveMarker(position)}
                    >
                      {console.log(item)}
                      {console.log(item.coordinate.latitude, item.coordinate.longitude)}
                      {activeMarker === position ? (
                        <InfoWindow onCloseClick={() => setActiveMarker(position)}>
                          <>
                            {/* {item.key ? <div><b>Key:</b> {item.key}</div> : null} */}
                            {item.title ? <div><b>Title:</b> {item.title}</div> : null}
                            {item.description ? <div><b>Description:</b> {item.description}</div> : null}
                            {item.workflowid ? <div><b>Workflow Id:</b> {item.workflowid}</div> : null}
                          </>
                        </InfoWindow>
                      ) : null}
                    </Marker>
                  ))}
                </GoogleMap>

              </div>

              <CardFooter className="py-4">
                <Row>
                  <Col lg="6" className="text-left">
                    <Button
                      style={MbppStyles.GeneralButton}
                      color="primary"
                      onClick={() => { history.goBack(); }}>
                      BACK
                    </Button>
                  </Col>
                  <Col lg="6" className="text-right">
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default MapsLocationDetails;
