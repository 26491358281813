import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Moment from "moment";
import Lodash from "lodash";

// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Button,
  FormGroup,
  Form
} from "reactstrap";

import { TextField, FormControlLabel, Switch, Grid, Tooltip } from '@material-ui/core';

import { fetchBreedingAllSites, fetchBreedingByOptions, deleteBreedingComplain } from 'actions/breeding';
import { MbppStyles } from 'constants/globalStyles';
import { basicInformation } from "globalGeoInformation"
import { BreedingComplainStatus } from "helper"

// core components
import PageHeader from "components/Headers/PageHeader.js";
import { CollectionsBookmark } from "@material-ui/icons";
import { isNonNullChain } from "typescript";

const TableRow = ({ complain, /*id, title, place, severity, status, description,*/ editClick, deleteClick }) => (
  <tr id={complain.id}>
    <td>{Moment(complain.date).format("YYYY-MM-DD")}</td>
    <th className="mb-0 text-sm">{complain.title}</th>
    <td>{complain.place}</td>
    <td>{complain.severity}</td>
    <td>{complain.status}</td>
    {/* <td style={{ 'whiteSpace': 'break-spaces' }}>{description}</td> */}
    <td className="text-right">
      <div className="row">
        <Tooltip title="Edit" arrow>
          <div className="navbar-toggler"
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              editClick(e, complain)
            }}>
            <i className="far fa-edit" />
          </div>
        </Tooltip>
        <Tooltip title="Delete" arrow>
          <div className="navbar-toggler"
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              deleteClick(e, complain)
            }}>
            <i className="far fa-times-circle" />
          </div>
        </Tooltip>
      </div>
    </td>
  </tr>
);

const validationSchemaActivity = yup.object({
  startDay: yup
    .date('Enter start date')
    .required('Activity start date is required'),
  endDay: yup
    .date('Enter end date')
    .required('Activity end date is required'),
});

const BreedingAllComplain = () => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();

  const [searchParams, setSearchParams] = useState({})

  useEffect(() => {
    // fetching breeding site complains
    // dispatch(fetchBreedingAllSites());
  }, []);

  const breedingSiteComplaintsAll = useSelector((state) => state.breedingsite.breedingSiteComplaintsAll);
  console.log('############### breedingSiteComplaintsAll', breedingSiteComplaintsAll)
  const handleEditClick = (e, id) => {
    e.preventDefault();
    history.push({ pathname: '/registered/breeding_complain_from', state: { breedingId: id, searchParams: searchParams } })
  }

  const handleDeleteClick = (e, complain) => {
    e.preventDefault();
    console.log("delete complain: ", complain);
    dispatch(deleteBreedingComplain({ _id: complain._id, searchParams: searchParams }, history))
  }

  const formik = useFormik({
    initialValues: {
      startDay: '',
      endDay: '',
      moh: null,
      status: null,
      deleted: false,
    },
    validationSchema: validationSchemaActivity,
    onSubmit: (values, onSubmitProps) => {
      console.log("formik values: ", values);
      dispatch(fetchBreedingByOptions(values, history));
      setSearchParams(values);
    },
  });

  return (
    <>
      <PageHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table School */}
        <Row>
          <Col>
            <Card className="shadow">

              <CardHeader className="border-0">
                <h3 className="mb-0">Breeding Site Reports</h3>
                <Form role="form" onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                  <FormGroup className="mt-5">

                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={5} sm={5}>
                        <TextField
                          fullWidth
                          type="date"
                          id="startDay"
                          name="startDay"
                          label="Start Date*"
                          variant="outlined"
                          format={'DD/MM/YYYY'}
                          value={formik.values.startDay}
                          onChange={formik.handleChange}
                          InputLabelProps={{ shrink: true, }}
                          error={formik.touched.startDay && Boolean(formik.errors.startDay)}
                          helperText={formik.touched.startDay && formik.errors.startDay}
                        />
                      </Grid>
                      <Grid item xs={5} sm={5}>
                        <TextField
                          fullWidth
                          type="date"
                          id="endDay"
                          name="endDay"
                          label="End Date* (Not including the day)"
                          variant="outlined"
                          format={'DD/MM/YYYY'}
                          value={formik.values.endDay}
                          onChange={formik.handleChange}
                          InputLabelProps={{ shrink: true, }}
                          error={formik.touched.endDay && Boolean(formik.errors.endDay)}
                          helperText={formik.touched.endDay && formik.errors.endDay}
                        />
                      </Grid>
                      <Grid item xs={2} sm={2} className="text-center">
                        <Button className="my-2" variant="contained" color="primary" type="submit">
                          Load
                        </Button>
                      </Grid>
                    </Grid>

                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={5} sm={5}>
                        <TextField
                          select
                          fullWidth
                          id="moh"
                          name="moh"
                          label="MOH"
                          variant="outlined"
                          value={`${formik.values.moh}`}
                          onChange={formik.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          error={formik.touched.moh && Boolean(formik.errors.moh)}
                          helperText={formik.touched.moh && formik.errors.moh}
                        >
                          <option value=''></option>
                          {Object.entries(basicInformation.mohIds).map(([key, value]) => (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={5} sm={5}>
                        <TextField
                          select
                          fullWidth
                          id="status"
                          name="status"
                          label="Status"
                          variant="outlined"
                          value={`${formik.values.status}`}
                          onChange={formik.handleChange}
                          SelectProps={{ native: true, }}
                          error={formik.touched.status && Boolean(formik.errors.status)}
                          helperText={formik.touched.status && formik.errors.status}
                        >
                          <option value=''></option>
                          {Object.entries(BreedingComplainStatus).map(([key, value]) => (
                            <option key={value} value={value}>
                              {value}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>

                  </FormGroup>
                </Form>
              </CardHeader>


              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Title</th>
                    <th scope="col">Place</th>
                    <th scope="col">Severity</th>
                    <th scope="col">Status</th>
                    {/* <th scope="col">description</th> */}
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {breedingSiteComplaintsAll.length > 0 && Lodash.filter(breedingSiteComplaintsAll, { deleted: false }).map((props, i) => {
                    return (
                      <TableRow
                        key={i}
                        // id={props._id}
                        // title={props.title}
                        // place={props.place}
                        // severity={props.severity}
                        // status={props.status}
                        // description={props.description}
                        complain={props}
                        editClick={handleEditClick}
                        deleteClick={handleDeleteClick}
                      />
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BreedingAllComplain;
