import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { Typography, Avatar } from '@material-ui/core';
import { FormControl, TextField, Select, InputLabel, Grid, InputAdornment, IconButton, MenuItem, ListItemIcon, ListItemText, Checkbox, Chip } from '@material-ui/core';

import Config from "config/config.json";

import { fetchAllProfiles, updateProfile } from 'actions/user';

import { ages, genders, titles, emp_types, CryptoPassword, phoneRegExp, FILE_IMG_SIZE, SUPPORTED_IMG_FORMATS, Camelize, useMultiSelectStyles } from "helper.js"

import Lodash from "lodash";
import Moment from "moment";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  Col,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  Media,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
// core components
import PageHeader from "components/Headers/PageHeader.js";
import UserHeader from "components/Headers/UserHeader.js";

import { useFormik } from 'formik';
import * as yup from 'yup';

import { useLocation, useHistory } from 'react-router-dom';
import { MbppStyles } from 'constants/globalStyles';

import { basicInformation } from "globalGeoInformation";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import * as api from 'api/index';
import Pagination from 'react-responsive-pagination';


const User = () => {

  // get state all user profiles
  const alluserprofiles = useSelector((state) => state.user.usersProfiles);
  console.log('alluserprofiles: ', alluserprofiles);

  const dispatch = useDispatch();
  const history = useHistory();

  const [userDetails, setUserDetails] = useState({});
  const [userProfile, setUserProfile] = useState({});
  const [orderedUsers, setOrderedUsers] = useState([])
  // Show User or edit profile
  const [editSection, setEditSection] = useState("USERS");
  // set state edit
  const [isEdit, setIsEdit] = useState(true);
  const [contactList, setContactList] = useState([{ index: 0, contactno: "" }]);

  // multi select
  const classes = useMultiSelectStyles();
  const [mohsSelected, setMohsSelected] = useState([]);
  const [wardsSelected, setWardsSelected] = useState([]);
  const isMohsAllSelected = (Object.entries(basicInformation.mohIds)).length > 0 && mohsSelected.length === (Object.entries(basicInformation.mohIds)).length;
  const isWardsAllSelected = (Object.entries(basicInformation.wardIds)).length > 0 && wardsSelected.length === (Object.entries(basicInformation.wardIds)).length;


  // pagination 
  const itemPageSize = Config.ITEM_PAGE_SIZE;
  const [itemCurrent, setItemCurrent] = useState(1);
  const [itemPageContent, setItemPageContent] = useState([]);
  const [itemPageCount, setItemPageCount] = useState(1);
  // filter
  const [txtFilter, setTxtFilter] = useState("");
  const [filterdContent, setFilterdContent] = useState([]);


  useEffect(() => {
    // fetching all user profiles
    console.log("getting all the users");
    dispatch(fetchAllProfiles());
  }, []);


  // process content with filter
  useEffect(() => {
    const orderByUsers = Lodash.orderBy(alluserprofiles, ['createdAt'], ['asc'])
    console.log("orderByUsers: ", orderByUsers);
    setOrderedUsers(orderByUsers)
  }, [alluserprofiles])

  useEffect(() => {
    // 1. filter the users form the filter 
    let updatedUsers = Lodash.filter(orderedUsers, function (item) {
      return item.urole === "GENERAL" && item.email.indexOf(txtFilter) > -1;
    });
    setFilterdContent(updatedUsers)
  }, [orderedUsers, txtFilter]);

  // process the pagination with page content
  useEffect(() => {
    console.log("filterdContent.length: ", filterdContent.length)
    // Set the pagination count
    let currentPageCount = Math.ceil(filterdContent.length / itemPageSize);
    setItemPageCount(currentPageCount);

    // Set the pagination content 
    if (filterdContent && filterdContent.length > 0) {
      // make sure the page doesn't exceed the array endings
      let startCount = ((itemCurrent - 1) * itemPageSize) < 0 ? 0 : ((itemCurrent - 1) * itemPageSize);
      let endCount = (itemCurrent * itemPageSize) > filterdContent.length ? filterdContent.length : (itemCurrent * itemPageSize);

      let updatedArray = Lodash.slice(filterdContent, startCount, endCount);
      if (updatedArray && updatedArray.length > 0) {
        setItemPageContent(updatedArray);
      }
      else {
        setItemPageContent([])
      }
    }
    else {
      setItemPageContent([])
    }
  }, [itemCurrent, filterdContent]);

  ///////////////////////////////////




  const UserTableRow = ({ rowId, id, email, role, status, created, profile }) => (
    <tr id={id} key={id}>
      {/* <td className="text-right" style={{ margin: 0, padding: 0 }}>
        <UncontrolledDropdown>
          <DropdownToggle
            className="btn-icon-only text-light"
            href="#pablo"
            role="button"
            size="sm"
            color=""
            onClick={(e) => e.preventDefault()}
          >
            <i className="fas fa-ellipsis-v" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right>
            <DropdownItem
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              Change status
            </DropdownItem>
            <DropdownItem
              href="#pablo"
              onClick={(e) => {
                setUserDetails({ user: { id: id, email: email } });
                setUserProfile(profile);
              }}
            >
              Manage User
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </td> */}
      <th scope="row" style={{ /*marginLeft: 0, paddingLeft: 0*/ }}>
        <Media className="align-items-center">
          <div style={{ marginRight: 10 }}>{`${(rowId + 1) + (itemCurrent - 1) * itemPageSize}.`}</div>
          <Avatar
            className='purple'
            alt={profile ? profile.firstname : ""}
            src={(profile && !Lodash.isEmpty(profile.profilepic)) ? (Config.SERVER_API_URL + Config.PUBLIC_PROF_PIC + profile.profilepic) : null}
            style={{ marginRight: 5 }}>
            {profile?.firstname ? profile.firstname.charAt(0).toUpperCase() : ""}
          </Avatar>
          <div>
            {email}
            <Media>
              <span className="mb-0 text-sm">
                {profile && !Lodash.isEmpty(profile.firstname) ? profile.title + ". " + Camelize(profile.firstname + " " + profile.lastname) : ""}
              </span>
            </Media>
          </div>

        </Media>
      </th>
      {/* <td>{role}</td> */}
      <td>{Moment(created).format('YYYY-MM-DD HH:mm')}</td>
      <td>{(profile && profile.employment && profile.employment.type) ? profile.employment.type : ""}</td>
      <td>
        {status ?
          <Badge color="" className="badge-dot">
            <i className="bg-success" />
            Active
          </Badge>
          :
          <Badge color="" className="badge-dot mr-4">
            <i className="bg-warning" />
            Inactive
          </Badge>
        }
      </td>
      <td /*className="text-left"*/ style={{ margin: 0, padding: 0 }}>
        <div className="navbar-toggler"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setUserDetails({ user: { id: id, email: email, status: status } });
            setUserProfile(profile);
          }}>
          <i className="fas fa-angle-right" />
        </div>
      </td>
    </tr>
  );


  // Profile edit start -------------------------------------------


  useEffect(() => {

    if (userProfile && Object.keys(userProfile).length > 0) {

      formik.values.userId = userDetails?.user.id || '';
      formik.values.profileId = userProfile?._id || '';
      formik.values.firstname = userProfile?.firstname || '';
      formik.values.lastname = userProfile?.lastname || '';
      formik.values.email = userDetails?.user.email || '';
      formik.values.age = userProfile?.age || '';
      formik.values.gender = userProfile?.gender || '';
      formik.values.title = userProfile?.title || '';
      formik.values.telephone = userProfile?.telephone || '';
      formik.values.address = userProfile?.address || '';
      formik.values.description = userProfile?.description || '';
      formik.values.profilepic = userProfile?.profilepic || '';
      formik.values.profilethumbnail = userProfile?.profilethumbnail || '';
      formik.values.emp_type = userProfile?.employment?.type || '';
      formik.values.emp_job = userProfile?.employment?.job || '';
      formik.values.emp_institute = userProfile?.employment?.institute || '';
      formik.values.emp_department = userProfile?.employment?.department || '';
      formik.values.emp_telephone = userProfile?.employment?.telephone || '';
      formik.values.emp_email = userProfile?.employment?.email || '';
      formik.values.emp_address = userProfile?.employment?.address || '';
      formik.values.emp_designation = userProfile?.employment?.designation || '';
      formik.values.emp_regno = userProfile?.employment?.regno || '';
      formik.values.referenceNo = userProfile?.employment?.referenceNo || '';
      formik.values.notificationNo = userProfile?.employment?.notificationNo || '';
      formik.values.emp_region_name = userProfile?.employment?.region.name || '';
      formik.values.emp_region_code = userProfile?.employment?.region.code || '';
      formik.values.emp_region_wards = userProfile?.employment?.region.wards || [];
      formik.values.emp_region_mohs = userProfile?.employment?.region.mohs || [];
      formik.values.affiliation = userProfile?.employment?.affiliation || '';

      // Geographical information
      if (userProfile?.employment?.region.wards) {
        console.log('init ward list: ', wardsSelected)
        setWardsSelected(userProfile?.employment?.region.wards)
        console.log('init ward list: ', wardsSelected)
      }
      if (userProfile?.employment?.region.mohs) {
        setMohsSelected(userProfile?.employment?.region.mohs)
        console.log(mohsSelected)
      }

      // Contact information
      if (userProfile?.telephone?.length > 0) {
        const list = [...contactList];
        for (const [key, value] of Object.entries(userProfile?.telephone)) {
          list[key]['contactno'] = value;
        }
        setContactList(list);
      }
    }
  }, [userProfile]);



  const handleMohMultiSelectChange = (event) => {
    const value = event.target.value;
    let updatedMohs = [];
    if (value[value.length - 1] === "all") {
      updatedMohs = (mohsSelected.length === (Object.entries(basicInformation.mohIds)).length ? [] : Object.keys(basicInformation.mohIds));
    }
    else {
      updatedMohs = value;
    }
    setMohsSelected(updatedMohs);
    formik.values.emp_region_mohs = updatedMohs;
    console.log('selected moh items', updatedMohs);
  }

  const handleWardMultiSelectChange = (event) => {
    const value = event.target.value;
    let updatedWards = [];
    if (value[value.length - 1] === "all") {
      updatedWards = (wardsSelected.length === (Object.entries(basicInformation.wardIds)).length ? [] : Object.keys(basicInformation.wardIds));
    }
    else {
      updatedWards = value;
    }
    setWardsSelected(updatedWards);
    formik.values.emp_region_wards = updatedWards;
    console.log('selected ward items', updatedWards);
  }

  const changeUserStatus = async (user, status) => {

    const params = { user: user, status: status };

    console.log('params: ', params);
    const { data } = await api.changeStatus(params);
    console.log('data: ', data);

    if (data?.status === 'success') {
      dispatch(fetchAllProfiles());
      setEditSection("USERS");
      setUserProfile({});
    }
    if (data?.status === 'error') {
      console.log(data);
      confirmAlert({ message: "User status change failed.", buttons: [{ label: 'Ok', onClick: () => { } }] });
    }
  }

  const formik = useFormik({
    initialValues: {
      userId: '',
      profileId: '',
      email: '',
      firstname: '',
      lastname: '',
      age: '',
      gender: '',
      title: '',
      profilepic: '',
      profilethumbnail: '',
      newProfilePicture: false,
      coverpic: '',
      telephone: '',
      address: '',
      description: '',
      emp_type: '',
      emp_job: '',
      emp_institute: '',
      emp_department: '',
      emp_telephone: '',
      emp_email: '',
      emp_address: '',
      emp_designation: '',
      emp_regno: '',
      emp_referenceNo: '',
      emp_notificationNo: '',
      emp_region_name: '',
      emp_region_code: '',
      emp_region_wards: null,
      emp_region_mohs: null
    },
    // validationSchema: validationSchema,
    onSubmit: (values, onSubmitProps) => {
      values["update_by_admin"] = true;
      values["user"] = userDetails?.user?.id;
      console.log('Update', values);
      dispatch(updateProfile(values, false, history));
    }
  });

  // Profile edit end -------------------------------------------

  return (
    <>
      <PageHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>

        <Row>

          {/* Left panel */}
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            {(!Lodash.isEmpty(userProfile) &&
              <Card className="card-profile shadow">
                <Row className="justify-content-center">

                  <Col className="order-lg-2" lg="3" style={{ marginLeft: -20 }}>
                    <div className="card-profile-image">
                      <div className="rounded-circle"
                        style={{ backgroundImage: 'url("' + Config.SERVER_API_URL + Config.PUBLIC_PROF_PIC + userProfile?.profilepic + '")', backgroundColor: "#bdbdbd" }}>
                        {(userProfile?.profilepic || Lodash.isEmpty(userProfile?.firstname)) ? "" : userProfile?.firstname.charAt(0).toUpperCase()}
                      </div>
                    </div>
                  </Col>

                </Row>
                <Row style={{ paddingTop: 130, margin: 10, }}>

                  <Col className="d-flex justify-content-between order-xl-2 mb-5 mb-xl-0" style={{ padding: 0 }}>
                    <div></div>
                    <Button
                      className="mr-4"
                      color="primary"
                      href="#pablo"
                      onClick={(e) => { setEditSection("PROFILE") }}
                      size="sm"
                      // style={{ minWidth: 130 }}
                      style={{ width: 120, paddingTop: 3, paddingBottom: 3 }}
                    >
                      Upadate Profile
                    </Button>
                    <Button
                      className="float-right"
                      color="primary"
                      href="#pablo"
                      onClick={(e) => { changeUserStatus(userDetails?.user?.id, !userDetails?.user?.status) }}
                      size="sm"
                      style={{ width: 120, paddingTop: 3, paddingBottom: 3 }}
                    >
                      Change Status
                    </Button>
                    <div></div>
                  </Col>

                </Row>
                <CardBody className="pt-0 pt-md-4">
                  <div className="text-center"><h3> </h3></div>
                  <div className="text-center">
                    <h3>
                      {Camelize(userProfile?.title + '. ' + userProfile?.firstname + ' ' + userProfile?.lastname)}
                    </h3>
                    <div className="h5 font-weight-300">
                      {contactList[0].contactno + ' | ' + userDetails?.user?.email}
                    </div>
                    <div className="h4 font-weight-300">
                      {userProfile?.address}
                    </div>
                    <br />

                    <div className="h4 font-weight-300 form-check-label">
                      {userProfile?.employment?.type}
                    </div>
                    <div>
                      {userProfile?.employment?.designation}
                    </div>
                    <br />

                    <div className="h5 font-weight-300 form-check-label">
                      Institute
                    </div>
                    <div>
                      {userProfile?.employment?.institute}
                    </div>
                    <br />

                    <div className="h5 font-weight-300 form-check-label">
                      Department
                    </div>
                    <div>
                      {userProfile?.employment?.department}
                    </div>
                    <br />

                    <div className="h5 font-weight-300 form-check-label">
                      Working Address
                    </div>
                    <div>
                      {userProfile?.employment?.address}
                    </div>
                    <br />

                    <div className="h5 font-weight-300 form-check-label">
                      Designation
                    </div>
                    <div>
                      {userProfile?.employment?.designation}
                    </div>
                    <br />

                    {/* <div className="h5 font-weight-300 form-check-label">
                      MOH Details
                    </div>
                    <div>
                      {userProfile?.employment?.region?.mohs && userProfile?.employment?.region?.mohs.map((moh) => {
                        return basicInformation.mohIds[moh] + ', '
                      })}
                    </div>
                    <br />

                    <div className="h5 font-weight-300 form-check-label">
                      Ward Details
                    </div>
                    <div>
                      {userProfile?.employment?.region?.wards && userProfile?.employment?.region?.wards.map((ward) => {
                        return basicInformation.wardIds[ward] + ', '
                      })}
                    </div> */}
                    <br />
                  </div>
                </CardBody>
              </Card>
            )}
          </Col>


          {/* Right panel */}

          {/* Table user details */}
          <Col className="order-xl-1" xl={Lodash.isEmpty(userProfile) ? "12" : "8"} style={{ display: editSection === "USERS" ? 'block' : 'none' }}>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row>
                      <Col md="6">
                        <h3 className="mb-0">Public User Profiles</h3>
                      </Col>
                      <Col md="6">
                        <FormGroup style={{ marginBottom: 0 }}>
                          <InputGroup className="input-group-alternative" >
                            <InputGroupAddon addonType="prepend" >
                              <InputGroupText>
                                <i className="ni ni-zoom-split-in" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              className="form-control-alternative"
                              placeholder="By Email"
                              type="text"
                              onChange={(e) => {
                                console.log(e.target.value);
                                setTxtFilter(e.target.value);
                              }}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        {/* <th scope="col" /> */}
                        <th scope="col">
                          <div
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              const orderByUsers = Lodash.orderBy(alluserprofiles, ['email'], ['asc'])
                              setOrderedUsers(orderByUsers)
                            }}>User (email)</div>
                        </th>
                        <th scope="col">
                          <div
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              const orderByUsers = Lodash.orderBy(alluserprofiles, ['createdAt'], ['asc'])
                              setOrderedUsers(orderByUsers)
                            }}>created</div>
                        </th>
                        <th scope="col">Category</th>
                        <th scope="col">Status</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {itemPageContent && (itemPageContent.length > 0) && itemPageContent.map((props, i) => {
                        console.log(i, props);
                        return (
                          <UserTableRow
                            key={i}
                            rowId={i}
                            id={props._id}
                            email={props.email}
                            role={props.urole}
                            status={props.status.isActive}
                            created={props.createdAt}
                            profile={(props.profile) ? props.profile : null}
                          />
                        );
                      })}
                    </tbody>
                  </Table>

                  {/* pagination */}
                  <CardFooter>
                    <Row>
                      <Col lg="12">
                        <Pagination
                          current={itemCurrent}
                          total={itemPageCount}
                          onPageChange={(current) => { setItemCurrent(current); }}
                        />
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </Col>

          {/* Update profile */}
          <Col className="order-xl-1" xl="8" style={{ display: editSection === "PROFILE" ? 'block' : 'none' }}>
            <Card className="bg-secondary shadow">

              <CardBody>
                <Form
                  role="form"
                  onSubmit={formik.handleSubmit}
                  onReset={formik.handleReset}>

                  <div className="heading-title mb-4" style={MbppStyles.Topic}>
                    Update Profile
                  </div>

                  <br />
                  <hr className="my-2" />
                  <h6 className="heading-small mb-4" style={MbppStyles.SubTopic}>
                    Personal Details
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormControl fullWidth>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email address*
                          </label>
                          <TextField style={MbppStyles.Input}
                            fullWidth
                            id="email"
                            name="email"
                            variant="outlined"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                            className="form-control-alternative form-control-display form-control-css"
                            InputProps={{ readOnly: true, disabled: true }}
                          />
                        </FormControl>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormControl fullWidth>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            First Name*
                          </label>
                          <TextField style={MbppStyles.Input}
                            fullWidth
                            id="firstname"
                            name="firstname"
                            variant="outlined"
                            value={formik.values.firstname}
                            onChange={formik.handleChange}
                            error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                            helperText={formik.touched.firstname && formik.errors.firstname}
                            className="form-control-alternative form-control-display form-control-css"
                            InputProps={{ readOnly: true, disabled: true }}
                          />
                        </FormControl>
                      </Col>
                      <Col lg="6">
                        <FormControl fullWidth>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Last Name
                          </label>
                          <TextField style={MbppStyles.Input}
                            fullWidth
                            id="lastname"
                            name="lastname"
                            variant="outlined"
                            value={formik.values.lastname}
                            onChange={formik.handleChange}
                            error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                            helperText={formik.touched.lastname && formik.errors.lastname}
                            className="form-control-alternative form-control-display form-control-css"
                            InputProps={{ readOnly: true, disabled: true }}
                          />
                        </FormControl>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormControl fullWidth>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Salutation*
                          </label>
                          <TextField style={MbppStyles.Input}
                            select
                            fullWidth
                            id="title"
                            name="title"
                            variant="outlined"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            SelectProps={{
                              native: true,
                            }}
                            error={formik.touched.title && Boolean(formik.errors.title)}
                            helperText={formik.touched.title && formik.errors.title}
                            className="form-control-alternative form-control-display form-control-css"
                            InputProps={{ readOnly: true, disabled: true }}
                          >
                            {titles.map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </TextField>
                        </FormControl>
                      </Col>
                      <Col lg="4">
                        <FormControl fullWidth>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Gender*
                          </label>
                          <TextField style={MbppStyles.Input}
                            select
                            fullWidth
                            id="gender"
                            name="gender"
                            variant="outlined"
                            value={formik.values.gender}
                            onChange={formik.handleChange}
                            SelectProps={{
                              native: true,
                            }}
                            error={formik.touched.gender && Boolean(formik.errors.gender)}
                            helperText={formik.touched.gender && formik.errors.gender}
                            className="form-control-alternative form-control-display form-control-css"
                            InputProps={{ readOnly: true, disabled: true }}
                          >
                            <option value=''></option>
                            {genders.map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </TextField>
                        </FormControl>
                      </Col>
                      <Col lg="4">
                        <FormControl fullWidth>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Age
                          </label>
                          <TextField style={MbppStyles.Input}
                            select
                            fullWidth
                            id="age"
                            name="age"
                            variant="outlined"
                            value={formik.values.age}
                            onChange={formik.handleChange}
                            SelectProps={{
                              native: true,
                            }}
                            error={formik.touched.age && Boolean(formik.errors.age)}
                            helperText={formik.touched.age && formik.errors.age}
                            className="form-control-alternative form-control-display form-control-css"
                            InputProps={{ readOnly: true, disabled: true }}
                          >
                            <option value=''></option>
                            {ages.map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </TextField>
                        </FormControl>
                      </Col>
                    </Row>
                  </div>

                  {/* Address */}
                  <div className="pl-lg-4">
                    <Row>
                      {contactList.map((x, i) => {
                        return (

                          <Col lg="4" key={i}>
                            <FormControl fullWidth>
                              <label
                                className="form-control-label"
                                htmlFor="input-address"
                              >
                                {/* {'Contact Number #' + (1 + i)} */}
                                {'Contact Number'}
                              </label>
                              <TextField style={MbppStyles.Input}
                                fullWidth
                                id="contactno"
                                name="contactno"
                                // label={'Contact Number #'+ (1 + i)}
                                variant="outlined"
                                value={x.contactno}
                                onChange={e => { }}
                                error={formik.touched.telephone && Boolean(formik.errors.telephone)}
                                helperText={formik.touched.telephone && formik.errors.telephone}
                                InputProps={!isEdit ? {
                                  endAdornment: (
                                    <>
                                      {/* <InputAdornment position="end">
                                        {contactList.length !== 1 && <RemoveCircleOutlineIcon className="mr10" onClick={() => handleRemoveClick(i)} />}
                                        {(contactList.length - 1 === i && !(contactList.length > 4)) && <AddCircleOutlineIcon className="ma10" onClick={handleAddClick} />}
                                      </InputAdornment> */}
                                    </>
                                  ),
                                } : null}
                                className="form-control-alternative form-control-display form-control-css"
                                InputProps={{ readOnly: true, disabled: true }}
                              />
                            </FormControl>
                          </Col>
                        );
                      })}
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormControl fullWidth>
                          <label
                            className="form-control-label"
                            htmlFor="input-address"
                          >
                            Resident Address*
                          </label>
                          <TextField style={MbppStyles.Input}
                            fullWidth
                            id="address"
                            name="address"
                            variant="outlined"
                            value={formik.values.address}
                            onChange={formik.handleChange}
                            multiline rows={2}
                            error={formik.touched.address && Boolean(formik.errors.address)}
                            helperText={formik.touched.address && formik.errors.address}
                            className="form-control-alternative form-control-display form-control-css"
                            InputProps={{ readOnly: true, disabled: true }}
                          />
                        </FormControl>
                      </Col>
                    </Row>
                  </div>


                  <br />
                  <hr className="my-2" />
                  <h6 className="heading-small mb-4" style={MbppStyles.SubTopic}>
                    Occupation Details
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormControl fullWidth>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Workplace Category*
                          </label>
                          <TextField style={MbppStyles.Input}
                            select
                            fullWidth
                            id="emp_type"
                            name="emp_type"
                            variant="outlined"
                            value={formik.values.emp_type}
                            onChange={formik.handleChange}
                            SelectProps={{
                              native: true,
                            }}
                            error={formik.touched.emp_type && Boolean(formik.errors.emp_type)}
                            helperText={formik.touched.emp_type && formik.errors.emp_type}
                            className={isEdit ? "form-control-alternative form-control-edit" : "form-control-alternative form-control-display"}
                            InputProps={{ readOnly: !isEdit, disabled: !isEdit }}
                          >
                            <option value=''></option>
                            {emp_types.map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </TextField>
                        </FormControl>
                      </Col>
                      <Col lg="6">
                        <FormControl fullWidth>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Working Institute
                          </label>
                          <TextField style={MbppStyles.Input}
                            fullWidth
                            id="emp_institute"
                            name="emp_institute"
                            variant="outlined"
                            value={formik.values.emp_institute}
                            onChange={formik.handleChange}
                            error={formik.touched.emp_institute && Boolean(formik.errors.emp_institute)}
                            helperText={formik.touched.emp_institute && formik.errors.emp_institute}
                            className={isEdit ? "form-control-alternative form-control-edit" : "form-control-alternative form-control-display"}
                            InputProps={{ readOnly: !isEdit, disabled: !isEdit }}
                          />
                        </FormControl>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormControl fullWidth>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Working Department
                          </label>
                          <TextField style={MbppStyles.Input}
                            fullWidth
                            id="emp_department"
                            name="emp_department"
                            variant="outlined"
                            value={formik.values.emp_department}
                            onChange={formik.handleChange}
                            error={formik.touched.emp_department && Boolean(formik.errors.emp_department)}
                            helperText={formik.touched.emp_department && formik.errors.emp_department}
                            className={isEdit ? "form-control-alternative form-control-edit" : "form-control-alternative form-control-display"}
                            InputProps={{ readOnly: !isEdit, disabled: !isEdit }}
                          />
                        </FormControl>
                      </Col>
                      <Col lg="6">
                        <FormControl fullWidth>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Working Designation
                          </label>
                          <TextField style={MbppStyles.Input}
                            fullWidth
                            id="emp_designation"
                            name="emp_designation"
                            variant="outlined"
                            value={formik.values.emp_designation}
                            onChange={formik.handleChange}
                            error={formik.touched.emp_designation && Boolean(formik.errors.emp_designation)}
                            helperText={formik.touched.emp_designation && formik.errors.emp_designation}
                            className={isEdit ? "form-control-alternative form-control-edit" : "form-control-alternative form-control-display"}
                            InputProps={{ readOnly: !isEdit, disabled: !isEdit }}
                          />
                        </FormControl>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormControl fullWidth>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Workplace Address*
                          </label>
                          <TextField style={MbppStyles.Input}
                            fullWidth
                            id="emp_address"
                            name="emp_address"
                            variant="outlined"
                            value={formik.values.emp_address}
                            onChange={formik.handleChange}
                            error={formik.touched.emp_address && Boolean(formik.errors.emp_address)}
                            helperText={formik.touched.emp_address && formik.errors.emp_address}
                            className={isEdit ? "form-control-alternative form-control-edit" : "form-control-alternative form-control-display"}
                            InputProps={{ readOnly: !isEdit, disabled: !isEdit }}
                          />
                        </FormControl>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormControl fullWidth>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Affiliation Type*
                          </label>
                          <TextField style={MbppStyles.Input}
                            fullWidth
                            id="emp_regno"
                            name="emp_regno"
                            variant="outlined"
                            value={formik.values.emp_regno}
                            onChange={formik.handleChange}
                            error={formik.touched.emp_regno && Boolean(formik.errors.emp_regno)}
                            helperText={formik.touched.emp_regno && formik.errors.emp_regno}
                            className={isEdit ? "form-control-alternative form-control-edit" : "form-control-alternative form-control-display"}
                            InputProps={{ readOnly: !isEdit, disabled: !isEdit }}
                          />
                        </FormControl>
                      </Col>
                    </Row>
                  </div>


                  {/* <br />
                  <hr className="my-2" />
                  <h6 className="heading-small mb-4" style={MbppStyles.SubTopic}>
                    Geographic Details
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormControl fullWidth disabled={!isEdit}>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Wards
                          </label>
                          <Select style={MbppStyles.Input}
                            fullWidth
                            labelId="mutiple-select-label"
                            multiple
                            variant="outlined"
                            value={wardsSelected}
                            onChange={handleWardMultiSelectChange}
                            renderValue={(wardsSelected) => (
                              <div style={{ display: 'flex', flexWrap: 'wrap', }}>
                                {(wardsSelected).map((key) => (
                                  <Chip key={basicInformation.wardIds[key]} label={basicInformation.wardIds[key]} style={{ margin: 2 }} />
                                ))}
                              </div>
                            )}
                            error={formik.touched.emp_region_wards && Boolean(formik.errors.emp_region_wards)}
                            helperText={formik.touched.emp_region_wards && formik.errors.emp_region_wards}
                            className={isEdit ? "form-control-alternative form-control-edit" : "form-control-alternative form-control-display"}
                            InputProps={{ readOnly: !isEdit, disabled: !isEdit }}
                          >
                            <MenuItem
                              value="all"
                              className={{
                                root: isWardsAllSelected ? classes.selectedAll : ""
                              }}
                            >
                              <ListItemIcon>
                                <Checkbox
                                  className={{ indeterminate: classes.indeterminateColor }}
                                  checked={isWardsAllSelected}
                                  indeterminate={
                                    wardsSelected.length > 0 && wardsSelected.length < Object.entries(basicInformation.wardIds).length
                                  }
                                />
                              </ListItemIcon>
                              <ListItemText
                                className={{ primary: classes.selectAllText }}
                                primary="Select All"
                              />
                            </MenuItem>
                            {Object.entries(basicInformation.wardIds).map(([key, value]) =>
                            (
                              <MenuItem key={key} value={key}>
                                <ListItemIcon>
                                  <Checkbox checked={wardsSelected && wardsSelected.indexOf(key) > -1} />
                                </ListItemIcon>
                                <ListItemText primary={value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Col>
                      <Col lg="6">
                        <FormControl fullWidth disabled={!isEdit}>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            MOHS
                          </label>
                          <Select style={MbppStyles.Input}
                            fullWidth
                            labelId="mutiple-select-label"
                            multiple
                            variant="outlined"
                            value={mohsSelected}
                            onChange={handleMohMultiSelectChange}
                            renderValue={(mohsSelected) => (
                              <div style={{ display: 'flex', flexWrap: 'wrap', }}>
                                {(mohsSelected).map((key) => (
                                  <Chip key={basicInformation.mohIds[key]} label={basicInformation.mohIds[key]} style={{ margin: 2 }} />
                                ))}
                              </div>
                            )}
                            error={formik.touched.emp_region_mohs && Boolean(formik.errors.emp_region_mohs)}
                            helperText={formik.touched.emp_region_mohs && formik.errors.emp_region_mohs}
                            className={isEdit ? "form-control-alternative form-control-edit" : "form-control-alternative form-control-display"}
                            InputProps={{ readOnly: !isEdit, disabled: !isEdit }}
                          >
                            <MenuItem
                              value="all"
                              className={{
                                root: isMohsAllSelected ? classes.selectedAll : ""
                              }}
                            >
                              <ListItemIcon>
                                <Checkbox
                                  className={{ indeterminate: classes.indeterminateColor }}
                                  checked={isMohsAllSelected}
                                  indeterminate={
                                    mohsSelected.length > 0 && mohsSelected.length < Object.entries(basicInformation.mohIds).length
                                  }
                                />
                              </ListItemIcon>
                              <ListItemText
                                className={{ primary: classes.selectAllText }}
                                primary="Select All"
                              />
                            </MenuItem>
                            {Object.entries(basicInformation.mohIds).map(([key, value]) =>
                            (
                              <MenuItem key={key} value={key}>
                                <ListItemIcon>
                                  <Checkbox checked={mohsSelected && mohsSelected.indexOf(key) > -1} />
                                </ListItemIcon>
                                <ListItemText primary={value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Col>
                    </Row>
                    <p className="small mb-4">
                      * are mandatory fields
                    </p>
                  </div> */}

                  <br />
                  <div className="pl-lg-4">
                    <p className="small mb-4">
                      * are mandatory fields
                    </p>
                  </div>
                  <br />


                  <div className="d-flex justify-content-between order-xl-2 mb-5 mb-xl-0" style={{ padding: 0 }}>
                    <div>
                      <Button variant="contained" color="success" onClick={() => {
                        dispatch(fetchAllProfiles());
                        setEditSection("USERS");
                        setUserProfile({});
                      }}>
                        BACK
                      </Button>
                    </div>
                    <div className="text-center">
                      <Button variant="contained" color="primary" type="reset">
                        CLEAR
                      </Button>
                      <Button variant="contained" color="primary" type="submit">
                        UPDATE
                      </Button>
                    </div>
                  </div>

                </Form>
              </CardBody>

            </Card>
          </Col>

        </Row>

      </Container>
    </>
  );
};

export default User;
