
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import PageHeader from "components/Headers/PageHeader.js";
import Config from "config/config.json";
import { MbppStyles, GlobalStylesColor } from 'constants/globalStyles';
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// reactstrap components
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Form, Row } from "reactstrap";
import * as Yup from "yup";
import { ListItemIcon, TextField, Tooltip } from '@material-ui/core';
import { curdEMaterial, fetchEMaterial } from 'actions/education';
import Lodash from "lodash";


const reviewSchema = Yup.object({
  Order: Yup.number().required()
  , Title: Yup.string().required()
  , Description: Yup.string()
  // , File: Yup.object().shape({
  //   name: Yup.string().required()
  // })
});

const EducationalMaterialDetails = (props) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const formRef = useRef();

  const initArticle = { paragraph: null, imageUrl: "" };

  // set state edit
  const [isEdit, setIsEdit] = useState(false);

  const [currentArticles, setCurrentArticles] = useState([initArticle]);

  useEffect(() => {
    // check for material-id, if exist get the article. otherwise create new.
    let materialId = props.location.state?.materialId;
    console.log("materialId: ", materialId);
    if (!Lodash.isEmpty(materialId)) {
      let params = {
        materialId: materialId
      }
      dispatch(fetchEMaterial("FETCH_ALL", params, history));
    }
    else {
      setIsEdit(false);
      setCurrentArticles([initArticle]);
    }
  }, []);

  // get data
  const dataAll = useSelector((state) => state.education.educationMaterials);
  console.log('dataAll: ', dataAll);

  // process content with filter
  useEffect(() => {
    let materialId = props.location.state?.materialId; // to avoid fetching already existing data and update
    if (!Lodash.isEmpty(dataAll) && materialId) {
      // Fill formik
      if (formRef.current) {
        formRef.current.setFieldValue("materialId", dataAll[0]._id, false);
        formRef.current.setFieldValue("Title", dataAll[0].title, false);
        formRef.current.setFieldValue("Description", dataAll[0].description, false);
        formRef.current.setFieldValue("Order", dataAll[0].order, false);
        formRef.current.setFieldValue("Articles", dataAll[0].articles, false);
        formRef.current.setFieldValue("KeyWords", dataAll[0].keyWords, false);
        setCurrentArticles(dataAll[0].articles);
        setIsEdit(true);
      }
    }
  }, [dataAll]);

  // handle change event of comments
  const handleListChange = (e, index) => {
    const { name, value } = e.target;
    console.log("e target", e.target);
    const list = [...currentArticles];
    list[index][name] = value;

    if (formRef.current) {
      setCurrentArticles(list);
      formRef.current.setFieldValue("Articles", list, false);
    }
  };
  // handle click event of the Remove button of comment
  const handleRemoveClick = (index) => {
    const list = [...currentArticles];
    list.splice(index, 1);

    if (formRef.current) {
      setCurrentArticles(list);
      formRef.current.setFieldValue("Articles", list, false);
    }
  };
  // handle click event of the Add button of comment
  const handleAddClick = () => {
    let list = currentArticles;
    list.push({ paragraph: null, imageUrl: "" });

    if (formRef.current) {
      setCurrentArticles(list);
      formRef.current.setFieldValue("Articles", list, false);
    }
  };


  return (
    <>
      <PageHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>

        <Row>
          <Col className="order-xl-1">

            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col md="12">
                    <h3 className="mb-0">Educational Article</h3>
                  </Col>
                </Row>
              </CardHeader>

              <CardBody>
                <Formik
                  initialValues={{
                    materialId: '',
                    Title: '',
                    Description: '',
                    Order: 0,
                    Articles: [],
                    KeyWords: '',
                  }}
                  validationSchema={reviewSchema}
                  onSubmit={(values, actions) => {
                    console.log('formik values: ', values);
                    let params = {
                      title: values.Title,
                      description: values.Description,
                      order: values.Order,
                      articles: values.Articles,
                      keyWords: values.KeyWords,
                    }

                    if (values.materialId !== "" && values.materialId.length > 10) {
                      params["materialId"] = values.materialId;
                      dispatch(curdEMaterial("UPDATE", params, history));
                    }
                    else {
                      dispatch(curdEMaterial("CREATE", params, history));
                    }
                  }}
                  onReset={() => {
                    setCurrentArticles([{ paragraph: null, imageUrl: "" }]);
                  }}
                  innerRef={formRef}
                >
                  {props => (
                    <Form
                      role="form"
                      style={{ marginTop: -25 }}
                      onReset={props.handleReset}
                      onSubmit={props.handleSubmit}>

                      <Row>
                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={'Order*'}
                            variant="outlined"
                            value={props.values.Order}
                            onChange={props.handleChange('Order')}
                            onBlur={props.handleChange('Order')}
                            style={MbppStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.Order && Boolean(props.errors.Order)}
                            helperText={props.touched.Order && props.errors.Order}
                          // disabled={isEdit}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="11">
                          <TextField
                            fullWidth
                            label={'Title*'}
                            variant="outlined"
                            value={props.values.Title}
                            onChange={props.handleChange('Title')}
                            onBlur={props.handleChange('Title')}
                            style={MbppStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.Title && Boolean(props.errors.Title)}
                            helperText={props.touched.Title && props.errors.Title}
                          // disabled={isEdit}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="10">
                          <TextField
                            fullWidth
                            label={'Description'}
                            variant="outlined"
                            value={props.values.Description}
                            onChange={props.handleChange('Description')}
                            onBlur={props.handleChange('Description')}
                            style={MbppStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.Description && Boolean(props.errors.Description)}
                            helperText={props.touched.Description && props.errors.Description}
                          />
                        </Col>
                      </Row>

                      <br />

                      {currentArticles && !Lodash.isEmpty(currentArticles) && currentArticles.map((x, i) => {
                        return (
                          <>
                            <hr className="mt-2" />
                            <Row style={MbppStyles.Question}>
                              <Col lg="1"></Col>
                              <Col lg="10">
                                <TextField
                                  fullWidth
                                  label={"Paragraph Text: Support Basic HTML Tags"}
                                  name="paragraph" // this is to pass name through event-->e
                                  variant="outlined"
                                  value={x.paragraph}
                                  multiline
                                  rows={4}
                                  onChange={(e) => handleListChange(e, i)}
                                  onBlur={(e) => handleListChange(e, i)}
                                  style={MbppStyles.Question}
                                  className={"form-control-alternative form-control-edit"}
                                  error={props.errors.Articles && props.touched.Articles && props.touched.Articles[i]?.paragraph && Boolean(props.errors.Articles[i]?.paragraph)}
                                  helperText={props.errors.Articles && props.touched.Articles && props.touched.Articles[i]?.paragraph ? props.errors.Articles[i]?.paragraph : ""}
                                />
                                <small>* End characters are supported by mobile client</small>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg="1"></Col>
                              <Col lg="10">
                                <TextField
                                  fullWidth
                                  label={"Image: File Name From Resources"}
                                  name="imageUrl" // this is to pass name through event-->e
                                  variant="outlined"
                                  value={x.imageUrl}
                                  onChange={(e) => handleListChange(e, i)}
                                  onBlur={(e) => handleListChange(e, i)}
                                  style={MbppStyles.Question}
                                  className={"form-control-alternative form-control-edit"}
                                  error={props.errors.Articles && props.touched.Articles && props.touched.Articles[i]?.imageUrl && Boolean(props.errors.Articles[i]?.imageUrl)}
                                  helperText={props.errors.Articles && props.touched.Articles && props.touched.Articles[i]?.imageUrl ? props.errors.Articles[i]?.imageUrl : ""}
                                />
                                <small>* Put the name of the image, after uploading it to resources</small>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg="11"></Col>
                              <Col lg="1" className="text-right">
                                {/* {currentArticles.length !== 1 && (<RemoveCircleOutlineIcon className="mr10" onClick={() => handleRemoveClick(i)} />)}
                                {currentArticles.length - 1 === i && (<AddCircleOutlineIcon className="ma10" onClick={handleAddClick} />)} */}
                                <div className="row">
                                  {currentArticles.length !== 1 && (
                                    <Tooltip title="Remove section" arrow>
                                      <div
                                        className="navbar-toggler"
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                          handleRemoveClick(i);
                                        }}
                                      >
                                        <i className="fas fa-minus-circle" style={{ fontSize: 30, color: GlobalStylesColor.mbppThemePrimary }} />
                                      </div>
                                    </Tooltip>
                                  )}
                                  {currentArticles.length - 1 === i && (
                                    <Tooltip title="Add new section" arrow>
                                      <div
                                        className="navbar-toggler"
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                          handleAddClick();
                                        }}
                                      >
                                        <i className="fas fa-plus-circle" style={{ fontSize: 30, color: GlobalStylesColor.mbppThemePrimary }} />
                                      </div>
                                    </Tooltip>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </>
                        );
                      })}

                      <br />

                      <Row>
                        <Col lg="10">
                          <TextField
                            fullWidth
                            label={'KeyWords'}
                            variant="outlined"
                            value={props.values.KeyWords}
                            onChange={props.handleChange('KeyWords')}
                            onBlur={props.handleChange('KeyWords')}
                            style={MbppStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.KeyWords && Boolean(props.errors.KeyWords)}
                            helperText={props.touched.KeyWords && props.errors.KeyWords}
                          />
                        </Col>
                      </Row>

                    </Form>
                  )}
                </Formik>
              </CardBody>

              <CardFooter className="py-4">
                <Row>
                  <Col lg="6" className="text-left">
                    <Button
                      style={MbppStyles.GeneralButton}
                      color="primary"
                      onClick={() => {
                        history.goBack();
                      }}>
                      BACK
                    </Button>
                  </Col>
                  <Col lg="6" className="text-right">
                    {isEdit ? null
                      :
                      <Button
                        style={MbppStyles.GeneralButton}
                        color="primary"
                        onClick={() => {
                          if (formRef.current) {
                            formRef.current.handleReset();
                          }
                        }}>
                        RESET
                      </Button>
                    }
                    <Button
                      style={MbppStyles.GeneralButton}
                      color="primary"
                      onClick={() => {
                        if (formRef.current) {
                          formRef.current.handleSubmit();
                        }
                      }}>
                      SAVE
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>

          </Col>
        </Row>

      </Container>
    </>
  );
};

export default EducationalMaterialDetails;
