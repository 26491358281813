import * as api from '../api/index.js';
import {
  BREEDING_FETCH_ONE,
  BREEDING_FETCH_USER,
  BREEDING_FETCH_LOCATION,
  BREEDING_FETCH_REGION_STATUS,
  BREEDING_FETCH_ALL,
  BREEDING_CREATE,
  BREEDING_UPDATE,
  BREEDING_DELETE,
  SHOW_LOADING
} from '../constants/actionTypes';

export const fetchBreedingComplainById = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchBreedingComplainById(formData);
    if (data?.status === 'success') {
      dispatch({ type: BREEDING_FETCH_ONE, payload: data?.data });
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error);
    alert(error.response.data.message);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const fetchBreedingComplainByUser = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchBreedingComplainByUser(formData);
    if (data?.status === 'success') {
      dispatch({ type: BREEDING_FETCH_USER, payload: data?.data });
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error);
    alert(error.response.data.message);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const fetchBreedingComplainLocation = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchBreedingComplainLocation(formData);
    if (data?.status === 'success') {
      dispatch({ type: BREEDING_FETCH_LOCATION, payload: data?.data });
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error);
    alert(error.response.data.message);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const fetchBreedingComplainByRegionStatus = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchBreedingComplainByRegionStatus(formData);
    if (data?.status === 'success') {
      dispatch({ type: BREEDING_FETCH_REGION_STATUS, payload: data?.data });
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error);
    alert(error.response.data.message);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const fetchBreedingAllSites = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchBreedingAllSites(formData);
    if (data?.status === 'success') {
      dispatch({ type: BREEDING_FETCH_ALL, payload: data?.data });
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error);
    alert(error.response.data.message);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const fetchBreedingByOptions = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchBreedingByOptions(formData);
    if (data?.status === 'success') {
      dispatch({ type: BREEDING_FETCH_ALL, payload: data?.data });
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error);
    alert(error.response.data.message);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const createBreedingComplain = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.createBreedingComplain(formData);
    if (data?.status === 'success') {
      dispatch({ type: BREEDING_CREATE, payload: data?.data });
      alert(data?.message);
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error);
    alert(error.response.data.message);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const updateBreedingComplain = (formData, history) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.updateBreedingComplain(formData);
    if (data?.status === 'success') {
      dispatch({ type: BREEDING_UPDATE, payload: data?.data });
      //update and go back to parent
      // dispatch(fetchBreedingByOptions(formData.searchParams, history)); 
      history.goBack();
      alert(data?.message);
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error);
    alert(error.response.data.message);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const deleteBreedingComplain = (formData, history) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.deleteBreedingComplain(formData);
    if (data?.status === 'success') {
      dispatch({ type: BREEDING_DELETE, payload: data?.data });
      dispatch(fetchBreedingByOptions(formData.searchParams, history));
      alert(data?.message);
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error);
    alert(error.response.data.message);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const uploadBreedingComplainImage = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    console.log('Upload Picture form data: ', formData)
    const { data } = await api.uploadBreedingComplainImage(formData);
    console.log('Upload Picture: ', data)
    if (data?.status === 'success') {
      // dispatch({ type: BREEDING_DELETE, payload: data });
      alert(data?.message);
      // formData.redirect && router.push(formData.redirect);
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error)
    alert(error.response.data.message);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};