import * as api from '../api/index.js';
import { STAT_BREEDING_SITES, STAT_REPORTS, STAT_BREEDING_SITES_DATE, STAT_DASHBOARD, SHOW_LOADING } from "constants/actionTypes";

// forum fetch Stat Summery 2
export const fetchStatSummery = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    console.log('request data: ', formData);
    const { data } = await api.fetchStatSummery(formData);
    console.log('graph data: ', data);
    if (data?.status === 'success') {
      dispatch({ type: formData.reqtype, payload: data?.data });
      // alert(data?.message);
    }
    else if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log("catch error: ", error);
    // alert(error.response.data.message);
    if (error?.response?.status === 425) {
      alert("Connection refreshed. Please try again.");
    }
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// forum fetch stat breeding sites
export const fetchStatBreedingSites = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    console.log('request data: ', formData);
    const { data } = await api.fetchStatBreedingSites(formData);
    console.log('graph data: ', data);
    if (data?.status === 'success') {
      dispatch({ type: STAT_BREEDING_SITES, payload: data?.data });
      // alert(data?.message);
    }
    else if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log("catch error: ", error);
    // alert(error.response.data.message);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// forum fetch stat breeding sites
export const fetchStatBreedingSitesDate = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    console.log('request data: ', formData);
    const { data } = await api.fetchStatBreedingSitesDate(formData);
    console.log('graph data: ', data);
    if (data?.status === 'success') {
      dispatch({ type: STAT_BREEDING_SITES_DATE, payload: data?.data });
      // alert(data?.message);
    }
    // else if (data?.status === 'error') {
    //   console.log(data);
    //   alert(data?.message);
    // }
    else {
      dispatch({ type: STAT_BREEDING_SITES_DATE, payload: [] });
    }
  } catch (error) {
    console.log("catch error: ", error);
    // alert(error.response.data.message);
    dispatch({ type: STAT_BREEDING_SITES_DATE, payload: [] });
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// forum fetch stat reports
export const fetchStatReports = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    console.log('request data: ', formData);
    const { data } = await api.fetchStatReports(formData);
    console.log('graph data: ', data);
    if (data?.status === 'success') {
      dispatch({ type: STAT_REPORTS, payload: data?.data });
      // alert(data?.message);
    }
    else if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log("catch error: ", error);
    // alert(error.response.data.message);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};



// forum fetch Stat Summery 2
export const fetchDashStat = (formData) => async (dispatch) => {
  console.log('request data: ', formData);
  try {
    dispatch({ type: SHOW_LOADING, payload: true });

    const { data } = await api.fetchDashStat(formData);
    console.log('graph data: ', data);

    if (data?.status === 'success') {
      dispatch({ type: STAT_DASHBOARD, payload: data?.data });
    }
    else {
      dispatch({ type: STAT_DASHBOARD, payload: {} });
    }
  } catch (error) {
    console.log("catch error: ", error);
    dispatch({ type: STAT_DASHBOARD, payload: {} })
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};
