import React, { useEffect, useState, useRef } from 'react';
import Lodash from "lodash";
import { Formik } from "formik";
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom'
import { Checkbox, Chip, FormControl, InputAdornment, ListItemIcon, ListItemText, MenuItem, Select, TextField, InputLabel, OutlinedInput, useTheme } from '@material-ui/core';
import {
  Card,
  CardBody,
  CardFooter, CardHeader,
  CardTitle, Col, Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle, Pagination,
  PaginationItem,
  PaginationLink, Row,
  Table,
  UncontrolledDropdown,
  Label,
  Form,
  FormGroup,
  Input,
  FormText,
  Button,
} from "reactstrap";
import PageHeader from "components/Headers/PageHeader.js";
import { MbppStyles } from 'constants/globalStyles';
import { sendNotification } from "actions/notification";
import { basicInformation } from "globalGeoInformation";
import { useMultiSelectStyles } from "helper";


const reviewSchema = Yup.object({
  Category: Yup.string()
    .required()
  , Title: Yup.string()
    .required()
    .min(4)
    .max(60)
  , Message: Yup.string()
    .required()
    .min(4)
    .max(200)
});

// Note: Push notification message lengths
// Best title -39 characters, message 150 characters
// Android title -65 characters, message 240 characters
// iOS 110

const USER_GROUPS = {
  ROLE_CMC: ["ADMIN", "EPID-STAFF"],
  ROLE_ALL: ["ADMIN", "EPID-STAFF", "MOH-DOC", "PHI", "GENERAL"],
  ROLE_CONSTRUCTION: ["CONSTRUCTION"],
  ROLE_SCHOOL: ["SCHOOL"],
  WARDS: ["MOH-DOC", "PHI", "GENERAL"],
  MOH: ["MOH-DOC"]
}


const SendNotification = (props) => {

  const formRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();

  // multi select
  const classes = useMultiSelectStyles();
  const [wardsSelected, setWardsSelected] = useState([]);
  const isWardsAllSelected = (Object.entries(basicInformation.wardIds)).length > 0 && wardsSelected.length === (Object.entries(basicInformation.wardIds)).length;


  useEffect(() => {

  }, [])

  const handleWardMultiSelectChange = (event) => {
    const value = event.target.value;
    let updatedWards = [];
    if (value[value.length - 1] === "all") {
      updatedWards = (wardsSelected.length === (Object.entries(basicInformation.wardIds)).length ? [] : Object.keys(basicInformation.wardIds));
    }
    else {
      updatedWards = value;
    }
    setWardsSelected(updatedWards);
    if (formRef.current) {
      formRef.current.setFieldValue('Wards', updatedWards, false);
    }
    console.log('selected ward items', updatedWards);
  }

  ////////////////////////////

  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }


  return (
    <>

      <PageHeader />


      {/* Page content */}
      <Container className="mt--9" fluid>

        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <div className="mb-xl-0 col-11">
                    <h3 className="mb-0">Send Notification</h3>
                    <h6 className="mb-0">To Mo-Buzz+ Public app users</h6>
                  </div>
                  <div className="mb-xl-0">
                  </div>
                </div>
              </CardHeader>
              <CardBody>

                <Formik
                  initialValues={{
                    userId: null,
                    Title: '',
                    Message: '',
                    Category: '',
                    Wards: null,
                  }}
                  validationSchema={reviewSchema}
                  onSubmit={(values, actions) => {
                    console.log('formik values: ', values);
                    let params = {
                      userId: null,
                      title: values.Title,
                      message: values.Message,
                      role: values.Category,
                      wards: values.Wards
                    }
                    dispatch(sendNotification(params));
                  }}
                  innerRef={formRef}
                >
                  {props => (
                    <Form
                      role="form"
                      style={{ marginTop: -25 }}
                      onSubmit={props.handleSubmit}>

                      <Row>
                        <Col lg="4">
                          <TextField
                            select
                            fullWidth
                            label={'User Category*'}
                            variant="outlined"
                            value={props.values.Category}
                            onChange={props.handleChange('Category')}
                            onBlur={props.handleChange('Category')}
                            SelectProps={{ native: true }}
                            style={MbppStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.Category && Boolean(props.errors.Category)}
                            helperText={props.touched.Category && props.errors.Category}
                            disabled={false}
                          >
                            <option key="" value=""></option>
                            <option key="ROLE_CMC" value="ROLE_CMC">CMC Users</option>
                            <option key="MOH" value="MOH">MOH Users</option>
                            <option key="ROLE_CONSTRUCTION" value="ROLE_CONSTRUCTION">Construction Users</option>
                            <option key="ROLE_SCHOOL" value="ROLE_SCHOOL">School Users</option>
                            <option key="WARDS" value="WARDS">Ward Users</option>
                            <option key="ROLE_ALL" value="ROLE_ALL">All Users</option>
                            {/* <option key="USER" value="USER">Specific User</option> */}
                          </TextField>
                        </Col>
                      </Row>


                      {Lodash.isEmpty(USER_GROUPS[props.values.Category]) ? null :
                        <Row>
                          <Col lg="12">
                          <small>* User Groups: {String(USER_GROUPS[props.values.Category])}</small>
                          </Col>
                        </Row>
                      }


                      <Row>
                        <Col lg="8">
                          <TextField
                            fullWidth
                            label={'Message Title*'}
                            variant="outlined"
                            value={props.values.Title}
                            onChange={props.handleChange('Title')}
                            onBlur={props.handleChange('Title')}
                            style={MbppStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.Title && Boolean(props.errors.Title)}
                            helperText={props.touched.Title && props.errors.Title}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <TextField
                            fullWidth
                            label={'Message Body*'}
                            variant="outlined"
                            value={props.values.Message}
                            onChange={props.handleChange('Message')}
                            onBlur={props.handleChange('Message')}
                            style={MbppStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.Message && Boolean(props.errors.Message)}
                            helperText={props.touched.Message && props.errors.Message}
                          />
                        </Col>
                      </Row>

                      {(props.values.Category === "WARDS") ?
                        <Row >
                          <Col lg="12" style={MbppStyles.Question}>
                            <FormControl lg="12">
                              {/* label is not correctly align with the container. have to manually adjust */}
                              <InputLabel id="wards-label" style={{ marginLeft: 15, marginTop: -5 }}>Wards</InputLabel>
                              <Select
                                input={<OutlinedInput label="Wards" />}
                                label={"Wards"}
                                fullWidth
                                labelId="wards-label"
                                id="wards-multiple-checkbox"
                                variant="outlined"
                                multiple={true}
                                autoWidth={true}
                                value={wardsSelected}
                                onChange={handleWardMultiSelectChange}
                                style={{ minWidth: 200 }}
                                renderValue={(selected) => (
                                  <div style={{ display: 'flex', flexWrap: 'wrap', }}>
                                    {(selected).map((key) => (
                                      <Chip key={basicInformation.wardIds[key]} label={basicInformation.wardIds[key]} style={{ margin: 2 }} />
                                    ))}
                                  </div>
                                )}
                                error={props.touched.Wards && Boolean(props.errors.Wards)}
                              // helperText={props.touched.Wards && props.errors.Wards}
                              // className={"form-control-alternative"}
                              >
                                {/* select all option */}
                                <MenuItem value="all" className={{ root: isWardsAllSelected ? classes.selectedAll + "" : "" }}>
                                  <ListItemIcon>
                                    <Checkbox
                                      className={{ indeterminate: classes.indeterminateColor + "" }}
                                      checked={isWardsAllSelected}
                                      indeterminate={wardsSelected.length > 0 && wardsSelected.length < Object.entries(basicInformation.wardIds).length}
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    className={{ primary: classes.selectAllText + "" }}
                                    primary="Select All"
                                  />
                                </MenuItem>

                                {Object.entries(basicInformation.wardIds).map(([key, value]) => (
                                  <MenuItem key={key} value={key}>
                                    <ListItemIcon>
                                      <Checkbox checked={wardsSelected && wardsSelected.indexOf(key) > -1} />
                                    </ListItemIcon>
                                    <ListItemText primary={value} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Col>
                        </Row>
                        :
                        null
                      }

                    </Form>
                  )}
                </Formik>

              </CardBody>
              <CardFooter className="py-4">
                <Row>
                  <Col lg="6" className="text-left">
                    <Button
                      style={MbppStyles.GeneralButton}
                      color="primary"
                      onClick={() => {
                        history.goBack();
                      }}>
                      BACK
                    </Button>
                    <Button
                      style={MbppStyles.GeneralButton}
                      color="primary"
                      onClick={() => {
                        if (formRef.current) {
                          formRef.current.handleSubmit();
                        }
                      }}>
                      SEND
                    </Button>
                  </Col>
                  <Col lg="6" className="text-right">
                    <Button
                      style={MbppStyles.GeneralButton}
                      color="primary"
                      onClick={() => {
                        history.push({ pathname: "/registered/notification_history" });
                      }}>
                      HISTORY
                    </Button>
                  </Col>
                </Row>
              </CardFooter>

            </Card>
          </div>
        </Row>

      </Container>
    </>

  );

}

export default SendNotification;



