

export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const FETCH_ONE = 'FETCH_ONE';
export const FETCH_ALL = 'FETCH_ALL';
export const LIKE = 'LIKE';

// User Auth
export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';

// user
export const USER_FETCH_ONE = 'USER_FETCH_ONE';
export const USER_FETCH_ONE_ORG = 'USER_FETCH_ONE_ORG';
export const USER_FETCH_ALL = 'USER_FETCH_ALL';
export const USER_CREATE = 'USER_CREATE';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_FETCH_BY_ADMIN = 'USER_FETCH_BY_ADMIN';
export const USER_FETCH_PRE_REGISTER_ALL = 'USER_FETCH_PRE_REGISTER_ALL';
export const USER_CREATE_PRE_REGISTER = 'USER_CREATE_PRE_REGISTER';

// Forum topic
export const FRM_TOPIC_FETCH_ALL = 'FRM_TOPIC_FETCH_ALL';
export const FRM_TOPIC_FETCH_ONE = 'FRM_TOPIC_FETCH_ONE';
export const FRM_TOPIC_CREATE = 'FRM_TOPIC_CREATE';
export const FRM_TOPIC_UPDATE = 'FRM_TOPIC_UPDATE';
// Forum sub topic
export const FRM_SUB_TOPIC_FETCH_ALL = 'FRM_SUB_TOPIC_FETCH_ALL';
export const FRM_SUB_TOPIC_FETCH_ONE = 'FRM_SUB_TOPIC_FETCH_ONE';
export const FRM_SUB_TOPIC_CREATE = 'FRM_SUB_TOPIC_CREATE';
export const FRM_SUB_TOPIC_UPDATE = 'FRM_SUB_TOPIC_UPDATE';
// Forum Questions
export const FRM_QUS_FETCH_ONE = 'FRM_QUS_FETCH_ONE';
export const FRM_QUS_FETCH_ALL = 'FRM_QUS_FETCH_ALL';
export const FRM_QUS_TOPIC_FETCH_ALL = 'FRM_QUS_TOPIC_FETCH_ALL';
export const FRM_QUS_CREATE = 'FRM_QUS_CREATE';
export const FRM_QUS_UPDATE = 'FRM_QUS_UPDATE';
// Forum Answers
export const FRM_ANS_FETCH_ALL = 'FRM_ANS_FETCH_ALL';
export const FRM_ANS_FETCH_ONE = 'FRM_ANS_FETCH_ONE';
export const FRM_ANS_CREATE = 'FRM_ANS_CREATE';
export const FRM_ANS_UPDATE = 'FRM_ANS_UPDATE';
// Forum New Questions
export const FRM_NEW_QUESTIONS = 'FRM_NEW_QUESTIONS';
export const FRM_NEW_QUESTION_ANSWER = 'FRM_NEW_QUESTION_ANSWER';

// Dashbord
export const STAT_SUMMERY_TOTAL = 'STAT_SUMMERY_TOTAL';
export const STAT_SUMMERY_CON_MON_FETCH = 'STAT_SUMMERY_CON_MON_FETCH';
export const STAT_SUMMERY_CON_WEK_FETCH = 'STAT_SUMMERY_CON_WEK_FETCH';
export const STAT_SUMMERY_REP_FETCH = 'STAT_SUMMERY_REP_FETCH';
// Loading Indicator
export const SHOW_LOADING = 'SHOW_LOADING';

// Resources
export const RESOURCE_CREATE = "RESOURCE_CREATE";
export const RESOURCE_UPDATE = "RESOURCE_UPDATE";
export const RESOURCE_FETCH_ALL = "RESOURCE_FETCH_ALL";
export const RESOURCE_DELETE = "RESOURCE_DELETE";

// Report
export const REPORT_ANSWER_ALL = "REPORT_ANSWER_ALL";
export const REPORT_ANSWER_BY_USER = "REPORT_ANSWER_BY_USER";
export const REPORT_ANSWER_BY_COUNTRY = "REPORT_ANSWER_BY_COUNTRY";

///////////////////////////////////


// contact
export const CONTACT_FETCH_ONE = 'CONTACT_FETCH_ONE';
export const CONTACT_FETCH_ALL = 'CONTACT_FETCH_ALL';
export const CONTACT_CREATE = 'CONTACT_CREATE';
export const CONTACT_UPDATE = 'CONTACT_UPDATE';

// educational
export const EDUCATIONAL_MATERIALS = "EDUCATIONAL_MATERIALS";
// edu topic
export const EDU_TOPIC_FETCH_ALL = 'EDU_TOPIC_FETCH_ALL';
export const EDU_TOPIC_FETCH_ONE = 'EDU_TOPIC_FETCH_ONE';
export const EDU_TOPIC_CREATE = 'EDU_TOPIC_CREATE';
export const EDU_TOPIC_UPDATE = 'EDU_TOPIC_UPDATE';
// edu sub topic
export const EDU_SUB_TOPIC_FETCH_ALL = 'EDU_SUB_TOPIC_FETCH_ALL';
export const EDU_SUB_TOPIC_FETCH_ONE = 'EDU_SUB_TOPIC_FETCH_ONE';
export const EDU_SUB_TOPIC_CREATE = 'EDU_SUB_TOPIC_CREATE';
export const EDU_SUB_TOPIC_UPDATE = 'EDU_SUB_TOPIC_UPDATE';
// edu articles
export const EDU_ART_FETCH_ONE = 'EDU_ART_FETCH_ONE';
export const EDU_ART_FETCH_ALL = 'EDU_ART_FETCH_ALL';
export const EDU_ART_TOPIC_FETCH_ALL = 'EDU_ART_TOPIC_FETCH_ALL';
export const EDU_ART_CREATE = 'EDU_ART_CREATE';
export const EDU_ART_UPDATE = 'EDU_ART_UPDATE';

// Analytics
export const STAT_SUMMERY = 'STAT_SUMMERY';
export const STAT_BREEDING_SITES = 'STAT_BREEDING_SITES';
export const STAT_REPORTS = 'STAT_REPORTS';
export const STAT_BREEDING_SITES_DATE = 'STAT_BREEDING_SITES_DATE';
export const STAT_DASHBOARD = 'STAT_DASHBOARD';

// Breeding
export const BREEDING_FETCH_ONE = 'BREEDING_FETCH_ONE';
export const BREEDING_FETCH_USER = 'BREEDING_FETCH_USER';
export const BREEDING_FETCH_LOCATION = 'BREEDING_FETCH_LOCATION';
export const BREEDING_FETCH_REGION_STATUS = 'BREEDING_FETCH_REGION_STATUS';
export const BREEDING_FETCH_ALL = 'BREEDING_FETCH_ALL';
export const BREEDING_CREATE = 'BREEDING_CREATE';
export const BREEDING_UPDATE = 'BREEDING_UPDATE';
export const BREEDING_DELETE = 'BREEDING_DELETE';

// Calender
export const CALENDAR_FETCH_ALL = 'CALENDAR_FETCH_ALL';
export const CALENDAR_FETCH_USER = 'CALENDAR_FETCH_USER';
export const CALENDAR_FETCH_PUB = 'CALENDAR_FETCH_PUB';
export const CALENDAR_CREATE = 'CALENDAR_CREATE';
export const CALENDAR_UPDATE = 'CALENDAR_UPDATE';
export const CALENDAR_DELETE = 'CALENDAR_DELETE';

////////////////////////////

// Maps
export const MAP_BUILDINGS = 'MAP_BUILDINGS';
export const MAP_DANGER_ZONES = 'MAP_DANGER_ZONES';
export const MAP_DANGER_PATIENTS = 'MAP_DANGER_PATIENTS';

// Notification
export const NOTIFICATION_FETCH_PUBLIC = 'NOTIFICATION_FETCH_PUBLIC';

////////////////////////////
// Mbp cases
export const MBP_FIND_CASES_DETAIL = 'MBP_FIND_CASES_DETAIL';
export const MBP_FIND_CASES_FULL = 'MBP_FIND_CASES_FULL';
export const MBP_CREATE_CASES_DENSYS = 'MBP_CREATE_CASES_DENSYS';
