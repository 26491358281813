import {
  FRM_ANS_CREATE, FRM_ANS_FETCH_ALL,
  FRM_ANS_FETCH_ONE,
  FRM_ANS_UPDATE, FRM_QUS_CREATE, FRM_QUS_FETCH_ALL, FRM_QUS_FETCH_ONE,
  FRM_QUS_TOPIC_FETCH_ALL,
  FRM_QUS_UPDATE, FRM_SUB_TOPIC_CREATE, FRM_SUB_TOPIC_FETCH_ALL,
  FRM_SUB_TOPIC_FETCH_ONE,
  FRM_SUB_TOPIC_UPDATE, FRM_TOPIC_CREATE, FRM_TOPIC_FETCH_ALL,
  FRM_TOPIC_FETCH_ONE,
  FRM_TOPIC_UPDATE,
  FRM_NEW_QUESTIONS,
  FRM_NEW_QUESTION_ANSWER,
} from 'constants/actionTypes';

const initState = {
  frmAllTopics: [],
  frmTopic: {},
  frmTopicCreate: [],
  frmTopicUpdate: [],

  frmAllSubTopics: [],
  frmSubTopic: {},
  frmSubTopicCreate: [],
  frmSubTopicUpdate: [],

  frmQus: {},
  frmAllQus: [],
  frmQusByTopic: [],
  frmQusCreate: [],
  frmQusUpdate: [],

  frmAllAnswers: [],
  frmAnswer: {},
  frmAnswerCreate: [],
  frmAnswerUpdate: [],

  userNewQuestions: [],
  userNewQuestionAnswer: {},
}

const forumReducer = (forum = initState, action) => {
  switch (action.type) {
    // forum topics
    case FRM_TOPIC_FETCH_ALL:
      return { ...forum, frmAllTopics: action.payload };
    case FRM_TOPIC_FETCH_ONE:
      return { ...forum, frmTopic: action.payload };
    case FRM_TOPIC_CREATE:
      return { ...forum, frmTopicCreate: action.payload };
    case FRM_TOPIC_UPDATE:
      return { ...forum, frmTopicUpdate: action.payload };
    // forum sub topics
    case FRM_SUB_TOPIC_FETCH_ALL:
      return { ...forum, frmAllSubTopics: action.payload };
    case FRM_SUB_TOPIC_FETCH_ONE:
      return { ...forum, frmSubTopic: action.payload };
    case FRM_SUB_TOPIC_CREATE:
      return { ...forum, frmSubTopicCreate: action.payload };
    case FRM_SUB_TOPIC_UPDATE:
      return { ...forum, frmSubTopicUpdate: action.payload };
    // forum Questions
    case FRM_QUS_FETCH_ONE:
      return { ...forum, frmQus: action.payload };
    case FRM_QUS_FETCH_ALL:
      return { ...forum, frmAllQus: action.payload };
    case FRM_QUS_TOPIC_FETCH_ALL:
      return { ...forum, frmQusByTopic: action.payload };
    case FRM_QUS_CREATE:
      return { ...forum, frmQusCreate: action.payload };
    case FRM_QUS_UPDATE:
      return { ...forum, frmQusUpdate: action.payload };
    // forum Answers
    case FRM_ANS_FETCH_ALL:
      return { ...forum, frmAllAnswers: action.payload };
    case FRM_ANS_FETCH_ONE:
      return { ...forum, frmAnswer: action.payload };
    case FRM_ANS_CREATE:
      return { ...forum, frmAnswerCreate: action.payload };
    case FRM_ANS_UPDATE:
      return { ...forum, frmAnswerUpdate: action.payload };

    case FRM_NEW_QUESTIONS:
      return { ...forum, userNewQuestions: action.payload };
    case FRM_NEW_QUESTION_ANSWER:
      return { ...forum, userNewQuestionAnswer: action.payload };

    default:
      return forum;
  }
};

export default forumReducer;