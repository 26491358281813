import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { MbppStyles } from 'constants/globalStyles';
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  FormGroup,
  Form,
  Button,
  Row,
  Col,
} from "reactstrap";
import { TextField, FormControlLabel, Switch, Grid } from '@material-ui/core';
import { fetchCalendarEventAll, createCalendarEvent, updateCalendarEvent } from 'actions/calendar';
import { basicInformation } from "globalGeoInformation"
// core components
import PageHeader from "components/Headers/PageHeader.js";
import Moment from "moment";


const validationSchemaActivity = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Your email is required'),
  date: yup
    .date('Enter activity date')
    .required('Activity date is required'),
  title: yup
    .string('Enter activity title')
    .min(3, 'Title should be of minimum 3 characters length')
    .max(255, 'Activity title should be of maximum 255 characters length'),
  description: yup
    .string('Enter activity description')
    .min(3, 'Description should be of minimum 3 characters length')
    .max(500, 'Description should be of maximum 500 characters length'),
  type: yup
    .string('Enter activity type')
    .max(255, 'Activity type should be of maximum 255 characters length'),
  ward: yup
    .string('Enter activity ward'),
  public: yup
    .boolean('Enter activity status'),
});

const CalendarActivityFrom = (props) => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [activityDetails, setActivityDetails] = useState({})
  const [refreshCounter, setRefreshCounter] = useState(0);

  useEffect(() => {
    let event = props.location.state?.caleEvent;
    console.log("##################", event);
    setActivityDetails(event ? event : {})
  }, []);

  useEffect(() => {
    if (activityDetails && Object.keys(activityDetails).length > 0 && activityDetails._id) {
      formik.values._id = activityDetails._id;
      formik.values.email = activityDetails.email;
      formik.values.date = activityDetails.date;
      formik.values.title = activityDetails.title;
      formik.values.description = activityDetails.description;
      formik.values.type = activityDetails.type;
      formik.values.ward = activityDetails.ward;
      formik.values.public = activityDetails.public;
      setRefreshCounter(refreshCounter + 1);
    }
  }, [activityDetails]);

  const formik = useFormik({
    initialValues: {
      _id: '',
      email: '',
      date: '',
      title: '',
      description: '',
      type: '',
      ward: '',
      public: false
    },
    validationSchema: validationSchemaActivity,
    onSubmit: (values, onSubmitProps) => {
      if (values._id) {
        dispatch(updateCalendarEvent(values, history));
      }
      else {
        dispatch(createCalendarEvent(values, history));
      }
      console.log(values);
      onSubmitProps.setSubmitting(false);
      // onSubmitProps.resetForm();
    },
  });

  return (
    <>
      <PageHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table School */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Activity Details</h3>
              </CardHeader>
              <Form
                id={refreshCounter}
                role="form"
                onSubmit={formik.handleSubmit}
                onReset={formik.handleReset}>

                <CardBody>
                  <FormGroup className="mb-3">
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="ni ni-email-83" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          id="email"
                          name="email"
                          label="Email"
                          variant="outlined"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          error={formik.touched.email && Boolean(formik.errors.email)}
                          helperText={formik.touched.email && formik.errors.email}
                          disabled={formik.values._id}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-calendar-day" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          type="date"
                          id="date"
                          name="date"
                          label="Date"
                          variant="outlined"
                          format={'DD/MM/YYYY'}
                          value={Moment(formik.values.date).format('YYYY-MM-DD')}
                          onChange={formik.handleChange}
                          InputLabelProps={{ shrink: true, }}
                          error={formik.touched.date && Boolean(formik.errors.date)}
                          helperText={formik.touched.date && formik.errors.date}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-heading" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          id="title"
                          name="title"
                          label="Title"
                          variant="outlined"
                          value={formik.values.title}
                          onChange={formik.handleChange}
                          error={formik.touched.title && Boolean(formik.errors.title)}
                          helperText={formik.touched.title && formik.errors.title}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="ni ni-align-left-2" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          id="description"
                          name="description"
                          label="Description"
                          variant="outlined"
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          multiline rows={4}
                          error={formik.touched.description && Boolean(formik.errors.description)}
                          helperText={formik.touched.description && formik.errors.description}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-heading" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          id="type"
                          name="type"
                          label="Type"
                          variant="outlined"
                          value={formik.values.type}
                          onChange={formik.handleChange}
                          error={formik.touched.type && Boolean(formik.errors.type)}
                          helperText={formik.touched.type && formik.errors.type}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-map-marked" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          select
                          fullWidth
                          id="ward"
                          name="ward"
                          label="Ward"
                          variant="outlined"
                          value={formik.values.ward}
                          onChange={formik.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          error={formik.touched.ward && Boolean(formik.errors.ward)}
                          helperText={formik.touched.ward && formik.errors.ward}
                        >
                          <option value=''></option>
                          {Object.entries(basicInformation.wardIds).map(([key, value]) => (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-toggle-off" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <FormControlLabel
                          value="public"
                          control={
                            <Switch
                              checked={formik.values.public}
                              onChange={formik.handleChange}
                              color="primary"
                              name="public"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                              error={formik.touched.public && Boolean(formik.errors.public)}
                              helperText={formik.touched.public && formik.errors.public}
                            />}
                          label="Public"
                          labelPlacement="start"
                        />
                      </Grid>
                    </Grid>
                  </FormGroup>
                </CardBody>

                <CardFooter className="py-4">
                  <Row>
                    <Col lg="6" className="text-left">
                      {formik.values._id ?
                        <Button
                          style={MbppStyles.GeneralButton}
                          color="primary"
                          onClick={() => {
                            history.goBack();
                          }}>
                          BACK
                        </Button>
                        : null}
                    </Col>
                    <Col lg="6" className="text-right">
                      <Button
                        style={MbppStyles.GeneralButton}
                        color="primary"
                        type="reset"
                        onClick={() => { }}>
                        RESET
                      </Button>
                      <Button
                        style={MbppStyles.GeneralButton}
                        color="primary"
                        type="submit"
                        onClick={() => { }}>
                        {(formik.values._id) ? 'UPDATE' : 'SAVE'}
                      </Button>
                    </Col>
                  </Row>
                </CardFooter>

              </Form>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CalendarActivityFrom;
