import {
  MAP_BUILDINGS,
  MAP_DANGER_ZONES,
  MAP_DANGER_PATIENTS,
  SHOW_LOADING,
} from 'constants/actionTypes';
import * as api from '../api/index.js';
import Lodash from "lodash";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


export const fetchMapBuildings = (formData, history) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchMapBuildings(formData);
    console.log("map data", data);
    if (data?.status === 'success') {
      dispatch({ type: MAP_BUILDINGS, payload: data?.data });
    }
    else if (data?.status === 'error') {
      console.log(data);
    }
  } catch (error) {
    console.log("catch error: ", error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const updateMapBuildings = (formData, history) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.updateMapBuildings(formData);
    console.log("map data", data);
    if (data?.status === 'success') {
      confirmAlert({
        title: "Success", message: "Map updated.", buttons: [{
          label: 'Ok', onClick: () => {
            dispatch(fetchMapBuildings(null, history));
            if (history) history.goBack();
          }
        }]
      });
    }
    else if (data?.status === 'error') {
      console.log("error");
      confirmAlert({ title: "Error", message: "Map update failed.", buttons: [{ label: 'Ok', onClick: () => { } }] });
    }
    else {
      console.log("else");
    }
  } catch (error) {
    console.log("catch error: ", error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};


export const fetchMapDangerZones = (formData, history) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchMapDangerZones(formData);
    console.log("map data", data);
    if (data?.status === 'success') {
      dispatch({ type: MAP_DANGER_ZONES, payload: data?.data });
    }
    else if (data?.status === 'error') {
      console.log(data);
    }
  } catch (error) {
    console.log("catch error: ", error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};


export const fetchMapPatients = (formData, history) => async (dispatch) => {
  let dispatchData = [];
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchPatientLocations(formData);
    console.log("map data", data);
    if (data?.status === 'success') {
      // dispatch({ type: MAP_DANGER_PATIENTS, payload: data?.data });
      dispatchData = data?.data;
    }
    else if (data?.status === 'error') {
      console.log(data);
    }
  } catch (error) {
    console.log("catch error: ", error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }

  dispatch({ type: MAP_DANGER_PATIENTS, payload: dispatchData });
};





