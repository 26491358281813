import LandingFooter from "components/Footers/LandingFooter";
import LandingNavBar from "components/Navbars/LandingNavbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";
import '../assets/css/chs-custom.css';
import { routesAdmin } from "routes.js";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

const Landing = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();


  useEffect(() => {
  }, []);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/public") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div style={{ backgroundColor: "#fff" }}>
        <Row className="justify-content-center" >
          <Col lg='10' md='12'>
            <LandingNavBar />
          </Col>
        </Row>
      </div>


      <div className="main-content" ref={mainContent}>
        <Row className="justify-content-center" >
          <Col class="shadow" lg='10' md='12' >
            <Switch>
              {getRoutes(routesAdmin)}
              <Redirect from="*" to="/public/landing" />
            </Switch>
          </Col>
        </Row>
      </div>

      <div style={{ backgroundColor: "#fff" }}>
        <Row className="justify-content-center" >
          <Col lg='10' md='12'>
            <LandingFooter />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Landing;
