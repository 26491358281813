import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { MbppStyles } from 'constants/globalStyles';
import { dateToString, isValidString } from 'helper'

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  Form,
  FormGroup,
  Button,
  Table,
  Row,
  Col,
} from "reactstrap"; // reactstrap components
import { FormControl, TextField, Tooltip } from '@material-ui/core';
import Lodash from "lodash";
import Moment from "moment";

import * as XLSX from 'xlsx';
import { showCaseTemplate } from "api"

// core components
import PageHeader from "components/Headers/PageHeader.js";
import { createMbpCasesDensys } from "actions/mbp";
import { basicInformation, cmcRegionBoundaries } from "globalGeoInformation"

const genderList = [
  'Male',
  'Female'
]

const TableRow = ({ dateSend, admission, patient, addClick, deleteClick }) => (
  <tr id={dateSend}>
    <td>{dateSend}</td>

    <td style={{ 'whiteSpace': 'break-spaces' }}>
      <div>Institute: {admission.institute} </div>
      <div>Date: {admission.date}</div>
      <div>BHT No: {admission.bhtNo} </div>
    </td>
    <td style={{ 'whiteSpace': 'break-spaces' }}>
      <div>Name: {patient.name}</div>
      <div>Age: {patient.age}</div>
      <div>Gender: {patient.gender}</div>
      <div>Telephone mobile: {patient.telephoneMobile}</div>
      <div>Telephone home: {patient.telephoneHome}</div>
      <div>Address: {patient.address}</div>
    </td>

    <td className="text-right">
      <div className="row">
        <Tooltip title="Add case" arrow>
          <div className="navbar-toggler"
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              console.log('admission1: ', admission)
              console.log('patient1: ', patient)
              addClick(e, admission, patient)
            }}>
            <i className="fas fa-plus-circle" />
          </div>
        </Tooltip>
        {/* <Tooltip title="Delete" arrow>
          <div className="navbar-toggler"
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              deleteClick(e, complain)
            }}>
            <i className="far fa-times-circle" />
          </div>
        </Tooltip> */}
      </div>
    </td>
  </tr>
);

const validationSchemaAnalytics = yup.object({
});


const FindCaseIdDirectory = (props) => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [xlsxData, setXlsxData] = useState(undefined)
  const [caseData, setCaseData] = useState(undefined)

  const readUploadXlsx = (e) => {
    e.preventDefault();
    setXlsxData(undefined)
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet, { raw: false });
        console.log('Data in excel sheet: ', json);
        // Validate json structure
        if (!Lodash.isArray(json) || json.length <= 1 || json[0].length < 10) {
          alert('File structure not supported, please check and upload again')
        }
        else {
          const caseDetails = []
          Lodash.map(json, (item) => {
            // Map props by name or position
            if (Lodash.size(item) > 10) {
              const jsonItem = {
                admission: {},
                patient: {}
              }
              Lodash.map(item, (prop, i) => {
                console.log(`prop --->: ${i} :`, prop)
                if (i.toLocaleLowerCase().includes('send')) {
                  jsonItem['sendDate'] = Moment(prop, 'M/D/YYYY').format("YYYY-MM-DD")
                }
                if (i.toLocaleLowerCase().includes('hospital')) jsonItem['admission']['institute'] = prop
                if (i.toLocaleLowerCase().includes('addmited')) {
                  jsonItem['admission']['date'] = Moment(prop, 'M/D/YYYY').format("YYYY-MM-DD")
                }
                if (i.toLocaleLowerCase().includes('bht')) jsonItem['admission']['bhtNo'] = prop

                if (i.toLocaleLowerCase().includes('name')) jsonItem['patient']['name'] = prop
                if (i.toLocaleLowerCase().includes('address')) jsonItem['patient']['address'] = prop
                if (i.toLocaleLowerCase().includes('tel')) {
                  if (Lodash.startsWith(prop, '07') || Lodash.startsWith(prop, '7'))
                    jsonItem['patient']['telephoneMobile'] = prop
                  else
                    jsonItem['patient']['telephoneHome'] = prop
                }
                if (i.toLocaleLowerCase().includes('age')) jsonItem['patient']['age'] = prop
                if (i.toLocaleLowerCase().includes('gender')) jsonItem['patient']['gender'] = prop
              })

              caseDetails.push(jsonItem)
            }
          })
          console.log(`caseDetails ---> :`, caseDetails)
          setXlsxData(caseDetails)
        }
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  }

  const handleAddClick = (e, admission, patient) => {
    console.log('admission # ', admission)
    console.log('patient # ', patient)
    const addressInDetail = patient.address.split(',')


    formik.values.institute = admission.institute
    formik.values.admitDate = admission.date
    formik.values.bhtNo = admission.bhtNo
    formik.values.name = patient.name
    formik.values.age = patient.age
    formik.values.gender = patient.gender
    formik.values.telephoneMobile = patient.telephoneMobile
    formik.values.telephoneHome = patient.telephoneHome
    formik.values.address = patient.address
    formik.values.addressNo = addressInDetail.length >= 0 ? addressInDetail.shift() : ''
    formik.values.street = ''
    formik.values.addressOther = addressInDetail.length > 0 ? addressInDetail.join(' , ') : ''
    formik.values.postalcode = ''

    setCaseData({ admission, patient })
  }

  const formik = useFormik({
    initialValues: {
      diseaseNotified: ['Dengue Fever / Dengue Haemorhagic Fever', 'DEN'],
      institute: '',
      admitDate: '',
      bhtNo: '',
      name: '',
      age: '',
      gender: '',
      telephoneMobile: '',
      telephoneHome: '',
      address: '',
      addressNo: '',
      street: '',
      addressOther: '',
      postalcode: '',
      addressType: 'Home',
      ward: ''
    },
    validationSchema: validationSchemaAnalytics,
    onSubmit: (values, { setSubmitting }) => {
      console.log("formik values: ", values);

      const paramPatient = {
        "name": values.name,
        "telephoneHome": values.telephoneHome,
        "telephoneMobile": values.telephoneMobile,
        "address": values.address,
        "age": values.age,
        // "dateofbirth": values,
        "gender": values.gender,
        // "guardianname": '',
        // "occupation": '',
        "ethnicgroup": '',
        "addressNo": values.addressNo,
        "street": values.street,
        "addressOther": values.addressOther,
        "postalcode": values.postalcode,
        "addresstype": values.addressType
      }

      if (isValidString(values.ward)) {
        const wardDetails = Lodash.find(cmcRegionBoundaries, { _id: values.ward })
        if (wardDetails) {
          paramPatient["ward"] = wardDetails.name;
          paramPatient["wardid"] = wardDetails._id;
          paramPatient["moh"] = wardDetails.mohName;
          paramPatient["mohid"] = wardDetails.moh;
        }
      }

      //       diseaseNotified: ['Dengue Fever / Dengue Haemorhagic Fever', 'DEN'],
      // institute: '',
      // admitDate: '',
      // bhtNo: '',
      // name: '',
      // age: '',
      // gender: '',
      // telephoneMobile: '',
      // telephoneHome: '',
      // address: '',
      // addressNo: '',
      // street: '',
      // addressOther: '',
      // postalcode: '',
      // addressType: 'Home',
      // ward: ''

      // Notification
      var paramNotification = {
        "institute": values.institute,
        // "ward": '', //this is the hospital ward
        "bhtno": values.bhtNo,
        "dateofadmission": values.admitDate,
        "disease": values.diseaseNotified,
        // "labresults": '',
        // "patientid": '',
        // "dateofonset": dateOfOnSet,
        // "note": notes,
      }

      // Case
      // var params = {
      //   "_id": newNotification._id,
      //   "notificationid": newNotification._id,
      //   "diseasenotified": this.state.disease
      // }


      const params = {
        paramPatient,
        paramNotification
      }

      dispatch(createMbpCasesDensys(params));
      // setSubmitting(true);
    }
  });



  return (
    <>
      <PageHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Params*/}

        <Row style={caseData ? { display: 'none' } : { display: 'block' }}>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Upload Details</h3>
              </CardHeader>
              <CardBody>
                <FormGroup className="mb-3">
                  <FormControl fullWidth style={MbppStyles.Input}>
                    <label
                      className="form-control-label"
                      htmlFor="input-address"
                    >
                      Select an excel sheet to create cases
                    </label>
                    <TextField
                      fullWidth
                      id="xlsxdocument"
                      name="xlsxdocument"
                      // label='Select an excel sheet to create cases'
                      type="file"
                      variant="outlined"
                      multiple={false}
                      onChange={readUploadXlsx}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="form-control-alternative form-control-edit"
                    />
                  </FormControl>
                  <FormControl fullWidth style={MbppStyles.Input}>
                    <a href={showCaseTemplate} >Excel sheet structure should match with the following Densys template.</a>
                  </FormControl>
                </FormGroup>
              </CardBody>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Send Date</th>
                    <th scope="col">Admission</th>
                    <th scope="col">Patient</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {xlsxData && xlsxData.length > 0 && xlsxData.map((props, i) => {
                    return (
                      <TableRow
                        key={i}
                        dateSend={props.sendDate}
                        admission={props.admission}
                        patient={props.patient}
                        addClick={handleAddClick}
                        deleteClick={() => { }}
                      />
                    );
                  })}
                </tbody>
              </Table>
            </Card>

          </div>
        </Row>

        <br />

        {/* <Row style={caseData ? { display: 'block' } : { display: 'none' }}>

        </Row> */}

        {
          caseData && (
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <h3 className="mb-0">Selection Details</h3>
                  </CardHeader>
                  <Form
                    role="form"
                    onSubmit={formik.handleSubmit}
                  >
                    <CardBody>
                      <FormGroup className="mb-3">

                        {/* <Row>
                          <Col lg="6">
                            <TextField
                              fullWidth
                              type="date"
                              id="sendDate"
                              name="sendDate"
                              label="Send Date*"
                              variant="outlined"
                              format={'DD/MM/YYYY'}
                              value={formik.values.sendDate}
                              onChange={formik.handleChange}
                              style={MbppStyles.Input}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={formik.touched.sendDate && Boolean(formik.errors.sendDate)}
                              helperText={formik.touched.sendDate && formik.errors.sendDate}
                            />
                          </Col>
                        </Row> */}
                        <Row>
                          <Col lg="6">
                            <TextField
                              fullWidth
                              id="diseaseNotified"
                              name="diseaseNotified"
                              label="Disease Notified"
                              variant="outlined"
                              value={formik.values.diseaseNotified[0]}
                              onChange={formik.handleChange}
                              style={MbppStyles.Input}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              disabled={true}
                              error={formik.touched.diseaseNotified && Boolean(formik.errors.diseaseNotified)}
                              helperText={formik.touched.diseaseNotified && formik.errors.diseaseNotified}
                            >
                            </TextField>
                          </Col>
                        </Row>

                        {/* ------------------- */}
                        <label
                          className="form-control-label"
                          htmlFor="input-address"
                          style={{ marginBottom: 20 }}
                        >
                          Admission
                        </label>

                        <Row>
                          <Col lg="6">
                            <TextField
                              fullWidth
                              id="institute"
                              name="institute"
                              label="Institute"
                              variant="outlined"
                              value={formik.values.institute}
                              onChange={formik.handleChange}
                              style={MbppStyles.Input}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={formik.touched.institute && Boolean(formik.errors.institute)}
                              helperText={formik.touched.institute && formik.errors.institute}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            {console.log('formik.values.admitDate: ', formik.values.admitDate)}
                            <TextField
                              fullWidth
                              type="date"
                              id="admitDate"
                              name="admitDate"
                              label="Date"
                              variant="outlined"
                              format={'YYYY-MM-DD'}
                              value={formik.values.admitDate}
                              onChange={formik.handleChange}
                              style={MbppStyles.Input}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={formik.touched.admitDate && Boolean(formik.errors.admitDate)}
                              helperText={formik.touched.admitDate && formik.errors.admitDate}
                            />
                          </Col>
                          <Col lg="6">
                            <TextField
                              fullWidth
                              id="bhtNo"
                              name="bhtNo"
                              label="BHT No"
                              variant="outlined"
                              value={formik.values.bhtNo}
                              onChange={formik.handleChange}
                              style={MbppStyles.Input}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={formik.touched.bhtNo && Boolean(formik.errors.bhtNo)}
                              helperText={formik.touched.bhtNo && formik.errors.bhtNo}
                            />
                          </Col>
                        </Row>

                        {/* ------------------- */}
                        <label
                          className="form-control-label"
                          htmlFor="input-address"
                          style={{ marginBottom: 20 }}
                        >
                          Patient
                        </label>

                        <Row>
                          <Col lg="12">
                            <TextField
                              fullWidth
                              id="name"
                              name="name"
                              label="Name"
                              variant="outlined"
                              value={formik.values.name}
                              onChange={formik.handleChange}
                              style={MbppStyles.Input}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={formik.touched.name && Boolean(formik.errors.name)}
                              helperText={formik.touched.name && formik.errors.name}
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col lg="6">
                            <TextField
                              fullWidth
                              id="age"
                              name="age"
                              label="Age"
                              variant="outlined"
                              value={formik.values.age}
                              onChange={formik.handleChange}
                              style={MbppStyles.Input}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={formik.touched.age && Boolean(formik.errors.age)}
                              helperText={formik.touched.age && formik.errors.age}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <TextField
                              select
                              fullWidth
                              id="gender"
                              name="gender"
                              label="Gender"
                              variant="outlined"
                              value={formik.values.gender}
                              onChange={formik.handleChange}
                              style={MbppStyles.Input}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={formik.touched.gender && Boolean(formik.errors.gender)}
                              helperText={formik.touched.gender && formik.errors.gender}
                            >
                              <option value=''></option>
                              {genderList.map((item) => (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              ))}
                            </TextField>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <TextField
                              fullWidth
                              id="telephoneMobile"
                              name="telephoneMobile"
                              label="Telephone Mobile"
                              variant="outlined"
                              value={formik.values.telephoneMobile}
                              onChange={formik.handleChange}
                              style={MbppStyles.Input}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={formik.touched.telephoneMobile && Boolean(formik.errors.telephoneMobile)}
                              helperText={formik.touched.telephoneMobile && formik.errors.telephoneMobile}
                            />
                          </Col>
                          <Col lg="6">
                            <TextField
                              fullWidth
                              id="telephoneHome"
                              name="telephoneHome"
                              label="Telephone Home"
                              variant="outlined"
                              value={formik.values.telephoneHome}
                              onChange={formik.handleChange}
                              style={MbppStyles.Input}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={formik.touched.telephoneHome && Boolean(formik.errors.telephoneHome)}
                              helperText={formik.touched.telephoneHome && formik.errors.telephoneHome}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <TextField
                              fullWidth
                              id="address"
                              name="address"
                              label="Address"
                              variant="outlined"
                              value={formik.values.address}
                              onChange={formik.handleChange}
                              style={MbppStyles.Input}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={formik.touched.address && Boolean(formik.errors.address)}
                              helperText={formik.touched.address && formik.errors.address}
                            />
                          </Col>
                        </Row>

                        {/* ------------------- */}
                        <label
                          className="form-control-label"
                          htmlFor="input-address"
                          style={{ marginBottom: 20 }}
                        >
                          Address in details
                        </label>

                        <Row>
                          <Col lg="6">
                            <TextField
                              fullWidth
                              id="addressNo"
                              name="addressNo"
                              label="Address No"
                              variant="outlined"
                              value={formik.values.addressNo}
                              onChange={formik.handleChange}
                              style={MbppStyles.Input}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={formik.touched.addressNo && Boolean(formik.errors.addressNo)}
                              helperText={formik.touched.addressNo && formik.errors.addressNo}
                            >
                            </TextField>
                          </Col>
                          <Col lg="6">
                            <TextField
                              fullWidth
                              id="street"
                              name="street"
                              label="Street"
                              variant="outlined"
                              value={formik.values.street}
                              onChange={formik.handleChange}
                              style={MbppStyles.Input}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={formik.touched.street && Boolean(formik.errors.street)}
                              helperText={formik.touched.street && formik.errors.street}
                            >
                            </TextField>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <TextField
                              fullWidth
                              id="addressOther"
                              name="addressOther"
                              label="Address Other"
                              variant="outlined"
                              value={formik.values.addressOther}
                              onChange={formik.handleChange}
                              style={MbppStyles.Input}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={formik.touched.addressOther && Boolean(formik.errors.addressOther)}
                              helperText={formik.touched.addressOther && formik.errors.addressOther}
                            >
                            </TextField>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <TextField
                              fullWidth
                              id="postalcode"
                              name="postalcode"
                              label="Postal Code"
                              variant="outlined"
                              value={formik.values.postalcode}
                              onChange={formik.handleChange}
                              style={MbppStyles.Input}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={formik.touched.postalcode && Boolean(formik.errors.postalcode)}
                              helperText={formik.touched.postalcode && formik.errors.postalcode}
                            >
                            </TextField>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg="6">
                            <TextField
                              select
                              fullWidth
                              id="ward"
                              name="ward"
                              label="CMC Ward"
                              variant="outlined"
                              value={formik.values.ward}
                              onChange={formik.handleChange}
                              style={MbppStyles.Input}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={formik.touched.ward && Boolean(formik.errors.ward)}
                              helperText={formik.touched.ward && formik.errors.ward}
                            >
                              <option value=''></option>
                              {Object.entries(basicInformation.wardIds).map(([key, value]) => (
                                <option key={key} value={key}>
                                  {value}
                                </option>
                              ))}
                            </TextField>
                          </Col>
                        </Row>

                      </FormGroup>
                    </CardBody>
                    <CardFooter className="py-2 text-right">
                      <div className="d-flex justify-content-between order-xl-2 mb-5 mb-xl-0" style={{ padding: 0 }}>
                        <div>
                          <Button variant="contained" color="success" onClick={() => {
                            setCaseData(undefined)
                          }}>
                            BACK
                          </Button>
                        </div>
                        <div className="text-center">
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            // disabled={formik.isSubmitting}
                          >
                            CREATE
                          </Button>
                        </div>
                      </div>
                    </CardFooter>
                  </Form>
                </Card>
              </div>
            </Row>
          )
        }

      </Container >
    </>
  );
};

export default FindCaseIdDirectory;
