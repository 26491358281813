import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const UserHeader = () => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {

  }, [])

  return (
    <>

      <div className="header pb-9 pt-6 pt-md-9">
        {/* Mask */}
        <span className="mask bg-gradient-default opacity-6" />

      </div>
    </>
  );
};

export default UserHeader;
