import { 
  BREEDING_FETCH_ONE, 
  BREEDING_FETCH_USER, 
  BREEDING_FETCH_LOCATION, 
  BREEDING_FETCH_REGION_STATUS, 
  BREEDING_FETCH_ALL, 
  BREEDING_CREATE, 
  BREEDING_UPDATE, 
  BREEDING_DELETE 
} from '../constants/actionTypes';

const initState = {
  breedingSiteComplaintsById:{},
  breedingSiteComplaintsByUser:[],
  breedingSiteComplaintsByLocation:[],
  breedingSiteComplaintsByRegionStatus:[],
  breedingSiteComplaintsAll:[],
  breedingSiteComplaintsCreate:[],
  breedingSiteComplaintsUpdate:[],
  breedingSiteComplaintsDelete:[]
}

const breedingReducer =(breedingsite = initState, action) => {
  switch (action.type) {
    case BREEDING_FETCH_ONE:
        return {...breedingsite, breedingSiteComplaintsById: action.payload};
    case BREEDING_FETCH_USER:
        return {...breedingsite, breedingSiteComplaintsByUser: action.payload};
    case BREEDING_FETCH_LOCATION:
        return {...breedingsite, breedingSiteComplaintsByLocation: action.payload};
    case BREEDING_FETCH_REGION_STATUS:
        return {...breedingsite, breedingSiteComplaintsByRegionStatus: action.payload};
    case BREEDING_FETCH_ALL:
        return {...breedingsite, breedingSiteComplaintsAll: action.payload};
    case BREEDING_CREATE:
        return {...breedingsite, breedingSiteComplaintsCreate: action.payload};
    case BREEDING_UPDATE:
        return {...breedingsite, breedingSiteComplaintsUpdate: action.payload};
    case BREEDING_DELETE:
        return {...breedingsite, breedingSiteComplaintsDelete: action.payload};
    default:
      return breedingsite;
  }
};

export default breedingReducer;