import {
  MBP_FIND_CASES_DETAIL,
  MBP_FIND_CASES_FULL,
} from 'constants/actionTypes';

const initState = {
  CaseReports: [],
  CaseReportsFull: [],
}

const mbpCaseReducer = (mbpCase = initState, action) => {
  switch (action.type) {
    case MBP_FIND_CASES_DETAIL:
      return { ...mbpCase, CaseReports: action.payload };
    case MBP_FIND_CASES_FULL:
      return { ...mbpCase, CaseReportsFull: action.payload };
    default:
      return mbpCase;
  }
};

export default mbpCaseReducer;