import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { MbppStyles } from 'constants/globalStyles';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  FormGroup,
  Form,
  Button,
  Row,
  Col
} from "reactstrap";

import { TextField, FormControlLabel, Switch, Grid } from '@material-ui/core';

import { fetchFrmTopics, createFrmSubTopic, updateFrmSubTopic } from 'actions/forum';

// core components
import PageHeader from "components/Headers/PageHeader.js";

const validationSchemaSubTopic = yup.object({
  subTopic: yup
    .string('Enter contact title')
    .min(3, 'Description should be of minimum 3 characters length')
    .max(255, 'Contact title should be of maximum 255 characters length')
    .required('Contact title is required'),
  description: yup
    .string('Enter contact description')
    .min(5, 'Description should be of minimum 5 characters length')
    .max(500, 'Description should be of maximum 500 characters length'),
  status: yup
    .boolean('Enter contact Status')

});

const ForumSubTopicForm = (props) => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  // set state topic
  const [topic, setTopic] = useState(null);
  // set state topic description
  const [topicDescription, setTopicDescription] = useState(null);
  // get topic details
  const selectFrmTopic = (state) => {
    const itemArray = state.forum.frmAllTopics?.filter((item) => {
      return item._id === props.location.state?.frmTopicId
    });
    if (itemArray && itemArray[0]) {
      return itemArray[0]
    }
  }
  const topicDetails = useSelector(selectFrmTopic);
  // get sub topic details
  const selectFrmSubTopic = (topicDetails) => {
    const itemArray = topicDetails?.subTopic?.length > 0 && topicDetails?.subTopic?.filter((item) => {
      return item._id === props.location.state?.frmSubTopicId
    });
    if (itemArray && itemArray[0]) {
      return itemArray[0]
    }
  }
  const subTopicDetails = selectFrmSubTopic(topicDetails);

  useEffect(() => {
    // fetching forum topics
    dispatch(fetchFrmTopics());
  }, []);

  useEffect(() => {
    if (topicDetails && topicDetails._id) {
      formik.values.forumTopicId = topicDetails._id;
      setTopic(topicDetails.topic);
      setTopicDescription(topicDetails.description);
    }
    if (subTopicDetails && subTopicDetails._id) {
      formik.values.forumSubTopicId = subTopicDetails._id;
      formik.values.subTopic = subTopicDetails.subTopic;
      formik.values.description = subTopicDetails.description;
      formik.values.status = subTopicDetails.status;
    }
  }, [topicDetails, subTopicDetails]);

  const formik = useFormik({
    initialValues: {
      forumTopicId: '',
      forumSubTopicId: '',
      subTopic: '',
      description: '',
      status: true,
      redirect: '/registered/faq_management'
    },
    validationSchema: validationSchemaSubTopic,
    onSubmit: (values, onSubmitProps) => {
      if (values.forumTopicId && values.forumSubTopicId) {
        dispatch(updateFrmSubTopic(values, history));
      }
      else {
        dispatch(createFrmSubTopic(values, history));
      }
      console.log(values);
      // onSubmitProps.setSubmitting(false);
      // onSubmitProps.resetForm();
    },
  });

  return (
    <>
      <PageHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table School */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Sub Topic Details</h3>
              </CardHeader>
              <Form role="form" onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <CardBody>
                  <FormGroup className="mb-3">
                    {/* <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-heading" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <h3>{topic && topic}</h3>
                        <h6>{topicDescription && topicDescription}</h6>
                      </Grid>
                    </Grid> */}
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-heading  text-muted" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          id="subTopic"
                          name="subTopic"
                          label="Sub Topic"
                          variant="outlined"
                          value={formik.values.subTopic}
                          onChange={formik.handleChange}
                          multiline rows={3}
                          error={formik.touched.subTopic && Boolean(formik.errors.subTopic)}
                          helperText={formik.touched.subTopic && formik.errors.subTopic}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="ni ni-align-left-2" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          id="description"
                          name="description"
                          label="Description"
                          variant="outlined"
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          multiline rows={4}
                          error={formik.touched.description && Boolean(formik.errors.description)}
                          helperText={formik.touched.description && formik.errors.description}
                        />
                      </Grid>
                    </Grid>
                    {/* <Grid container spacing={1} alignItems="center" style={ChsStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-toggle-off" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <FormControlLabel
                          value="status"
                          control={
                            <Switch
                              checked={formik.values.status}
                              onChange={formik.handleChange}
                              color="primary"
                              name="status"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                              error={formik.touched.status && Boolean(formik.errors.status)}
                              helperText={formik.touched.status && formik.errors.status}
                            />}
                          label="Status"
                          labelPlacement="start"
                        />
                      </Grid>
                    </Grid> */}
                  </FormGroup>
                </CardBody>
   
                <CardFooter className="py-4">
                  <Row>
                    <Col lg="6" className="text-left">
                      <Button
                        style={MbppStyles.GeneralButton}
                        color="primary"
                        onClick={() => {
                          history.goBack();
                        }}>
                        BACK
                      </Button>
                    </Col>
                    <Col lg="6" className="text-right">
                      <Button
                        style={MbppStyles.GeneralButton}
                        color="primary"
                        type="reset">
                        RESET
                      </Button>
                      <Button
                        style={MbppStyles.GeneralButton}
                        color="primary"
                        type="submit">
                        {(formik.values.forumTopicId && formik.values.forumSubTopicId) ? 'UPDATE' : 'SAVE'}
                      </Button>
                    </Col>
                  </Row>
                </CardFooter>

              </Form>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ForumSubTopicForm;
