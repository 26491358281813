import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { MbppStyles } from 'constants/globalStyles';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  FormGroup,
  Form,
  Button,
  Row,
  Col,
} from "reactstrap";

import { TextField, FormControlLabel, Switch, Grid } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import { fetchContact, createContact, updateContact } from 'actions/contact';

import { Contactgroup, phoneRegExp, FILE_IMG_SIZE, SUPPORTED_IMG_FORMATS } from "helper.js"
import Lodash from "lodash";

// core components
import PageHeader from "components/Headers/PageHeader.js";

const validationSchemaContact = yup.object({
  title: yup
    .string('Enter contact title')
    .max(255, 'Contact title should be of maximum 255 characters length')
    .required('Contact title is required'),
  contact: yup
    .array()
    .of(yup
      .object({
        person: yup
          .string('Enter contact person name')
          .min(3, 'Address should be of minimum 3 characters length')
          .max(255, 'Contact person name should be of maximum 255 characters length'),
        number: yup
          .string('Enter contact person phone number')
          .matches(phoneRegExp, 'Phone number is not valid')
          .required('Phone number is required'),
      })
      .required('Contact details Required'),
    ),
  group: yup
    .string('Select contact group')
    .required('Contact groupRequired'),
  email: yup
    .string('Enter contact email')
    .email('Enter a valid email'),
  address: yup
    .string('Enter contact address')
    .min(3, 'Address should be of minimum 3 characters length')
    .max(500, 'Address should be of maximum 500 characters length'),
  description: yup
    .string('Enter contact description')
    .min(3, 'Description should be of minimum 3 characters length')
    .max(500, 'Description should be of maximum 500 characters length'),
  coverpic: yup
    .mixed()
    .nullable(true)
    .test(
      "fileSize",
      "File too large",
      function (value) { return value ? FILE_IMG_SIZE >= value.size : true }
    )
    .test(
      "fileFormat",
      "Unsupported Format",
      function (value) { return value ? SUPPORTED_IMG_FORMATS.includes(value.type) : true }
    ),
  status: yup
    .boolean('Enter contact Status')

});

const ContactForm = (props) => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [contactDetails, setContactDetails] = useState({})
  // set state coverpic
  const [coverpic, setCoverpic] = useState(null);
  // set state contact number list
  const [contactList, setContactList] = useState([{ person: "", number: "" }]);

  useEffect(() => {
    // fetching contacts
    let stateContact = props.location.state?.contact;
    if (stateContact && !Lodash.isEmpty(stateContact)) {
      setContactDetails(stateContact);
    }
  }, []);

  useEffect(() => {
    console.log("contactDetails: ", contactDetails);
    if (contactDetails && contactDetails._id) {
      formik.values.contactId = contactDetails._id;
      formik.values.title = contactDetails.title;
      formik.values.contact = contactDetails.contact;
      formik.values.group = contactDetails.group;
      formik.values.email = contactDetails.email;
      formik.values.address = contactDetails.address;
      formik.values.description = contactDetails.description;
      formik.values.status = contactDetails.status;
      setContactList(contactDetails.contact);
    }
  }, [contactDetails]);

  // handle change event of the coverpic
  const handleFileChange = (e) => {
    let reader = new FileReader();
    formik.values.coverpic = e.target.files[0];
    // console.log(formik.values.coverpic);
    reader.addEventListener("load", function (e) {
      setCoverpic(e.target.result);
      // console.log(e.target.result);
    });

    reader.readAsDataURL(e.target.files[0]);
  };

  // handle change event of the contact number
  const handleContactChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...contactList];
    list[index][name] = value;
    setContactList(list);
    formik.values.contact = contactList;
  };

  // handle click event of the Remove button of contact number
  const handleRemoveClick = index => {
    const list = [...contactList];
    list.splice(index, 1);
    setContactList(list);
  };

  // handle click event of the Add button of contact number
  const handleAddClick = () => {
    setContactList([...contactList, { person: "", number: "" }]);
  };

  const formik = useFormik({
    initialValues: {
      contactId: '',
      title: '',
      contact: [{
        person: '',
        number: ''
      }],
      group: '',
      email: '',
      address: '',
      description: '',
      coverpic: '',
      status: true,
      redirect: '/registered/contact'
    },
    validationSchema: validationSchemaContact,
    onSubmit: (values, onSubmitProps) => {
      console.log("formik values: ", values);

      // values.coverpic = coverpic;
      if (values.contactId) {
        dispatch(updateContact(values, history));
      }
      else {
        dispatch(createContact(values, history));
      }

      onSubmitProps.setSubmitting(false);
      setContactList([{ person: "", number: "" }]);
      // onSubmitProps.resetForm();
    },
    onReset: () => {
      setContactList([{ person: "", number: "" }]);
    },
  });

  return (
    <>
      <PageHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table School */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Contact Details</h3>
              </CardHeader>
              <Form role="form" onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <CardBody>
                  <FormGroup className="mb-3">
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-heading" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          id="title"
                          name="title"
                          label="Title"
                          variant="outlined"
                          value={formik.values.title}
                          onChange={formik.handleChange}
                          error={formik.touched.title && Boolean(formik.errors.title)}
                          helperText={formik.touched.title && formik.errors.title}
                        />
                      </Grid>
                    </Grid>
                    {/* {contactList.map((x, i) => {
                      return (
                        <Grid key={i} container spacing={1} alignItems="center">
                          <Grid item xs={1} sm={1} className="px-2 text-right">
                            <i className="ni ni-mobile-button">{(1 + i)}</i>
                          </Grid>
                          <Grid item xs={10} sm={10}>
                            <TextField style={{ width: '50%' }}
                              id="person"
                              name="person"
                              label={'Contact Person'}
                              variant="outlined"
                              value={x.person}
                              onChange={e => handleContactChange(e, i)}
                              error={(formik.errors.contact && formik.touched.contact) && (formik.touched.contact[i])?.person && Boolean((formik.errors.contact[i])?.person)}
                              helperText={(formik.errors.contact && formik.touched.contact) && (formik.touched.contact[i])?.person ? (formik.errors.contact[i])?.person : ''}
                            />
                            <TextField style={{ width: '50%' }}
                              id="number"
                              name="number"
                              label={'Contact Number'}
                              variant="outlined"
                              value={x.number}
                              onChange={e => handleContactChange(e, i)}
                              error={(formik.errors.contact && formik.touched.contact) && (formik.touched.contact[i])?.number && Boolean((formik.errors.contact[i])?.number)}
                              helperText={(formik.errors.contact && formik.touched.contact) && (formik.touched.contact[i])?.number ? (formik.errors.contact[i])?.number : ''}
                            />
                          </Grid>
                          <Grid item xs={1} sm={1} >
                            {contactList.length !== 1 && <RemoveCircleOutlineIcon className="mr10" onClick={() => handleRemoveClick(i)} />}
                            {(contactList.length - 1 === i && !(contactList.length > 4)) && <AddCircleOutlineIcon className="ma10" onClick={handleAddClick} />}
                          </Grid>
                        </Grid>
                      );
                    })} */}
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="ni ni-mobile-button" />
                      </Grid>
                      <Grid item xs={5} sm={5}>
                        <TextField
                          fullWidth
                          id="person"
                          name="person"
                          label={'Contact Person'}
                          variant="outlined"
                          value={contactList[0].person}
                          onChange={e => handleContactChange(e, 0)}
                          error={(formik.errors.contact && formik.touched.contact) && (formik.touched.contact[0])?.person && Boolean((formik.errors.contact[0])?.person)}
                          helperText={(formik.errors.contact && formik.touched.contact) && (formik.touched.contact[0])?.person ? (formik.errors.contact[0])?.person : ''}
                        />
                      </Grid>
                      <Grid item xs={5} sm={5}>
                        <TextField
                          fullWidth
                          id="number"
                          name="number"
                          label={'Contact Number'}
                          variant="outlined"
                          value={contactList[0].number}
                          onChange={e => handleContactChange(e, 0)}
                          error={(formik.errors.contact && formik.touched.contact) && (formik.touched.contact[0])?.number && Boolean((formik.errors.contact[0])?.number)}
                          helperText={(formik.errors.contact && formik.touched.contact) && (formik.touched.contact[0])?.number ? (formik.errors.contact[0])?.number : ''}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-users" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          select
                          fullWidth
                          id="group"
                          name="group"
                          label="Group"
                          variant="outlined"
                          value={formik.values.group}
                          onChange={formik.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          error={formik.touched.group && Boolean(formik.errors.group)}
                          helperText={formik.touched.group && formik.errors.group}
                          disabled={true}
                        >
                          {/* <option value=''></option> */}
                          {Contactgroup.map((item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="ni ni-email-83" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          id="email"
                          name="email"
                          label="Email"
                          variant="outlined"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          error={formik.touched.email && Boolean(formik.errors.email)}
                          helperText={formik.touched.email && formik.errors.email}
                          disabled={true}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-address-card" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          id="address"
                          name="address"
                          label="Address"
                          variant="outlined"
                          value={formik.values.address}
                          onChange={formik.handleChange}
                          multiline rows={4}
                          error={formik.touched.address && Boolean(formik.errors.address)}
                          helperText={formik.touched.address && formik.errors.address}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="ni ni-align-left-2" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          id="description"
                          name="description"
                          label="Description"
                          variant="outlined"
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          multiline rows={4}
                          error={formik.touched.description && Boolean(formik.errors.description)}
                          helperText={formik.touched.description && formik.errors.description}
                        />
                      </Grid>
                    </Grid>
                    {/* <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="ni ni-image" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          id="coverpic"
                          name="coverpic"
                          type="file"
                          label="Picture"
                          name='coverpic'
                          variant="outlined"
                          multiple={false}
                          onChange={handleFileChange}
                          InputLabelProps={{ shrink: true, }}
                          error={formik.touched.coverpic && Boolean(formik.errors.coverpic)}
                          helperText={formik.touched.coverpic && formik.errors.coverpic}
                          disabled={true}
                        />
                      </Grid>
                    </Grid> */}
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-toggle-off" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <FormControlLabel
                          value="status"
                          control={
                            <Switch
                              checked={formik.values.status}
                              onChange={formik.handleChange}
                              color="primary"
                              name="status"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                              error={formik.touched.status && Boolean(formik.errors.status)}
                              helperText={formik.touched.status && formik.errors.status}
                            />}
                          label="Public"
                          labelPlacement="start"
                        />
                      </Grid>
                    </Grid>
                  </FormGroup>
                </CardBody>
                {/* <CardFooter className="py-2 text-right">
                  <Button className="my-2" variant="contained" color="primary" type="reset">
                    Clear
                  </Button>
                  <Button className="my-2" variant="contained" color="primary" type="submit">
                    {(formik.values.contactId) ? 'Update' : 'Save'}
                  </Button>
                </CardFooter> */}
                <CardFooter className="py-4">
                  <Row>
                    <Col lg="6" className="text-left">
                      <Button
                        style={MbppStyles.GeneralButton}
                        color="primary"
                        onClick={() => {
                          history.goBack();
                        }}>
                        BACK
                      </Button>
                    </Col>
                    <Col lg="6" className="text-right">
                      <Button
                        style={MbppStyles.GeneralButton}
                        color="primary"
                        type="reset"
                        onClick={() => { }}>
                        RESET
                      </Button>
                      <Button
                        style={MbppStyles.GeneralButton}
                        color="primary"
                        type="submit"
                        onClick={() => { }}>
                        {(formik.values.contactId) ? 'UPDATE' : 'SAVE'}
                      </Button>
                    </Col>
                  </Row>
                </CardFooter>
              </Form>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ContactForm;
