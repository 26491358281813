// javascipt plugin for creating charts
import Chart from "chart.js";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
import Header from "components/Headers/Header.js";
import * as actionType from 'constants/actionTypes';
import React, { useEffect, useState } from "react";
// react plugin used to create charts
import { Bar, Line } from "react-chartjs-2";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// import { fetchStatSummery } from 'actions/analytics'
import {
  Card,
  CardBody, CardHeader, CardTitle,
  Col, Container, Nav, NavItem,
  NavLink,
  Row, Table
} from "reactstrap"; // reactstrap components
import {
  chartOptions,
  getChart1,
  getChart2, parseOptions, setInitData
} from "variables/charts.js"; // core components
import { fetchStatBreedingSitesDate, fetchDashStat } from "actions/analytics";
import Moment from "moment";
import Lodash from "lodash";
import { Tooltip } from '@material-ui/core';
import { GlobalStylesColor } from 'constants/globalStyles';

const monthOptions = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]


const optionsGrid = {
  scales: {
    xAxes: [
      {
        display: true,
        scaleLabel: {
          display: true,
          labelString: "Months",
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          color: "#142237",
          zeroLineColor: "#142237",
        },
        ticks: {
          callback: function (value) {
            if (!(value % 1)) {
              return value;
            }
          },
          beginAtZero: true,
          steps: 1,
          stepValue: 1,
          // max: 5.2,
        },
        scaleLabel: {
          display: true,
          labelString: "Report count",
        },
      },
    ],
  },
};

const Index = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [lineChart, setLineChart] = useState({});
  const [dataSync, setDataSync] = useState(false);

  // tiles
  const [totalReports, setTotalReports] = useState(0);
  const [totalFaqs, setTotalFaqs] = useState(0);
  const [totalEvents, setTotalEvents] = useState(0);

  // get state Analytics details
  const statDashboard = useSelector((state) => state.analytics.StatDashboard);

  useEffect(() => {

    if (Lodash.isEmpty(statDashboard)) {
      console.log("#");
      dispatch(fetchDashStat());
    }
    else {
      console.log("..");
    }

  }, [])



  useEffect(() => {
    console.log("statDashboard: ", statDashboard);

    if (!Lodash.isEmpty(statDashboard)) {

      // Breeding sites
      let breedingSiteReports = statDashboard?.breedingSites;
      let yValues = [];
      let ttlBreedingSite = 0;
      let ttlFaqs = (statDashboard?.faqPublic && statDashboard?.faqPublic.length > 0) ? statDashboard?.faqPublic[0]?.count : 0;
      let ttlEvents = (statDashboard?.eventsPublic && statDashboard?.eventsPublic.length > 0) ? statDashboard?.eventsPublic[0]?.count : 0

      if (breedingSiteReports && breedingSiteReports.length > 0) {
        for (let index = 1; index <= 12; index++) {
          let monthValue = Lodash.find(breedingSiteReports, { _id: { month: index } });
          monthValue = Lodash.isEmpty(monthValue) ? 0 : monthValue.count;
          yValues.push(monthValue);
          ttlBreedingSite = ttlBreedingSite + monthValue;
        }
      }
      setLineChart({ xLabels: monthOptions, yValues: yValues });

      setTotalReports(ttlBreedingSite);
      setTotalFaqs(ttlFaqs);
      setTotalEvents(ttlEvents);
    }

  }, [statDashboard])


  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }


  return (
    <>
      {/* Header content */}
      <Header />

      {/* Page content */}
      <Container className="mt--7" fluid>

        {/* Card stats */}
        <Row>

          <Col lg="6" xl="4">
            <Card className="card-stats mb-4 mb-xl-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      This year <b>Reports</b>
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0">
                      {totalReports}
                    </span>
                  </div>
                  <Col className="col-auto">
                    {/* <div className="icon icon-shape text-white rounded-circle shadow" style={{ backgroundColor: GlobalStylesColor.mbppLogoBlue }}></div> */}
                    <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                      <i className="fas fa-clipboard-list" style={{ fontSize: 32 }} />
                    </div>
                  </Col>
                </Row>
                <p className="mt-3 mb-0 text-muted text-sm">
                  <span className="text-nowrap">Breeding site reports</span>
                </p>
              </CardBody>
            </Card>
          </Col>

          <Col lg="6" xl="4">
            <Card className="card-stats mb-4 mb-xl-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      This year <b>Questions</b>
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0">
                      {totalFaqs}
                    </span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                      <i className="fas fa-question" style={{ fontSize: 32 }} />
                    </div>
                  </Col>
                </Row>
                <p className="mt-3 mb-0 text-muted text-sm">
                  <span className="text-nowrap">Questions by the public</span>
                </p>
              </CardBody>
            </Card>
          </Col>

          <Col lg="6" xl="4">
            <Card className="card-stats mb-4 mb-xl-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      This month <b>Activities</b>
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0">
                      {totalEvents}
                    </span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                      <i className="fas fa-bell" style={{ fontSize: 32 }} />
                    </div>
                  </Col>
                </Row>
                <p className="mt-3 mb-0 text-muted text-sm">
                  <span className="text-nowrap">Public calendar activities</span>
                </p>
              </CardBody>
            </Card>
          </Col>

        </Row>

        <br />

        {/* Top charts */}
        <Row>
          <Col className="mb-5 mb-xl-0">
            <Card className="bg-gradient-default shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <Col md="11">
                    <h6 className="text-uppercase text-light ls-1 mb-1">
                      Overview
                    </h6>
                    <h2 className="text-white mb-0">This Year's Breeding Site Reports</h2>
                  </Col>
                  <Col md="1">
                    <Tooltip title="Update Statistics" arrow>
                      <div
                        className="navbar-toggler"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          setDataSync(true);
                          dispatch(fetchDashStat());
                          setTimeout(() => {
                            setDataSync(false);
                          }, 3000);
                        }}
                      >
                        <i className={dataSync ? "fas fa-sync-alt fa-spin" : "fas fa-sync-alt"} style={{ fontSize: 30, color: "#fff" }} />
                      </div>
                    </Tooltip>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                {Object.keys(lineChart).length > 0 && (
                  <div className="chart">
                    <Line
                      data={{
                        labels: lineChart.xLabels,
                        datasets: [
                          {
                            data: lineChart.yValues,
                            label: "Reports",
                            fill: false,
                            backgroundColor: "#142237",
                            borderColor: "#142237",
                            pointBorderWidth: 5,

                            pointBorderColor: "white",
                            pointStyle: "circle",
                            pointRadius: 1,
                            pointBackgroundColor: "rgb(255, 255, 255)",
                            borderWidth: 0,
                          },
                        ],
                      }}
                      options={optionsGrid}
                      getDatasetAtEvent={(e) => console.log(e)}
                    />
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

      </Container>
    </>
  );
};

export default Index;
