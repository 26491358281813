export const basicInformation = {
  mohNames: {
    "MOH D1": "5c7692b41427cc104cd56626",
    "MOH D2A": "5c7692c71427cc104cd56627",
    "MOH D2B": "5c7692d21427cc104cd56628",
    "MOH D3": "5c7692e61427cc104cd56629",
    "MOH D4": "5c7692ee1427cc104cd5662a",
    "MOH D5": "5c7693151427cc104cd5662b"
  },
  mohIds: {
    "5c7692b41427cc104cd56626": "MOH D1",
    "5c7692c71427cc104cd56627": "MOH D2A",
    "5c7692d21427cc104cd56628": "MOH D2B",
    "5c7692e61427cc104cd56629": "MOH D3",
    "5c7692ee1427cc104cd5662a": "MOH D4",
    "5c7693151427cc104cd5662b": "MOH D5",
    "60977e00663a7b15f071f14d": "Other"
  },
  // wardIds:{
  //   "5c78bd83be95e91110854b81":"Mattakkuliya",
  //   "5c78bd96be95e91110854b82":"Modera",
  //   "5c78bebbbe95e91110854b83":"Aluthmawatha",
  //   "5c78bed5be95e91110854b84":"Lunupokuna",
  //   "5c78bef7be95e91110854b85":"Bloemandhal",
  //   "5c78bf0abe95e91110854b86":"Kotahena East",
  //   "5c78bf20be95e91110854b87":"Kotahena West",
  //   "5c78bf85be95e91110854b88":"Kochchikade North",
  //   "5c78bfa6be95e91110854b89":"Gintupitiya",
  //   "5c78bfcdbe95e91110854b8a":"Masangas Weediya",
  //   "5c78bfe2be95e91110854b8b":"New Bazaar",
  //   "5c78c004be95e91110854b8c":"Grandpass North",
  //   "5c78c014be95e91110854b8d":"Grandpass South",
  //   "5c78c15dbe95e91110854b8e":"Aluthkade East",
  //   "5c78c17abe95e91110854b8f":"Aluthkade West",
  //   "5c78c195be95e91110854b90":"Kehelwatte",
  //   "5c78c1bbbe95e91110854b91":"Kochchikade South",
  //   "5c78c1d5be95e91110854b92":"Fort",
  //   "5c78c234be95e91110854b93":"Maligawatte West",
  //   "5c78c277be95e91110854b94":"Kopannaweediya",
  //   "5c78c2adbe95e91110854b95":"Wekanda",
  //   "5c78c36dbe95e91110854b96":"Hunupitiya",
  //   "5c78c385be95e91110854b97":"Suduwella",
  //   "5c78c461be95e91110854b98":"Panchikawatte",
  //   "5c78c47bbe95e91110854b99":"Maradana",
  //   "5c78c4a3be95e91110854b9a":"Maligakanda",
  //   "5c78c4c5be95e91110854b9b":"Maligawatte East",
  //   "5c78c500be95e91110854b9c":"Kollupitiya",
  //   "5c78c550be95e91110854b9d":"Dematagoda",
  //   "5c78c57cbe95e91110854b9e":"Wanathamulla",
  //   "5c78c599be95e91110854b9f":"Kuppiyawatte East",
  //   "5c78c5b4be95e91110854ba0":"Kuppiyawatte West",
  //   "5c78c5e7be95e91110854ba1":"Borella North",
  //   "5c78c62cbe95e91110854ba2":"Borella South",
  //   "5c78c650be95e91110854ba3":"Cinnamon Gardens",
  //   "5c78c6c0be95e91110854ba4":"Narahenpita",
  //   "5c78c70cbe95e91110854ba5":"Thimbirigasyaya",
  //   "5c78c72ebe95e91110854ba6":"Kirula",
  //   "5c78c761be95e91110854ba7":"Kirillapone",
  //   "5c78c78abe95e91110854ba8":"Pamankada East",
  //   "5c78c7dfbe95e91110854ba9":"Bambalapitiya",
  //   "5c78c7f6be95e91110854baa":"Milagiriya",
  //   "5c78c81abe95e91110854bab":"Havelok Twon",
  //   "5c78c838be95e91110854bac":"Wellewatte North",
  //   "5c78c85abe95e91110854bad":"Pamankada West",
  //   "5c78c86fbe95e91110854bae":"Wellewatte South",
  //   "5d831ea799c972b35b73559f":"Mahawatta",
  //   "60977e4f663a7b15f071f14e":"Other"
  // },
  wardIds: {
    "5c78c15dbe95e91110854b8e": "Aluthkade East",
    "5c78c17abe95e91110854b8f": "Aluthkade West",
    "5c78bebbbe95e91110854b83": "Aluthmawatha",
    "5c78c7dfbe95e91110854ba9": "Bambalapitiya",
    "5c78bef7be95e91110854b85": "Bloemandhal",
    "5c78c5e7be95e91110854ba1": "Borella North",
    "5c78c62cbe95e91110854ba2": "Borella South",
    "5c78c650be95e91110854ba3": "Cinnamon Gardens",
    "5c78c550be95e91110854b9d": "Dematagoda",
    "5c78c1d5be95e91110854b92": "Fort",
    "5c78bfa6be95e91110854b89": "Gintupitiya",
    "5c78c004be95e91110854b8c": "Grandpass North",
    "5c78c014be95e91110854b8d": "Grandpass South",
    "5c78c81abe95e91110854bab": "Havelok Twon",
    "5c78c36dbe95e91110854b96": "Hunupitiya",
    "5c78c195be95e91110854b90": "Kehelwatte",
    "5c78c761be95e91110854ba7": "Kirillapone",
    "5c78c72ebe95e91110854ba6": "Kirula",
    "5c78bf85be95e91110854b88": "Kochchikade North",
    "5c78c1bbbe95e91110854b91": "Kochchikade South",
    "5c78c500be95e91110854b9c": "Kollupitiya",
    "5c78c277be95e91110854b94": "Kopannaweediya",
    "5c78bf0abe95e91110854b86": "Kotahena East",
    "5c78bf20be95e91110854b87": "Kotahena West",
    "5c78c599be95e91110854b9f": "Kuppiyawatte East",
    "5c78c5b4be95e91110854ba0": "Kuppiyawatte West",
    "5c78bed5be95e91110854b84": "Lunupokuna",
    "5d831ea799c972b35b73559f": "Mahawatta",
    "5c78c4a3be95e91110854b9a": "Maligakanda",
    "5c78c4c5be95e91110854b9b": "Maligawatte East",
    "5c78c234be95e91110854b93": "Maligawatte West",
    "5c78c47bbe95e91110854b99": "Maradana",
    "5c78bfcdbe95e91110854b8a": "Masangas Weediya",
    "5c78bd83be95e91110854b81": "Mattakkuliya",
    "5c78c7f6be95e91110854baa": "Milagiriya",
    "5c78bd96be95e91110854b82": "Modera",
    "5c78c6c0be95e91110854ba4": "Narahenpita",
    "5c78bfe2be95e91110854b8b": "New Bazaar",
    "5c78c78abe95e91110854ba8": "Pamankada East",
    "5c78c85abe95e91110854bad": "Pamankada West",
    "5c78c461be95e91110854b98": "Panchikawatte",
    "5c78c385be95e91110854b97": "Suduwella",
    "5c78c70cbe95e91110854ba5": "Thimbirigasyaya",
    "5c78c57cbe95e91110854b9e": "Wanathamulla",
    "5c78c2adbe95e91110854b95": "Wekanda",
    "5c78c838be95e91110854bac": "Wellewatte North",
    "5c78c86fbe95e91110854bae": "Wellewatte South",
    "60977e4f663a7b15f071f14e": "Other",
  }
}

export const cmcRegionBoundaries = [
  {
    _id: "5c78bd83be95e91110854b81",
    name: "Mattakkuliya",
    number: "01",
    code: "01",
    moh: "5c7692b41427cc104cd56626",
    mohName: "D1",
    coordinates: [{ longitude: 79.867904, latitude: 6.970363 }, { longitude: 79.872198, latitude: 6.969175 }, { longitude: 79.872678, latitude: 6.969836 }, { longitude: 79.875576, latitude: 6.968265 }, { longitude: 79.874555, latitude: 6.966411 }, { longitude: 79.875006, latitude: 6.964471 }, { longitude: 79.878460, latitude: 6.963227 }, { longitude: 79.880240, latitude: 6.965805 }, { longitude: 79.883406, latitude: 6.969432 }, { longitude: 79.885221, latitude: 6.974277 }, { longitude: 79.884903, latitude: 6.976615 }, { longitude: 79.880775, latitude: 6.980602 }, { longitude: 79.878176, latitude: 6.980969 }, { longitude: 79.877622, latitude: 6.980576 }, { longitude: 79.869799, latitude: 6.978942 }, { longitude: 79.868491, latitude: 6.977492 }, { longitude: 79.867836, latitude: 6.970231 }],
  },
  {
    _id: "5c78bd96be95e91110854b82",
    name: "Modera",
    number: "02",
    code: "02",
    moh: "5c7692b41427cc104cd56626",
    mohName: "D1",
    coordinates: [{ longitude: 79.868118, latitude: 6.970227 }, { longitude: 79.867767, latitude: 6.970232 }, { longitude: 79.866753, latitude: 6.968044 }, { longitude: 79.865921, latitude: 6.967121 }, { longitude: 79.868561, latitude: 6.964220 }, { longitude: 79.867676, latitude: 6.962897 }, { longitude: 79.867836, latitude: 6.961428 }, { longitude: 79.868500, latitude: 6.959419 }, { longitude: 79.869598, latitude: 6.956671 }, { longitude: 79.871490, latitude: 6.960781 }, { longitude: 79.872253, latitude: 6.961238 }, { longitude: 79.872490, latitude: 6.963903 }, { longitude: 79.872681, latitude: 6.965234 }, { longitude: 79.873642, latitude: 6.966422 }, { longitude: 79.874550, latitude: 6.966544 }, { longitude: 79.875641, latitude: 6.968464 }, { longitude: 79.872536, latitude: 6.969971 }, { longitude: 79.872192, latitude: 6.969442 }, { longitude: 79.868118, latitude: 6.970227 }],
  },
  {
    _id: "5d831ea799c972b35b73559f",
    name: "Mahawatta",
    number: "03",
    code: "03",
    moh: "5c7692b41427cc104cd56626",
    mohName: "D1",
    coordinates: [{ longitude: 79.872398, latitude: 6.965238 }, { longitude: 79.872917, latitude: 6.963697 }, { longitude: 79.872253, latitude: 6.961105 }, { longitude: 79.871208, latitude: 6.960718 }, { longitude: 79.869385, latitude: 6.956540 }, { longitude: 79.868637, latitude: 6.959417 }, { longitude: 79.866943, latitude: 6.956105 }, { longitude: 79.875427, latitude: 6.955730 }, { longitude: 79.874977, latitude: 6.953335 }, { longitude: 79.875893, latitude: 6.952656 }, { longitude: 79.879166, latitude: 6.958416 }, { longitude: 79.878464, latitude: 6.963227 }, { longitude: 79.875145, latitude: 6.964469 }, { longitude: 79.874413, latitude: 6.966479 }],
  },
  {
    _id: "5c78bebbbe95e91110854b83",
    name: "Aluthmawatha",
    number: "04",
    code: "04",
    moh: "5c7692b41427cc104cd56626",
    mohName: "D1",
    coordinates: [{ longitude: 79.861084, latitude: 6.962440 }, { longitude: 79.861198, latitude: 6.962332 }, { longitude: 79.863930, latitude: 6.959313 }, { longitude: 79.863548, latitude: 6.958732 }, { longitude: 79.864632, latitude: 6.957599 }, { longitude: 79.863960, latitude: 6.957288 }, { longitude: 79.864426, latitude: 6.956217 }, { longitude: 79.866951, latitude: 6.956184 }, { longitude: 79.868309, latitude: 6.958458 }, { longitude: 79.868584, latitude: 6.959201 }, { longitude: 79.868294, latitude: 6.959471 }, { longitude: 79.867928, latitude: 6.961394 }, { longitude: 79.867683, latitude: 6.962835 }, { longitude: 79.868446, latitude: 6.964264 }, { longitude: 79.865768, latitude: 6.966963 }, { longitude: 79.856102, latitude: 6.962078 }, { longitude: 79.858124, latitude: 6.961839 }, { longitude: 79.859062, latitude: 6.962466 }, { longitude: 79.861084, latitude: 6.962440 }],
  },
  {
    _id: "5c78bed5be95e91110854b84",
    name: "Lunupokuna",
    number: "05",
    code: "05",
    moh: "5c7692b41427cc104cd56626",
    mohName: "D1",
    coordinates: [{ longitude: 79.861092, latitude: 6.962492 }, { longitude: 79.860977, latitude: 6.962600 }, { longitude: 79.859070, latitude: 6.962625 }, { longitude: 79.858246, latitude: 6.961889 }, { longitude: 79.856285, latitude: 6.962021 }, { longitude: 79.855278, latitude: 6.962034 }, { longitude: 79.855286, latitude: 6.961501 }, { longitude: 79.852150, latitude: 6.961168 }, { longitude: 79.852051, latitude: 6.960636 }, { longitude: 79.855469, latitude: 6.960593 }, { longitude: 79.855629, latitude: 6.957447 }, { longitude: 79.853119, latitude: 6.956840 }, { longitude: 79.852951, latitude: 6.956575 }, { longitude: 79.856705, latitude: 6.956634 }, { longitude: 79.856438, latitude: 6.956051 }, { longitude: 79.855263, latitude: 6.955853 }, { longitude: 79.855286, latitude: 6.954574 }, { longitude: 79.853210, latitude: 6.954441 }, { longitude: 79.852036, latitude: 6.951206 }, { longitude: 79.858330, latitude: 6.949580 }, { longitude: 79.858322, latitude: 6.950006 }, { longitude: 79.859612, latitude: 6.950043 }, { longitude: 79.859970, latitude: 6.951903 }, { longitude: 79.860748, latitude: 6.952107 }, { longitude: 79.862099, latitude: 6.951716 }, { longitude: 79.862061, latitude: 6.954115 }, { longitude: 79.862785, latitude: 6.954692 }, { longitude: 79.863441, latitude: 6.955269 }, { longitude: 79.863434, latitude: 6.956069 }, { longitude: 79.864326, latitude: 6.956004 }, { longitude: 79.864082, latitude: 6.957286 }, { longitude: 79.864578, latitude: 6.957759 }, { longitude: 79.863564, latitude: 6.958625 }, { longitude: 79.863831, latitude: 6.959367 }, { longitude: 79.861092, latitude: 6.962492 }],
  },
  {
    _id: "5c78bef7be95e91110854b85",
    name: "Bloemandhal",
    number: "06",
    code: "06",
    moh: "5c7692b41427cc104cd56626",
    mohName: "D1",
    coordinates: [{ longitude: 79.863319, latitude: 6.956202 }, { longitude: 79.863380, latitude: 6.955356 }, { longitude: 79.862831, latitude: 6.954785 }, { longitude: 79.863312, latitude: 6.954024 }, { longitude: 79.865349, latitude: 6.952353 }, { longitude: 79.865456, latitude: 6.951418 }, { longitude: 79.864120, latitude: 6.947211 }, { longitude: 79.865761, latitude: 6.946746 }, { longitude: 79.866478, latitude: 6.946070 }, { longitude: 79.867401, latitude: 6.946769 }, { longitude: 79.868576, latitude: 6.946176 }, { longitude: 79.868027, latitude: 6.945605 }, { longitude: 79.870811, latitude: 6.946459 }, { longitude: 79.872406, latitude: 6.949017 }, { longitude: 79.874062, latitude: 6.950596 }, { longitude: 79.874763, latitude: 6.950632 }, { longitude: 79.874886, latitude: 6.951875 }, { longitude: 79.875809, latitude: 6.952575 }, { longitude: 79.875000, latitude: 6.953296 }, { longitude: 79.875427, latitude: 6.955736 }, { longitude: 79.866966, latitude: 6.956155 }, { longitude: 79.864349, latitude: 6.956144 }, { longitude: 79.863327, latitude: 6.956157 }, { longitude: 79.863319, latitude: 6.956202 }],
  },
  {
    _id: "5c78bf0abe95e91110854b86",
    name: "Kotahena East",
    number: "07",
    code: "07",
    moh: "5c7692b41427cc104cd56626",
    mohName: "D1",
    coordinates: [{ longitude: 79.862846, latitude: 6.954899 }, { longitude: 79.862183, latitude: 6.953956 }, { longitude: 79.862221, latitude: 6.951599 }, { longitude: 79.862297, latitude: 6.949844 }, { longitude: 79.861153, latitude: 6.945748 }, { longitude: 79.862999, latitude: 6.945574 }, { longitude: 79.863823, latitude: 6.943408 }, { longitude: 79.864975, latitude: 6.943794 }, { longitude: 79.864967, latitude: 6.944346 }, { longitude: 79.865494, latitude: 6.944440 }, { longitude: 79.866570, latitude: 6.946030 }, { longitude: 79.865768, latitude: 6.946842 }, { longitude: 79.864021, latitude: 6.947215 }, { longitude: 79.865433, latitude: 6.951307 }, { longitude: 79.865364, latitude: 6.952461 }, { longitude: 79.863396, latitude: 6.953740 }, { longitude: 79.863380, latitude: 6.954441 }, { longitude: 79.862907, latitude: 6.954648 }, { longitude: 79.862846, latitude: 6.954899 }],
  },
  {
    _id: "5c78bf20be95e91110854b87",
    name: "Kotahena West",
    number: "08",
    code: "08",
    moh: "5c7692b41427cc104cd56626",
    mohName: "D1",
    coordinates: [{ longitude: 79.862320, latitude: 6.951648 }, { longitude: 79.860893, latitude: 6.952117 }, { longitude: 79.860161, latitude: 6.951776 }, { longitude: 79.859665, latitude: 6.949978 }, { longitude: 79.858345, latitude: 6.950095 }, { longitude: 79.858299, latitude: 6.949544 }, { longitude: 79.852112, latitude: 6.951227 }, { longitude: 79.851425, latitude: 6.947877 }, { longitude: 79.855392, latitude: 6.946724 }, { longitude: 79.857399, latitude: 6.946448 }, { longitude: 79.860466, latitude: 6.945657 }, { longitude: 79.860992, latitude: 6.945750 }, { longitude: 79.862244, latitude: 6.949744 }],
  },
  {
    _id: "5c78bf85be95e91110854b88",
    name: "Kochchikade North",
    number: "09",
    code: "09",
    moh: "5c7692c71427cc104cd56627",
    mohName: "D2A",
    coordinates: [{ longitude: 79.857521, latitude: 6.946404 }, { longitude: 79.855286, latitude: 6.946694 }, { longitude: 79.851463, latitude: 6.947861 }, { longitude: 79.848808, latitude: 6.942564 }, { longitude: 79.851334, latitude: 6.941451 }, { longitude: 79.852600, latitude: 6.940614 }, { longitude: 79.852707, latitude: 6.938786 }, { longitude: 79.853363, latitude: 6.939076 }, { longitude: 79.855370, latitude: 6.941213 }, { longitude: 79.855652, latitude: 6.943297 }, { longitude: 79.858475, latitude: 6.943223 }, { longitude: 79.857483, latitude: 6.946367 }, { longitude: 79.857521, latitude: 6.946404 }],
  },
  {
    _id: "5c78bfa6be95e91110854b89",
    name: "Gintupitiya",
    number: "10",
    code: "10",
    moh: "5c7692c71427cc104cd56627",
    mohName: "D2A",
    coordinates: [{ longitude: 79.860573, latitude: 6.945694 }, { longitude: 79.857384, latitude: 6.946443 }, { longitude: 79.858414, latitude: 6.943224 }, { longitude: 79.855675, latitude: 6.943259 }, { longitude: 79.855354, latitude: 6.941325 }, { longitude: 79.857384, latitude: 6.941709 }, { longitude: 79.859306, latitude: 6.941572 }, { longitude: 79.861839, latitude: 6.942323 }, { longitude: 79.860535, latitude: 6.945657 }, { longitude: 79.860573, latitude: 6.945694 }],
  },
  {
    _id: "5c78bfcdbe95e91110854b8a",
    name: "Masangas Weediya",
    number: "11",
    code: "11",
    moh: "5c7692c71427cc104cd56627",
    mohName: "D2A",
    coordinates: [{ longitude: 79.860489, latitude: 6.945658 }, { longitude: 79.861755, latitude: 6.942399 }, { longitude: 79.859459, latitude: 6.941683 }, { longitude: 79.857536, latitude: 6.941782 }, { longitude: 79.858559, latitude: 6.939234 }, { longitude: 79.860863, latitude: 6.939353 }, { longitude: 79.864334, latitude: 6.940539 }, { longitude: 79.864006, latitude: 6.941326 }, { longitude: 79.863220, latitude: 6.941485 }, { longitude: 79.863014, latitude: 6.942084 }, { longitude: 79.863945, latitude: 6.942706 }, { longitude: 79.863937, latitude: 6.943340 }, { longitude: 79.862999, latitude: 6.945477 }, { longitude: 79.861191, latitude: 6.945686 }, { longitude: 79.860565, latitude: 6.945694 }, { longitude: 79.860489, latitude: 6.945658 }],
  },
  {
    _id: "5c78bfe2be95e91110854b8b",
    name: "New Bazaar",
    number: "12",
    code: "12",
    moh: "5c7692c71427cc104cd56627",
    mohName: "D2A",
    coordinates: [{ longitude: 79.869957, latitude: 6.945273 }, { longitude: 79.869415, latitude: 6.946038 }, { longitude: 79.868187, latitude: 6.945465 }, { longitude: 79.868706, latitude: 6.946047 }, { longitude: 79.867455, latitude: 6.946653 }, { longitude: 79.866585, latitude: 6.945822 }, { longitude: 79.865730, latitude: 6.944233 }, { longitude: 79.864929, latitude: 6.944243 }, { longitude: 79.865204, latitude: 6.943650 }, { longitude: 79.863792, latitude: 6.943415 }, { longitude: 79.863983, latitude: 6.942655 }, { longitude: 79.862930, latitude: 6.942079 }, { longitude: 79.863380, latitude: 6.941315 }, { longitude: 79.864006, latitude: 6.941307 }, { longitude: 79.864548, latitude: 6.940374 }, { longitude: 79.864922, latitude: 6.939274 }, { longitude: 79.864571, latitude: 6.938942 }, { longitude: 79.864586, latitude: 6.938184 }, { longitude: 79.866005, latitude: 6.937913 }, { longitude: 79.866211, latitude: 6.936057 }, { longitude: 79.868851, latitude: 6.937287 }, { longitude: 79.870743, latitude: 6.940295 }, { longitude: 79.869675, latitude: 6.941066 }, { longitude: 79.868752, latitude: 6.943099 }, { longitude: 79.869370, latitude: 6.943344 }, { longitude: 79.869164, latitude: 6.945199 }, { longitude: 79.869690, latitude: 6.945277 }, { longitude: 79.869957, latitude: 6.945273 }],
  },
  {
    _id: "5c78c004be95e91110854b8c",
    name: "Grandpass North",
    number: "13",
    code: "13",
    moh: "5c7692c71427cc104cd56627",
    mohName: "D2A",
    coordinates: [{ longitude: 79.874809, latitude: 6.950602 }, { longitude: 79.873970, latitude: 6.950569 }, { longitude: 79.872406, latitude: 6.949033 }, { longitude: 79.871140, latitude: 6.946604 }, { longitude: 79.871513, latitude: 6.946199 }, { longitude: 79.871017, latitude: 6.945227 }, { longitude: 79.872108, latitude: 6.944146 }, { longitude: 79.873672, latitude: 6.942703 }, { longitude: 79.875519, latitude: 6.944102 }, { longitude: 79.876556, latitude: 6.943822 }, { longitude: 79.877960, latitude: 6.943271 }, { longitude: 79.878754, latitude: 6.943350 }, { longitude: 79.878830, latitude: 6.947528 }, { longitude: 79.876144, latitude: 6.949074 }, { longitude: 79.875198, latitude: 6.949619 }, { longitude: 79.874863, latitude: 6.950513 }, { longitude: 79.874809, latitude: 6.950602 }],
  },
  {
    _id: "5c78c014be95e91110854b8d",
    name: "Grandpass South",
    number: "14",
    code: "14",
    moh: "5c7692c71427cc104cd56627",
    mohName: "D2A",
    coordinates: [{ longitude: 79.871140, latitude: 6.946604 }, { longitude: 79.870628, latitude: 6.946432 }, { longitude: 79.869606, latitude: 6.946001 }, { longitude: 79.869797, latitude: 6.945509 }, { longitude: 79.869240, latitude: 6.945294 }, { longitude: 79.869316, latitude: 6.943470 }, { longitude: 79.868858, latitude: 6.943209 }, { longitude: 79.869583, latitude: 6.941333 }, { longitude: 79.872978, latitude: 6.939289 }, { longitude: 79.873589, latitude: 6.939592 }, { longitude: 79.875603, latitude: 6.938900 }, { longitude: 79.877174, latitude: 6.939858 }, { longitude: 79.878166, latitude: 6.939623 }, { longitude: 79.877380, latitude: 6.938655 }, { longitude: 79.877419, latitude: 6.936698 }, { longitude: 79.878723, latitude: 6.936681 }, { longitude: 79.878761, latitude: 6.943261 }, { longitude: 79.878014, latitude: 6.943270 }, { longitude: 79.876602, latitude: 6.943777 }, { longitude: 79.875572, latitude: 6.944057 }, { longitude: 79.873672, latitude: 6.942659 }, { longitude: 79.872154, latitude: 6.944101 }, { longitude: 79.871017, latitude: 6.945271 }, { longitude: 79.871513, latitude: 6.946288 }, { longitude: 79.871185, latitude: 6.946470 }, { longitude: 79.871140, latitude: 6.946604 }],
  },
  {
    _id: "5c78c15dbe95e91110854b8e",
    name: "Aluthkade East",
    number: "16",
    code: "16",
    moh: "5c7692c71427cc104cd56627",
    mohName: "D2A",
    coordinates: [{ longitude: 79.864365, latitude: 6.940671 }, { longitude: 79.860802, latitude: 6.939453 }, { longitude: 79.861168, latitude: 6.938690 }, { longitude: 79.862709, latitude: 6.939050 }, { longitude: 79.862526, latitude: 6.936905 }, { longitude: 79.861801, latitude: 6.935187 }, { longitude: 79.862518, latitude: 6.934294 }, { longitude: 79.864716, latitude: 6.935529 }, { longitude: 79.865028, latitude: 6.935399 }, { longitude: 79.863754, latitude: 6.934489 }, { longitude: 79.864082, latitude: 6.933347 }, { longitude: 79.864143, latitude: 6.932547 }, { longitude: 79.865341, latitude: 6.932447 }, { longitude: 79.866104, latitude: 6.934332 }, { longitude: 79.866119, latitude: 6.936016 }, { longitude: 79.866005, latitude: 6.937913 }, { longitude: 79.864494, latitude: 6.938058 }, { longitude: 79.864487, latitude: 6.938816 }, { longitude: 79.864922, latitude: 6.939274 }, { longitude: 79.864372, latitude: 6.940587 }, { longitude: 79.864365, latitude: 6.940671 }],
  },
  {
    _id: "5c78c17abe95e91110854b8f",
    name: "Aluthkade West",
    mohName: "D2A",
    number: "17",
    code: "17",
    moh: "5c7692c71427cc104cd56627",
    coordinates: [{ longitude: 79.858749, latitude: 6.939230 }, { longitude: 79.858406, latitude: 6.937189 }, { longitude: 79.857735, latitude: 6.935464 }, { longitude: 79.857986, latitude: 6.934438 }, { longitude: 79.859154, latitude: 6.934423 }, { longitude: 79.859703, latitude: 6.934816 }, { longitude: 79.859734, latitude: 6.935749 }, { longitude: 79.860550, latitude: 6.937295 }, { longitude: 79.862518, latitude: 6.937092 }, { longitude: 79.862625, latitude: 6.939047 }, { longitude: 79.861183, latitude: 6.938754 }, { longitude: 79.860847, latitude: 6.939425 }, { longitude: 79.858749, latitude: 6.939230 }],
  },
  {
    _id: "5c78c195be95e91110854b90",
    name: "Kehelwatte",
    number: "18",
    code: "18",
    moh: "5c7692c71427cc104cd56627",
    mohName: "D2A",
    coordinates: [{ longitude: 79.862656, latitude: 6.936903 }, { longitude: 79.860703, latitude: 6.937265 }, { longitude: 79.859756, latitude: 6.935761 }, { longitude: 79.859772, latitude: 6.934750 }, { longitude: 79.859291, latitude: 6.934419 }, { longitude: 79.858009, latitude: 6.934394 }, { longitude: 79.857681, latitude: 6.935450 }, { longitude: 79.855118, latitude: 6.935315 }, { longitude: 79.854958, latitude: 6.934222 }, { longitude: 79.856529, latitude: 6.932770 }, { longitude: 79.857246, latitude: 6.932003 }, { longitude: 79.859108, latitude: 6.931811 }, { longitude: 79.860168, latitude: 6.932218 }, { longitude: 79.861839, latitude: 6.932534 }, { longitude: 79.864090, latitude: 6.933221 }, { longitude: 79.863716, latitude: 6.934363 }, { longitude: 79.865028, latitude: 6.935272 }, { longitude: 79.864761, latitude: 6.935528 }, { longitude: 79.862518, latitude: 6.934336 }, { longitude: 79.861710, latitude: 6.935146 }, { longitude: 79.862572, latitude: 6.936820 }, { longitude: 79.862656, latitude: 6.936903 }],
  },
  {
    _id: "5c78c1bbbe95e91110854b91",
    name: "Kochchikade South",
    number: "19",
    code: "19",
    moh: "5c7692c71427cc104cd56627",
    mohName: "D2A",
    coordinates: [{ longitude: 79.853256, latitude: 6.939072 }, { longitude: 79.853386, latitude: 6.939045 }, { longitude: 79.855988, latitude: 6.939442 }, { longitude: 79.856087, latitude: 6.938352 }, { longitude: 79.854897, latitude: 6.936418 }, { longitude: 79.855095, latitude: 6.935377 }, { longitude: 79.857727, latitude: 6.935495 }, { longitude: 79.858421, latitude: 6.937208 }, { longitude: 79.858597, latitude: 6.939307 }, { longitude: 79.857498, latitude: 6.941676 }, { longitude: 79.855324, latitude: 6.941248 }, { longitude: 79.853256, latitude: 6.939072 }],
  },
  {
    _id: "5c78c1d5be95e91110854b92",
    name: "Fort",
    number: "20",
    code: "20",
    moh: "5c7692c71427cc104cd56627",
    mohName: "D2A",
    coordinates: [{ longitude: 79.852493, latitude: 6.928070 }, { longitude: 79.852661, latitude: 6.931173 }, { longitude: 79.853119, latitude: 6.933665 }, { longitude: 79.856537, latitude: 6.932676 }, { longitude: 79.855019, latitude: 6.934248 }, { longitude: 79.855072, latitude: 6.935327 }, { longitude: 79.854774, latitude: 6.936411 }, { longitude: 79.856018, latitude: 6.938353 }, { longitude: 79.856003, latitude: 6.939568 }, { longitude: 79.853523, latitude: 6.939195 }, { longitude: 79.852753, latitude: 6.938800 }, { longitude: 79.852654, latitude: 6.940624 }, { longitude: 79.851288, latitude: 6.941451 }, { longitude: 79.846306, latitude: 6.938207 }, { longitude: 79.845093, latitude: 6.938357 }, { longitude: 79.846100, latitude: 6.941922 }, { longitude: 79.845032, latitude: 6.942408 }, { longitude: 79.843948, latitude: 6.938980 }, { longitude: 79.842026, latitude: 6.939612 }, { longitude: 79.843643, latitude: 6.944991 }, { longitude: 79.843842, latitude: 6.950254 }, { longitude: 79.843056, latitude: 6.950399 }, { longitude: 79.841270, latitude: 6.942592 }, { longitude: 79.840363, latitude: 6.941523 }, { longitude: 79.839653, latitude: 6.937009 }, { longitude: 79.838524, latitude: 6.936956 }, { longitude: 79.838531, latitude: 6.936281 }, { longitude: 79.839325, latitude: 6.935461 }, { longitude: 79.839272, latitude: 6.934247 }, { longitude: 79.840820, latitude: 6.930717 }, { longitude: 79.845078, latitude: 6.930730 }, { longitude: 79.847198, latitude: 6.926787 }, { longitude: 79.849174, latitude: 6.927167 }, { longitude: 79.849174, latitude: 6.927370 }, { longitude: 79.850670, latitude: 6.926946 }, { longitude: 79.852211, latitude: 6.928073 }, { longitude: 79.852493, latitude: 6.928070 }],
  },
  {
    _id: "5c78c234be95e91110854b93",
    name: "Maligawatte West",
    number: "15",
    code: "15",
    moh: "5c7692d21427cc104cd56628",
    mohName: "D2B",
    coordinates: [{ longitude: 79.872940, latitude: 6.939293 }, { longitude: 79.870842, latitude: 6.940415 }, { longitude: 79.868904, latitude: 6.937282 }, { longitude: 79.866135, latitude: 6.936054 }, { longitude: 79.866165, latitude: 6.934369 }, { longitude: 79.867538, latitude: 6.934351 }, { longitude: 79.867630, latitude: 6.933929 }, { longitude: 79.866409, latitude: 6.932766 }, { longitude: 79.866135, latitude: 6.930832 }, { longitude: 79.867111, latitude: 6.930399 }, { longitude: 79.868172, latitude: 6.930511 }, { longitude: 79.868530, latitude: 6.930212 }, { longitude: 79.870201, latitude: 6.930738 }, { longitude: 79.870750, latitude: 6.929510 }, { longitude: 79.871635, latitude: 6.929709 }, { longitude: 79.872948, latitude: 6.930450 }, { longitude: 79.872368, latitude: 6.931131 }, { longitude: 79.871803, latitude: 6.935771 }, { longitude: 79.872963, latitude: 6.938198 }, { longitude: 79.872986, latitude: 6.939166 }, { longitude: 79.872940, latitude: 6.939293 }],
  },
  {
    _id: "5c78c277be95e91110854b94",
    name: "Kopannaweediya",
    number: "21",
    code: "21",
    moh: "5c7692d21427cc104cd56628",
    mohName: "D2B",
    coordinates: [{ longitude: 79.844948, latitude: 6.930676 }, { longitude: 79.840790, latitude: 6.930687 }, { longitude: 79.844353, latitude: 6.920830 }, { longitude: 79.846817, latitude: 6.921598 }, { longitude: 79.846970, latitude: 6.923239 }, { longitude: 79.847412, latitude: 6.923444 }, { longitude: 79.848213, latitude: 6.920359 }, { longitude: 79.848900, latitude: 6.921530 }, { longitude: 79.850266, latitude: 6.921933 }, { longitude: 79.850014, latitude: 6.923831 }, { longitude: 79.851753, latitude: 6.923093 }, { longitude: 79.850677, latitude: 6.926771 }, { longitude: 79.849121, latitude: 6.927254 }, { longitude: 79.847176, latitude: 6.926815 }, { longitude: 79.845039, latitude: 6.930632 }, { longitude: 79.844948, latitude: 6.930676 }],
  },
  {
    _id: "5c78c2adbe95e91110854b95",
    name: "Wekanda",
    number: "22",
    code: "22",
    moh: "5c7692d21427cc104cd56628",
    mohName: "D2B",
    coordinates: [{ longitude: 79.848190, latitude: 6.920339 }, { longitude: 79.849762, latitude: 6.916643 }, { longitude: 79.852509, latitude: 6.916448 }, { longitude: 79.855492, latitude: 6.915291 }, { longitude: 79.855507, latitude: 6.914278 }, { longitude: 79.856125, latitude: 6.914217 }, { longitude: 79.858185, latitude: 6.915203 }, { longitude: 79.857605, latitude: 6.916223 }, { longitude: 79.857178, latitude: 6.918626 }, { longitude: 79.857430, latitude: 6.920168 }, { longitude: 79.851677, latitude: 6.922959 }, { longitude: 79.850098, latitude: 6.923832 }, { longitude: 79.850349, latitude: 6.921964 }, { longitude: 79.848839, latitude: 6.921610 }],
  },
  {
    _id: "5c78c36dbe95e91110854b96",
    name: "Hunupitiya",
    number: "23",
    code: "23",
    moh: "5c7692d21427cc104cd56628",
    mohName: "D2B",
    coordinates: [{ longitude: 79.852730, latitude: 6.931250 }, { longitude: 79.852684, latitude: 6.931251 }, { longitude: 79.852379, latitude: 6.928096 }, { longitude: 79.850632, latitude: 6.926855 }, { longitude: 79.851799, latitude: 6.922966 }, { longitude: 79.857323, latitude: 6.920327 }, { longitude: 79.857178, latitude: 6.918603 }, { longitude: 79.859940, latitude: 6.917430 }, { longitude: 79.860718, latitude: 6.918768 }, { longitude: 79.861290, latitude: 6.918550 }, { longitude: 79.861588, latitude: 6.919388 }, { longitude: 79.860954, latitude: 6.920281 }, { longitude: 79.860809, latitude: 6.921041 }, { longitude: 79.859680, latitude: 6.922403 }, { longitude: 79.859528, latitude: 6.923752 }, { longitude: 79.858086, latitude: 6.925328 }, { longitude: 79.853752, latitude: 6.930900 }, { longitude: 79.852730, latitude: 6.931250 }],
  },
  {
    _id: "5c78c385be95e91110854b97",
    name: "Suduwella",
    number: "24",
    code: "24",
    moh: "5c7692d21427cc104cd56628",
    mohName: "D2B",
    coordinates: [{ longitude: 79.857269, latitude: 6.932034 }, { longitude: 79.856514, latitude: 6.932591 }, { longitude: 79.853088, latitude: 6.933688 }, { longitude: 79.852638, latitude: 6.931167 }, { longitude: 79.853752, latitude: 6.930942 }, { longitude: 79.858131, latitude: 6.925370 }, { longitude: 79.859573, latitude: 6.923709 }, { longitude: 79.859772, latitude: 6.922275 }, { longitude: 79.860855, latitude: 6.921082 }, { longitude: 79.860863, latitude: 6.920366 }, { longitude: 79.861549, latitude: 6.919305 }, { longitude: 79.861290, latitude: 6.918550 }, { longitude: 79.860535, latitude: 6.918728 }, { longitude: 79.859985, latitude: 6.917472 }, { longitude: 79.857178, latitude: 6.918561 }, { longitude: 79.857567, latitude: 6.916197 }, { longitude: 79.858208, latitude: 6.915179 }, { longitude: 79.856186, latitude: 6.914194 }, { longitude: 79.855431, latitude: 6.914246 }, { longitude: 79.855324, latitude: 6.912857 }, { longitude: 79.856384, latitude: 6.912928 }, { longitude: 79.867043, latitude: 6.918645 }, { longitude: 79.866585, latitude: 6.919788 }, { longitude: 79.865555, latitude: 6.923085 }, { longitude: 79.864761, latitude: 6.923138 }, { longitude: 79.865135, latitude: 6.924691 }, { longitude: 79.861633, latitude: 6.924736 }, { longitude: 79.861610, latitude: 6.926589 }, { longitude: 79.862350, latitude: 6.929906 }, { longitude: 79.860146, latitude: 6.932124 }, { longitude: 79.859131, latitude: 6.931842 }, { longitude: 79.857269, latitude: 6.931992 }, { longitude: 79.857269, latitude: 6.932034 }],
  },
  {
    _id: "5c78c461be95e91110854b98",
    name: "Panchikawatte",
    number: "25",
    code: "25",
    moh: "5c7692d21427cc104cd56628",
    mohName: "D2B",
    coordinates: [{ longitude: 79.866119, latitude: 6.934412 }, { longitude: 79.866074, latitude: 6.934370 }, { longitude: 79.865265, latitude: 6.932443 }, { longitude: 79.864067, latitude: 6.932543 }, { longitude: 79.864098, latitude: 6.933258 }, { longitude: 79.861855, latitude: 6.932529 }, { longitude: 79.860268, latitude: 6.932086 }, { longitude: 79.862297, latitude: 6.929997 }, { longitude: 79.863754, latitude: 6.929936 }, { longitude: 79.864517, latitude: 6.929168 }, { longitude: 79.863869, latitude: 6.928461 }, { longitude: 79.864632, latitude: 6.927819 }, { longitude: 79.868530, latitude: 6.930086 }, { longitude: 79.868263, latitude: 6.930510 }, { longitude: 79.867241, latitude: 6.930397 }, { longitude: 79.866173, latitude: 6.930832 }, { longitude: 79.866318, latitude: 6.932809 }, { longitude: 79.867584, latitude: 6.934014 }, { longitude: 79.867622, latitude: 6.934392 }, { longitude: 79.866119, latitude: 6.934412 }],
  },
  {
    _id: "5c78c47bbe95e91110854b99",
    name: "Maradana",
    number: "26",
    code: "26",
    moh: "5c7692d21427cc104cd56628",
    mohName: "D2B",
    coordinates: [{ longitude: 79.864601, latitude: 6.927784 }, { longitude: 79.864601, latitude: 6.927821 }, { longitude: 79.863960, latitude: 6.928575 }, { longitude: 79.864456, latitude: 6.929314 }, { longitude: 79.863823, latitude: 6.929844 }, { longitude: 79.862289, latitude: 6.930013 }, { longitude: 79.861641, latitude: 6.926592 }, { longitude: 79.861710, latitude: 6.924727 }, { longitude: 79.865120, latitude: 6.924683 }, { longitude: 79.864594, latitude: 6.923087 }, { longitude: 79.865540, latitude: 6.923000 }, { longitude: 79.865929, latitude: 6.923033 }, { longitude: 79.868103, latitude: 6.924384 }, { longitude: 79.866508, latitude: 6.925970 }, { longitude: 79.864601, latitude: 6.927784 }],
  },
  {
    _id: "5c78c4a3be95e91110854b9a",
    name: "Maligakanda",
    number: "27",
    code: "27",
    moh: "5c7692d21427cc104cd56628",
    mohName: "D2B",
    coordinates: [{ longitude: 79.868576, latitude: 6.930169 }, { longitude: 79.868622, latitude: 6.930000 }, { longitude: 79.864632, latitude: 6.927819 }, { longitude: 79.866562, latitude: 6.925984 }, { longitude: 79.869820, latitude: 6.926911 }, { longitude: 79.869766, latitude: 6.927754 }, { longitude: 79.870285, latitude: 6.928210 }, { longitude: 79.871262, latitude: 6.928324 }, { longitude: 79.870750, latitude: 6.929636 }, { longitude: 79.870247, latitude: 6.930737 }, { longitude: 79.868576, latitude: 6.930169 }],
  },
  {
    _id: "5c78c4c5be95e91110854b9b",
    name: "Maligawatte East",
    number: "28",
    code: "28",
    moh: "5c7692d21427cc104cd56628",
    mohName: "D2B",
    coordinates: [{ longitude: 79.873039, latitude: 6.930449 }, { longitude: 79.875336, latitude: 6.930883 }, { longitude: 79.876755, latitude: 6.930654 }, { longitude: 79.878578, latitude: 6.930084 }, { longitude: 79.878784, latitude: 6.936566 }, { longitude: 79.877457, latitude: 6.936751 }, { longitude: 79.877289, latitude: 6.938732 }, { longitude: 79.878113, latitude: 6.939690 }, { longitude: 79.877045, latitude: 6.939915 }, { longitude: 79.875519, latitude: 6.938924 }, { longitude: 79.873688, latitude: 6.939578 }, { longitude: 79.873123, latitude: 6.939207 }, { longitude: 79.872879, latitude: 6.937947 }, { longitude: 79.871712, latitude: 6.935814 }, { longitude: 79.872368, latitude: 6.931047 }, { longitude: 79.872948, latitude: 6.930450 }, { longitude: 79.873039, latitude: 6.930449 }],
  },
  {
    _id: "5c78c500be95e91110854b9c",
    name: "Kollupitiya",
    number: "37",
    code: "37",
    moh: "5c7692d21427cc104cd56628",
    mohName: "D2B",
    coordinates: [{ longitude: 79.852448, latitude: 6.916502 }, { longitude: 79.849815, latitude: 6.916695 }, { longitude: 79.848244, latitude: 6.920285 }, { longitude: 79.847466, latitude: 6.923439 }, { longitude: 79.846909, latitude: 6.923286 }, { longitude: 79.846771, latitude: 6.921530 }, { longitude: 79.844322, latitude: 6.920708 }, { longitude: 79.848747, latitude: 6.906319 }, { longitude: 79.850655, latitude: 6.906508 }, { longitude: 79.849884, latitude: 6.908809 }, { longitude: 79.854073, latitude: 6.909714 }, { longitude: 79.853249, latitude: 6.911909 }, { longitude: 79.855713, latitude: 6.911878 }, { longitude: 79.855309, latitude: 6.912842 }, { longitude: 79.855400, latitude: 6.914226 }, { longitude: 79.855553, latitude: 6.915077 }, { longitude: 79.852562, latitude: 6.916500 }, { longitude: 79.852448, latitude: 6.916502 }],
  },
  {
    _id: "5c78c550be95e91110854b9d",
    name: "Dematagoda",
    number: "29",
    code: "29",
    moh: "5c7692e61427cc104cd56629",
    mohName: "D3",
    coordinates: [{ longitude: 79.878700, latitude: 6.943347 }, { longitude: 79.878700, latitude: 6.936527 }, { longitude: 79.878639, latitude: 6.929921 }, { longitude: 79.879822, latitude: 6.929533 }, { longitude: 79.881126, latitude: 6.928184 }, { longitude: 79.881355, latitude: 6.927915 }, { longitude: 79.883919, latitude: 6.928521 }, { longitude: 79.885201, latitude: 6.929091 }, { longitude: 79.883591, latitude: 6.931669 }, { longitude: 79.882858, latitude: 6.932158 }, { longitude: 79.882324, latitude: 6.933977 }, { longitude: 79.882301, latitude: 6.935309 }, { longitude: 79.883392, latitude: 6.937213 }, { longitude: 79.884438, latitude: 6.938212 }, { longitude: 79.882614, latitude: 6.940207 }, { longitude: 79.882607, latitude: 6.940687 }, { longitude: 79.881462, latitude: 6.942140 }, { longitude: 79.879784, latitude: 6.942321 }, { longitude: 79.878761, latitude: 6.943240 }, { longitude: 79.878700, latitude: 6.943347 }],
  },
  {
    _id: "5c78c57cbe95e91110854b9e",
    name: "Wanathamulla",
    number: "30",
    code: "30",
    moh: "5c7692e61427cc104cd56629",
    mohName: "D3",
    coordinates: [{ longitude: 79.878700, latitude: 6.929875 }, { longitude: 79.876579, latitude: 6.930654 }, { longitude: 79.876778, latitude: 6.928296 }, { longitude: 79.877144, latitude: 6.925033 }, { longitude: 79.876411, latitude: 6.924992 }, { longitude: 79.876587, latitude: 6.923637 }, { longitude: 79.878487, latitude: 6.923462 }, { longitude: 79.878708, latitude: 6.926066 }, { longitude: 79.879829, latitude: 6.925249 }, { longitude: 79.884361, latitude: 6.925041 }, { longitude: 79.885040, latitude: 6.925183 }, { longitude: 79.884811, latitude: 6.926689 }, { longitude: 79.884430, latitude: 6.927446 }, { longitude: 79.885414, latitude: 6.928236 }, { longitude: 79.885193, latitude: 6.929191 }, { longitude: 79.883881, latitude: 6.928756 }, { longitude: 79.881416, latitude: 6.927836 }, { longitude: 79.880943, latitude: 6.928193 }, { longitude: 79.879921, latitude: 6.929459 }, { longitude: 79.878807, latitude: 6.929824 }, { longitude: 79.878700, latitude: 6.929875 }],
  },
  {
    _id: "5c78c599be95e91110854b9f",
    name: "Kuppiyawatte East",
    number: "31",
    code: "31",
    moh: "5c7692e61427cc104cd56629",
    mohName: "D3",
    coordinates: [{ longitude: 79.869781, latitude: 6.926908 }, { longitude: 79.869781, latitude: 6.926877 }, { longitude: 79.870171, latitude: 6.926996 }, { longitude: 79.870842, latitude: 6.925561 }, { longitude: 79.872437, latitude: 6.925696 }, { longitude: 79.872139, latitude: 6.923839 }, { longitude: 79.873024, latitude: 6.923766 }, { longitude: 79.873711, latitude: 6.923168 }, { longitude: 79.874138, latitude: 6.921117 }, { longitude: 79.878471, latitude: 6.921062 }, { longitude: 79.878502, latitude: 6.923448 }, { longitude: 79.876640, latitude: 6.923658 }, { longitude: 79.876419, latitude: 6.925025 }, { longitude: 79.877136, latitude: 6.925047 }, { longitude: 79.876793, latitude: 6.928306 }, { longitude: 79.876656, latitude: 6.930664 }, { longitude: 79.875351, latitude: 6.930835 }, { longitude: 79.872978, latitude: 6.930494 }, { longitude: 79.871658, latitude: 6.929705 }, { longitude: 79.870743, latitude: 6.929561 }, { longitude: 79.871323, latitude: 6.928314 }, { longitude: 79.870277, latitude: 6.928203 }, { longitude: 79.869865, latitude: 6.927682 }, { longitude: 79.869781, latitude: 6.926908 }],
  },
  {
    _id: "5c78c5b4be95e91110854ba0",
    name: "Kuppiyawatte West",
    number: "32",
    code: "32",
    moh: "5c7692e61427cc104cd56629",
    mohName: "D3",
    coordinates: [{ longitude: 79.870255, latitude: 6.927032 }, { longitude: 79.869766, latitude: 6.926870 }, { longitude: 79.866600, latitude: 6.925942 }, { longitude: 79.868034, latitude: 6.924365 }, { longitude: 79.865891, latitude: 6.923003 }, { longitude: 79.865540, latitude: 6.922966 }, { longitude: 79.866470, latitude: 6.919753 }, { longitude: 79.869110, latitude: 6.921025 }, { longitude: 79.868607, latitude: 6.922211 }, { longitude: 79.869789, latitude: 6.922701 }, { longitude: 79.871498, latitude: 6.921121 }, { longitude: 79.874062, latitude: 6.921046 }, { longitude: 79.873672, latitude: 6.923199 }, { longitude: 79.873001, latitude: 6.923797 }, { longitude: 79.872208, latitude: 6.923849 }, { longitude: 79.872444, latitude: 6.925741 }, { longitude: 79.870895, latitude: 6.925550 }, { longitude: 79.870255, latitude: 6.926864 }, { longitude: 79.870255, latitude: 6.927032 }],
  },
  {
    _id: "5c78c5e7be95e91110854ba1",
    name: "Borella North",
    number: "33",
    code: "33",
    moh: "5c7692e61427cc104cd56629",
    mohName: "D3",
    coordinates: [{ longitude: 79.885101, latitude: 6.925242 }, { longitude: 79.884399, latitude: 6.925041 }, { longitude: 79.879837, latitude: 6.925225 }, { longitude: 79.878716, latitude: 6.926124 }, { longitude: 79.878448, latitude: 6.923474 }, { longitude: 79.878532, latitude: 6.920989 }, { longitude: 79.874283, latitude: 6.921085 }, { longitude: 79.871498, latitude: 6.921037 }, { longitude: 79.876129, latitude: 6.916514 }, { longitude: 79.880638, latitude: 6.916667 }, { longitude: 79.880745, latitude: 6.915571 }, { longitude: 79.882393, latitude: 6.914750 }, { longitude: 79.883629, latitude: 6.915239 }, { longitude: 79.884651, latitude: 6.914679 }, { longitude: 79.885155, latitude: 6.916441 }, { longitude: 79.885956, latitude: 6.916431 }, { longitude: 79.885963, latitude: 6.915967 }, { longitude: 79.886490, latitude: 6.915834 }, { longitude: 79.886658, latitude: 6.916590 }, { longitude: 79.887543, latitude: 6.916874 }, { longitude: 79.887917, latitude: 6.918216 }, { longitude: 79.887932, latitude: 6.919900 }, { longitude: 79.887062, latitude: 6.921680 }, { longitude: 79.887383, latitude: 6.923697 }, { longitude: 79.886612, latitude: 6.925139 }, { longitude: 79.885147, latitude: 6.925284 }, { longitude: 79.885101, latitude: 6.925242 }],
  },
  {
    _id: "5c78c62cbe95e91110854ba2",
    name: "Borella South",
    number: "35",
    code: "35",
    moh: "5c7692e61427cc104cd56629",
    mohName: "D3",
    coordinates: [{ longitude: 79.878326, latitude: 6.908722 }, { longitude: 79.878372, latitude: 6.908806 }, { longitude: 79.884567, latitude: 6.911421 }, { longitude: 79.886810, latitude: 6.914888 }, { longitude: 79.886536, latitude: 6.915776 }, { longitude: 79.886002, latitude: 6.915951 }, { longitude: 79.885994, latitude: 6.916414 }, { longitude: 79.885147, latitude: 6.916425 }, { longitude: 79.884689, latitude: 6.914662 }, { longitude: 79.883575, latitude: 6.915182 }, { longitude: 79.882439, latitude: 6.914691 }, { longitude: 79.880783, latitude: 6.915470 }, { longitude: 79.880592, latitude: 6.916652 }, { longitude: 79.876167, latitude: 6.916413 }, { longitude: 79.875175, latitude: 6.914868 }, { longitude: 79.876740, latitude: 6.911100 }, { longitude: 79.878204, latitude: 6.910745 }, { longitude: 79.878326, latitude: 6.908722 }],
  },
  {
    _id: "5c78c650be95e91110854ba3",
    name: "Cinnamon Gardens",
    number: "36",
    code: "36",
    moh: "5c7692e61427cc104cd56629",
    mohName: "D3",
    coordinates: [{ longitude: 79.876122, latitude: 6.916247 }, { longitude: 79.876076, latitude: 6.916416 }, { longitude: 79.871399, latitude: 6.920982 }, { longitude: 79.869781, latitude: 6.922645 }, { longitude: 79.868637, latitude: 6.922238 }, { longitude: 79.869141, latitude: 6.921011 }, { longitude: 79.866600, latitude: 6.919696 }, { longitude: 79.867188, latitude: 6.918551 }, { longitude: 79.856354, latitude: 6.912879 }, { longitude: 79.855331, latitude: 6.912807 }, { longitude: 79.855835, latitude: 6.911875 }, { longitude: 79.858765, latitude: 6.905858 }, { longitude: 79.858223, latitude: 6.903675 }, { longitude: 79.860603, latitude: 6.898591 }, { longitude: 79.860214, latitude: 6.898091 }, { longitude: 79.860092, latitude: 6.897377 }, { longitude: 79.869415, latitude: 6.901258 }, { longitude: 79.878273, latitude: 6.908851 }, { longitude: 79.878197, latitude: 6.910789 }, { longitude: 79.876694, latitude: 6.910934 }, { longitude: 79.875214, latitude: 6.914869 }, { longitude: 79.876122, latitude: 6.916247 }],
  },
  {
    _id: "5c78c6c0be95e91110854ba4",
    name: "Narahenpita",
    number: "34",
    code: "34",
    moh: "5c7692ee1427cc104cd5662a",
    mohName: "D4",
    coordinates: [{ longitude: 79.887489, latitude: 6.916732 }, { longitude: 79.886742, latitude: 6.916657 }, { longitude: 79.886620, latitude: 6.915817 }, { longitude: 79.886810, latitude: 6.914972 }, { longitude: 79.884567, latitude: 6.911295 }, { longitude: 79.878235, latitude: 6.908765 }, { longitude: 79.878075, latitude: 6.896598 }, { longitude: 79.878120, latitude: 6.893818 }, { longitude: 79.885376, latitude: 6.893978 }, { longitude: 79.883781, latitude: 6.899430 }, { longitude: 79.887596, latitude: 6.904519 }, { longitude: 79.888657, latitude: 6.904505 }, { longitude: 79.889145, latitude: 6.907531 }, { longitude: 79.890617, latitude: 6.909323 }, { longitude: 79.890350, latitude: 6.912190 }, { longitude: 79.891342, latitude: 6.913903 }, { longitude: 79.891006, latitude: 6.915255 }, { longitude: 79.888969, latitude: 6.915660 }, { longitude: 79.887856, latitude: 6.915969 }, { longitude: 79.887627, latitude: 6.916688 }, { longitude: 79.887489, latitude: 6.916732 }],
  },
  {
    _id: "5c78c70cbe95e91110854ba5",
    name: "Thimbirigasyaya",
    number: "40",
    code: "40",
    moh: "5c7692ee1427cc104cd5662a",
    mohName: "D4",
    coordinates: [{ longitude: 79.878212, latitude: 6.908633 }, { longitude: 79.869476, latitude: 6.901291 }, { longitude: 79.860161, latitude: 6.897241 }, { longitude: 79.860039, latitude: 6.896569 }, { longitude: 79.862167, latitude: 6.893384 }, { longitude: 79.862724, latitude: 6.891861 }, { longitude: 79.864075, latitude: 6.890370 }, { longitude: 79.864502, latitude: 6.888596 }, { longitude: 79.866348, latitude: 6.889246 }, { longitude: 79.866333, latitude: 6.890425 }, { longitude: 79.867210, latitude: 6.891088 }, { longitude: 79.869202, latitude: 6.890978 }, { longitude: 79.873596, latitude: 6.895470 }, { longitude: 79.877960, latitude: 6.896677 }, { longitude: 79.878296, latitude: 6.908632 }, { longitude: 79.878212, latitude: 6.908633 }],
  },
  {
    _id: "5c78c72ebe95e91110854ba6",
    name: "Kirula",
    number: "41",
    code: "41",
    moh: "5c7692ee1427cc104cd5662a",
    mohName: "D4",
    coordinates: [{ longitude: 79.878136, latitude: 6.893854 }, { longitude: 79.878098, latitude: 6.896549 }, { longitude: 79.873688, latitude: 6.895511 }, { longitude: 79.869156, latitude: 6.890894 }, { longitude: 79.869614, latitude: 6.889878 }, { longitude: 79.869537, latitude: 6.889205 }, { longitude: 79.869011, latitude: 6.889128 }, { longitude: 79.868980, latitude: 6.888075 }, { longitude: 79.870132, latitude: 6.887976 }, { longitude: 79.870384, latitude: 6.886289 }, { longitude: 79.871666, latitude: 6.886062 }, { longitude: 79.871864, latitude: 6.884965 }, { longitude: 79.873581, latitude: 6.885659 }, { longitude: 79.876114, latitude: 6.887521 }, { longitude: 79.878014, latitude: 6.887792 }, { longitude: 79.877838, latitude: 6.884888 }, { longitude: 79.877312, latitude: 6.884516 }, { longitude: 79.877121, latitude: 6.882834 }, { longitude: 79.879555, latitude: 6.882845 }, { longitude: 79.880020, latitude: 6.883892 }, { longitude: 79.880791, latitude: 6.882787 }, { longitude: 79.882042, latitude: 6.881929 }, { longitude: 79.885849, latitude: 6.882007 }, { longitude: 79.885818, latitude: 6.884028 }, { longitude: 79.883904, latitude: 6.887379 }, { longitude: 79.885216, latitude: 6.888415 }, { longitude: 79.886086, latitude: 6.892110 }, { longitude: 79.885483, latitude: 6.893844 }, { longitude: 79.878181, latitude: 6.893769 }, { longitude: 79.878136, latitude: 6.893854 }],
  },
  {
    _id: "5c78c761be95e91110854ba7",
    name: "Kirillapone",
    number: "44",
    code: "44",
    moh: "5c7692ee1427cc104cd5662a",
    mohName: "D4",
    coordinates: [{ longitude: 79.871979, latitude: 6.884774 }, { longitude: 79.871742, latitude: 6.880437 }, { longitude: 79.873947, latitude: 6.878344 }, { longitude: 79.876381, latitude: 6.878102 }, { longitude: 79.881363, latitude: 6.876648 }, { longitude: 79.883591, latitude: 6.872996 }, { longitude: 79.885788, latitude: 6.871535 }, { longitude: 79.886253, latitude: 6.872625 }, { longitude: 79.887833, latitude: 6.873700 }, { longitude: 79.886299, latitude: 6.878102 }, { longitude: 79.885933, latitude: 6.881941 }, { longitude: 79.881989, latitude: 6.881992 }, { longitude: 79.880829, latitude: 6.882765 }, { longitude: 79.879967, latitude: 6.883872 }, { longitude: 79.879494, latitude: 6.882824 }, { longitude: 79.877151, latitude: 6.882938 }, { longitude: 79.877304, latitude: 6.884369 }, { longitude: 79.877869, latitude: 6.884783 }, { longitude: 79.877998, latitude: 6.887731 }, { longitude: 79.876099, latitude: 6.887545 }, { longitude: 79.873520, latitude: 6.885555 }, { longitude: 79.871979, latitude: 6.884943 }, { longitude: 79.871979, latitude: 6.884774 }],
  },
  {
    _id: "5c78c78abe95e91110854ba8",
    name: "Pamankada East",
    number: "45",
    code: "45",
    moh: "5c7692ee1427cc104cd5662a",
    mohName: "D4",
    coordinates: [{ longitude: 79.874466, latitude: 6.873618 }, { longitude: 79.874550, latitude: 6.873491 }, { longitude: 79.877464, latitude: 6.871346 }, { longitude: 79.879784, latitude: 6.870432 }, { longitude: 79.881554, latitude: 6.870452 }, { longitude: 79.882721, latitude: 6.869510 }, { longitude: 79.883339, latitude: 6.869670 }, { longitude: 79.883095, latitude: 6.870811 }, { longitude: 79.883484, latitude: 6.871649 }, { longitude: 79.885796, latitude: 6.871072 }, { longitude: 79.885788, latitude: 6.871493 }, { longitude: 79.883682, latitude: 6.872910 }, { longitude: 79.881363, latitude: 6.876690 }, { longitude: 79.876427, latitude: 6.878144 }, { longitude: 79.873947, latitude: 6.878176 }, { longitude: 79.875000, latitude: 6.876140 }, { longitude: 79.874985, latitude: 6.874244 }, { longitude: 79.874466, latitude: 6.873618 }],
  },
  {
    _id: "5c78c7dfbe95e91110854ba9",
    name: "Bambalapitiya",
    number: "38",
    code: "38",
    moh: "5c7693151427cc104cd5662b",
    mohName: "D5",
    coordinates: [{ longitude: 79.855743, latitude: 6.911879 }, { longitude: 79.853134, latitude: 6.911871 }, { longitude: 79.854187, latitude: 6.909794 }, { longitude: 79.849953, latitude: 6.908711 }, { longitude: 79.850700, latitude: 6.906554 }, { longitude: 79.848801, latitude: 6.906241 }, { longitude: 79.852638, latitude: 6.893096 }, { longitude: 79.855324, latitude: 6.893778 }, { longitude: 79.854492, latitude: 6.896147 }, { longitude: 79.858849, latitude: 6.897480 }, { longitude: 79.858849, latitude: 6.897817 }, { longitude: 79.860168, latitude: 6.898011 }, { longitude: 79.860565, latitude: 6.898427 }, { longitude: 79.858177, latitude: 6.903679 }, { longitude: 79.858719, latitude: 6.905946 }, { longitude: 79.855835, latitude: 6.911794 }, { longitude: 79.855743, latitude: 6.911879 }],
  },
  {
    _id: "5c78c7f6be95e91110854baa",
    name: "Milagiriya",
    number: "39",
    code: "39",
    moh: "5c7693151427cc104cd5662b",
    mohName: "D5",
    coordinates: [{ longitude: 79.859940, latitude: 6.896534 }, { longitude: 79.860107, latitude: 6.897290 }, { longitude: 79.860092, latitude: 6.897879 }, { longitude: 79.858856, latitude: 6.897937 }, { longitude: 79.858910, latitude: 6.897516 }, { longitude: 79.854462, latitude: 6.896183 }, { longitude: 79.855293, latitude: 6.893814 }, { longitude: 79.852646, latitude: 6.893048 }, { longitude: 79.855049, latitude: 6.883964 }, { longitude: 79.858360, latitude: 6.884764 }, { longitude: 79.858551, latitude: 6.883793 }, { longitude: 79.862343, latitude: 6.884671 }, { longitude: 79.861511, latitude: 6.886955 }, { longitude: 79.860672, latitude: 6.886755 }, { longitude: 79.860329, latitude: 6.888865 }, { longitude: 79.861916, latitude: 6.889308 }, { longitude: 79.861412, latitude: 6.893146 }, { longitude: 79.862206, latitude: 6.893347 }, { longitude: 79.859940, latitude: 6.896450 }, { longitude: 79.859940, latitude: 6.896534 }],
  },
  {
    _id: "5c78c81abe95e91110854bab",
    name: "Havelok Twon",
    number: "42",
    code: "42",
    moh: "5c7693151427cc104cd5662b",
    mohName: "D5",
    coordinates: [{ longitude: 79.855011, latitude: 6.883880 }, { longitude: 79.856155, latitude: 6.878644 }, { longitude: 79.862679, latitude: 6.880287 }, { longitude: 79.865036, latitude: 6.882110 }, { longitude: 79.871811, latitude: 6.884802 }, { longitude: 79.871658, latitude: 6.885941 }, { longitude: 79.870323, latitude: 6.886253 }, { longitude: 79.870163, latitude: 6.887939 }, { longitude: 79.868881, latitude: 6.887956 }, { longitude: 79.868996, latitude: 6.889049 }, { longitude: 79.869614, latitude: 6.889210 }, { longitude: 79.869606, latitude: 6.889884 }, { longitude: 79.869186, latitude: 6.890857 }, { longitude: 79.867195, latitude: 6.891051 }, { longitude: 79.866364, latitude: 6.890262 }, { longitude: 79.866295, latitude: 6.889210 }, { longitude: 79.864494, latitude: 6.888559 }, { longitude: 79.864105, latitude: 6.890375 }, { longitude: 79.862671, latitude: 6.891867 }, { longitude: 79.862244, latitude: 6.893304 }, { longitude: 79.861450, latitude: 6.893146 }, { longitude: 79.861778, latitude: 6.889268 }, { longitude: 79.860374, latitude: 6.888780 }, { longitude: 79.860580, latitude: 6.886714 }, { longitude: 79.861557, latitude: 6.886828 }, { longitude: 79.862343, latitude: 6.884628 }, { longitude: 79.858505, latitude: 6.883709 }, { longitude: 79.858406, latitude: 6.884679 }, { longitude: 79.855095, latitude: 6.883879 }, { longitude: 79.855011, latitude: 6.883880 }],
  },
  {
    _id: "5c78c838be95e91110854bac",
    name: "Wellewatte North",
    number: "43",
    code: "43",
    moh: "5c7693151427cc104cd5662b",
    mohName: "D5",
    coordinates: [{ longitude: 79.857910, latitude: 6.872650 }, { longitude: 79.861885, latitude: 6.873484 }, { longitude: 79.861870, latitude: 6.874327 }, { longitude: 79.866325, latitude: 6.875323 }, { longitude: 79.865997, latitude: 6.876381 }, { longitude: 79.866669, latitude: 6.876372 }, { longitude: 79.866096, latitude: 6.878866 }, { longitude: 79.871529, latitude: 6.879681 }, { longitude: 79.871826, latitude: 6.880562 }, { longitude: 79.871979, latitude: 6.884690 }, { longitude: 79.865021, latitude: 6.882124 }, { longitude: 79.862656, latitude: 6.880427 }, { longitude: 79.856224, latitude: 6.878697 }, { longitude: 79.857956, latitude: 6.872733 }, { longitude: 79.857910, latitude: 6.872650 }],
  },
  {
    _id: "5c78c85abe95e91110854bad",
    name: "Pamankada West",
    number: "46",
    code: "46",
    moh: "5c7693151427cc104cd5662b",
    mohName: "D5",
    coordinates: [{ longitude: 79.866371, latitude: 6.869845 }, { longitude: 79.866371, latitude: 6.869803 }, { longitude: 79.866966, latitude: 6.871312 }, { longitude: 79.868645, latitude: 6.871375 }, { longitude: 79.870094, latitude: 6.872452 }, { longitude: 79.872650, latitude: 6.873262 }, { longitude: 79.874458, latitude: 6.873407 }, { longitude: 79.874977, latitude: 6.874159 }, { longitude: 79.874947, latitude: 6.876140 }, { longitude: 79.873985, latitude: 6.878259 }, { longitude: 79.871872, latitude: 6.880351 }, { longitude: 79.871574, latitude: 6.879639 }, { longitude: 79.866135, latitude: 6.878823 }, { longitude: 79.866623, latitude: 6.876331 }, { longitude: 79.865997, latitude: 6.876339 }, { longitude: 79.866417, latitude: 6.875238 }, { longitude: 79.861870, latitude: 6.874285 }, { longitude: 79.861923, latitude: 6.873568 }, { longitude: 79.863152, latitude: 6.868875 }, { longitude: 79.864159, latitude: 6.869662 }, { longitude: 79.866371, latitude: 6.869845 }],
  },
  {
    _id: "5c78c86fbe95e91110854bae",
    name: "Wellewatte South",
    number: "47",
    code: "47",
    moh: "5c7693151427cc104cd5662b",
    mohName: "D5",
    coordinates: [{ longitude: 79.857956, latitude: 6.872649 }, { longitude: 79.860023, latitude: 6.862467 }, { longitude: 79.863174, latitude: 6.862217 }, { longitude: 79.865662, latitude: 6.864165 }, { longitude: 79.865074, latitude: 6.865016 }, { longitude: 79.865196, latitude: 6.865899 }, { longitude: 79.866364, latitude: 6.867443 }, { longitude: 79.866241, latitude: 6.869762 }, { longitude: 79.864113, latitude: 6.869621 }, { longitude: 79.863106, latitude: 6.868959 }, { longitude: 79.862015, latitude: 6.873482 }, { longitude: 79.858047, latitude: 6.872648 }, { longitude: 79.857956, latitude: 6.872649 }],
  }
]
