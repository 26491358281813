import React, { useEffect, useState, useRef } from 'react';
import Lodash from "lodash";
import { Formik } from "formik";
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom'
import { Checkbox, Chip, FormControl, InputAdornment, ListItemIcon, ListItemText, MenuItem, Select, TextField } from '@material-ui/core';
import {
  Card,
  CardBody,
  CardFooter, CardHeader,
  CardTitle, Col, Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle, Pagination,
  PaginationItem,
  PaginationLink, Row,
  Table,
  UncontrolledDropdown,
  Label,
  Form,
  FormGroup,
  Input,
  FormText,
  Button,
} from "reactstrap";
import PageHeader from "components/Headers/PageHeader.js";
import { MbppStyles } from 'constants/globalStyles';
import { fetchNewQuestionAnswerById } from "actions/forum";
import Moment from "moment";

const reviewSchema = Yup.object({
});


const UserQuestionsDetail = (props) => {

  const formRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();

  // const [userQuestion, setUserQuestion] = useState({});
  const [isEdit, setIsEdit] = useState(true); // For edit userQuestion details passed by parent page

  useEffect(() => {
    const tempQuestion = props.location.state?.userQuestion;
    if (tempQuestion && !Lodash.isEmpty(tempQuestion)) {
      // setUserQuestion(tempQuestion);
      let params = {
        questionId: tempQuestion._id
      }
      dispatch(fetchNewQuestionAnswerById(params));
    }
  }, [])

  const userQuestion = useSelector((state) => state.forum.userNewQuestionAnswer)

  useEffect(() => {
    // console.log("userQuestion: ", userQuestion);
    if (formRef.current && userQuestion && !Lodash.isEmpty(userQuestion) && (userQuestion._id === props.location.state?.userQuestion?._id)) {

      formRef.current?.resetForm();
      formRef.current.setFieldValue('date', userQuestion.date, false);
      formRef.current.setFieldValue('createdBy', userQuestion.createdBy?.email, false);
      formRef.current.setFieldValue('topic', userQuestion.topic, false);
      formRef.current.setFieldValue('subTopic', userQuestion.subTopic, false);
      formRef.current.setFieldValue('question', userQuestion.question, false);
      formRef.current.setFieldValue('remark', userQuestion.remark, false);
      formRef.current.setFieldValue('outcome', userQuestion.outcome, false);
      formRef.current.setFieldValue('answer', userQuestion.answer, false);

    }
  }, [userQuestion])

  return (
    <>

      <PageHeader />


      {/* Page content */}
      <Container className="mt--7" fluid>

        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <div className="mb-xl-0 col-11">
                    <h3 className="mb-0">User Question in Detail</h3>
                    <h6 className="mb-0">Question and answers</h6>
                  </div>
                  <div className="mb-xl-0">
                  </div>
                </div>
              </CardHeader>
              <CardBody>

                <Formik
                  initialValues={{
                    date: '',
                    createdBy: '',
                    topic: '',
                    subTopic: '',
                    question: '',
                    remark: '',
                    outcome: '',
                    answer: [],
                  }}
                  validationSchema={reviewSchema}
                  onSubmit={(values, actions) => {
                    console.log('formik values: ', values);
                  }}
                  innerRef={formRef}
                >
                  {props => (
                    <Form
                      role="form"
                      style={{ marginTop: -25 }}
                      onSubmit={props.handleSubmit}>

                      <h6
                        className="heading-small mb-4"
                        style={MbppStyles.SubTopic}
                      >
                        Question Details
                      </h6>

                      <Row>
                        <Col lg="1"></Col>
                        <Col lg="11">

                          <Row>
                            <Col lg="6">
                              <TextField
                                fullWidth
                                label={'Question Date'}
                                variant="outlined"
                                value={Moment(props.values.date).format('YYYY-MM-DD hh:mm A')}
                                style={MbppStyles.Question}
                                className={"form-control-alternative form-control-edit"}
                                disabled={isEdit}
                              />
                            </Col>
                            <Col lg="6">
                              <TextField
                                fullWidth
                                label={'Question Created By'}
                                variant="outlined"
                                value={props.values.createdBy}
                                style={MbppStyles.Question}
                                className={"form-control-alternative form-control-edit"}
                                disabled={isEdit}
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="8">
                              <TextField
                                fullWidth
                                label={'Topic'}
                                variant="outlined"
                                value={props.values.topic}
                                style={MbppStyles.Question}
                                className={"form-control-alternative form-control-edit"}
                                disabled={isEdit}
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="8">
                              <TextField
                                fullWidth
                                label={'Sub Topic'}
                                variant="outlined"
                                value={props.values.subTopic}
                                style={MbppStyles.Question}
                                className={"form-control-alternative form-control-edit"}
                                disabled={isEdit}
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="12">
                              <TextField
                                fullWidth
                                label={'Question'}
                                variant="outlined"
                                value={props.values.question}
                                multiline
                                rows={4}
                                style={MbppStyles.Question}
                                className={"form-control-alternative form-control-edit"}
                                disabled={isEdit}
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="12">
                              <TextField
                                fullWidth
                                label={'Remark'}
                                variant="outlined"
                                value={props.values.remark}
                                multiline
                                rows={2}
                                style={MbppStyles.Question}
                                className={"form-control-alternative form-control-edit"}
                                disabled={isEdit}
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="6">
                              <TextField
                                fullWidth
                                label={'Question Outcome'}
                                variant="outlined"
                                value={props.values.outcome}
                                style={MbppStyles.Question}
                                className={"form-control-alternative form-control-edit"}
                                disabled={isEdit}
                              />
                            </Col>
                          </Row>

                        </Col>
                      </Row>

                      <br />
                      <h6
                        className="heading-small mb-4"
                        style={MbppStyles.SubTopic}
                      >
                        Answer Details
                      </h6>

                      {props.values.answer && props.values.answer.map((item) => (
                        <>
                          <hr className="mt-2" />

                          <Row>
                            <Col lg="1"></Col>
                            <Col lg="11">

                              <Row>
                                <Col lg="6">
                                  <TextField
                                    fullWidth
                                    label={'Answer Date'}
                                    variant="outlined"
                                    value={Moment(item.date).format('YYYY-MM-DD hh:mm A')}
                                    style={MbppStyles.Question}
                                    className={"form-control-alternative form-control-edit"}
                                    disabled={isEdit}
                                  />
                                </Col>
                                <Col lg="6">
                                  <TextField
                                    fullWidth
                                    label={'Answer Created By'}
                                    variant="outlined"
                                    value={item.createdBy?.email}
                                    style={MbppStyles.Question}
                                    className={"form-control-alternative form-control-edit"}
                                    disabled={isEdit}
                                  />
                                </Col>
                              </Row>

                              <Row>
                                <Col lg="6">
                                  <TextField
                                    fullWidth
                                    label={'Action'}
                                    variant="outlined"
                                    value={item.action}
                                    style={MbppStyles.Question}
                                    className={"form-control-alternative form-control-edit"}
                                    disabled={isEdit}
                                  />
                                </Col>
                              </Row>

                              <Row>
                                <Col lg="12">
                                  <TextField
                                    fullWidth
                                    label={'Answer'}
                                    variant="outlined"
                                    value={item.answer}
                                    multiline
                                    rows={4}
                                    style={MbppStyles.Question}
                                    className={"form-control-alternative form-control-edit"}
                                    disabled={isEdit}
                                  />
                                </Col>
                              </Row>

                              <Row>
                                <Col lg="12">
                                  <TextField
                                    fullWidth
                                    label={'Answer Description'}
                                    variant="outlined"
                                    value={item.description}
                                    multiline
                                    rows={2}
                                    style={MbppStyles.Question}
                                    className={"form-control-alternative form-control-edit"}
                                    disabled={isEdit}
                                  />
                                </Col>
                              </Row>

                            </Col>
                          </Row>

                        </>
                      ))}

                    </Form>
                  )}
                </Formik>

              </CardBody>
              <CardFooter className="py-4">
                <Row>
                  <Col lg="6" className="text-left">
                    <Button
                      style={MbppStyles.GeneralButton}
                      color="primary"
                      onClick={() => {
                        history.goBack();
                      }}>
                      BACK
                    </Button>
                  </Col>
                </Row>
              </CardFooter>

            </Card>
          </div>
        </Row>

      </Container>
    </>

  );

}

export default UserQuestionsDetail;



