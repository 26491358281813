// import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Tooltip } from "@material-ui/core";
import { fetchNewQuestionsByOptions } from "actions/forum";
import PageHeader from "components/Headers/PageHeader.js"; // core components
import Config from "config/config.json";
import { GlobalStylesColor } from "constants/globalStyles";
import Lodash from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Badge, Card, CardHeader, CardFooter, Container, Row, Col, Table, InputGroupText, FormGroup, Input, InputGroup, InputGroupAddon } from "reactstrap"; // reactstrap components
import Moment from "moment";
import Pagination from 'react-responsive-pagination';

const ResourceRow = ({ userQuestion, editClick, statusClick, viewClick, createClick, deleteClick }) => (
  // CATEGORY |	TITLE |	DESCRIPTION |	FILE |	PUBLISHED |	ACTIONS
  <tr>
    <td style={{ whiteSpace: "break-spaces" }}>{Moment(userQuestion.date).format('YYYY-MM-DD')}</td>
    <td style={{ whiteSpace: "break-spaces", color: GlobalStylesColor.mbppThemePrimary }}>{userQuestion.topic}</td>
    <td style={{ whiteSpace: "break-spaces" }}>{userQuestion.question}</td>
    <td>
      {userQuestion.outcome === "closed" ? (
        <Badge color="" className="badge-dot">
          <i className="bg-success" />
          CLOSED
        </Badge>
      ) : (
        <Badge color="" className="badge-dot mr-4">
          <i className="bg-warning" />
          OPEN
        </Badge>
      )}
    </td>
    <td className="text-right">
      <div className="row">
        <Tooltip title="View userQuestion" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              viewClick(e, userQuestion);
            }}
          >
            <i className="far fa-eye" />
          </div>
        </Tooltip>
        {/* <Tooltip title="Delete userQuestion" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              deleteClick(e, userQuestion);
            }}
          >
            <i className="far fa-times-circle" />
          </div>
        </Tooltip> */}
      </div>
    </td>
  </tr>
);

// export function
const UserQuestions = () => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // pagination 
  const itemPageSize = Config.ITEM_PAGE_SIZE;
  const [itemCurrent, setItemCurrent] = useState(1);
  const [itemPageContent, setItemPageContent] = useState([]);
  const [itemPageCount, setItemPageCount] = useState(1);
  // filter
  const [txtFilter, setTxtFilter] = useState("");
  const [filterdContent, setFilterdContent] = useState([]);


  useEffect(() => {
    let params = {
      selectString: "_id date topic question outcome createdAt"
    }
    dispatch(fetchNewQuestionsByOptions(params));
  }, []);

  // get forum topics
  const userQuestionsAll = useSelector((state) => state.forum.userNewQuestions);
  console.log("userQuestionsAll: ", userQuestionsAll);


  // process content with filter
  useEffect(() => {
    // 1. filter the users form the filter 
    let updatedUsers = Lodash.filter(userQuestionsAll, function (item) {
      return (item.question.toLowerCase()).indexOf(txtFilter.toLowerCase()) > -1;
    });
    setFilterdContent(updatedUsers)
  }, [userQuestionsAll, txtFilter]);


  // process the pagination with page content
  useEffect(() => {
    console.log("filterdContent.length: ", filterdContent.length)
    // Set the pagination count
    let currentPageCount = Math.ceil(filterdContent.length / itemPageSize);
    setItemPageCount(currentPageCount);

    // Set the pagination content 
    if (filterdContent && filterdContent.length > 0) {
      // make sure the page doesn't exceed the array endings
      let startCount = ((itemCurrent - 1) * itemPageSize) < 0 ? 0 : ((itemCurrent - 1) * itemPageSize);
      let endCount = (itemCurrent * itemPageSize) > filterdContent.length ? filterdContent.length : (itemCurrent * itemPageSize);

      let updatedArray = Lodash.slice(filterdContent, startCount, endCount);
      if (updatedArray && updatedArray.length > 0) {
        setItemPageContent(updatedArray);
      }
      else {
        setItemPageContent([])
      }
    }
    else {
      setItemPageContent([])
    }
  }, [itemCurrent, filterdContent]);




  const handleStatusClick = (e, userQuestion) => {
    e.preventDefault();
  };

  const handleViewClick = (e, userQuestion) => {
    e.preventDefault();
    history.push({ pathname: "/registered/faq_user_question_detail", state: { userQuestion: userQuestion } });
  };

  const handleEditClick = (e, userQuestion, type) => {
    e.preventDefault();

  };

  const handleCreateClick = (e, userQuestion, type) => {
    e.preventDefault();
  };

  const handleCreateNewClick = (e, userQuestion, type) => {
    e.preventDefault();

  };

  const handleDeleteClick = (e, userQuestion) => {
    console.log("userQuestion", userQuestion);
    e.preventDefault();

  };

  return (
    <>
      <PageHeader />

      {/* Page content */}
      <Container className="mt--9" fluid>
        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col lg="6">
                    <h3 className="mb-0">User Questions</h3>
                    <h6 className="mb-0">Questions by users and the given answers</h6>
                  </Col>
                  <Col lg="6">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <InputGroup className="input-group-alternative" >
                        <InputGroupAddon addonType="prepend" >
                          <InputGroupText>
                            <i className="ni ni-zoom-split-in" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="form-control-alternative"
                          placeholder="By Question"
                          type="text"
                          onChange={(e) => {
                            console.log(e.target.value);
                            setTxtFilter(e.target.value);
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Topic</th>
                    <th scope="col">Question</th>
                    <th scope="col">Outcome</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr></tr>
                  {/* {userQuestionsAll && userQuestionsAll.length > 0 && userQuestionsAll.map((userQuestion, i) => { */}
                  {itemPageContent && (itemPageContent.length > 0) && itemPageContent.map((props, i) => {
                    return <ResourceRow
                      key={i}
                      userQuestion={props}
                      editClick={handleEditClick}
                      statusClick={handleStatusClick}
                      viewClick={handleViewClick}
                      createClick={handleCreateClick}
                      deleteClick={handleDeleteClick}
                    />;
                  })}
                </tbody>
              </Table>
              {/* pagination */}
              <CardFooter>
                <Row>
                  <Col lg="12">
                    <Pagination
                      current={itemCurrent}
                      total={itemPageCount}
                      onPageChange={(current) => { setItemCurrent(current); }}
                    />
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default UserQuestions;
