import { GoogleMap, InfoWindow, Marker, Polygon } from "@react-google-maps/api";
import PageHeader from "components/Headers/PageHeader.js"; // core components
import { MbppStyles } from "constants/globalStyles";
import Lodash from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Row } from "reactstrap"; // reactstrap components
import { useDispatch, useSelector } from "react-redux";
import { fetchMapDangerZones } from "actions/map";
import { updateNonNullChain } from "typescript";

const MapsDangerZones = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [mapLocations, setMapLocations] = useState(null);

  const handleOnLoad = (map) => {
    console.log("handleOnLoad");

    if (mapLocations) {
      var bounds = new window.google.maps.LatLngBounds();

      //Add all polygon coordinates to the focusing array
      let arrayPolyCoordinates = [];
      for (const item of mapLocations) {
        if (item.coordinates) {
          // arrayPolyCoordinates = [...arrayPolyCoordinates, ...item.coordinates];

          for (const coordinate of item.coordinates) {
            bounds.extend({ lat: coordinate.latitude, lng: coordinate.longitude })
          }

        }
      }
      console.log("arrayPolyCoordinates: ", arrayPolyCoordinates);
      // ([
      //   {
      //     "longitude": 79.88915529102087,
      //     "latitude": 6.872865268023713
      //   }
      // ]).forEach((coordinate) => bounds.extend({ lat: coordinate.latitude, lng: coordinate.longitude }));
      map.fitBounds(bounds);
    }
  };

  useEffect(() => {
    dispatch(fetchMapDangerZones(null, null));
  }, [])

  const dangerZones = useSelector(state => state.map.dangerZones);
  useEffect(() => {
    console.log("map locations: ", dangerZones);
    if (!Lodash.isEmpty(dangerZones)) {
      console.log("map locations 1: ", dangerZones);
      setMapLocations(dangerZones.polygons);
    }
  }, [dangerZones])

  return (
    <>
      <PageHeader />

      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow border-0">

              <CardHeader className="border-0">
                <div className="row">
                  <div className="mb-xl-0 col-10">
                    <h3 className="mb-0">Dengue Danger Zones</h3>
                    <h6 className="mb-0">Clusters</h6>
                  </div>
                </div>
              </CardHeader>

              <div
                style={{ height: `600px`, display: mapLocations ? 'block' : 'none' }}
                className="map-canvas"
                id="map-canvas"
              >
                {mapLocations &&
                  <GoogleMap
                    onLoad={handleOnLoad}
                    mapContainerStyle={{ width: "100%", height: "100%" }}
                  >
                    {Lodash.isArray(mapLocations) && mapLocations.map((item, position) => {

                      let polyPath = [];
                      item.coordinates.forEach((coordinate) => polyPath.push(new window.google.maps.LatLng(coordinate.latitude, coordinate.longitude)))

                      return <Polygon
                        // Make the Polygon editable / draggable
                        // editable
                        // draggable
                        key={position}
                        path={polyPath}
                        options={{
                          fillColor: item.fillColor,
                          fillOpacity: 0.6,
                          strokeColor: item.strokeColor,
                          strokeOpacity: 1,
                          strokeWeight: item.strokeWidth
                        }}
                      />

                    })}
                  </GoogleMap>
                }
              </div>

              <CardFooter className="py-4">
                <Row>
                  <Col lg="6" className="text-left">
                  </Col>
                  <Col lg="6" className="text-right">
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default MapsDangerZones;
