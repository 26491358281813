import {
  RESOURCE_CREATE,
  RESOURCE_UPDATE,
  RESOURCE_FETCH_ALL,
  RESOURCE_DELETE,
  SHOW_LOADING
} from 'constants/actionTypes';
import * as api from '../api/index.js';
import Lodash from "lodash";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


export const fetchResourceAll = () => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchResources();
    console.log("resource data", data);
    if (data?.status === 'success') {
      dispatch({ type: RESOURCE_FETCH_ALL, payload: data?.data });
    }
    else if (data?.status === 'error') {
      console.log(data);
    }
  } catch (error) {
    console.log(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const createResource = (formData, history) => async (dispatch) => {
  // console.log("#### resource formdata: ", formData);
  try {
    dispatch({ type: SHOW_LOADING, payload: true });

    let fileIsReady = false;

    if (formData.file) {
      fileIsReady = await uploadResource(formData, alert, history);
    }

    if (!fileIsReady) {
      confirmAlert({ message: "No attachment, or attachment failed to upload. Please try again later.", buttons: [{ label: 'Ok', onClick: () => { } }] });
      return;
    }

    console.log("resource fileIsReady", fileIsReady);

    const { data } = await api.createResource(formData);
    console.log("resource data", data);
    if (data?.status === 'success') {
      // dispatch({ type: RESOURCE_CREATE, payload: data?.data });
      confirmAlert({ message: "Resource is saved.", buttons: [{ label: 'Ok', onClick: () => { history.goBack(); } }] });
    }
    else if (data?.status === 'error') {
      console.log(data);
      confirmAlert({ message: "Resource couldn't save.", buttons: [{ label: 'Ok', onClick: () => { history.goBack(); } }] });
    }
  } catch (error) {
    console.log(error);
    confirmAlert({ message: "Resource couldn't save.", buttons: [{ label: 'Ok', onClick: () => { history.goBack(); } }] });
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};


const uploadResource = (values, history) => async (dispatch) => {
  // console.log("#### resource formdata: ", values);
  try {
    dispatch({ type: SHOW_LOADING, payload: true });

    if (values.file) {
      console.log('resource data: ', values.file);
      // continue
    }
    else {
      console.log('empty resource');
      return false;
    }

    console.log('upload picture data');
    const params = new FormData();
    params.append('resource', values.file);

    let res = await api.uploadResource(params);
    console.log('server returned: ', res);

    if (res && res.data && res.data.status === "success" && !Lodash.isEmpty(res.data.data)) {
      console.log('server returned new file name: ', res.data.data);
      values["file"] = res.data.data.name;

      return true;
    }
    else {
      return false;
    }

  } catch (error) {
    console.log("catch error in file upload: ", error);
    let errorMessage = error?.response?.data?.message;
    confirmAlert({ message: errorMessage, buttons: [{ label: 'Yes', onClick: () => { } }] });
    return false;
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const updateResource = (formData) => async (dispatch) => {
  // console.log("#### resource formdata: ", formData);
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const response = await api.updateResource(formData);
    const { data } = response;
    console.log('update resources: ', data);
    if (data?.status === 'success') {

      dispatch({ type: RESOURCE_UPDATE, payload: dispatch });
      confirmAlert({ message: "Resource updated.", buttons: [{ label: 'Ok', onClick: () => { window.location.reload(true); } }] });
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    let errorMessage = error?.response?.data?.message;
    confirmAlert({ message: errorMessage, buttons: [{ label: 'Yes', onClick: () => { } }] });
    console.log(error);
    // alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};


export const deleteResource = (formData) => async (dispatch) => {
  console.log("formdata", formData);
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    // dispatch({ type: SHOW_LOADING, payload: true });
    const response = await api.deleteResource(formData);
    const { data } = response;
    console.log("update resources: ", data);
    if (data?.status === "success") {
      dispatch({ type: RESOURCE_DELETE, payload: dispatch });
      confirmAlert({
        message: "Resource deleted.",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              // window.location.reload(true);
              dispatch(fetchResourceAll());
            },
          },
        ],
      });
    }
    if (data?.status === "error") {
      console.log(data);
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: 'Ok', onClick: () => { } }] });
    }
  } catch (error) {
    let errorMessage = error?.response?.data?.message;
    confirmAlert({ message: errorMessage, buttons: [{ label: "Yes", onClick: () => { } }] });
    console.log(error);
    // alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

