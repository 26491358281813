import {
  NOTIFICATION_FETCH_PUBLIC
} from 'constants/actionTypes';

const initState = {
  publicNotification: []
}

const notificationReducer = (Notification = initState, action) => {
  switch (action.type) {
    // Get all the public notification
    case NOTIFICATION_FETCH_PUBLIC:
      return { ...Notification, publicNotification: action.payload };
    default:
      return Notification;
  }
};

export default notificationReducer;