import { Avatar, TextField, Tooltip } from '@material-ui/core';
import { createPreRegister, fetchPreRegisterAll, updatePreRegister, deletePreRegister } from 'actions/user';
import PageHeader from "components/Headers/PageHeader.js";
import Config from "config/config.json";
import { MbppStyles, GlobalStylesColor } from 'constants/globalStyles';
import { Formik } from "formik";
import { Camelize } from "helper.js";
import Lodash from "lodash";
import React, { useEffect, useRef, useState } from "react";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useDispatch, useSelector } from 'react-redux';
import Pagination from 'react-responsive-pagination';
import { useHistory } from 'react-router-dom';
// reactstrap components
import {
  Badge, Card, CardBody, CardFooter, CardHeader, Col, Container, Form, FormGroup, Input, InputGroup,
  InputGroupAddon, Button,
  InputGroupText, Media, Row, Table
} from "reactstrap";
import * as Yup from "yup";






const reviewSchema = Yup.object({
  // Role: Yup.string()
  //   .required()
  // , Email: Yup.string()
  //   .required()
  //   .min(4)
  //   .max(1000)
  // , File: Yup.object().shape({
  //   name: Yup.string().required()
  // })
});




const User = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const formRef = useRef();

  // set state edit
  const [isShow, setIsShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  // pagination 
  const itemPageSize = Config.ITEM_PAGE_SIZE;
  const [itemCurrent, setItemCurrent] = useState(1);
  const [itemPageContent, setItemPageContent] = useState([]);
  const [itemPageCount, setItemPageCount] = useState(1);
  // filter
  const [txtFilter, setTxtFilter] = useState("");
  const [filterdContent, setFilterdContent] = useState([]);

  useEffect(() => {
    // fetching all user profiles
    console.log("getting all the users");
    dispatch(fetchPreRegisterAll());
  }, []);


  // get state all user profiles
  const allPreRegisters = useSelector((state) => state.user.userPreRegisterAll);
  console.log('allPreRegisters: ', allPreRegisters);

  // process content with filter
  useEffect(() => {
    // 1. filter the users form the filter 
    let updatedUsers = Lodash.filter(allPreRegisters, function (item) {
      return item.email.indexOf(txtFilter) > -1;
    });
    setFilterdContent(updatedUsers)
  }, [allPreRegisters, txtFilter]);


  // process the pagination with page content
  useEffect(() => {
    console.log("filterdContent.length: ", filterdContent.length)
    // Set the pagination count
    let currentPageCount = Math.ceil(filterdContent.length / itemPageSize);
    setItemPageCount(currentPageCount);

    // Set the pagination content 
    if (filterdContent && filterdContent.length > 0) {
      // make sure the page doesn't exceed the array endings
      let startCount = ((itemCurrent - 1) * itemPageSize) < 0 ? 0 : ((itemCurrent - 1) * itemPageSize);
      let endCount = (itemCurrent * itemPageSize) > filterdContent.length ? filterdContent.length : (itemCurrent * itemPageSize);

      let updatedArray = Lodash.slice(filterdContent, startCount, endCount);
      if (updatedArray && updatedArray.length > 0) {
        setItemPageContent(updatedArray);
      }
      else {
        setItemPageContent([])
      }
    }
    else {
      setItemPageContent([])
    }
  }, [itemCurrent, filterdContent]);



  const UserTableRow = ({ id, email, role, designation, status, editClick, deleteClick }) => (
    <tr key={id}>

      <th>{email}</th>
      <td>{role}</td>
      <td>{designation}</td>
      <td>
        {status ?
          <Badge color="" className="badge-dot">
            <i className="bg-success" />
            Registered
          </Badge>
          :
          <Badge color="" className="badge-dot mr-4">
            <i className="bg-warning" />
            Open
          </Badge>
        }
      </td>
      <td className="text-right">
        {status ? null
          :
          <div className="row">
            <Tooltip title="Edit" arrow>
              <div className="navbar-toggler"
                style={{ cursor: 'pointer' }}
                onClick={(e) => { editClick(e, id, email, role, designation) }}>
                <i className="far fa-edit" />
              </div>
            </Tooltip>
            <Tooltip title="Delete" arrow>
              <div className="navbar-toggler"
                style={{ cursor: 'pointer' }}
                onClick={(e) => { deleteClick(e, id) }}>
                <i className="far fa-times-circle" />
              </div>
            </Tooltip>
          </div>
        }
      </td>

    </tr>
  );


  const handleCreateNewClick = (e) => {
    e.preventDefault();
    if (formRef.current) {
      formRef.current.handleReset();
    }
    setIsEdit(false);
    setIsShow(true);
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  };

  const handleEditClick = (e, id, email, role, designation) => {
    e.preventDefault();
    if (formRef.current) {
      formRef.current.setFieldValue("Id", id, false);
      formRef.current.setFieldValue("Email", email, false);
      formRef.current.setFieldValue("Designation", designation, false);
      formRef.current.setFieldValue("Role", role, false);
      setIsEdit(true);
      setIsShow(true);

      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  };

  const handleDeleteClick = (e, id) => {
    e.preventDefault();
    dispatch(deletePreRegister({ preId: id }));
  };


  return (
    <>
      <PageHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>

        <Row style={{ display: isShow ? 'block' : 'none' }}>
          <Col className="order-xl-1">

            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col md="12">
                    <h3 className="mb-0">New Pre-Registration</h3>
                  </Col>
                </Row>
              </CardHeader>

              <CardBody>
                <Formik
                  initialValues={{
                    Id: '',
                    Email: '',
                    Designation: '',
                    Role: '',
                  }}
                  validationSchema={reviewSchema}
                  onSubmit={(values, actions) => {
                    console.log('formik values: ', values);
                    let params = {
                      email: values.Email,
                      designation: values.Designation,
                      role: values.Role,
                    }

                    if (values.Id !== "" && values.Id.length > 10) {
                      params["preId"] = values.Id;
                      dispatch(updatePreRegister(params));
                    }
                    else {
                      dispatch(createPreRegister(params));
                    }

                  }}
                  onReset={() => {
                    setIsEdit(false);
                  }}
                  innerRef={formRef}
                >
                  {props => (
                    <Form
                      role="form"
                      style={{ marginTop: -25 }}
                      onReset={props.handleReset}
                      onSubmit={props.handleSubmit}>

                      <Row>
                        <Col lg="4">
                          <TextField
                            select
                            fullWidth
                            label={'User Group*'}
                            variant="outlined"
                            value={props.values.Role}
                            onChange={props.handleChange('Role')}
                            onBlur={props.handleChange('Role')}
                            SelectProps={{ native: true }}
                            style={MbppStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.Role && Boolean(props.errors.Role)}
                            helperText={props.touched.Role && props.errors.Role}
                          >
                            <option key="" value=""></option>
                            <option key="ADMIN" value="ADMIN">ADMIN</option>
                            <option key="CMC-STAFF" value="CMC-STAFF">CMC-STAFF</option>
                            <option key="EPID-STAFF" value="EPID-STAFF">EPID-STAFF</option>
                            <option key="MOH-DOC" value="MOH-DOC">MOH-DOC</option>
                            <option key="PHI" value="PHI">PHI</option>
                            <option key="SCHOOL" value="SCHOOL">SCHOOL-USER</option>
                            <option key="CONSTRUCTION" value="CONSTRUCTION">CONSTRUCTION-USER</option>
                          </TextField>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="8">
                          <TextField
                            fullWidth
                            label={'Email*'}
                            variant="outlined"
                            value={props.values.Email}
                            onChange={props.handleChange('Email')}
                            onBlur={props.handleChange('Email')}
                            style={MbppStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.Email && Boolean(props.errors.Email)}
                            helperText={props.touched.Email && props.errors.Email}
                            disabled={isEdit}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <TextField
                            fullWidth
                            label={'Designation'}
                            variant="outlined"
                            value={props.values.Designation}
                            onChange={props.handleChange('Designation')}
                            onBlur={props.handleChange('Designation')}
                            style={MbppStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.Designation && Boolean(props.errors.Designation)}
                            helperText={props.touched.Designation && props.errors.Designation}
                          />
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </CardBody>

              <CardFooter className="py-4">
                <Row>
                  <Col lg="6" className="text-left">
                    <Button
                      style={MbppStyles.GeneralButton}
                      color="primary"
                      onClick={() => {
                        if (formRef.current) {
                          formRef.current.handleReset();
                        }
                      }}>
                      RESET
                    </Button>
                    <Button
                      style={MbppStyles.GeneralButton}
                      color="primary"
                      onClick={() => {
                        if (formRef.current) {
                          formRef.current.handleSubmit();
                        }
                      }}>
                      SAVE
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>

          </Col>
        </Row>

        <Row className={isShow ? "mt-5" : ""}>
          <Col className="order-xl-1">

            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col md="5">
                    <h3 className="mb-0">Pre-Registered Users</h3>
                  </Col>
                  <Col md="6">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <InputGroup className="input-group-alternative" >
                        <InputGroupAddon addonType="prepend" >
                          <InputGroupText>
                            <i className="ni ni-zoom-split-in" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="form-control-alternative"
                          placeholder="By Email"
                          type="text"
                          onChange={(e) => {
                            console.log(e.target.value);
                            setTxtFilter(e.target.value);
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md="1">
                    <Tooltip title="Add registered user" arrow>
                      <div
                        className="navbar-toggler"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          handleCreateNewClick(e);
                        }}
                      >
                        <i className="fas fa-plus-circle" style={{ fontSize: 30, color: GlobalStylesColor.mbppThemePrimary }} />
                      </div>
                    </Tooltip>
                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    {/* <th scope="col" /> */}
                    <th scope="col">User (email&darr;)</th>
                    <th scope="col">Role</th>
                    <th scope="col">Designation</th>
                    <th scope="col">Status</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {itemPageContent && (itemPageContent.length > 0) && itemPageContent.map((props, i) => {
                    console.log(i, props);
                    return (
                      <UserTableRow
                        key={i}
                        id={props._id}
                        email={props.email}
                        role={props.role}
                        designation={props.designation}
                        status={props.registered}
                        editClick={handleEditClick}
                        deleteClick={handleDeleteClick}
                      />
                    );
                  })}
                </tbody>
              </Table>

              {/* pagination */}
              <CardFooter>
                <Row>
                  <Col lg="12">
                    <Pagination
                      current={itemCurrent}
                      total={itemPageCount}
                      onPageChange={(current) => { setItemCurrent(current); }}
                    />
                  </Col>
                </Row>
              </CardFooter>
            </Card>

          </Col>
        </Row>

      </Container>
    </>
  );
};

export default User;
