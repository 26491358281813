import { makeStyles } from "@material-ui/core/styles";
import CryptoJS from 'crypto-js';


export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const FILE_IMG_SIZE = 1024 * 720;

export const SUPPORTED_IMG_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

export const ages = ["0-15", "16-21", "22-35", "36-50", "51-75", "75+"];

export const genders = ["Male", "Female", "Other"];

export const titles = ["Mr", "Mrs", "Ms", "Dr", "Rev", ""];

export const emp_types = ["", "Government", "Non-government", "Private sector", "Other"];

export const countries = ["", "Cambodia", "Nepal", "Philippines", "Pakistan", "Myanmar", "Sri Lanka"];

export const Contactgroup = ["CMC", "Construction", "School", "Other"]; // ["School", "Construction", "Genaral", "Construction Site", "Other"]

export const legal_entitys = ["", "Yes", "No"];

export const vision = ["", "Yes", "No"];

export const unit = ["", "Yes", "No"];

export const functional = ["", "Yes", "No"];

export const stakeholder = ["", "Yes", "No"];

export const headoffice = ["", "Yes", "No"];

export const geo_areas = ["", "National", "Provincial", "District", "City/Municipality", "Village"];

export const drm_acts = ["", "All phases of DRM", "Before disasters", "During disasters", "After disasters"];

export const city_drm_acts = ["", "All phases of DRM", "Before disasters", "During disasters", "After disasters"];

export const user_role = ["GENERAL", "ADMIN"];

export const emp_insitutes = ["", "ADPC", "Other"];

export const ins_cities = ["Bankok", "Other"];

export const chsCountries = { "Cambodia": { "Organization_C1": ["City_C1"], "Organization_C2": ["City_C1", "City_C2"] }, "Nepal": { "Organization_N1": ["City_N1", "City_N2"], "Organization_N2": ["City_N3", "City_N4"] }, "Philippines": { "Organization_P1": ["City_P1", "City_P2"], "Organization_P2": ["City_P3", "City_P4"] }, "Pakistan": { "Organization_Pk1": ["City_Pk1", "City_PK2"], "Organization_Pk2": ["City_Pk3", "City_Pk4"] }, "Myanmar": { "Organization_M1": ["City_M1", "City_M2"], "Organization_M2": ["City_M3", "City_M4"] }, "Sri Lanka": { "Organization_S1": ["City_S1", "City_S2"], "Organization_S2": ["City_S3", "City_S4"] } }
// export const chsCountries={"Cambodia":{"Organization_C1":["City_C1"], "Organization_C2":["City_C1", "City_C2"]}, "Nepal":{"Organization_N1":["City_N1", "City_N2"], "Organization_N2":["City_N3", "City_N4"]}, "Philippines":["Organization_P1", "Organization_P2"],"Pakistan":["Organization_Pk1", "Organization_Pk2"], "Myanmar": ["Organization_M1", "Organization_M2"], "Sri Lanka":["Organization_S1", "Organization_S2"]}

export const institutionalQuestions = {
  organization: "Name of the organization:",
  type: "Type of organization:",
  legalStatus: "Are you a legal entity under the Laws of your country?",
  legalMandate: "Nature of legal mandate:",
  geographicArea: "Geographic area of operations:",
  numberOfEmployees: "Number of employees:",
  drmActivities: "DRM activities performed:",
  visionMission: "Does your organization have vision and mission statements?",
  functionalStructure: "Does your organization have a functional organizational structure?",
  unitProject: "Is there a dedicated unit(s) charged with project formulation, Project implementation, Project monitoring and evaluation for preparedness planning and emergency response?",
  unitStakeholders: "Is there a dedicated unit charged with coordinating identified stakeholders for emergency response?"
}

export const answer_status = ["PROGRESS", "COMPLETE"];
export const survey_status = { PROGRESS: "PROGRESS", COMPLETE: "COMPLETE" };

export const initialValues_assessmentPage = {
  languageCode: '',
  app_ok: "Ok",
  app_yes: "Yes",
  app_no: "No",
  app_next: "NEXT",
  app_back: "BACK",
  app_success: "Success",
  app_error: "Error",
  app_partly_save: "PARTLY SAVE",
  app_complete: "COMPLETE",
  app_assessment_switch_title: "Confirm to switch surveys",
  app_assessment_switch_message: "Are you sure to do this? You will loose unsaved answers.",
  app_assessment_incomplete_title: "Survey is incomplete. Please complete the segment.",
  app_assessment_institute_details: "Institutional Details of the User",
  app_assessment_step1_title: "STEP 1: ORGANIZATION DETAILS",
  app_assessment_step1_message: "Details for institutional profile",
  app_assessment_profile_update: "Your organization's details are used for the institutional profile. If it's not up to the date, please update now",
  app_assessment_answer_opt1: "Not applicable",
  app_assessment_answer_opt2: "Achievements are negligible or minor",
  app_assessment_answer_opt3: "Achievements are incomplete",
  app_assessment_answer_opt4: "Achievements are moderate",
  app_assessment_answer_opt5: "Substantial achievement",
  app_assessment_answer_opt6: "Comprehensive achievement",
};


// camel case
export const Camelize = (str) => {
  return str ? str.replace(/\b(\w)/g, s => s.toUpperCase()) : '';
}

//password encryption
export const CryptoPassword = (value) => {
  return CryptoJS.SHA256(value).toString();
}

export const useMultiSelectStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300
  },
  indeterminateColor: {
    color: "#f50057"
  },
  selectAllText: {
    fontWeight: 500
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  }
}));

export const RoundValue = (value, toString) => {
  if (toString) {
    return value > 0 ? (Math.round((value + Number.EPSILON) * 100) / 100) + "" : "";
  }
  else {
    return Math.round((value + Number.EPSILON) * 100) / 100;
  }
}


// Process array actions
export const processArray = async (array, process, position, value) => {
  let deletedElements = null;
  if (process === "ADD") {
    deletedElements = array.splice(position, 0, value);
    return array;
  }
  else if (process === "DELETE") {
    deletedElements = array.splice(position, 1);
    return array;
  }
  else if (process === "MOVEUP") {
    if (array.length > (position + 1)) {
      let temp = array[position];
      array[position] = array[position + 1];
      array[position + 1] = temp;
      return array;
    }
    else {
      return []
    }
  }
  else if (process === "MOVEDOWN") {
    if (0 <= (position - 1)) {
      let temp = array[position];
      array[position] = array[position - 1];
      array[position - 1] = temp;
      return array;
    }
    else {
      return []
    }
  }
  else if (process === "UPDATEAT") {
    array[position] = value;
    return array;
  }
  else {
    return array;
  }

}


export const isValidString = (str) => {
  if (str === undefined || str === null || str === ' ' || str === '' || str.length < 1) {
    return false;
  }
  else {
    return true;
  }
}

export const dateToString = (date) => {
  if (isValidString(date)) {
    return date.split('T')[0] ?? ''
  }
  return ''
}
///////////////////////////////////


export const affiliation_types = ["", "Construction", "School", "Other"];

// export const AnalyticStatus = ["open", "processing", "confirmation", "complete", "closed"];
export const AnalyticStatus = ["Open", "Processing", "Confirmation", "Complete"];

export const BreedingComplainActions = ["Update", "Misinformation", "No Action Needed", "Attended"];

export const BreedingComplainStatus = ["open", "processing", "closed"];

export const contactGroups = ["CMC", "Construction", "School", "Other"];



