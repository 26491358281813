import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { MbppStyles } from 'constants/globalStyles';
// import * as WebDataRocksReact from "../../../assets/js/webdatarocks.react";

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  FormGroup,
  Form,
  Button,
  Row,
  Col,
} from "reactstrap"; // reactstrap components
import { TextField } from '@material-ui/core';
import Lodash from "lodash";

import * as XLSX from 'xlsx';

// core components
import PageHeader from "components/Headers/PageHeader.js";
import { fetchMbpCasesInFull } from "actions/mbp";


const validationSchemaAnalytics = yup.object({
  startday: yup
    .date('Enter start date')
    .required('Start date Required'),
  endday: yup
    .date('Enter end date')
    .required('Start date Required'),
});



const FindCaseReportsInFull = (props) => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [reportConfiguration, setReportConfiguration] = useState(undefined)
  const [getData, setGetData] = useState(false)


  const caseReports = useSelector((state) => state.mbpCase.CaseReportsFull);
  console.log("CaseReports in full 11 -------: ", caseReports);

  const formik = useFormik({
    initialValues: {
      startday: '',
      endday: '',
    },
    validationSchema: validationSchemaAnalytics,
    onSubmit: (values, onSubmitProps) => {
      console.log("formik values: ", values);

      let params = {
        "status": 'complete',
        "startday": values.startday,
        "endday": values.endday,
      }

      console.log("params", params);
      dispatch(fetchMbpCasesInFull(params));
      setGetData(true)
    }
  });

  useEffect(() => {
    // console.log("caseReports -------: ", caseReports);
    if (!caseReports || caseReports.length === 0) return

    // Pre process data
    const cleanedData = []
    Lodash.map(caseReports, function mapAssessment(details) {
      cleanedData.push({
        Serial_No: details?.workflowid,
        Date_Received: details?.createdAt,
        Institute: details?.notification?.diseaseInfo?.disease,
        Week: details?.createdAt,
        Notified_as: details?.confirmation?.records?.diseaseNotified,
        Date_of_Onset: details?.confirmation?.dateOfOnset,
        Ethnic_Group: details?.investigation?.patientRecords?.ethnicGroup,
        N: Lodash.isArray(details?.notification?.patient?.location) ? details?.notification?.patient?.location[0]?.gps?.lat : '',
        E: Lodash.isArray(details?.notification?.patient?.location) ? details?.notification?.patient?.location[0]?.gps?.lon : '',
        Name: details?.investigation?.patientRecords?.name,
        Address: details?.investigation?.patientRecords?.address,
        Road: Lodash.isArray(details?.notification?.patient?.location) ? details?.notification?.patient?.location[0]?.street : '',
        Date_of_Birth: details?.investigation?.patientRecords?.dateOfBirth,
        Age: details?.investigation?.patientRecords?.age,
        Sex: details?.confirmation?.patientRecords?.gender,
        District: details?.moh,
        Ward: details?.ward,
        Status: details?.investigation?.status,
        Remark: '',
        Others: '',
        School: '',
        Active_Passive: '',
        mohWorkflowid: details.mohWorkflowid,
        value: 1,
      })
      return true
    })

    console.log('cleanedData -------: ', cleanedData)
    setReportConfiguration(cleanedData)

  }, [caseReports])


  const exportDataToXlsx = () => {

    let header = ["Serial_No",
      "Date_Received",
      "Institute",
      "Week",
      "Notified_as",
      "Date_of_Onset",
      "Ethnic_Group",
      "N",
      "E",
      "Name",
      "Address",
      "Road",
      "Date_of_Birth",
      "Age",
      "Sex",
      "District",
      "Ward",
      "Status",
      "Remark",
      "Others",
      "School",
      "Active_Passive",
      "mohWorkflowid",
    ];
    const ws = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, [header]);
    XLSX.utils.sheet_add_json(ws, reportConfiguration, { origin: 'A2', skipHeader: true });
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    XLSX.writeFile(wb, 'out.xlsx');
  }

  return (
    <>
      <PageHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Params*/}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Selection Details</h3>
              </CardHeader>
              <Form
                role="form"
                onSubmit={formik.handleSubmit}
                onReset={formik.handleReset}>
                <CardBody>
                  <FormGroup className="mb-3">

                    <Row>
                      <Col xs="1" sm="1" className="px-3 pt-3 text-right">
                        <i className="fas fa-calendar-day" />
                      </Col>
                      <Col xs="10" sm="10">
                        <Row>
                          <Col xs="12" sm="6">
                            <TextField
                              fullWidth
                              type="date"
                              id="startday"
                              name="startday"
                              label="Start Date*"
                              variant="outlined"
                              format={'DD/MM/YYYY'}
                              value={formik.values.startday}
                              onChange={formik.handleChange}
                              style={MbppStyles.Input}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={formik.touched.startday && Boolean(formik.errors.startday)}
                              helperText={formik.touched.startday && formik.errors.startday}
                            />
                          </Col>
                          <Col xs="12" sm="6">
                            <TextField
                              fullWidth
                              type="date"
                              id="endday"
                              name="endday"
                              label="End Date*"
                              variant="outlined"
                              format={'DD/MM/YYYY'}
                              value={formik.values.endday}
                              onChange={formik.handleChange}
                              style={MbppStyles.Input}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={formik.touched.endday && Boolean(formik.errors.endday)}
                              helperText={formik.touched.endday && formik.errors.endday}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                  </FormGroup>
                </CardBody>
                <CardFooter className="py-2 text-right">
                  <Row>
                    <Col sm="12" className="text-right">
                      <Button className="my-2" variant="contained" color="primary" type="reset">
                        Clear
                      </Button>
                      <Button className="my-2" variant="contained" color="primary" type="submit">
                        Visualize
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      {getData && (!caseReports || caseReports.length === 0) && (<div>No data. Please try again with a different dates.</div>)}
                      {reportConfiguration && reportConfiguration.length > 1 && (
                        <Button className="my-2" variant="contained" color="success" onClick={() => { exportDataToXlsx() }}>
                          Download data
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardFooter>
              </Form>
            </Card>
          </div>
        </Row>

        <br />
      </Container >
    </>
  );
};

export default FindCaseReportsInFull;
