import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import Lodash from "lodash";
import { fetchFrmTopics, fetchFrmQus } from "actions/forum";

// reactstrap components
import {
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  CardBody,
  Table,
  Container,
  Row,
  Button,
} from "reactstrap";

// core components
import PageHeader from "components/Headers/PageHeader.js";

const ForumTableSubTopicRow = ({
  topicId,
  subTopicId,
  subTopic,
  coverpic,
  description,
  status,
  subTopicClick,
  QusId,
  question,
  topic,
}) => (
  <tr id={subTopicId}>
    <td style={{ whiteSpace: "break-spaces", margin: 0, padding: 0 }}><h4>{subTopic}</h4></td>
    {/* <td style={{ whiteSpace: "break-spaces" }}>{description}</td> */}
  </tr>
);

const ForumTableQuestionRow = ({
  QusId,
  question,
  subTopicId,
  qdescription,
}) => (
  <tr id={QusId}>
    <td style={{ whiteSpace: "break-spaces", margin: 0, padding: 0, color: "#fdba2b" }}><b>{question}</b></td>
    {/* <td style={{ whiteSpace: "break-spaces" }}>{qdescription}</td>  */}
  </tr>
);

const ForumTableAnswerRow = ({ answerId, answer, ansdescription }) => (
  <tr id={answerId}>
    <td style={{ whiteSpace: "break-spaces", margin: 0, padding: 0 }}>{answer}</td>
    {/* <td style={{ whiteSpace: "break-spaces" }}>{ansdescription}</td> */}
  </tr>
);

const Faq = () => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  // get forum topics
  const forumalltopics = useSelector((state) => state.forum.frmAllTopics);
  const forumallquestions = useSelector((state) => state.forum.frmAllQus);

  // set state topic id
  const [topicId, setTopicId] = useState(null);
  // set state topic
  const [topic, setTopic] = useState(null);
  // set state sub topic id
  const [subTopicId, setSubTopicId] = useState(null);
  // set state sub topic
  const [subTopic, setSubTopic] = useState(null);
  // set state sub topic description
  const [subTopicDescription, setSubTopicDescription] = useState(null);
  const [topicDescription, setTopicDescription] = useState(null);

  const [QusId, setQ] = useState(null);
  const [question, setQuestions] = useState(null);

  useEffect(() => {
    // fetching forum topics
    dispatch(fetchFrmTopics());
    dispatch(fetchFrmQus());
  }, []);

  useEffect(() => {
    if (subTopic && subTopicId) {
      setQ(subTopic._id);
      setQuestions(subTopic.topic);
    }
  }, [forumalltopics, forumallquestions]);

  return (
    <>
      <PageHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {forumalltopics.length > 0 &&
          Object.values(forumalltopics).map((props, i) => {
            return (
              <Row key={i} className={i !== 0 ? "mt-5" : ""} id={props.id}>
                <div className="col">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                      <div className="row">
                        <div className="mb-xl-0 col-10">
                          <h3 className="mb-0">{props.topic}</h3>
                          <h6 className="mb-0">{props.description}</h6>
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        {Object.values(props.subTopic).map((subprops, i) => {
                          return (
                            <tbody>
                              <>
                                <ForumTableSubTopicRow
                                  key={i}
                                  topic={props.topic}
                                  description={props.description}
                                  topicId={props._id}
                                  subTopicId={subprops._id}
                                  subTopic={subprops.subTopic}
                                  description={subprops.description}
                                  status={subprops.status}
                                />

                                {Lodash.filter(forumallquestions, {
                                  subTopic: subprops._id,
                                }).map((index, j) => {
                                  {
                                    console.log("id", subprops._id);
                                    console.log("loop 2" + j, index);
                                  }
                                  return (
                                    <tbody>
                                      <tr>
                                        <td>
                                          <ForumTableQuestionRow
                                            QusId={index}
                                            question={index.question}
                                            qdescription={index.description}
                                          />
                                        </td>
                                      </tr>

                                      {Object.values(index.answer).map(
                                        (ans, l) => {
                                          return (
                                            <tbody>
                                              <tr>
                                                <td>
                                                  <ForumTableAnswerRow
                                                    answerId={ans._id}
                                                    answer={ans.answer}
                                                    ansdescription={
                                                      ans.description
                                                    }
                                                  />
                                                </td>
                                              </tr>
                                            </tbody>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  );
                                })}
                              </>
                            </tbody>
                          );
                        })}
                      </Table>
                    </CardBody>
                  </Card>
                </div>
              </Row>
            );
          })}
      </Container>
    </>
  );
};

export default Faq;
