
import { 
  CALENDAR_FETCH_ALL, 
  CALENDAR_FETCH_USER,
  CALENDAR_FETCH_PUB, 
  CALENDAR_CREATE, 
  CALENDAR_UPDATE, 
  CALENDAR_DELETE,
} from 'constants/actionTypes';

const initState = {
  allCalendarTask:[],
  userCalendarTask:[],
  publicCalendarTask:[],
  createCalendarTask:[],
  updateCalendarTask:[],
  deleteCalendarTask:[]
}

const calendarReducer =(calendar = initState, action) => {
  switch (action.type) {
    case CALENDAR_FETCH_ALL:
        return {...calendar, allCalendarTask: action.payload};
    case CALENDAR_FETCH_USER:
        return {...calendar, userCalendarTask: action.payload};
    case CALENDAR_FETCH_PUB:
        return {...calendar, publicCalendarTask: action.payload};
    case CALENDAR_CREATE:
        return {...calendar, createCalendarTask: action.payload};
    case CALENDAR_UPDATE:
        return {...calendar, updateCalendarTask: action.payload};
    case CALENDAR_DELETE:
        return {...calendar, deleteCalendarTask: action.payload};
    default:
      return calendar;
  }
};

export default calendarReducer;