import LandingFooter from "components/Footers/LandingFooter";
import React, { } from "react";
// reactstrap components
import '../assets/css/chs-custom.css';
import { Route, Switch } from "react-router-dom";

import PrivacyPolicy from "views/pages/user/PrivacyPolicy"
import DataPolicy from "views/pages/user/DataPolicy"

const Simple = (props) => {
  const mainContent = React.useRef(null);

  return (
    <>
      <div class="shadow" style={{ backgroundColor: "#fff" }}>
      </div>


      <div className="main-content" ref={mainContent}>
        <Switch>
          <Route
            path={'/privacy_policy'}
            component={PrivacyPolicy}
            key={'privacy_policy'}
          />
          <Route
            path={'/data_policy'}
            component={DataPolicy}
            key={'data_policy'}
          />
        </Switch>
      </div>

      <div class="shadow" style={{ backgroundColor: "#fff" }}>
        <LandingFooter />
      </div>

    </>
  );
};

export default Simple;
