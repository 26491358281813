import LandingFooter from "components/Footers/LandingFooter";
import LandingNavBar from "components/Navbars/LandingNavbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";
import '../../../assets/css/chs-custom.css';


const Landing = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  return (
    <>

      <div className="main-content" ref={mainContent}>

        <div style={{
          position: "relative",
          width: "100%",
          background: "linear-gradient(to left, #016864, #142237)",
        }}>
          <Container>
            <Row style={{ position: "relative", minHeight: '500px' }}>
              <img
                md="12"
                alt="MoBuzz apps"
                src={require("../../../assets/img/theme/front-splash.png").default}
                style={{ right: 16, position: "absolute", width: 'auto', height: '100%', maxHeight: '800px', float: 'right' }}
              />
              <h6 className="pt-9" md="12"
                style={{ left: 40, color: '#fff', fontSize: 40, position: "relative", maxWidth: '60%' }}
              >
                MO-BUZZ+ is beyond Dengue. It’s a disease reporting and monitoring tool.
              </h6>
            </Row>
          </Container>
        </div>

        <div style={{
          position: "relative",
          width: "100%",
          backgroundColor: "#fff",
        }}>
          <Container>
            <Row>
              <Col md="12" className="text-center">
                <h2 className="pt-5" style={{ color: '#142237', fontSize: 48, fontWeight: 'bold' }}>Mo-Buzz+ Apps</h2>
              </Col>
            </Row>
            <Row>
              <Col md="6" className="text-center">
                <img
                  alt="MoBuzz plus"
                  src={require("../../../assets/img/theme/logo-mobuzz-plus.png").default}
                  style={{ maxWidth: '100%', height: 'auto', width: 300, margin: 20, alignSelf: 'center' }}
                />
                <span style={{ color: '#142237', textAlign: 'justify' }}>
                  <p style={{ fontSize: 18 }}>
                    <b>MO-BUZZ+</b> is a mobile surveillance application for public health inspectors in Colombo, Sri Lanka to control and reduce the spread of dengue.
                  </p>
                  <p style={{ fontSize: 18 }}>
                    Features include:
                    <br />- Digitized dengue forms to streamline tracking mosquito breeding and spread, for health inspectors
                    <br />- Maps to visually track dengue cases, workload, and danger zones
                    <br />- Analytics to monitor the number of dengue cases and trends over time and by location
                  </p>
                  <p>
                    <a href="https://play.google.com">
                      <img
                        alt="Get it on Google play"
                        src={require("../../../assets/img/icons/common/googlePlay.png").default}
                        style={{ maxWidth: '100%', height: 'auto', width: 200, margin: 20, alignSelf: 'center' }}
                      />
                    </a>
                  </p>
                </span>
              </Col>
              <Col md="6" className="text-center">
                <img
                  alt="MoBuzz Public"
                  src={require("../../../assets/img/theme/logo-mobuzz-public.png").default}
                  style={{ maxWidth: '100%', height: 'auto', width: 300, margin: 20, alignSelf: 'center' }}
                />
                <span style={{ color: '#142237', textAlign: 'justify' }}>
                  <p style={{ fontSize: 18 }}>
                    <b>Mo-Buzz+ Public</b> is an integrated participatory surveillance application that allows the general public of Colombo Sri Lanka to directly report potential dengue breeding sites or cases to the health authorities to help reduce the spread of dengue. It provides information to stay informed on the latest dengue situation in the community and also provides advice on how to prevent dengue to keep the population healthy.
                  </p>
                  <p>
                    <a href="https://play.google.com">
                      <img
                        alt="Get it on Google play"
                        src={require("../../../assets/img/icons/common/googlePlay.png").default}
                        style={{ maxWidth: '100%', height: 'auto', width: 200, margin: 20, alignSelf: 'center' }}
                      />
                    </a>
                  </p>
                </span>
              </Col>
            </Row>
          </Container>
        </div>

        <div style={{ backgroundColor: '#fdba2b', color: 'white' }}>
          <Container>
            <Row >
              <Col md="12">
                <span style={{ color: '#fff', textAlign: 'justify', marginTop: 16, marginBottom: 16 }}>
                  <p style={{ fontSize: 18, fontWeight: '900' }}>
                    <br />
                    Mo-Buzz+ was developed by a research team led by Prof May Oo Lwin at Wee Kim Wee School of Communication and Information, Nanyang Technological University, Singapore, in collaboration with Colombo Municipal Council, University of Colombo and Mobitel.
                    <br />
                    <br />
                  </p>
                </span>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

    </>
  );
};

export default Landing;
