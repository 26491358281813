import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { TextField, FormControlLabel, Switch, Tooltip, Grid } from '@material-ui/core';
import { MbppStyles } from 'constants/globalStyles';

// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Button,
  FormGroup,
  Form,
} from "reactstrap";

import { fetchCalendarEventPub } from 'actions/calendar';

// core components
import PageHeader from "components/Headers/PageHeader.js";

import { basicInformation } from "globalGeoInformation"

const validationSchemaActivity = yup.object({
  startday: yup
    .date('Enter activity date')
    .required('Activity date is required'),
  endday: yup
    .date('Enter activity date')
    .required('Activity date is required'),
});

const date_options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };

const TableRow = ({ task, editClick }) => (
  <tr id={task._id}>
    <th>{new Date(task.date).toLocaleDateString(undefined, date_options)}</th>
    <td>{task.title}</td>
    <td style={{ 'whiteSpace': 'break-spaces' }}>{task.description}</td>
    <td>{task.type}</td>
    <td>{basicInformation.wardIds[task.ward]}</td>

    <td className="text-right">
      <div className="row">
        <Tooltip title="Edit" arrow>
        <div className="navbar-toggler"
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            editClick(e, task)
          }}>
          <i className="far fa-edit" />
        </div>
        </Tooltip>
      </div>
    </td>
  </tr>
);

const CalendarPublicActivity = () => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  // get state Contacts details
  const publicCalendarTask = useSelector((state) => state.calendar.publicCalendarTask);
  console.log(publicCalendarTask)
  useEffect(() => {
    var date = new Date();
    // console.log(new Date(date.getFullYear(), date.getMonth(), 1))
    // console.log(new Date(date.getFullYear(), date.getMonth() + 1, 0))
    formik.values.startday = new Date(date.getFullYear(), 1, 1)
    formik.values.endday = new Date(date.getFullYear(), 12 + 1, 0)
    // fetching calendar Events
    dispatch(fetchCalendarEventPub(formik.values, history));
  }, []);

  const handleEditClick = (e, task) => {
    e.preventDefault();
    history.push({ pathname: '/registered/calendar_activity_details_form', state: { caleEvent: task } })
  }

  const formik = useFormik({
    initialValues: {
      startday: '',
      endday: ''
    },
    validationSchema: validationSchemaActivity,
    onSubmit: (values, onSubmitProps) => {
      console.log(values);
      dispatch(fetchCalendarEventPub(values, history));
    },
  });
  return (
    <>
      <PageHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table School */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Public Calendar Events</h3>
                <Form role="form" onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                  <FormGroup className="mt-5">
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={5} sm={5}>
                        <TextField
                          fullWidth
                          type="date"
                          id="startday"
                          name="startday"
                          label="Start Day"
                          variant="outlined"
                          format={'DD/MM/YYYY'}
                          value={formik.values.startday}
                          onChange={formik.handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={5} sm={5}>
                        <TextField
                          fullWidth
                          type="date"
                          id="endday"
                          name="endday"
                          label="EndDay"
                          variant="outlined"
                          format={'DD/MM/YYYY'}
                          value={formik.values.endday}
                          onChange={formik.handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={2} sm={2} className="text-center">
                        <Button className="my-2" variant="contained" color="primary" type="submit">
                          Load
                        </Button>
                      </Grid>
                    </Grid>
                  </FormGroup>
                </Form>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Title</th>
                    <th scope="col">Description</th>
                    <th scope="col">Type</th>
                    <th scope="col">Ward</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {publicCalendarTask.length > 0 && Object.values(publicCalendarTask).map((props, i) => {
                    return (
                      <TableRow
                        key={i}
                        task={props}
                        editClick={handleEditClick}
                      />
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CalendarPublicActivity;
