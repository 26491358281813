import {
    EDU_TOPIC_FETCH_ALL, 
    EDU_TOPIC_FETCH_ONE, 
    EDU_TOPIC_CREATE, 
    EDU_TOPIC_UPDATE,
    EDU_SUB_TOPIC_FETCH_ALL,
    EDU_SUB_TOPIC_FETCH_ONE,
    EDU_SUB_TOPIC_CREATE,
    EDU_SUB_TOPIC_UPDATE,
    EDU_ART_FETCH_ONE,
    EDU_ART_FETCH_ALL,
    EDU_ART_TOPIC_FETCH_ALL,
    EDU_ART_CREATE,
    EDU_ART_UPDATE,

    EDUCATIONAL_MATERIALS,
} from 'constants/actionTypes';

const initState = {
  eduAllTopics: [],
  eduTopic:{},
  eduTopicCreate:[],
  eduTopicUpdate:[],

  eduAllSubTopics:[],
  eduSubTopic:{},
  eduSubTopicCreate:[],
  eduSubTopicUpdate:[],

  eduArticle: {},
  eduAllArticles:[],
  eduArticlesByTopic:[],
  eduArticleCreate:[],
  eduArticleUpdate:[],

  educationMaterials:[],
}

const educationReducer =(education = initState, action) => {
  switch (action.type) {

    // education topics
    case EDU_TOPIC_FETCH_ALL:
      return {...education, eduAllTopics: action.payload};
    case EDU_TOPIC_FETCH_ONE:
      return {...education, eduTopic: action.payload};
    case EDU_TOPIC_CREATE:
      return {...education, eduTopicCreate: action.payload};
    case EDU_TOPIC_UPDATE:
      return {...education, eduTopicUpdate: action.payload};

    // education sub topics
    case EDU_SUB_TOPIC_FETCH_ALL:
      return {...education, eduAllSubTopics: action.payload};
    case EDU_SUB_TOPIC_FETCH_ONE:
      return {...education, eduSubTopic: action.payload};
    case EDU_SUB_TOPIC_CREATE:
      return {...education, eduSubTopicCreate: action.payload};
    case EDU_SUB_TOPIC_UPDATE:
      return {...education, eduSubTopicUpdate: action.payload};
      
    // education Articles
    case EDU_ART_FETCH_ONE:
      return {...education, eduArticle: action.payload};
    case EDU_ART_FETCH_ALL:
      return {...education, eduAllArticles: action.payload};
    case EDU_ART_TOPIC_FETCH_ALL:
      return {...education, eduArticlesByTopic: action?.payload };
    case EDU_ART_CREATE:
      return {...education, eduArticleCreate: action.payload};
    case EDU_ART_UPDATE:
      return {...education, eduArticleUpdate: action.payload};

    case EDUCATIONAL_MATERIALS:
      return {...education, educationMaterials: action.payload};

    default:
      return education;
  }
};

export default educationReducer;