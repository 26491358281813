import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { fetchFrmTopics } from 'actions/forum';

// reactstrap components
import {
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Container,
  Row,
  Col,
  Button
} from "reactstrap";
// core components
import PageHeader from "components/Headers/PageHeader.js";
import { GlobalStylesColor } from "constants/globalStyles";
import { Tooltip } from "@material-ui/core";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PublicOutlinedIcon from "@material-ui/icons/PublicOutlined";
import VpnLockOutlinedIcon from "@material-ui/icons/VpnLockOutlined";
import { updateFrmSubTopic, deleteFrmSubTopic, deleteFrmTopic } from 'actions/forum';
import { LocationDisabledSharp } from "@material-ui/icons";
import Lodash from "lodash";
import { confirmAlert } from 'react-confirm-alert';

const ForumTableSubTopicRow = ({ topicId, subTopicId, subTopic, coverpic, description, status, subTopicClick, subTopicEdit, subTopicDelete, statusClick }) => (
  <tr id={subTopicId}>
    <th scope="row" style={{ 'whiteSpace': 'break-spaces' }}>
      <Media className="align-items-center">
        <a
          href='#'
          onClick={(e) => { subTopicClick(e, topicId, subTopicId) }}
        >
          {coverpic ?
            <div className="avatar rounded-circle mr-3">
              <img
                alt={subTopic}
                src={coverpic}
              />
            </div>
            : null}
          <Media>
            <span className="mb-0 text-sm">
              {subTopic}
            </span>
          </Media>
        </a>
      </Media>
    </th>
    <td style={{ 'whiteSpace': 'break-spaces' }}>{description}</td>
    <td className="text-right">
      <div className="row">
        <Tooltip title="Edit" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              subTopicEdit(e, topicId, subTopicId);
            }}
          >
            <i className="far fa-edit" style={{ color: GlobalStylesColor.mbppIconColor, }} />
          </div>
        </Tooltip>
        <Tooltip title="Change publish status" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              statusClick(e, topicId, subTopicId, status);
            }}
          >
            {status ? <PublicOutlinedIcon /> : <VpnLockOutlinedIcon />}
          </div>
        </Tooltip>
        <Tooltip title="Delete" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              subTopicDelete(e, topicId, subTopicId);
            }}
          >
            <i className="far fa-times-circle" style={{ color: GlobalStylesColor.mbppIconColor, }} />
          </div>
        </Tooltip>
        <Tooltip title="FAQs" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              subTopicClick(e, topicId, subTopicId);
            }}
          >
            <NavigateNextIcon style={{ color: GlobalStylesColor.mbppIconColor }} />
          </div>
        </Tooltip>
      </div>
    </td>
  </tr>
);

const Forum = () => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  // get forum topics
  const forumalltopics = useSelector((state) => state.forum.frmAllTopics);

  useEffect(() => {
    // fetching forum topics
    dispatch(fetchFrmTopics());
  }, []);

  const frmSubTopicUpdate = useSelector((state) => state.forum.frmSubTopicUpdate);
  useEffect(() => {
    if (frmSubTopicUpdate && !Lodash.isEmpty(frmSubTopicUpdate)) {
      dispatch(fetchFrmTopics());
    }
  }, [frmSubTopicUpdate]);

  // handle Click event of the SubTopic
  const handleSubTopicClick = (e, topicId, subTopicId) => {
    e.preventDefault();
    history.push({ pathname: '/registered/faq_subtopic', state: { frmTopicId: topicId, frmSubTopicId: subTopicId } })
  }

  // handle click event of Edit topic
  const handleEditClick = (e, topicId) => {
    e.preventDefault();
    history.push({ pathname: '/registered/faq_topic_form', state: { frmTopicId: topicId } })
  }

  // handle click event of Edit topic
  const handleDeleteClick = (e, topicId) => {
    e.preventDefault();
    confirmAlert({
      title: "Delete", message: 'Delete topic?', buttons: [{
        label: 'Ok',
        onClick: () => {
          dispatch(deleteFrmTopic({ forumTopicId: topicId }, history));
        }
      },
      {
        label: 'Cancel',
        onClick: () => {}
      }
    ]
    });
  }

  // handle click event of add sub topic
  const handleAddSubTopicClick = (e, topicId) => {
    e.preventDefault();
    history.push({ pathname: '/registered/faq_sub_topic_form', state: { frmTopicId: topicId } })
  }

  // handle click event of edit sub topic
  const handleEditSubTopicClick = (e, topicId, subTopicId) => {
    e.preventDefault();
    history.push({ pathname: '/registered/faq_sub_topic_form', state: { frmTopicId: topicId, frmSubTopicId: subTopicId } })
  }

  const handleStatusSubTopicClick = (e, topicId, subTopicId, status) => {
    e.preventDefault();
    dispatch(updateFrmSubTopic({ forumTopicId: topicId, forumSubTopicId: subTopicId, publish: !status }, history));
  }

  const handleDeleteSubTopicClick = (e, topicId, subTopicId) => {
    e.preventDefault();
    confirmAlert({
      title: "Delete", message: 'Delete sub topic?', buttons: [{
        label: 'Ok',
        onClick: () => {
          dispatch(deleteFrmSubTopic({ forumTopicId: topicId, forumSubTopicId: subTopicId }, history));
        }
      },
    {
      label: 'Cancel',
      onClick: () => {}
    }]
    });
  }

  // const handleAddQuestionClick = (e, topicId, subTopicId) => {
  //   e.preventDefault();
  //   history.push({ pathname: '/registered/faq_question_form', state: { frmTopicId: topicId, frmSubTopicId: subTopicId } })
  // }

  return (
    <>
      <PageHeader />
      {/* Page content */}
      <Container className="mt--9" fluid>

        <Row>
          <Col>
            <CardHeader className="border-0" style={{ backgroundColor: "transparent" }}>
              <div className="row">
                <div className="mb-xl-0 col-10"></div>
                <div className="mb-xl-0 col-2 text-right">
                  <Tooltip title="Create new topic" >
                    <div
                      className="navbar-toggler"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        history.push({ pathname: '/registered/faq_topic_form' })
                      }}
                    >
                      <i className="fas fa-plus-square" style={{ fontSize: 35, color: GlobalStylesColor.mbppLogoBlue, }} />
                    </div>
                  </Tooltip>
                </div>
              </div>
            </CardHeader>
          </Col>
        </Row>


        {forumalltopics.length > 0 && Object.values(forumalltopics).map((props, i) => {
          return (
            <Row key={i} className={i !== 0 ? "mt-5" : ""} id={props.id}>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div className="row">
                      <div className="mb-xl-0 col-9">
                        <h3 className="mb-0">{props.topic}</h3>
                        <h6 className="mb-0">{props.description}</h6>
                      </div>
                      <div className="mb-xl-0 col-3 text-right">
                        <div className="row text-right" style={{ marginLeft: 0 }}>
                          <Tooltip title="Edit topic" >
                            <div
                              className="navbar-toggler"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                handleEditClick(e, props._id);
                              }}
                            >
                              <i className="fas fa-edit" style={{ fontSize: 30, color: GlobalStylesColor.mbppThemePrimary, }} />
                            </div>
                          </Tooltip>
                          <Tooltip title="Delete topic" >
                            <div
                              className="navbar-toggler"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                handleDeleteClick(e, props._id);
                              }}
                            >
                              <i className="fas fa-times-circle" style={{ fontSize: 30, color: GlobalStylesColor.mbppThemePrimary, }} />
                            </div>
                          </Tooltip>
                          <Tooltip title="Create new sub topic" >
                            <div
                              className="navbar-toggler"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                handleAddSubTopicClick(e, props._id);
                              }}
                            >
                              <i className="fas fa-plus-circle" style={{ fontSize: 30, color: GlobalStylesColor.mbppThemePrimary, }} />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </CardHeader>



                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th className="col-lg-5">Sub Topic</th>
                        <th className="col-lg-4">Description</th>
                        <th className="col-lg-3">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr></tr>
                      {Object.values(props.subTopic).map((subprops, i) => {

                        return (
                          <ForumTableSubTopicRow
                            key={i}
                            topicId={props._id}
                            subTopicId={subprops._id}
                            subTopic={subprops.subTopic}
                            description={subprops.description}
                            status={subprops.status}
                            subTopicClick={handleSubTopicClick}
                            subTopicEdit={handleEditSubTopicClick}
                            subTopicDelete={handleDeleteSubTopicClick}
                            // addQuestionClick={handleAddQuestionClick}
                            statusClick={handleStatusSubTopicClick}
                          />
                        );
                      })}
                    </tbody>
                  </Table>
                </Card>
              </div>
            </Row>
          );
        })}
      </Container>
    </>
  );
};

export default Forum;
