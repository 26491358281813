import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Lodash from "lodash";
import Config from "config/config.json";

// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Button,
} from "reactstrap";

import { fetchContact } from 'actions/contact';
import { contactGroups } from 'helper';

import { TextField, FormControlLabel, Switch, Tooltip, Grid } from '@material-ui/core';

// core components
import PageHeader from "components/Headers/PageHeader.js";

const ContactTableRow = ({ details, editClick }) => (
  <tr id={details.id} >
    <th scope="row">
      <Media className="align-items-center">
        {details.coverpic ?
          <a
            className="avatar rounded-circle mr-3"
            href="#pablo"
            onClick={(e) => e.preventDefault()}
          >
            <img
              alt={details.coverpic}
              src={Config.SERVER_API_URL + Config.PUBLIC_PROF_PIC + details.coverpic}
            />
          </a>
          : null}
        <Media>
          <span className="mb-0 text-sm">
            {details.title}
          </span>
        </Media>
      </Media>
    </th>
    <td>
      <table>
        <thead></thead>
        <tbody>
          {Object.values(details.contact).map((props, i) => {
            return (
              <tr key={i}>
                {props.person ?
                  <>
                    <th style={{ padding: "0.2rem" }}>Name :</th>
                    <td style={{ padding: "0.2rem" }}>{props.person}&nbsp;&nbsp;&nbsp;</td>
                  </>
                  : null}
                <th style={{ padding: "0.2rem" }}>Number :</th>
                <td style={{ padding: "0.2rem" }}>{props.number}</td>
                {props.person ? null :
                  <>
                    <th style={{ padding: "0.2rem" }}></th>
                    <td style={{ padding: "0.2rem" }}></td>
                  </>
                }
              </tr>
            );
          })}
        </tbody>
      </table>
    </td>
    {/* <td style={{ 'whiteSpace': 'break-spaces' }}>{details.address}</td> */}
    <td style={{ 'whiteSpace': 'break-spaces' }}>{details.description}</td>

    <td className="text-right">
      <div className="row">
        <Tooltip title="Edit" arrow>
          <div className="navbar-toggler"
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              editClick(e, details)
            }}>
            <i className="far fa-edit" />
          </div>
        </Tooltip>
      </div>
    </td>
  </tr>
);

const Contact = () => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  // get state Contacts details
  const contactsDetails = useSelector((state) => state.contact.contactsDetails);

  useEffect(() => {
    // fetching contacts
    dispatch(fetchContact());
  }, []);

  const handleEditClick = (e, details) => {
    e.preventDefault();
    history.push({ pathname: '/registered/contact_form', state: { contact: details } })
  }

  return (
    <>
      <PageHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* <Row>
          <div className="col text-right">
            <Button
              color="primary"
              href="/registered/contact_form"
              size="md"
              className="m-3"
            >
              Add Contact
            </Button>
            {console.log("contactGroups: ", contactGroups)}
          </div>
        </Row> */}
        {/* Table School */}

        {contactGroups.map((group, i) => {
          return (
            <Row className={(group === contactGroups[0]) ? "" : "mt-5"} >
              <Col>
                {/* {console.log("group: ", group)} */}
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <h3 className="mb-0">{group}</h3>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Title</th>
                        <th scope="col">Contacts</th>
                        {/* <th scope="col">address</th> */}
                        <th scope="col">description</th>
                        {/* <th scope="col">Status</th> */}
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {contactsDetails.length > 0 && (Lodash.filter(contactsDetails, { group: group, status: true })).map((props, i) => {
                        return (
                          <ContactTableRow
                            key={i}
                            details={props}
                            editClick={handleEditClick}
                          />
                        );
                      })}
                    </tbody>
                  </Table>
                </Card>
              </Col>
            </Row>
          );
        })}

      </Container>
    </>
  );
};

export default Contact;
