import { Grid, IconButton, InputAdornment, TextField } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { signin, signup } from 'actions/user';
import { MbppStyles } from 'constants/globalStyles';
import { useFormik } from 'formik';
import { legal_entitys, geo_areas, drm_acts, countries, emp_insitutes, ins_cities } from 'helper';
import { affiliation_types, ages, CryptoPassword, emp_types, FILE_IMG_SIZE, genders, phoneRegExp, SUPPORTED_IMG_FORMATS, titles } from "helper.js";
import React, { useState } from "react";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Resizer from "react-image-file-resizer";
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';


import { Redirect, Route, Switch, useLocation } from "react-router-dom";
// reactstrap components
import { routes } from "routes.js";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Col, Form, FormGroup,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
  Container,
} from "reactstrap";
import * as yup from 'yup';


const validationSchemaSignin = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(3, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
});

const validationSchemaSignUp = yup.object({});

const Login = () => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  // set state contact number list
  const [contactList, setContactList] = useState([{ index: 0, contactno: "" }]);
  // set state profilePicture
  const [profilePicture, setProfilepic] = useState("");
  // set state signup /signin
  const [isSignup, setIsSignup] = useState(false);

  // handle click event of togle signup and signin
  const switchMode = (e) => {
    e.preventDefault();
    setIsSignup((prevIsSignup) => !prevIsSignup);
    setShowPassword(false);
  };

  const mainContent = React.useRef(null);
  const location = useLocation();

  // handle change event of the profilePicture
  const handleFileChange = async (e) => {
    // Resize the image to generate thumbnail
    let image = null;
    try {
      const file = e.target.files[0];
      image = await resizeImage(file);
      console.log(image);
      formik.values.profilePicture = file;
      formik.values.profileThumbnail = image;
      formik.values.newProfilePicture = true;
    } catch (err) {
      console.log(err);
    }
  };

  const resizeImage = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        200,
        200,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
        200,
        200
      );
    });

  // handle change event of the contact number
  const handleContactChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...contactList];
    list[index][name] = value;
    setContactList(list);
    // console.log(contactList);
    formik.values.telephone = ((Object.keys(contactList).map((key) => contactList[key]['contactno'])).filter(el => { return el !== null && el !== '' }))
  };

  // handle click event of the Remove button of contact number

  // handle click event of the Add button of contact number

  // handle click event of password visible and password dotted
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);



  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      profilePicture: '',
      profileThumbnail: '',
      newProfilePicture: false,
      age: '',
      gender: '',
      title: '',
      telephone: [''],
      address: '',
      emp_type: '',
      emp_designation: '',
      emp_institute: '',
      emp_department: '',
      // emp_telephone: [''],
      emp_address: '',
      // emp_regno: '',
      // emp_region_name:'',
      // emp_region_code:'',
      // emp_region_wards:[''],
      // emp_region_mohs:['']
      // employment: {
      //   institute: '',
      //   department: '',
      //   telephone: '',
      //   address: '',
      //   designation: '',
      //   regno: '',
      //   region:{
      //     name:'',
      //     code:'',
      //     wards:'',
      //     mohs:''
      //   }
      // }
      country: '',
      legal_entity: '',
      geo_area: '',
      no_emp: '',
      drm_act: '',
      ins_city: '',
    },
    validationSchema: isSignup ? validationSchemaSignUp : validationSchemaSignin,
    onSubmit: (values, onSubmitProps) => {

      if (isSignup) {
        values.password = CryptoPassword(values.password);
        // values.profilePicture = profilePicture;
        dispatch(signup(values, history));
      } else {
        values.password = CryptoPassword(values.password);
        dispatch(signin(values, history));
      }
      // console.log(values);
      onSubmitProps.setSubmitting(false);
      setContactList([{ index: 0, contactno: "" }])
      onSubmitProps.resetForm();
    },
    onReset: () => {
      setContactList([{ index: 0, contactno: "" }])
    },
  });

  return (
    <>
      <div className="main-content" style={{backgroundColor:'#fdba2b'}}>
        {/* ToDo: explore */}
        {/* <AuthNavbar /> */}
        <div className="header py-6 py-lg-6"
          style={{
            backgroundImage: "url(" + require("../../../assets/img/theme/img_bg.jpg").default + ")",
            backgroundSize: "cover",
            backgroundPosition: "center top",
          }}
          
        >
          {/* Mask */}
          {/*bg-gradient-yellow*/}
          <span className="mask opacity-2" />
          <Container>
            <div className="header-body text-center mb-5">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100" style={{ marginBottom: '-2px', }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center" >



            <Col lg='5' md='6'>
              <Card className="bg-secondary shadow border-1">
                <CardBody className="px-lg-4 py-lg-4">

                  <div className="heading-title text-center mb-4" style={MbppStyles.Topic}>
                    <span>User Login</span>
                  </div>
                  <br />

                  <Form role="form" onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                    <FormGroup className="mb-3 ">

                      <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                        <Grid item xs={1} sm={1}>
                          <i className="ni ni-email-83" />
                        </Grid>
                        <Grid item xs={11} sm={11}>
                          <TextField
                            fullWidth
                            id="email"
                            name="email"
                            label="Email address*"
                            variant="outlined"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            className={"form-control-alternative form-control-edit"}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                        <Grid item xs={1} sm={1}>
                          <i className="ni ni-lock-circle-open" />
                        </Grid>
                        <Grid item xs={11} sm={11}>
                          <TextField
                            fullWidth
                            id="password"
                            name="password"
                            label="Password*"
                            variant="outlined"
                            type={showPassword ? 'text' : 'password'}
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            className={"form-control-alternative form-control-edit"}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                            InputProps={showPassword !== null ? {
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={handleShowPassword}>
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            } : null}
                          />
                        </Grid>
                      </Grid>

                    </FormGroup>

                    <div className="text-center">
                      <Button variant="contained" color="primary" type="submit">
                        LOGIN
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <br/>
              <br/>
              <br/>
              <br/>
              {/* <Row className="mt-3">
                <Col xs="6">
                  {!isSignup && <a
                    className="text-light"
                    href="#chs"
                    onClick={(e) => e.preventDefault()}
                  >
                    <small>Forgot password?</small>
                  </a>
                  }
                </Col>
                <Col className="text-right" xs="6">
                  <a
                    className="text-light"
                    href="register"
                  // onClick={switchMode}
                  >
                    <small>{isSignup ? "Already have an account? Login" : "Create new account"}</small>
                  </a>
                </Col>
              </Row> */}
            </Col>



          </Row>
        </Container>
      </div>

    </>
  );
};

export default Login;
