import React from "react";


const PageHeader = () => {
  return (
    <>
      <div className="header pb-9 pt-6 pt-md-9"
        style={{
          backgroundImage:
            "url(" +
            require("../../assets/img/theme/img_bg.jpg").default +
            ")",
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}> {/*bg-gradient-info  */}
        {/* Mask */}
        <span className="mask bg-gradient-default opacity-5" />
      </div>
    </>
  );
};

export default PageHeader;
