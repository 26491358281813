import Lodash from "lodash";
import { convertToObject } from "typescript";
import * as api from '../api/index.js';
import {
  AUTH,
  USER_FETCH_ALL,
  USER_FETCH_ONE,
  USER_FETCH_ONE_ORG,
  USER_UPDATE,
  ORG_UPDATE,
  USER_FETCH_BY_ADMIN,
  USER_FETCH_PRE_REGISTER_ALL,
  USER_CREATE_PRE_REGISTER,
  SHOW_LOADING
} from '../constants/actionTypes';
import { confirmAlert } from 'react-confirm-alert';
import '../assets/css/react-confirm-alert-custom.css'; //'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export const fetchProfile = () => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchProfile();
    console.log('profile data: ', data);
    if (data?.status === 'success') {
      dispatch({ type: USER_FETCH_ONE, payload: data?.data });
    }
    if (data?.status === 'error') {
      console.log(data);
      confirmAlert({ message: data?.message, buttons: [{ label: 'Ok', onClick: () => { } }] });
    }
  } catch (error) {
    console.log("catch error: ", error);
    confirmAlert({ message: String(error), buttons: [{ label: 'Ok', onClick: () => { } }] });
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};


export const updateProfile = (formData, alert, byUser, router) => async (dispatch) => {
  console.log('Updating the profile with: ', formData);

  // Check whether the profile picture is new.
  // If yes, upload it first. Otherwise update the profile Details. 
  try {
    dispatch({ type: SHOW_LOADING, payload: true });

    if (formData.newProfilePicture) {
      let updatePicResult = await uploadProfileImage(formData, alert, router);
      // Profile picture update failed, stop the process;
      if (!updatePicResult) {
        // alert.show("Profile picture upload failed. Please try again later.", { title: "Error" });
        confirmAlert({ title: "Error", message: "Profile picture upload failed. Please try again later.", buttons: [{ label: 'Ok', onClick: () => { } }] });
        return;
      }
    }
    else {
      console.log('no new profile picture, continue with existing');
    }

    const { data } = await api.updateProfile(formData);
    console.log('update data: ', data)

    if (data?.status === 'success') {
      console.log('update data1: ', data)

      // Admin update is just value change in the database. Otherwise local cash need to be updated too
      if (byUser) {
        // dispatch({ type: USER_UPDATE, payload: data?.data });

        // Update local storage data
        let currentUser = localStorage.getItem('userDetails');
        let jUser = JSON.parse(currentUser);
        let newUserUpdate = {
          token: jUser.token,
          user: {
            id: formData.userId,
            firstname: formData.firstname,
            lastname: formData.lastname,
            email: formData.email,
            urole: jUser.user.urole,
            profilepic: formData.profilepic
          }
        }
        localStorage.setItem('userDetails', JSON.stringify(newUserUpdate));
        dispatch({ type: AUTH, payload: newUserUpdate });
        dispatch(fetchProfile());
      }

      confirmAlert({ message: "Success", buttons: [{ label: 'Ok', onClick: () => { } }] });
      // formData.redirect && router.push(formData.redirect);
    }
    else if (data?.status === 'error') {
      confirmAlert({ message: data?.message, buttons: [{ label: 'Ok', onClick: () => { } }] });
    }
    else {
      confirmAlert({ message: "Oops! Something went wrong.", buttons: [{ label: 'Ok', onClick: () => { } }] });
    }
  } catch (error) {
    console.log("catch error: ", error);
    confirmAlert({ message: String(error), buttons: [{ label: 'Ok', onClick: () => { } }] });
    // confirmAlert({ message: error?.response?.data?.message, buttons: [{ label: 'Ok', onClick: () => { } }] });
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};


const uploadProfileImage = async (values, alert, router) => {

  try {
    if (values.profilepic) {
      console.log('picture data: ', values.profilepic);
      // continue
    }
    else {
      console.log('empty picture');
      // dispatch(updateProfile(values, alert, router));
      return false;
    }

    console.log('upload picture data');
    const params = new FormData();
    params.append('photo[]', values.profilepic);
    params.append('photo[]', values.profilethumbnail);

    let res = await api.uploadProfileImage(params);

    if (res && res.data && !Lodash.isEmpty(res.data.data) && res.data.data.length >= 2) {
      console.log('server returned new file names: ', res.data.data);
      values.profilepic = res.data.data[0].name;
      values.profilethumbnail = res.data.data[1].name;
    }

    return true;

    // console.log('updated values: ', values);
    // dispatch(updateProfile(values, alert, router));

  } catch (error) {
    console.log("catch error: ", error)
    confirmAlert({ message: error?.response?.data?.message, buttons: [{ label: 'Ok', onClick: () => { } }] });
    values.profilepic = null;
    values.profilethumbnail = null;
    return false;
  }

};


export const changePassword = (formData, alert, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    // console.log('calling update password')
    let res = await api.changePassword(formData);
    // console.log('response: ', res);

    if (res && res.status === 200) {
      confirmAlert({ title: "Success", message: "Password updated.", buttons: [{ label: 'Ok', onClick: () => { } }] });
    }

  } catch (error) {
    if (error.response) {
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);

      if (error.response.status === 400) {
        confirmAlert({ title: "Error", message: "Current password is incorrect.", buttons: [{ label: 'Ok', onClick: () => { } }] });
      }
    }
    console.log(error)
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};


const uploadImage = (values, alert, router) => async (dispatch) => {

  try {
    dispatch({ type: SHOW_LOADING, payload: true });

    if (values.profilepic) {
      console.log('picture data: ', values.profilepic);
      // continue
    }
    else {
      console.log('empty picture');
      dispatch(updateProfile(values, alert, false, router));
      return;
    }

    const params = new FormData();
    // formik.values.profilepic, formik.values.profilethumbnail
    params.append('photo[]', values.profilepic);
    params.append('photo[]', values.profilethumbnail);

    let res = await api.uploadProfileImage(params);
    // console.log('############# 1 > ', res);
    // console.log('############# 2 > ', res.data);
    // console.log('############# 3 > ', res.status);
    // console.log('############# 4 > ', res.headers);

    if (res && res.data && !Lodash.isEmpty(res.data.data) && res.data.data.length >= 2) {
      console.log('server returned new file names: ', res.data.data);
      values.profilepic = res.data.data[0].name;
      values.profilethumbnail = res.data.data[1].name;
    }

    console.log('updated values: ', values);
    dispatch(updateProfile(values, alert, false, router));



  } catch (error) {
    console.log("catch error: ", error)
    confirmAlert({ message: error?.response?.data?.message, buttons: [{ label: 'Ok', onClick: () => { } }] });
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }

};


export const fetchAllProfiles = () => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchAllProfiles();
    if (data?.status === 'success') {
      dispatch({ type: USER_FETCH_ALL, payload: data?.data });
    }
    if (data?.status === 'error') {
      console.log(data);
      confirmAlert({ message: data?.message, buttons: [{ label: 'Ok', onClick: () => { } }] });
    }
  } catch (error) {
    console.log(error);
    confirmAlert({ message: String(error), buttons: [{ label: 'Ok', onClick: () => { } }] });
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};


export const fetchPreRegisterAll = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchPreRegisterAll(formData);
    if (data?.status === 'success') {
      dispatch({ type: USER_FETCH_PRE_REGISTER_ALL, payload: data?.data });
    }
    else if (data?.status === 'error') {
      console.log(data);
      confirmAlert({ message: data?.message, buttons: [{ label: 'Ok', onClick: () => { } }] });
    }
  } catch (error) {
    console.log("catch error: ", error);
    confirmAlert({ message: String(error), buttons: [{ label: 'Ok', onClick: () => { } }] });
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};


export const createPreRegister = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.createPreRegister(formData);
    if (data?.status === 'success') {
      confirmAlert({ message: data?.message, buttons: [{ label: 'Ok', onClick: () => { } }] });
      // dispatch({ type: USER_CREATE_PRE_REGISTER, payload: data?.data });
      dispatch(fetchPreRegisterAll());
    }
    else if (data?.status === 'error') {
      console.log(data);
      confirmAlert({ message: data?.message, buttons: [{ label: 'Ok', onClick: () => { } }] });
    }
  } catch (error) {
    console.log("catch error: ", error);
    confirmAlert({ message: String(error), buttons: [{ label: 'Ok', onClick: () => { } }] });
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const updatePreRegister = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.updatePreRegister(formData);
    if (data?.status === 'success') {
      confirmAlert({ message: data?.message, buttons: [{ label: 'Ok', onClick: () => { } }] });
      dispatch(fetchPreRegisterAll());
    }
    else if (data?.status === 'error') {
      console.log(data);
      confirmAlert({ message: data?.message, buttons: [{ label: 'Ok', onClick: () => { } }] });
    }
  } catch (error) {
    console.log("catch error: ", error);
    confirmAlert({ message: String(error), buttons: [{ label: 'Ok', onClick: () => { } }] });
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const deletePreRegister = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.deletePreRegister(formData);
    if (data?.status === 'success') {
      confirmAlert({ message: data?.message, buttons: [{ label: 'Ok', onClick: () => { } }] });
      dispatch(fetchPreRegisterAll());
    }
    else if (data?.status === 'error') {
      console.log(data);
      confirmAlert({ message: data?.message, buttons: [{ label: 'Ok', onClick: () => { } }] });
    }
  } catch (error) {
    console.log("catch error: ", error);
    confirmAlert({ message: "Oops! Something went wrong.", buttons: [{ label: 'Ok', onClick: () => { } }] });
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};


////////////////////////////////////////////////////////////////////////////////////////////


export const signin = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const response = await api.signIn(formData);
    console.log("sign in response", response);
    const { data } = response;

    if (data?.status === 'success') {
      if (data?.data?.user?.urole === "ADMIN") {
        dispatch({ type: AUTH, payload: data?.data });
        localStorage.setItem('userDetails', JSON.stringify({ ...data?.data }));
        router.push('/registered/index');
      }
      else {
        confirmAlert({ title: "Authorization Error", message: "You are not authorized to use the web.", buttons: [{ label: 'Ok', onClick: () => { } }] });
      }
    }
    else if (data?.status === 'error') {
      console.log("error: ", data);
      confirmAlert({ message: data?.message, buttons: [{ label: 'Ok', onClick: () => { } }] });
      router.push('/auth/signin');
    }
  }
  catch (error) {
    console.log("catch error", error.response);
    // console.log("status", error.response.data.status);
    // console.log("msg", error.response.data.message);

    // process the error to show proper error message in popup
    let errorTitle = error?.response?.data?.data ? error?.response?.data?.message : null;
    let errorMessge = error?.response?.data?.data ? error?.response?.data?.data : error?.response?.data?.message;
    errorMessge = Lodash.isEmpty(errorMessge) ? "Oops, something went wrong" : errorMessge;
    confirmAlert({ title: errorTitle, message: errorMessge, buttons: [{ label: 'Ok', onClick: () => { } }] });
    router.push('/auth/signin');
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};


// sign up with new account and new organization + new city
export const signup = (formData, router) => async (dispatch) => {

  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    // console.log("sign up form data", formData);
    const { data } = await api.signUp(formData);

    // console.log("sign up response data", data);
    if (data?.status === 'success') {
      console.log("req is success");
      confirmAlert({ title: "Success", message: "User successfully created.", buttons: [{ label: 'Ok', onClick: () => { } }] });
      router.push('/auth/signin');
    }
    else if (data?.status === 'error') {
      console.log("error: ", data);
      confirmAlert({ message: data?.message, buttons: [{ label: 'Ok', onClick: () => { } }] });
      router.push('/auth/signin');
    }
  }
  catch (error) {
    console.log("catch error", error.response);
    // console.log("status", error.response.data.status);
    // console.log("msg", error.response.data.message);

    // if (error.response && error.response.status && error.response.status === 409 && error.response.data && error.response.data.code && error.response.data.code === "409") {
    // process the error to show proper error message in popup
    let errorTitle = error?.response?.data?.data ? error?.response?.data?.message : null;
    let errorMessge = error?.response?.data?.data ? error?.response?.data?.data : error?.response?.data?.message;
    errorMessge = Lodash.isEmpty(errorMessge) ? "Oops, something went wrong" : errorMessge;
    confirmAlert({ title: errorTitle, message: errorMessge, buttons: [{ label: 'Ok', onClick: () => { } }] });
    router.push('/auth/signin');
    // }
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};


// sign up with new account and organization + new city
export const signupalreadyregister = (formData, router) => async (dispatch) => {

  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    // console.log("sign up form data", formData);
    const { data } = await api.signupalreadyregister(formData);

    // console.log("sign up response data", data);
    if (data?.status === 'success') {
      console.log("req is success");
      confirmAlert({ title: "Success", message: "User successfully created.", buttons: [{ label: 'Ok', onClick: () => { } }] });
      router.push('/auth/signin');
    }
    else if (data?.status === 'error') {
      console.log("error: ", data);
      confirmAlert({ message: data?.message, buttons: [{ label: 'Ok', onClick: () => { } }] });
      router.push('/auth/signin');
    }
  } catch (error) {
    console.log("catch error", error.response);
    // console.log("status", error.response.data.status);
    // console.log("msg", error.response.data.message);

    // if (error.response && error.response.status && error.response.status === 409 && error.response.data && error.response.data.code && error.response.data.code === "409") {
    // process the error to show proper error message in popup
    let errorTitle = error?.response?.data?.data ? error?.response?.data?.message : null;
    let errorMessge = error?.response?.data?.data ? error?.response?.data?.data : error?.response?.data?.message;
    errorMessge = Lodash.isEmpty(errorMessge) ? "Oops, something went wrong" : errorMessge;
    confirmAlert({ title: errorTitle, message: errorMessge, buttons: [{ label: 'Ok', onClick: () => { } }] });
    router.push('/auth/signin');
    // }
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};


// sign up an admin by another admin 
export const signupadmin = (formData, router) => async (dispatch) => {

  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    // console.log("sign up form data", formData);
    const { data } = await api.signupadmin(formData);

    // console.log("sign up response data", data);
    if (data?.status === 'success') {
      confirmAlert({ title: "Success", message: "User successfully created.", buttons: [{ label: 'Ok', onClick: () => { } }] });
    }
    else if (data?.status === 'error') {
      console.log("error: ", data);
      confirmAlert({ message: data?.message, buttons: [{ label: 'Ok', onClick: () => { } }] });
    }
  } catch (error) {
    console.log("catch error", error.response);
    // console.log("status", error.response.data.status);
    // console.log("msg", error.response.data.message);

    // if (error.response && error.response.status && error.response.status === 409 && error.response.data && error.response.data.code && error.response.data.code === "409") {
    // process the error to show proper error message in popup
    let errorTitle = error?.response?.data?.data ? error?.response?.data?.message : null;
    let errorMessge = error?.response?.data?.data ? error?.response?.data?.data : error?.response?.data?.message;
    errorMessge = Lodash.isEmpty(errorMessge) ? "Oops, something went wrong" : errorMessge;
    confirmAlert({ title: errorTitle, message: errorMessge, buttons: [{ label: 'Ok', onClick: () => { } }] });
    router.push('/registered/user');
    // }
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const fetchProfileOrg = () => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchProfileOrg();
    console.log('profile data: ', data);
    if (data?.status === 'success') {
      dispatch({ type: USER_FETCH_ONE_ORG, payload: data?.data });
    }
    if (data?.status === 'error') {
      console.log(data);
      confirmAlert({ message: data?.message, buttons: [{ label: 'Ok', onClick: () => { } }] });
    }
  } catch (error) {
    console.log(error);
    confirmAlert({ message: String(error)?.response?.data?.message, buttons: [{ label: 'Ok', onClick: () => { } }] });
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};


export const updateUser = (formData, needRefresh, history) => async (dispatch) => {
  console.log('Updating the profile with: ', formData);

  // Check whether the profile picture is new.
  // If yes, upload it first. Otherwise update the profile Details. 
  try {
    dispatch({ type: SHOW_LOADING, payload: true });

    if (formData.newProfilePicture) {
      let updatePicResult = await uploadProfileImage(formData, history);

      // Profile picture update failed, stop the process;
      if (!updatePicResult) {
        confirmAlert({ message: "Profile picture upload failed. Please try again later.", buttons: [{ label: 'Ok', onClick: () => { } }] });
        return;
      }
    }
    else {
      console.log('no new profile picture, continue with existing');
    }

    console.log('Updating the profile + picture with: ', formData);

    const { data } = await api.updateUser(formData);
    console.log('update data: ', data)

    if (data?.status === 'success') {
      // Admin update is just value change in the database

      dispatch({ type: USER_UPDATE, payload: data });

      // Update local storage data
      let currentUser = localStorage.getItem('userDetails');
      console.log("currentUser", currentUser);

      let jUser = JSON.parse(currentUser);
      let newUserUpdate = {
        token: jUser.token,
        user: {
          id: formData.userId,
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          urole: jUser.user.urole,
          profilePicture: formData.profilePicture
        }
      }

      let result = localStorage.setItem('userDetails', JSON.stringify(newUserUpdate));
      console.log("newUserUpdate", newUserUpdate);

      confirmAlert({ title: "Success", buttons: [{ label: 'Ok', onClick: () => { } }] });
      if (needRefresh) history.go(0)

      // formData.redirect && router.push(formData.redirect);
    }
    else if (data?.status === 'error') {
      console.log(data);
      confirmAlert({ message: data?.message, buttons: [{ label: 'Ok', onClick: () => { } }] });
    }
  } catch (error) {
    console.log("catch error in profile update", error)
    let errorMessge = error?.response?.data?.message;
    confirmAlert({ message: errorMessge, buttons: [{ label: 'Ok', onClick: () => { } }] });
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};


export const fetchProfileOrgAsAdmin = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const response = await api.fetchProfileOrgAsAdmin(formData);
    const { data } = response;
    console.log('profile data by admin: ', data);
    if (data?.status === 'success') {
      let dispatchData = data?.data;
      dispatchData["user"] = { user: formData };
      console.log('profile data by admin all: ', dispatchData);
      dispatch({ type: USER_FETCH_BY_ADMIN, payload: dispatchData });
    }
    if (data?.status === 'error') {
      console.log(data);
      confirmAlert({ message: data?.message, buttons: [{ label: 'Ok', onClick: () => { } }] });
    }
  } catch (error) {
    console.log(error);
    confirmAlert({ message: String(error)?.response?.data?.message, buttons: [{ label: 'Ok', onClick: () => { } }] });
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};



/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




