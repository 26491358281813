// import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Tooltip } from "@material-ui/core";
import { fetchResourceAll, updateResource, deleteResource } from "actions/resource";
// core components
import PageHeader from "components/Headers/PageHeader.js";
import Config from "config/config.json";
import { GlobalStylesColor } from "constants/globalStyles";
import Lodash from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
// reactstrap components
import { Badge, Card, CardHeader, Container, Row, Table } from "reactstrap";
import BorderColorOutlinedIcon from "@material-ui/icons/BorderColorOutlined";
import TranslateOutlinedIcon from "@material-ui/icons/TranslateOutlined";
import PublicOutlinedIcon from "@material-ui/icons/PublicOutlined";
import VpnLockOutlinedIcon from "@material-ui/icons/VpnLockOutlined";

const ResourceRow = ({ resource, editClick, statusClick, viewClick, createClick, deleteClick }) => (
  // CATEGORY |	TITLE |	DESCRIPTION |	FILE |	PUBLISHED |	ACTIONS
  <tr>
    <td style={{ whiteSpace: "break-spaces" }}>{resource.category}</td>
    <td style={{ whiteSpace: "break-spaces", color: GlobalStylesColor.mbppThemePrimary }}>{resource.title}</td>
    <td style={{ whiteSpace: "break-spaces" }}>{resource.description}</td>
    <td style={{ whiteSpace: "break-spaces" }}>{resource.file}</td>
    {/* <td>
      {resource.status ? (
        <Badge color="" className="badge-dot">
          <i className="bg-success" />
          YES
        </Badge>
      ) : (
        <Badge color="" className="badge-dot mr-4">
          <i className="bg-warning" />
          NO
        </Badge>
      )}
    </td> */}
    <td className="text-right">
      <div className="row">
        <Tooltip title="Edit" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              editClick(e, resource);
            }}
          >
            {/* <BorderColorOutlinedIcon style={{ opacity: resource.published ? 0.3 : 1 }} /> */}
            <i className="far fa-edit" />
          </div>
        </Tooltip>
        {/* <Tooltip title="Change publish status" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              statusClick(e, resource);
            }}
          >
            {resource.status ? <PublicOutlinedIcon /> : <VpnLockOutlinedIcon />}
          </div>
        </Tooltip> */}
        <Tooltip title="View resource" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              viewClick(e, resource);
            }}
          >
            {/* <i className="far fa-arrow-alt-circle-down" /> */}
            <i className="far fa-eye" />
          </div>
        </Tooltip>
        <Tooltip title="Delete resource" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              deleteClick(e, resource);
            }}
          >
            <i className="far fa-times-circle" />
          </div>
        </Tooltip>
      </div>
    </td>
  </tr>
);

// export function
const Recommendation = () => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // get forum topics
  const resourceAll = useSelector((state) => state.resource.resourceAll);

  useEffect(() => {
    dispatch(fetchResourceAll());
  }, []);

  const handleStatusClick = (e, resource) => {
    e.preventDefault();
    console.log("resource", resource);
    if (resource && !Lodash.isEmpty(resource) && resource.status === true) {
      let values = {
        resourceId: resource._id,
        status: false
      };
      dispatch(updateResource(values));
    } else {
      let values = {
        resourceId: resource._id,
        status: true
      };
      dispatch(updateResource(values));
    }
  };

  const handleViewClick = (e, resource) => {
    e.preventDefault();
    let pathToResource = Config.SERVER_API_URL + "uploads/resource/" + resource.file;
    console.log("pathToResource: ", pathToResource);
    window.open(pathToResource, "_blank");
  };

  const handleEditClick = (e, resource, type) => {
    e.preventDefault();
    history.push({ pathname: "/registered/resource_details", state: { resource: resource } });
  };

  const handleCreateClick = (e, resource, type) => {
    e.preventDefault();
  };

  const handleCreateNewClick = (e, resource, type) => {
    e.preventDefault();
    history.push({ pathname: "/registered/resource_details" });
  };

  const handleDeleteClick = (e, resource) => {
    console.log("resource", resource);
    e.preventDefault();
    let values = {
      resourceId: resource._id,
    };
    dispatch(deleteResource(values));
  };

  return (
    <>
      <PageHeader />

      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <div className="mb-xl-0 col-11">
                    <h3 className="mb-0">APP Resources</h3>
                    <h6 className="mb-0">Resources for tools and more</h6>
                  </div>
                  <div className="mb-xl-0">
                    <Tooltip title="Add new resource" arrow>
                      <div
                        className="navbar-toggler"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          handleCreateNewClick(e);
                        }}
                      >
                        <i className="fas fa-plus-circle" style={{ fontSize: 30, color: GlobalStylesColor.mbppThemePrimary }} />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Category</th>
                    <th scope="col">Title</th>
                    <th scope="col">Description</th>
                    <th scope="col">File Name</th>
                    {/* <th scope="col">Published</th> */}
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr></tr>
                  {resourceAll.map((resource, i) => {
                    {
                      /* console.log(i, resource); */
                    }
                    return <ResourceRow
                      key={i}
                      resource={resource}
                      editClick={handleEditClick}
                      statusClick={handleStatusClick}
                      viewClick={handleViewClick}
                      createClick={handleCreateClick}
                      deleteClick={handleDeleteClick}
                    />;
                  })}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Recommendation;
