// import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Tooltip } from "@material-ui/core";
import { fetchNotificationPublic } from "actions/notification";
// core components
import PageHeader from "components/Headers/PageHeader.js";
import Config from "config/config.json";
import { GlobalStylesColor } from "constants/globalStyles";
import Lodash from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
// reactstrap components
import { Badge, Card, CardHeader, Container, Row, Table, CardFooter, Col, Button } from "reactstrap";
import Moment from "moment";
import Pagination from 'react-responsive-pagination';
import { MbppStyles } from 'constants/globalStyles';

const ResourceRow = ({ notification, editClick, statusClick, viewClick, createClick, deleteClick }) => (
  // CATEGORY |	TITLE |	DESCRIPTION |	FILE |	PUBLISHED |	ACTIONS

  <tr>
    <td style={{ whiteSpace: "break-spaces" }}>{Moment(notification.date).format("YYYY-MM-DD hh:mm A")}</td>
    <td style={{ whiteSpace: "break-spaces" }}>{notification.message.title}</td>
    <td style={{ whiteSpace: "break-spaces" }}>{notification.message.body}</td>
    <td style={{ whiteSpace: "break-spaces" }}>{(notification.roles).toString()}</td>
    <td style={{ whiteSpace: "break-spaces" }}>{notification.tokens.length}</td>

    {console.log("notification: ", notification)}

    {/* <td style={{ whiteSpace: "break-spaces" }}>{(Lodash.filter(notification.tokens, { delivery: true })).length + " / " + notification.tokens.length}</td> */}

    {/* <td className="text-right">
      <Row>

        <Tooltip title="View notification" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              viewClick(e, notification);
            }}
          >
            <i className="far fa-eye" />
          </div>
        </Tooltip>
      </Row>
    </td> */}
  </tr>
);

// export function
const Recommendation = () => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();


  // pagination 
  const itemPageSize = Config.ITEM_PAGE_SIZE;
  const [itemCurrent, setItemCurrent] = useState(1);
  const [itemPageContent, setItemPageContent] = useState([]);
  const [itemPageCount, setItemPageCount] = useState(1);
  // filter
  const [txtFilter, setTxtFilter] = useState("");
  const [filterdContent, setFilterdContent] = useState([]);

  // get notifications
  const notificationAll = useSelector((state) => state.notification.publicNotification);

  useEffect(() => {
    dispatch(fetchNotificationPublic());
  }, []);


  // process content with filter
  useEffect(() => {
    setFilterdContent(notificationAll.reverse())
  }, [notificationAll, txtFilter]);

  // process the pagination with page content
  useEffect(() => {
    console.log("filterdContent.length: ", filterdContent.length)
    // Set the pagination count
    let currentPageCount = Math.ceil(filterdContent.length / itemPageSize);
    setItemPageCount(currentPageCount);

    // Set the pagination content 
    if (filterdContent && filterdContent.length > 0) {
      // make sure the page doesn't exceed the array endings
      let startCount = ((itemCurrent - 1) * itemPageSize) < 0 ? 0 : ((itemCurrent - 1) * itemPageSize);
      let endCount = (itemCurrent * itemPageSize) > filterdContent.length ? filterdContent.length : (itemCurrent * itemPageSize);

      let updatedArray = Lodash.slice(filterdContent, startCount, endCount);
      if (updatedArray && updatedArray.length > 0) {
        setItemPageContent(updatedArray);
      }
      else {
        setItemPageContent([])
      }
    }
    else {
      setItemPageContent([])
    }
  }, [itemCurrent, filterdContent]);

  const handleViewClick = (e, notification) => {
    e.preventDefault();
  };

  const handleDeleteClick = (e, notification) => {
    console.log("notification", notification);
  };

  return (
    <>
      <PageHeader />

      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <div className="mb-xl-0 col-11">
                    <h3 className="mb-0">Notification History</h3>
                    <h6 className="mb-0">Push notification details</h6>
                  </div>
                  <div className="mb-xl-0">
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Date-Time</th>
                    <th scope="col">Title</th>
                    <th scope="col">Description</th>
                    <th scope="col">Roles</th>
                    <th scope="col">Messages Sent</th>
                    {/* <th scope="col">Success / Sent</th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr></tr>
                  {itemPageContent && (itemPageContent.length > 0) && itemPageContent.map((notification, i) => {
                    return <ResourceRow
                      key={i}
                      notification={notification}
                      viewClick={handleViewClick}
                      deleteClick={handleDeleteClick}
                    />;
                  })}
                </tbody>
              </Table>
              {/* pagination */}
              <CardFooter>
                <Row>
                  <Col lg="6" className="text-left">
                    <Button
                      style={MbppStyles.GeneralButton}
                      color="primary"
                      onClick={() => {
                        history.goBack();
                      }}>
                      BACK
                    </Button>
                  </Col>
                  <Col lg="6" className="text-right">
                    <Pagination
                      current={itemCurrent}
                      total={itemPageCount}
                      onPageChange={(current) => { setItemCurrent(current); }}
                    />
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Recommendation;
