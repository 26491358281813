import { Grid, TextField, Tooltip } from '@material-ui/core';
import { fetchMapPatients } from 'actions/map';
// core components
import PageHeader from "components/Headers/PageHeader.js";
import { MbppStyles, GlobalStylesColor } from 'constants/globalStyles';
import { useFormik } from 'formik';
import { basicInformation } from "globalGeoInformation";
import { AnalyticStatus, isValidString } from "helper";
import Lodash from "lodash";
import Moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// reactstrap components
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Form, FormGroup, Row
} from "reactstrap";
import * as yup from 'yup';

const validationSchemaActivity = yup.object({
  startDay: yup
    .date('Enter start date')
    .required('Activity start date is required'),
  endDay: yup
    .date('Enter end date')
    .required('Activity end date is required'),
  status: yup
    .string('Select status')
    .required('Status is required'),
});

const MapPatients = () => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();

  const [searchParams, setSearchParams] = useState({});
  const [mapLocations, setMapLocations] = useState([]);

  const patientsAll = useSelector((state) => state.map.patients);

  useEffect(() => {
    if (!Lodash.isEmpty(searchParams) && !Lodash.isEmpty(patientsAll)) {

      let patientLocation = [];

      patientsAll.forEach((item) => {

        if (Lodash.has(item, 'addresses')) { // Addresses = locations

          //Extract the jsons which include gps coordinates
          item.addresses.forEach((detItem) => {
            if (Lodash.hasIn(detItem, 'gps') && isValidString(detItem.gps.lon) && isValidString(detItem.gps.lat)) {

              let pDisease = ''
              if (item.records && item.records.diseaseConfirmed && item.records.diseaseConfirmed.length > 0) { //411A details available
                pDisease = item.records.diseaseConfirmed[1]
              }
              else if (item.observations && item.observations.diseaseConfirmed && item.observations.diseaseConfirmed.length > 0) { //411 details available
                pDisease = item.observations.diseaseConfirmed[1]
              }
              else {
                pDisease = item.diseaseInfo.disease[1]
              }

              if (pDisease === "DEN") {
                patientLocation = [...patientLocation, {
                  workflowid: item.workflowid,
                  coordinate: {
                    longitude: detItem.gps.lon,
                    latitude: detItem.gps.lat,
                  }
                }]
              }
            }
            else {
              //Nothing to do
              // console.log("..... address with-out gps: " + JSON.stringify(item))
            }
          })

        }
      })

      console.log('patientLocation: ', patientLocation);

      // let mapBounds = patientLocation.map((item) => { if (item.coordinate.latitude > 0 && item.coordinate.longitude > 0) return item.coordinate });
      // console.log('map bounds: ', mapBounds); 

      setMapLocations(patientLocation);
      if (patientLocation.length > 0 && !Lodash.isEmpty(searchParams)) {
        history.push({ pathname: "/registered/map_location_details", state: { mapLocations: patientLocation, title: "Patient or Case Locations ("+patientLocation.length+")"} });
      }

    }
  }, [patientsAll])


  const formik = useFormik({
    initialValues: {
      startDay: '',
      endDay: '',
      moh: null,
      status: AnalyticStatus[3],
      deleted: false,
    },
    validationSchema: validationSchemaActivity,
    onSubmit: (values, onSubmitProps) => {
      console.log("formik values: ", values);
      let params = {
        "startday": values.startDay,
        "endday": values.endDay,
        "status": values.status.toLowerCase()
      }
      console.log("params: ", params);
      dispatch(fetchMapPatients(params, history));
      setSearchParams(values);
    },
  });

  return (
    <>
      <PageHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table School */}
        <Row>
          <Col>
            <Card className="shadow">

              <CardHeader className="border-0">
                <h3 className="mb-0">Cases of Dengue Patients</h3>
              </CardHeader>

              <Form role="form" onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <CardBody>
                  <FormGroup className="mb-3">

                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1}></Grid>
                      <Grid item xs={5} sm={5}>
                        <TextField
                          fullWidth
                          type="date"
                          id="startDay"
                          name="startDay"
                          label="Start Date"
                          variant="outlined"
                          format={'DD/MM/YYYY'}
                          value={formik.values.startDay}
                          onChange={formik.handleChange}
                          InputLabelProps={{ shrink: true }}
                          error={formik.touched.startDay && Boolean(formik.errors.startDay)}
                          helperText={formik.touched.startDay && formik.errors.startDay}
                        />
                      </Grid>
                      <Grid item xs={5} sm={5}>
                        <TextField
                          fullWidth
                          type="date"
                          id="endDay"
                          name="endDay"
                          label="End Date"
                          variant="outlined"
                          format={'DD/MM/YYYY'}
                          value={formik.values.endDay}
                          onChange={formik.handleChange}
                          InputLabelProps={{ shrink: true }}
                          error={formik.touched.endDay && Boolean(formik.errors.endDay)}
                          helperText={formik.touched.endDay && formik.errors.endDay}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1}></Grid>
                      {/* <Grid item xs={5} sm={5}>
                        <TextField
                          select
                          fullWidth
                          id="moh"
                          name="moh"
                          label="MOH"
                          variant="outlined"
                          value={formik.values.moh}
                          onChange={formik.handleChange}
                          SelectProps={{ native: true }}
                          error={formik.touched.moh && Boolean(formik.errors.moh)}
                          helperText={formik.touched.moh && formik.errors.moh}
                        >
                          <option value=''></option>
                          {Object.entries(basicInformation.mohIds).map(([key, value]) => (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          ))}
                        </TextField>
                      </Grid> */}
                      <Grid item xs={5} sm={5}>
                        <TextField
                          select
                          fullWidth
                          id="status"
                          name="status"
                          label="Status"
                          variant="outlined"
                          value={formik.values.status}
                          onChange={formik.handleChange}
                          SelectProps={{ native: true }}
                          error={formik.touched.status && Boolean(formik.errors.status)}
                          helperText={formik.touched.status && formik.errors.status}
                        >
                          {Object.entries(AnalyticStatus).map(([key, value]) => (
                            <option key={value} value={value}>
                              {value}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>

                  </FormGroup>
                </CardBody>

                <CardFooter className="py-4">
                  <Row>
                    <Col lg="6" className="text-left">
                      <div className="mt-3" style={{ color: GlobalStylesColor.mbppLabel, fontSize: 20 }}>
                        {!Lodash.isEmpty(searchParams) && mapLocations && mapLocations.length === 0 ? "No data to show on the map" : ""}
                      </div>
                    </Col>
                    <Col lg="6" className="text-right">
                      <Button className="my-2" variant="contained" color="primary" type="submit">
                        Show on Map
                      </Button>
                    </Col>
                  </Row>
                </CardFooter>
              </Form>

            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MapPatients;
