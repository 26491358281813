import {
  MAP_BUILDINGS,
  MAP_DANGER_ZONES,
  MAP_DANGER_PATIENTS,
} from 'constants/actionTypes';

const initState = {
  buildings: [],
  dangerZones: [],
  patients: [],
}

const mapReducer = (map = initState, action) => {
  switch (action.type) {
    // Get all the languages
    case MAP_BUILDINGS:
      return { ...map, buildings: action.payload };
    case MAP_DANGER_ZONES:
      return { ...map, dangerZones: action.payload };
    case MAP_DANGER_PATIENTS:
      return { ...map, patients: action.payload };

    default:
      return map;
  }
};

export default mapReducer;