import { CONTACT_FETCH_ALL, CONTACT_CREATE, CONTACT_UPDATE, CONTACT_FETCH_ONE } from '../constants/actionTypes';

const initState = {
  contactsDetails: [],
  contactDetails:{},
  contactCreate:[],
  contactUpdate:[]
}

const contactReducer =(contact = initState, action) => {
  switch (action.type) {
    case CONTACT_FETCH_ALL:
      return {...contact, contactsDetails: action.payload};
    case CONTACT_FETCH_ONE:
      return {...contact, contactDetails: action.payload};
    case CONTACT_CREATE:
      return {...contact, contactCreate: action.payload};
    case CONTACT_UPDATE:
      return {...contact, contactUpdate: action.payload};
    default:
      return contact;
  }
};

export default contactReducer;