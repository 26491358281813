import { CONTACT_FETCH_ALL, CONTACT_CREATE, CONTACT_UPDATE, CONTACT_FETCH_ONE, SHOW_LOADING } from '../constants/actionTypes';
import * as api from '../api/index.js';

export const fetchContact = () => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    console.log(await api.fetchContact())
    const { data } = await api.fetchContact();
    console.log(data)
    if (data?.status === 'success') {
      dispatch({ type: CONTACT_FETCH_ALL, payload: data?.data });
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error)
    alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const fetchContactById = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchContactById(formData);
    if (data?.status === 'success') {
      dispatch({ type: CONTACT_FETCH_ONE, payload: data?.data });
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error)
    alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const createContact = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.createContact(formData);
    dispatch({ type: CONTACT_CREATE, payload: data });
    if (data?.status === 'success') {
      alert(data?.message);
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error)
    alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const updateContact = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.updateContact(formData);
    dispatch({ type: CONTACT_UPDATE, payload: data });
    if (data?.status === 'success') {
      alert(data?.message);
      formData.redirect && router.push(formData.redirect);
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error)
    alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};