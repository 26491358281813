import {
  NOTIFICATION_FETCH_PUBLIC,
  SHOW_LOADING
} from 'constants/actionTypes';
import Lodash from "lodash";
import { confirmAlert } from 'react-confirm-alert';
import * as api from '../api/index.js';
// import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


export const sendNotification = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.sendNotification(formData);
    console.log("report data", data);

    if (data?.status === 'success') {
      confirmAlert({ title: "Success", message: "Notification will push to the users after sometime.", buttons: [{ label: 'Ok', onClick: () => { } }] });
    }
    else if (data?.status === 'error') {
      confirmAlert({ message: data?.message, buttons: [{ label: 'Ok', onClick: () => { } }] });
    }
    else {
      confirmAlert({ title: "Ooops!", message: "Notification couldn't send.", buttons: [{ label: 'Ok', onClick: () => { } }] });
    }
  }
  catch (error) {
    console.log("catch error", error.response);
    // console.log("status", error.response.data.status);
    // console.log("msg", error.response.data.message);

    // process the error to show proper error message in popup
    let errorTitle = error?.response?.data?.data ? error?.response?.data?.message : null;
    let errorMessage = error?.response?.data?.data ? error?.response?.data?.data : error?.response?.data?.message;
    errorMessage = Lodash.isEmpty(errorMessage) ? "Oops, something went wrong" : errorMessage;
    confirmAlert({ title: errorTitle, message: errorMessage, buttons: [{ label: 'Ok', onClick: () => { } }] });
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};


export const fetchNotificationPublic = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchNotificationPublic(formData);
    console.log("report data", data);

    if (data?.status === 'success') {
      dispatch({ type: NOTIFICATION_FETCH_PUBLIC, payload: data?.data });
    }
    else {
      dispatch({ type: NOTIFICATION_FETCH_PUBLIC, payload: [] });
    }
  }
  catch (error) {
    console.log("catch error", error.response);
    dispatch({ type: NOTIFICATION_FETCH_PUBLIC, payload: [] });
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};




