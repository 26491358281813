import * as actionType from 'constants/actionTypes';
import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// reactstrap components
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";


const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();


  useEffect(() => {

  }, [])

  return (
    <>
      <div className="header pb-8 pt-5 pt-md-8"

        style={{
          backgroundImage:
            "url(" +
            require("../../assets/img/theme/img_bg.jpg").default +
            ")",
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}> {/*bg-gradient-info  */}
        {/* Mask */}
        <span className="mask bg-gradient-default opacity-5" />
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            {/* <Row>

              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <b>CHSAT</b> Completion
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          20
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fas fa-clipboard-check" style={{ fontSize: 32 }} />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-danger mr-2">
                        5
                      </span>{" "}
                      <span className="text-nowrap">On going</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <b>ICAT</b> Completion
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          18
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="fas fa-university" style={{ fontSize: 32 }} />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-danger mr-2">
                        1
                      </span>{" "}
                      <span className="text-nowrap">On going</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <b>TIE</b> Completion
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          23
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                          <i className="fas fa-hands-helping" style={{ fontSize: 32 }} />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-warning mr-2">
                        {/* <i className="fas fa-arrow-down" />  * /}
                        3
                      </span>{" "}
                      <span className="text-nowrap">On going</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>

            </Row> */}
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
