import { Grid, TextField, Tooltip } from '@material-ui/core';
import { fetchBreedingByOptions } from 'actions/breeding';
// core components
import PageHeader from "components/Headers/PageHeader.js";
import { MbppStyles, GlobalStylesColor } from 'constants/globalStyles';
import { useFormik } from 'formik';
import { basicInformation } from "globalGeoInformation";
import { BreedingComplainStatus } from "helper";
import Lodash from "lodash";
import Moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// reactstrap components
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Form, FormGroup, Row
} from "reactstrap";
import * as yup from 'yup';

const validationSchemaActivity = yup.object({
  startDay: yup
    .date('Enter start date')
    .required('Activity start date is required'),
  endDay: yup
    .date('Enter end date')
    .required('Activity end date is required'),
});

const MapBreedingSite = () => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();

  const [searchParams, setSearchParams] = useState({});
  const [mapLocations, setMapLocations] = useState([]);

  const breedingSiteComplaintsAll = useSelector((state) => state.breedingsite.breedingSiteComplaintsAll);

  useEffect(() => {
    if (!Lodash.isEmpty(searchParams) && !Lodash.isEmpty(breedingSiteComplaintsAll)) {
      let breedingSites = Lodash.filter(breedingSiteComplaintsAll, { deleted: false });

      let markers = [];
      for (const item of breedingSites) {
        if (!Lodash.isEmpty(item.region.coordinates) && item.region.coordinates.latitude >= 0 && item.region.coordinates.longitude >= 0) {
          // {
          //   color: "#FF0000"
          //   coordinate: { longitude: 79.92667771875858, latitude: 7.033965622914534 }
          //   description: ""
          //   key: 0
          //   title: ""
          // }
          markers.push({
            id: item._id,
            key: Moment(item.date).format('YYYY-MMM-DD'),
            coordinate: item.region.coordinates,
            title: item.title,
            description: item.description
          })
        }
      }

      setMapLocations(markers);
      if (markers.length > 0 && !Lodash.isEmpty(searchParams)) {
        history.push({ pathname: "/registered/map_location_details", state: { mapLocations: markers, title: "Breeding Site Locations" } });
      }
    }
  }, [breedingSiteComplaintsAll])


  const formik = useFormik({
    initialValues: {
      startDay: '',
      endDay: '',
      moh: null,
      status: null,
      deleted: false,
    },
    validationSchema: validationSchemaActivity,
    onSubmit: (values, onSubmitProps) => {
      console.log("formik values: ", values);
      dispatch(fetchBreedingByOptions(values, history));
      setSearchParams(values);
    },
  });

  return (
    <>
      <PageHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table School */}
        <Row>
          <Col>
            <Card className="shadow">

              <CardHeader className="border-0">
                <h3 className="mb-0">Breeding Site Reports</h3>
              </CardHeader>

              <Form role="form" onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <CardBody>
                  <FormGroup className="mb-3">

                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1}></Grid>
                      <Grid item xs={5} sm={5}>
                        <TextField
                          fullWidth
                          type="date"
                          id="startDay"
                          name="startDay"
                          label="Start Date"
                          variant="outlined"
                          format={'DD/MM/YYYY'}
                          value={formik.values.startDay}
                          onChange={formik.handleChange}
                          InputLabelProps={{ shrink: true }}
                          error={formik.touched.startDay && Boolean(formik.errors.startDay)}
                          helperText={formik.touched.startDay && formik.errors.startDay}
                        />
                      </Grid>
                      <Grid item xs={5} sm={5}>
                        <TextField
                          fullWidth
                          type="date"
                          id="endDay"
                          name="endDay"
                          label="End Date"
                          variant="outlined"
                          format={'DD/MM/YYYY'}
                          value={formik.values.endDay}
                          onChange={formik.handleChange}
                          InputLabelProps={{ shrink: true }}
                          error={formik.touched.endDay && Boolean(formik.errors.endDay)}
                          helperText={formik.touched.endDay && formik.errors.endDay}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1}></Grid>
                      <Grid item xs={5} sm={5}>
                        <TextField
                          select
                          fullWidth
                          id="moh"
                          name="moh"
                          label="MOH"
                          variant="outlined"
                          value={formik.values.moh}
                          onChange={formik.handleChange}
                          SelectProps={{ native: true }}
                          error={formik.touched.moh && Boolean(formik.errors.moh)}
                          helperText={formik.touched.moh && formik.errors.moh}
                        >
                          <option value=''></option>
                          {Object.entries(basicInformation.mohIds).map(([key, value]) => (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={5} sm={5}>
                        <TextField
                          select
                          fullWidth
                          id="status"
                          name="status"
                          label="Status"
                          variant="outlined"
                          value={formik.values.status}
                          onChange={formik.handleChange}
                          SelectProps={{ native: true }}
                          error={formik.touched.status && Boolean(formik.errors.status)}
                          helperText={formik.touched.status && formik.errors.status}
                        >
                          <option value=''></option>
                          {Object.entries(BreedingComplainStatus).map(([key, value]) => (
                            <option key={value} value={value}>
                              {value}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>

                  </FormGroup>
                </CardBody>

                <CardFooter className="py-4">
                  <Row>
                    <Col lg="6" className="text-left">
                      {/* <Button
                      style={MbppStyles.GeneralButton}
                      color="primary"
                      onClick={() => { history.goBack(); }}>
                      BACK
                    </Button> */}
                      <div className="mt-3" style={{ color: GlobalStylesColor.mbppLabel, fontSize: 20 }}>
                        {!Lodash.isEmpty(searchParams) && mapLocations && mapLocations.length === 0 ? "No data to show on the map" : ""}
                      </div>
                    </Col>
                    <Col lg="6" className="text-right">
                      <Button className="my-2" variant="contained" color="primary" type="submit">
                        Show on Map
                      </Button>
                      {
                        mapLocations && mapLocations.length > 0 ?
                          <Button
                            style={MbppStyles.GeneralButton}
                            color="primary"
                            onClick={() => {
                              history.push({ pathname: "/registered/map_location_details", state: { mapLocations: mapLocations, title: "Breeding Site Locations" } });
                            }}>
                            NEXT
                          </Button>
                          : null
                      }
                    </Col>
                  </Row>
                </CardFooter>
              </Form>

            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MapBreedingSite;
