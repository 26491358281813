import {
  FRM_TOPIC_FETCH_ALL,
  FRM_TOPIC_FETCH_ONE,
  FRM_TOPIC_CREATE,
  FRM_TOPIC_UPDATE,
  FRM_SUB_TOPIC_FETCH_ALL,
  FRM_SUB_TOPIC_FETCH_ONE,
  FRM_SUB_TOPIC_CREATE,
  FRM_SUB_TOPIC_UPDATE,
  FRM_QUS_FETCH_ONE,
  FRM_QUS_FETCH_ALL,
  FRM_QUS_TOPIC_FETCH_ALL,
  FRM_QUS_CREATE,
  FRM_QUS_UPDATE,
  FRM_ANS_FETCH_ALL,
  FRM_ANS_FETCH_ONE,
  FRM_ANS_CREATE,
  FRM_ANS_UPDATE,
  FRM_NEW_QUESTIONS,
  FRM_NEW_QUESTION_ANSWER,
  SHOW_LOADING
} from 'constants/actionTypes';
import * as api from 'api/index';
import Lodash from 'lodash';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

// forum Topics
export const fetchFrmTopics = () => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchFrmTopics();
    if (data?.status === 'success') {
      dispatch({ type: FRM_TOPIC_FETCH_ALL, payload: data?.data });
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    alert(error);
    console.log(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const fetchFrmTopicById = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchFrmTopicById(formData);
    if (data?.status === 'success') {
      dispatch({ type: FRM_TOPIC_FETCH_ONE, payload: data?.data });
      formData.redirect && router.push(formData.redirect);
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error);
    alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const createFrmTopic = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.createFrmTopic(formData);
    dispatch({ type: FRM_TOPIC_CREATE, payload: data });
    if (data?.status === 'success') {
      confirmAlert({ title: "Success", message: "Topic successfully created.", buttons: [{ label: 'Ok', onClick: () => { } }] });
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error)
    alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const updateFrmTopic = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.updateFrmTopic(formData);
    dispatch({ type: FRM_TOPIC_UPDATE, payload: data });
    if (data?.status === 'success') {
      confirmAlert({ title: "Success", message: "Topic successfully updated.", buttons: [{ label: 'Ok', onClick: () => { } }] });
      formData.redirect && router.push(formData.redirect);
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error)
    alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};


export const deleteFrmTopic = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.deleteFrmTopic(formData);
    if (data?.status === 'success') {
      confirmAlert({
        title: "Success", message: "Topic successfully deleted.", buttons: [{
          label: 'Ok', onClick: () => {}
        }]
      });
      dispatch(fetchFrmTopics());
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error)
    alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};


// forum Sub Topics
export const fetchFrmSubTopics = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchFrmSubTopics(formData);
    if (data?.status === 'success') {
      dispatch({ type: FRM_SUB_TOPIC_FETCH_ALL, payload: data?.data });
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error);
    alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const fetchFrmSubTopicById = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchFrmSubTopicById(formData);
    if (data?.status === 'success') {
      dispatch({ type: FRM_SUB_TOPIC_FETCH_ONE, payload: data?.data });
      formData.redirect && router.push(formData.redirect);
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error);
    alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const createFrmSubTopic = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.createFrmSubTopic(formData);
    dispatch({ type: FRM_SUB_TOPIC_CREATE, payload: data });
    if (data?.status === 'success') {
      confirmAlert({ title: "Success", message: "Sub topic successfully created.", buttons: [{ label: 'Ok', onClick: () => { } }] });
      router.push('/registered/faq_management')
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error)
    alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const updateFrmSubTopic = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.updateFrmSubTopic(formData);
    dispatch({ type: FRM_SUB_TOPIC_UPDATE, payload: data });
    if (data?.status === 'success') {
      confirmAlert({ title: "Success", message: "Sub topic successfully updated.", buttons: [{ label: 'Ok', onClick: () => { } }] });
      router.push('/registered/faq_management')
      // formData.redirect && router.push(formData.redirect);
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error)
    alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const deleteFrmSubTopic = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.deleteFrmSubTopic(formData);
    dispatch({ type: FRM_SUB_TOPIC_UPDATE, payload: data });
    if (data?.status === 'success') {
      confirmAlert({ title: "Success", message: "Sub topic successfully deleted.", buttons: [{ label: 'Ok', onClick: () => { } }] });
      router.push('/registered/faq_management')
      // formData.redirect && router.push(formData.redirect);
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error)
    alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// forum Questions
export const fetchFrmQus = () => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchFrmQus();
    if (data?.status === 'success') {
      dispatch({ type: FRM_QUS_FETCH_ALL, payload: data?.data });
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error);
    alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const fetchFrmQusbyTopics = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchFrmQusbyTopics(formData);
    if (data?.status === 'success') {
      dispatch({ type: FRM_QUS_TOPIC_FETCH_ALL, payload: data?.data });
      router.push('/registered/faq_subtopic');
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error);
    alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const fetchFrmQusbyId = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchFrmQusbyId(formData);
    if (data?.status === 'success') {
      dispatch({ type: FRM_QUS_FETCH_ONE, payload: data?.data });
      formData.redirect && router.push(formData.redirect);
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error);
    alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const createFrmQus = (formData, history, pathParam) => async (dispatch) => {
  console.log(formData);
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.createFrmQus(formData);
    dispatch({ type: FRM_QUS_CREATE, payload: data });
    if (data?.status === 'success') {
      confirmAlert({ title: "Success", message: "Question successfully created.", buttons: [{ label: 'Ok', onClick: () => { } }] });
      if (pathParam && !Lodash.isEmpty(pathParam?.path)) {
        history.push({ pathname: pathParam.path, state: pathParam.state });
      }
      else if (pathParam && Lodash.isBoolean(pathParam?.reload)) {
        history.go(0);
      }

    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error)
    alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const updateFrmQus = (formData, history, pathParam) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.updateFrmQus(formData);
    dispatch({ type: FRM_QUS_UPDATE, payload: data });
    if (data?.status === 'success') {
      confirmAlert({ title: "Success", message: "Question successfully updated.", buttons: [{ label: 'Ok', onClick: () => { } }] });
      if (pathParam && !Lodash.isEmpty(pathParam?.path)) {
        history.push({ pathname: pathParam.path, state: pathParam.state });
      }
      else if (pathParam && Lodash.isBoolean(pathParam?.reload)) {
        history.go(0);
      }

    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error)
    alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// forum Answers
export const fetchFrmAns = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchFrmAns(formData);
    if (data?.status === 'success') {
      dispatch({ type: FRM_ANS_FETCH_ALL, payload: data?.data });
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error);
    alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const fetchFrmAnsById = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchFrmAnsById(formData);
    if (data?.status === 'success') {
      dispatch({ type: FRM_ANS_FETCH_ONE, payload: data?.data });
      formData.redirect && router.push(formData.redirect);
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error);
    alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const createFrmAns = (formData, history, pathParam) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.createFrmAns(formData);
    dispatch({ type: FRM_ANS_CREATE, payload: data });
    if (data?.status === 'success') {
      confirmAlert({ title: "Success", message: "Answer successfully created.", buttons: [{ label: 'Ok', onClick: () => { } }] });
      if (pathParam && !Lodash.isEmpty(pathParam?.path)) {
        history.push({ pathname: pathParam.path, state: pathParam.state });
      }
      else if (pathParam && Lodash.isBoolean(pathParam?.reload)) {
        history.go(0);
      }

    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error)
    alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const updateFrmAns = (formData, history, pathParam) => async (dispatch) => {
  console.log("formData", formData);
  console.log("pathParam", pathParam);

  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.updateFrmAns(formData);
    dispatch({ type: FRM_ANS_UPDATE, payload: data });
    if (data?.status === 'success') {
      confirmAlert({
        title: "Success", message: "Answer successfully updated.", buttons: [{
          label: 'Ok', onClick: () => {
            if (pathParam && !Lodash.isEmpty(pathParam?.path)) {
              history.push({ pathname: pathParam.path, state: pathParam.state });
            }
            else if (pathParam && Lodash.isBoolean(pathParam?.reload)) {
              history.go(0);
            }
          }
        }]
      });

    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error)
    alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const deleteFrmAns = (formData, history, pathParam) => async (dispatch) => {
  console.log("delete function is called");
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.deleteFrmAns(formData);
    console.log("delete response data: ", data);
    // dispatch({ type: FRM_ANS_UPDATE, payload: data });
    if (data?.status === 'success') {

      confirmAlert({
        title: "Success", /*message: data?.message,*/ buttons: [{
          label: 'Ok',
          onClick: () => {
            // redirect to relevant page with state parameters (to populate the page), otherwise go back
            if (pathParam && !Lodash.isEmpty(pathParam?.path)) {
              history.push({ pathname: pathParam.path, state: pathParam.state });
            }
            // else if (pathParam && Lodash.isBoolean(pathParam?.reload)) {
            else {
              history.go(0);
            }
          }
        }]
      });
    }
    else if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
      confirmAlert({ title: "Error", message: "Couldn't perform the task.", buttons: [{ label: 'Ok', onClick: () => { } }] });
    }
  } catch (error) {
    console.log(error)
    confirmAlert({ title: "Error", message: "Couldn't perform the task.", buttons: [{ label: 'Ok', onClick: () => { } }] });
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};



export const fetchNewQuestionsByOptions = (formData, history, pathParam) => async (dispatch) => {
  console.log("formData", formData);
  console.log("pathParam", pathParam);

  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchNewQuestionsByOptions(formData);

    if (data?.status === 'success') {
      dispatch({ type: FRM_NEW_QUESTIONS, payload: data?.data });
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error)
    // alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const fetchNewQuestionAnswerById = (formData) => async (dispatch) => {
  console.log("formData", formData);

  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchNewQuestionAnswerById(formData);

    if (data?.status === 'success') {
      dispatch({ type: FRM_NEW_QUESTION_ANSWER, payload: data?.data });
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error)
    // alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};
