import { MBP_FIND_CASES_FULL } from 'constants/actionTypes.js';
import * as api from '../api/index.js';
import { MBP_FIND_CASES_DETAIL, SHOW_LOADING } from "constants/actionTypes";
import { isValidString } from "helper";

// forum fetch stat reports
export const fetchMbpCasesInDetail = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    console.log('request data: ', formData);
    const { data } = await api.fetchMbpCasesInDetail(formData);
    // console.log('graph data --->: ', data);
    if (data?.status === 'success') {
      dispatch({ type: MBP_FIND_CASES_DETAIL, payload: data?.data });
    }
    else if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log("catch error: ", error);
    alert(error.response.data.message);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const fetchMbpCasesInFull = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    console.log('request data: ', formData);
    const { data } = await api.fetchMbpCasesInFull(formData);
    // console.log('graph data --->: ', data);
    if (data?.status === 'success') {
      dispatch({ type: MBP_FIND_CASES_FULL, payload: data?.data });
    }
    else if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log("catch error: ", error);
    alert(error.response.data.message);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};


export const createMbpCasesDensys = (formData) => async (dispatch) => {
  console.log('request data: ', formData);
  if (!(formData && formData.paramPatient && formData.paramNotification)) {
    return alert('All parameters are unavailable')
  }

  try {
    dispatch({ type: SHOW_LOADING, payload: true });

    let mbpPatient = undefined
    let mbpNotification = undefined
    let mbpCase = undefined

    // Step1: Create Patient 
    const resPatient = await api.createMbpPatient(formData.paramPatient);
    // console.log('resPatient --->: ', resPatient);
    if (resPatient?.data?.status && resPatient.data.status.toLowerCase() === 'success') {
      mbpPatient = resPatient?.data?.data
      // alert('Patient created');
    }
    else if (resPatient?.data?.status && resPatient?.data?.status.toLowerCase() === 'error') {
      return alert(`Patient error: ${resPatient?.data?.message}`);
    }
    else {
      return alert('Patient create error');
    }

    // Step2: Create Notification
    const paramNotification = { ...formData.paramNotification, patientid: mbpPatient._id }
    // console.log('request notification: ', paramNotification);
    const resNotification = await api.createMbpNotification(paramNotification);
    // console.log('resNotification --->: ', resNotification);
    if (resNotification?.data?.status && resNotification.data.status.toLowerCase() === 'success') {
      mbpNotification = resNotification?.data?.data?.notificaton
      // alert('Notification created');
    }
    else if (resNotification?.data?.status === 'error') {
      return alert(`Notification error: ${resNotification?.data?.message}`);
    }
    else {
      return alert('Notification create error');
    }

    // Step3: Create Case
    const params = {
      "notificationid": mbpNotification?._id,
      "diseasenotified": formData.paramNotification?.disease
    }
    if (isValidString(formData.paramPatient.ward)) {
      params["ward"] = formData.paramPatient.ward;
      params["wardid"] = formData.paramPatient.wardid;
      params["moh"] = formData.paramPatient.moh;
      params["mohid"] = formData.paramPatient.mohid;
    }
    // console.log('Step3: params', params);
    const resCase = await api.createMbpCase(params);
    // console.log('resCase --->: ', resCase);
    if (resCase?.data?.status && resCase.data.status.toLowerCase() === 'success') {
      mbpCase = resCase?.data?.data?.case
      alert(`Case created with work-flow-id: ${mbpCase?.workflowid}`);
    }
    else if (resCase?.data?.status && resCase.data.status.toLowerCase() === 'error') {
      return alert(`Case error: ${resCase?.data?.message}`);
    }
    else {
      return alert('Case create error');
    }

    // Step4: Add comments
    const paramCommentCreate = {
      case: mbpCase._id,
      comments: {
        "message": "Created",
        "messagetype": "text"
      }
    }
    const resCommentCreate = await api.createMbpComment(paramCommentCreate);
    console.log('resCommentCreate: ', resCommentCreate)

    if ("allocated" === mbpCase.status) {
      const paramCommentAllocate = {
        case: mbpCase._id,
        comments: {
          "message": `Allocated to ward: ${formData.paramPatient.ward}`,
          "messagetype": "text"
        }
      }
      const resCommentAllocate = await api.createMbpComment(paramCommentAllocate);
      console.log('Create comment: ', resCommentAllocate)
    }

  } catch (error) {
    console.log("catch error: ", error);
    alert(error.response.data.message);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};