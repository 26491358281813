import Index from "views/Index";

import Forum from "views/pages/forum/Forum";
import FAQ from "views/pages/forum/Faq";
import ForumSubTopic from "views/pages/forum/ForumSubTopic";
import ForumQuestion from "views/pages/forum/ForumQuestion";
import ForumTopicForm from "views/pages/forum/ForumTopicForm";
import ForumSubTopicForm from "views/pages/forum/ForumSubTopicForm";
import ForumQuestionFrom from "views/pages/forum/ForumQuestionFrom";
import ForumAnswerForm from "views/pages/forum/ForumAnswerForm";
import UserQuestions from "views/pages/forum/UserQuestions";
import UserQuestionsDetail from "views/pages/forum/UserQuestionsDetail";

import User from "views/pages/user/User";
import UserRegistered from "views/pages/user/UserRegistered";
import UserPublic from "views/pages/user/UserPublic";
import Login from "views/pages/user/Login";
import Profile from "views/pages/user/Profile";
import ProfileByAdmin from "views/pages/user/ProfileByAdmin";
// import Register from "views/pages/user/Register";
import UserPreRegister from "views/pages/user/UserPreRegister"
import PrivacyPolicy from "views/pages/user/PrivacyPolicy"
import DataPolicy from "views/pages/user/DataPolicy"
import Landing from "views/pages/user/Landing";

import Resource from "views/pages/resources/Resource";
import ResourceDetails from "views/pages/resources/ResourceDetails";


///////////////////////////////////////////////////////////////////

import AnalyticsCases from "views/pages/analytic/AnalyticsCases";
import AnalyticsBreedingSites from "views/pages/analytic/AnalyticsBreedingSites";
import AnalyticsReports from "views/pages/analytic/AnalyticsReports";

import BreedingComplainFrom from "views/pages/breedingSite/BreedingComplainFrom";
import BreedingMyComplain from "views/pages/breedingSite/BreedingMyComplain";
import BreedingOpenComplain from "views/pages/breedingSite/BreedingOpenComplain";
import BreedingAllComplain from "views/pages/breedingSite/BreedingAllComplain";
import BreedingDeletedComplain from "views/pages/breedingSite/BreedingDeletedComplain";

import CalendarActivityFrom from "views/pages/calendar/CalendarActivityFrom";
import CalendarNewActivityFrom from "views/pages/calendar/CalendarNewActivityFrom";
import CalendarMyActivity from "views/pages/calendar/CalendarMyActivity";
import CalendarPublicActivity from "views/pages/calendar/CalendarPublicActivity";

import Contact from "views/pages/contact/Contact";
import ContactForm from "views/pages/contact/ContactForm";

import EducationalMaterial from "views/pages/education/EducationalMaterial";
import EducationalMaterialDetails from "views/pages/education/EducationalMaterialDetails";
import EducationalMaterialShow from "views/pages/education/EducationalMaterialShow";

import Maps from "views/pages/map/Maps";
import MapBuilding from "views/pages/map/MapBuilding";
import MapBuildingDetails from "views/pages/map/MapBuildingDetails";
import MapBreedingSite from "views/pages/map/MapBreedingSite";
import MapsDangerZones from "views/pages/map/MapsDangerZones";
import MapsLocationDetails from "views/pages/map/MapsLocationDetails";
import MapPatients from "views/pages/map/MapPatients";

import SendNotification from "views/pages/notify/SendNotification";
import NotificationHistory from "views/pages/notify/NotificationHistory";

import FindCaseReportsInDetail from "views/pages/mbp/FindCaseReports"
import FindCaseReportsInFull from "views/pages/mbp/FindCaseReportsInFull"
import FindCaseIdDirectory from "views/pages/mbp/FindCaseIdDirectory"
import AddCases from "views/pages/mbp/AddCases"


import Tables from "views/pages/Tables";
import Icons from "views/pages/Icons";

let currentUser = localStorage.getItem('userDetails');
let jUser = JSON.parse(currentUser);
let uRole = currentUser && jUser && jUser.user && jUser.user.urole ? jUser.user.urole : "GENERAL"

export const routesAdmin = [
  //////////////////////////////////////////
  {
    path: "/index",
    name: "Dashboard",
    icon: "fas fa-chalkboard-teacher", // Showing
    component: Index,
    layout: "/registered",
    location: "registered",
  },
  //////////////////////////////////////////
  // {
  //   path: "/analytics",
  //   name: "Analytics",
  //   icon: "fas fa-chart-bar text-yellow",
  //   component: Analytics,
  //   layout: "/registered",
  //   location: "registered",
  // },
  {
    name: "Analytics",
    icon: "fas fa-chart-bar",
    location: "registered",
    innerlinks: [
      {
        path: "/analytic_dengue_cases",
        name: "Dengue Cases",
        icon: "fas fa-chart-line",
        component: AnalyticsCases,
        layout: "/registered",
        location: "registered",
      },
      {
        path: "/analytic_breeding_sites",
        name: "Breeding Sites",
        icon: "fas fa-chart-pie",
        component: AnalyticsBreedingSites,
        layout: "/registered",
        location: "registered",
      },
      {
        path: "/analytic_reports",
        name: "Reports",
        icon: "fas fa-chart-bar",
        component: AnalyticsReports,
        layout: "/registered",
        location: "registered",
      }
    ]
  },
  //////////////////////////////////////////
  {
    name: "Maps",
    icon: "fas fa-map-marked-alt ",
    location: "registered",
    innerlinks: [
      {
        path: "/map",
        name: "GPS Location",
        icon: "fas fa-map-marked-alt",
        component: Maps,
        layout: "/registered",
        location: "registered",
      },
      {
        path: "/map_buildings",
        name: "Map Buildings",
        icon: "fas fa-map-marked-alt",
        component: MapBuilding,
        layout: "/registered",
        location: "registered",
      },
      {
        path: "/map_patients",
        name: "Map Patients",
        icon: "fas fa-map-marked-alt",
        component: MapPatients,
        layout: "/registered",
        location: "registered",
      },
      {
        path: "/map_breeding_site",
        name: "Map Breeding Sites",
        icon: "fas fa-map-marked-alt",
        component: MapBreedingSite,
        layout: "/registered",
        location: "registered",
      },
      {
        path: "/map_danger_zones",
        name: "Map Danger Zones",
        icon: "fas fa-map-marked-alt",
        component: MapsDangerZones,
        layout: "/registered",
        location: "registered",
      },
    ]
  },
  {
    path: "/map_building_details",
    name: "Map Buildings",
    icon: "fas fa-map-marked-alt",
    component: MapBuildingDetails,
    layout: "/registered",
    location: "map",
  },
  {
    path: "/map_location_details",
    name: "Map Location Details",
    icon: "fas fa-map-marked-alt",
    component: MapsLocationDetails,
    layout: "/registered",
    location: "map",
  },
  //////////////////////////////////////////
  {
    name: "Breeding Sites",
    icon: "fas fa-cloud-rain ",  // Showing
    location: "registered",
    innerlinks: [
      // {
      //   path: "/breeding_my_complains",
      //   name: "User Reports",
      //   icon: "ni ni-bullet-list-67",
      //   component: BreedingMyComplain,
      //   layout: "/registered",
      //   location: "registered",
      // },
      // {
      //   path: "/breeding_open_complains",
      //   name: "Unattended Reports",
      //   icon: "ni ni-bullet-list-67",
      //   component: BreedingOpenComplain,
      //   layout: "/registered",
      //   location: "registered",
      // },
      {
        path: "/breeding_all_complains",
        name: "Find Reports",
        icon: "fas fa-search-plus",
        component: BreedingAllComplain,
        layout: "/registered",
        location: "registered",
      },
      {
        path: "/breeding_deleted_complains",
        name: "Deleted Reports",
        icon: "fas fa-search-minus",
        component: BreedingDeletedComplain,
        layout: "/registered",
        location: "registered",
      }
    ],
  },
  {
    path: "/breeding_complain_from",
    name: "Make A Report",
    icon: "ni ni-bullet-list-67",
    component: BreedingComplainFrom,
    layout: "/registered",
    location: "breedingSite",
  },
  //////////////////////////////////////////
  {
    path: "/contact",
    name: "Contact",
    icon: "fas fa-phone ",
    component: Contact,
    layout: "/registered",
    location: "registered",
  },
  {
    path: "/contact_form",
    name: "Add Contact",
    icon: "fas fa-phone",
    component: ContactForm,
    layout: "/registered",
    location: "contact",
  },
  //////////////////////////////////////////
  {
    name: "Calendar",
    icon: "fas fa-calendar ",
    location: "registered",
    innerlinks: [{
      path: "/calendar_activity_form",
      name: "New Activity",
      icon: "fas fa-calendar-plus",
      component: CalendarNewActivityFrom,
      layout: "/registered",
      location: "registered",
    },
    {
      path: "/calendar_my_activity",
      name: "My Activities",
      icon: "fas fa-calendar-day",
      component: CalendarMyActivity,
      layout: "/registered",
      location: "registered",
    },
    {
      path: "/calendar_public_activity",
      name: "Public Activities",
      icon: "fas fa-calendar-alt",
      component: CalendarPublicActivity,
      layout: "/registered",
      location: "registered",
    }]
  },
  {
    path: "/calendar_activity_details_form",
    name: "Activity Details",
    icon: "ni ni-bullet-list-67",
    component: CalendarActivityFrom,
    layout: "/registered",
    location: "calendar",
  },
  //////////////////////////////////////////
  // {
  //   name: "User Institutes",
  //   icon: "fas fa-building ", // Showing
  //   location: "registered",
  //   innerlinks: [
  //     {
  //       path: "/place_buildings",
  //       name: "Public Places",
  //       icon: "fas fa-building",
  //       component: MapBuilding,
  //       layout: "/registered",
  //       location: "registered",
  //     },
  //     {
  //       path: "/organization",
  //       name: "User Institutes",
  //       icon: "fas fa-building",
  //       component: Organization,
  //       layout: "/registered",
  //       location: "registered",
  //     },
  //   ]
  // },
  //////////////////////////////////////////
  {
    name: "EM Management",
    icon: "fas fa-graduation-cap ",
    location: "registered",
    innerlinks: [
      {
        path: "/educational_materials",
        name: "Educational Materials",
        icon: "fas fa-file-alt",
        component: EducationalMaterialShow,
        layout: "/registered",
        location: "registered",
      },
      {
        path: "/education",
        name: "Manage Articles",
        icon: "fas fa-book",
        component: EducationalMaterial,
        layout: "/registered",
        location: "registered",
      },
      {
        path: "/resource",
        name: "Manage Resources",
        icon: "fas fa-images", // Showing
        component: Resource,
        layout: "/registered",
        location: "registered",
      },
    ]
  },
  {
    path: "/educational_material_details",
    name: "Education SubTopic",
    icon: "fas fa-graduation-cap",
    component: EducationalMaterialDetails,
    layout: "/registered",
    location: "education",
  },
  //////////////////////////////////////////
  {
    name: "FAQ Management",
    icon: "fas fa-comments ",
    location: "registered",
    innerlinks: [
      {
        path: "/faq",
        name: "Public FAQs",
        icon: "fas fa-comment-dots", // Showing
        component: FAQ,
        layout: "/registered",
        location: "registered",
      },
      {
        path: "/faq_management",
        name: "Manage Content",
        icon: "fas fa-comments", // Showing
        component: Forum,
        layout: "/registered",
        location: "registered",
      },
      {
        path: "/faq_user_questions",
        name: "User Questions",
        icon: "fas fa-question-circle", // Showing
        component: UserQuestions,
        layout: "/registered",
        location: "registered",
      },
    ]
  },
  {
    path: "/faq_subtopic",
    name: "Forum SubTopic",
    icon: "fas fa-comments",
    component: ForumSubTopic,
    layout: "/registered",
    location: "forum",
  },
  {
    path: "/faq_question",
    name: "Forum Question",
    icon: "fas fa-comments",
    component: ForumQuestion,
    layout: "/registered",
    location: "forum",
  },
  {
    path: "/faq_topic_form",
    name: "Forum Topic Details",
    icon: "fas fa-comments",
    component: ForumTopicForm,
    layout: "/registered",
    location: "forum",
  },
  {
    path: "/faq_sub_topic_form",
    name: "Forum Sub Topic Details",
    icon: "fas fa-comments",
    component: ForumSubTopicForm,
    layout: "/registered",
    location: "forum",
  },
  {
    path: "/faq_question_form",
    name: "Forum Question Details",
    icon: "fas fa-comments",
    component: ForumQuestionFrom,
    layout: "/registered",
    location: "forum",
  },
  {
    path: "/faq_answer_form",
    name: "Forum Answer Details",
    icon: "fas fa-comments",
    component: ForumAnswerForm,
    layout: "/registered",
    location: "forum",
  },
  {
    path: "/faq_user_question_detail",
    name: "User Question Details",
    icon: "fas fa-comments",
    component: UserQuestionsDetail,
    layout: "/registered",
    location: "forum",
  },
  /////////////////////////////////////////
  {
    name: "User Management",
    icon: "fas fa-users ",
    location: "registered",
    innerlinks: [
      {
        path: "/pre_register_user",
        name: "Pre-Register",
        icon: "fas fa-user-tag", // Showing
        component: UserPreRegister,
        layout: "/registered",
        location: "registered",
      },
      {
        path: "/user_registered",
        name: "Registered Users",
        icon: "fas fa-user-tie", // Showing
        component: UserRegistered,
        layout: "/registered",
        location: "registered",
      },
      {
        path: "/user_general",
        name: "Public Users",
        icon: "fas fa-user", // Showing
        component: UserPublic,
        layout: "/registered",
        location: "registered",
      },
    ]
  },
  {
    path: "/user_profile_manage",
    name: "Manage User Profile",
    icon: "fas fa-user-alt",
    component: ProfileByAdmin,
    layout: "/registered",
    location: "user",
  },
  {
    path: "/user_profile",
    name: "User Profile",
    icon: "fas fa-user-alt",
    component: Profile,
    layout: "/registered",
    location: "user",
  },
  /////////////////////////////////////////
  // {
  //   name: "Reports",
  //   icon: "fas fa-chart-line",
  //   location: "registered",
  //   innerlinks: [
  //     {
  //       path: "/report",
  //       name: "Report",
  //       icon: "fas fa-chart-line", // Showing
  //       component: Report,
  //       layout: "/registered",
  //       location: "registered",
  //     },
  //   ]
  // },
  // {
  //   path: "/resource",
  //   name: "Resources",
  //   icon: "fas fa-book", // Showing
  //   component: Resource,
  //   layout: "/registered",
  //   location: "registered",
  // },
  {
    path: "/resource_details",
    name: "Resource Details",
    icon: "fas fa-book", // Showing
    component: ResourceDetails,
    layout: "/registered",
    location: "resource",
  },
  /////////////////////////////////////////
  {
    path: "/notification",
    name: "Send Notification",
    icon: "fas fa-paper-plane",
    component: SendNotification,
    layout: "/registered",
    location: "registered",
  },
  {
    path: "/notification_history",
    name: "Notification History",
    icon: "ni ni-key-25",
    component: NotificationHistory,
    layout: "/registered",
    location: "notification",
  },
  /////////////////////////////////////////
  {
    path: "/pre_register_user",
    name: "Register Admin",
    icon: "fas fa-user-tie",
    component: UserPreRegister,
    layout: "/registered",
    location: "user",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25",
    component: Login,
    layout: "/public",
    location: "auth",
  },  
  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-key-25",
  //   component: Register,
  //   layout: "/public",
  //   location: "user",
  // },
  {
    path: "/",
    name: "Register",
    icon: "ni ni-key-25",
    component: Landing,
    layout: "/public",
    location: "land",
  },
  /////////////////////////////////////////
  {
    name: "Patient Data",
    icon: "fas fa-user-md",  // Showing
    location: "registered",
    innerlinks: [
      {
        path: "/mbp_add_cases",
        name: "Add cases",
        icon: "fas fa-upload",
        component: AddCases,
        layout: "/registered",
        location: "registered",
      },
      // {
      //   path: "/mbp_find_cases",
      //   name: "Find cases",
      //   icon: "fas fa-search-plus",
      //   component: FindCaseReportsInDetail,
      //   layout: "/registered",
      //   location: "registered",
      // },
      {
        path: "/mbp_detail_cases_detail",
        name: "Find detail cases",
        icon: "fas fa-search-plus",
        component: FindCaseReportsInFull,
        layout: "/registered",
        location: "registered",
      },      
      {
        path: "/mbp_id_registry",
        name: "PHI Id registry",
        icon: "fas fa-search-plus",
        component: FindCaseIdDirectory,
        layout: "/registered",
        location: "registered",
      }
    ],
  },
];

export const routesUser = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "fas fa-chalkboard-teacher",
    component: Index,
    layout: "/registered",
    location: "registered",
  }
];

export const routes = (uRole === "ADMIN") ? routesAdmin : routesUser;

export const privacyRoutes = [
  // {
  //   path: "/privacy_policy",
  //   name: "Privacy Policy",
  //   icon: "ni ni-key-25",
  //   component: PrivacyPolicy,
  //   layout: "/simple",
  //   location: "land",
  // },
  // {
  //   path: "/data_policy",
  //   name: "Data Policy",
  //   icon: "ni ni-key-25",
  //   component: DataPolicy,
  //   layout: "/simple",
  //   location: "land",
  // }
];

