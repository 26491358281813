import {
  Checkbox,
  Chip,
  FormControl,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
// import { updateOrganization } from "actions/organization";
// import { fetchMasterDataLanguageAll } from "actions/masterData";

// core components
import UserHeader from "components/Headers/UserHeader.js";
import Config from "config/config.json";
import { MbppStyles } from "constants/globalStyles";
import { Formik, useFormik } from "formik";
import {
  affiliation_types,
  ages,
  Camelize,
  CryptoPassword,
  emp_types,
  genders,
  phoneRegExp,
  titles,
  useMultiSelectStyles,
  chsCountries,
  legal_entitys,
  geo_areas,
  drm_acts,
  unit,
  functional,
  stakeholder,
  vision,
  city_drm_acts,
  headoffice,
} from "helper.js";
import Lodash from "lodash";
import React, { useEffect, useState } from "react";
// import { useAlert } from "react-alert";
import Resizer from "react-image-file-resizer";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Form, Row } from "reactstrap";
import * as yup from "yup";
import { object } from "yup/lib/locale";

const validationSchema = yup.object({
  firstName: yup
    .string("Enter your first name")
    .max(255, "First name should be of maximum 255 characters length")
    .required("First name is required"),
  lastName: yup
    .string("Enter your last name")
    .max(255, "Last name should be of maximum 255 characters length"),
  // profilePicture: yup
  //   .mixed()
  //   .test(
  //     "fileSize",
  //     "File too large",
  //     function (value) { return value ? FILE_IMG_SIZE >= value.size : true }
  //   )
  //   .test(
  //     "fileFormat",
  //     "Unsupported Format",
  //     function (value) { return value ? SUPPORTED_IMG_FORMATS.includes(value.type) : true }
  //   ),
  affiliation: yup
    .string("Enter your affiliation")
    .min(3, "Affiliation should be of minimum 3 characters length")
    .max(255, "Affiliation should be of maximum 255 characters length"),
});


const validationSchemaOrganization = yup.object({});

const Profile = (props) => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  // set state contact number list
  const [contactList, setContactList] = useState([{ index: 0, contactno: "" }]);
  // set state userDetails
  // get state user profile

  const [userProfile, setUserProfile] = useState({});
  const [userOrganization, setUserOrganization] = useState({});
  const [userDetails, setUserDetails] = useState({});

  const [userProfileOrg, setUserProfileOrg] = useState({});
  const [isOrgOwner, setIsOrgOwner] = useState(false);
  const [cityPosition, setCityPosition] = useState(0);
  const [isCityOwner, setIsCityOwner] = useState(false);

  // set state edit
  const [isEdit, setIsEdit] = useState(true);
  // Show edit profile or change password
  const [editSection, setEditSection] = useState("WELCOME");
  // Change the password text readability
  const [showPassword, setShowPassword] = useState(false);

  // handle click event of togle Edit
  const switchMode = (e) => {
    e.preventDefault();
    // setIsEdit((prevIsSignup) => !prevIsSignup);
    // setIsEdit(true);
    setEditSection("PROFILE");
  };

  const userProfileOrg1 = useSelector(
    (state) => state.user.userProfileOrgByAdmin
  );
  console.log("userOrg", userProfileOrg1);

  const language = useSelector(
    (state) => state.masterData.languageAll
  );
  console.log("language", language);


  useEffect(() => {
    console.log("userProfileOrg", userProfileOrg);
    if (userProfileOrg1 && !Lodash.isEmpty(userProfileOrg1)) {
      // const paramUserDetails = props.location.state?.userDetails; // JSON.parse(localStorage.getItem("userDetails"));
      // setUserDetails(paramUserDetails);
      // console.log("userDetails1111", paramUserDetails);
      let paramUserDetails = userProfileOrg1?.user;

      if (paramUserDetails && Object.keys(paramUserDetails).length > 0) {
        setEditSection("PROFILE");
        console.log("edit profile");
        setUserDetails(paramUserDetails);
      } else {
        console.log("edit welcome");
        setEditSection("WELCOME");
      }

      const profile1 = JSON.stringify(userProfileOrg1.profile);
      const profile = JSON.parse(profile1);

      setUserProfile(profile);

      let organization = userProfileOrg1.profile?.employment?.organization;
      setUserProfileOrg(organization);
      console.log("profile1", profile1);
      console.log("profile", profile);
      console.log("userProfileOrg1", userProfileOrg1);

      if (organization === !null) {
        profile.employment["organization"] =
          profile.employment?.organization?._id;
        if (userProfile?.user === organization.createdBy) {
          setIsOrgOwner(true);
        } else {
          setIsOrgOwner(false);
        }

        console.log("Owner", isOrgOwner);

        console.log("city array", organization.city);
        organization.city.map((city, i) => {
          console.log("i", i);
          console.log("linkeduser", city.linkedUser);
          console.log("userId", userProfile?.user);
          if (userProfile?.user === city.linkedUser) {
            setCityPosition(i);
            console.log("if i", i);
            setIsCityOwner(true);
          }

          console.log("City Owner", isCityOwner);
        });
      }
      //console.log("city position", cityPosition);
    }
    // dispatch(fetchMasterDataLanguageAll());
  }, [userProfileOrg1]);

  useEffect(() => {
    // setEditSection("WELCOME");
    console.log("---------- user details: ", userDetails);
    console.log("---------- user profile: ", userProfile);

    if (userDetails && !Lodash.isEmpty(userDetails)) {
      formik.values.userId = userDetails?.user.id || "";
      formik.values.user = userDetails?.user || "";
      formik.values.email = userDetails?.user.email || "";

      formik1.values.modification_modifiedBy = userDetails?.user.id || "";
    }

    if (userProfile && Object.keys(userProfile).length > 0) {
      formik.values.profileId = userProfile?._id || "";
      formik.values.firstName = userProfile?.firstName || "";
      formik.values.lastName = userProfile?.lastName || "";
      formik.values.language = userProfile?.language || "";
      formik.values.profilePicture = userProfile?.profilePicture || "";
      formik.values.profileThumbnail = userProfile?.profileThumbnail || "";
      formik.values.emp_email = userProfile?.employment?.email || "";
      formik.values.emp_address = userProfile?.city?.website || "";
      formik.values.emp_designation =
        userProfile?.employment?.designation || "";
    }

    if (userDetails?.user?.urole === "GENERAL") {
      if (userProfileOrg && Object.keys(userProfileOrg).length > 0) {
        // formik1.values.modification_modifiedBy = userDetails?.user.id || "";

        formik1.values.organizationId = userProfileOrg?._id || "";
        formik1.values.firstName = userProfileOrg?.firstName || "";
        formik1.values.lastName = userProfileOrg?.lastName || "";
        formik1.values.type = userProfileOrg?.type || "";
        formik1.values.legalStatus_status =
          userProfileOrg?.legalStatus?.status === true ? "Yes" : "No";
        formik1.values.legalStatus_mandate =
          userProfileOrg?.legalStatus?.mandate || "";
        formik1.values.drmActivities = userProfileOrg?.drmActivities || "";
        formik1.values.visionMission_status =
          userProfileOrg?.visionMission?.status === true ? "Yes" : "No";
        formik1.values.visionMission_remarks =
          userProfileOrg?.visionMission?.remark || "";
        formik1.values.functionalStructure_status =
          userProfileOrg?.functionalStructure?.status === true ? "Yes" : "No";
        formik1.values.functionalStructure_remarks =
          userProfileOrg?.functionalStructure?.remark || "";
        formik1.values.unitProject_status =
          userProfileOrg?.unitProject?.status === true ? "Yes" : "No";
        formik1.values.unitProject_remarks =
          userProfileOrg?.unitProject?.remark || "";
        formik1.values.unitStakeholders_status =
          userProfileOrg?.unitStakeholders?.status === true ? "Yes" : "No";
        formik1.values.unitStakeholders_remarks =
          userProfileOrg?.unitStakeholders?.remark || "";
        formik1.values.geographicArea = userProfileOrg?.geographicArea || "";
        formik1.values.organization = userProfileOrg?.name || "";
        formik1.values.createdBy = userProfileOrg?.createdBy || "";
        formik1.values.country = userProfileOrg?.country?.name || "";
        formik1.values.website = userProfileOrg?.website || "";
        formik1.values.type = userProfileOrg?.type || "";
        formik1.values.city_name =
          userProfileOrg?.city[cityPosition]?.name || "";
        formik1.values.city_linkedUser =
          userProfileOrg?.city[cityPosition]?.linkedUser || null;
        formik1.values.city_website =
          userProfileOrg?.city[cityPosition]?.website || "";
        formik1.values.city_email =
          userProfileOrg?.city[cityPosition]?.email || "";
        formik1.values.city_address =
          userProfileOrg?.city[cityPosition]?.address || "";
        formik1.values.numberOfOrganizationEmployees_number =
          userProfileOrg?.numberOfOrganizationEmployees?.number || 0;
        formik1.values.numberOfOrganizationEmployees_remark =
          userProfileOrg?.numberOfOrganizationEmployees?.remark || "";
        formik1.values.city_isHeadOffice =
          userProfileOrg?.city[cityPosition]?.isHeadOffice === true
            ? "Yes"
            : "No";
        formik1.values.city_numberOfEmployees_number =
          userProfileOrg?.city[cityPosition]?.numberOfEmployees?.number || 0;
        formik1.values.city_numberOfEmployees_remarks =
          userProfileOrg?.city[cityPosition]?.numberOfEmployees?.remark || "";
        formik1.values.city_drmActivities_performed =
          userProfileOrg?.city[cityPosition]?.drmActivities?.performed || "";
        formik1.values.city_drmActivities_remarks =
          userProfileOrg?.city[cityPosition]?.drmActivities?.remark || "";
        formik1.values.modification_modifiedRemark =
          userProfileOrg?.modification?.modifiedRemark || "";
      }
    }
  }, [userDetails, userProfile, userProfileOrg]);

  // const alert = useAlert();

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      profilePicture: "",
      profileThumbnail: "",
      language: "",
      emp_designation: "",
      emp_linkedcity: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, onSubmitProps) => {
      console.log("Update profile", values);
    },
  });

  const formik1 = useFormik({
    initialValues: {
      organizationId: "",
      country: "",
      organization: "",
      city_name: "",
      city_linkedUser: "",
      city_website: "",
      city_address: "",
      visionMission_status: false,
      visionMission_remarks: "",
      functionalStructure_status: false,
      functionalStructure_remarks: false,
      unitProject_status: false,
      unitProject_remarks: "",
      unitStakeholders_status: false,
      unitStakeholders_remarks: "",
      city_isHeadOffice: false,
      numberOfOrganizationEmployees_number: 0,
      numberOfOrganizationEmployees_remark: "",
      city_numberOfEmployees_number: 0,
      city_drmActivities_performed: "",
      city_numberOfEmployees_remarks: "",
      city_drmActivities_remarks: "",
      type: "",
      legalStatus_status: false,
      legalStatus_mandate: "",
      geographicArea: "",
      drmActivities: "",
      website: "",
      createdBy: "",
      modification_modifiedBy: "",
      modification_modifiedRemark: "",
    },
    validationSchema: validationSchemaOrganization,
    onSubmit: (values, onSubmitProps) => {
      // setEditSection("ORGANIZATION_UPDATE");
      console.log("Update organization", values);
      let legal = {
        status: values.legalStatus_status === "Yes" ? true : false,
        mandate: values.legalStatus_mandate,
      };
      values["legalStatus"] = legal;
      console.log("boolean", values.legalStatus_status);
      console.log("legal", values);

      let vision = {
        status: values.visionMission_status === "Yes" ? true : false,
        remark: values.visionMission_remarks,
      };
      values["visionMission"] = vision;
      console.log("visio", vision);

      let functional = {
        status: values.functionalStructure_status === "Yes" ? true : false,
        remark: values.functionalStructure_remarks,
      };
      values["functionalStructure"] = functional;
      console.log("functional", functional);

      let unit = {
        status: values.unitProject_status === "Yes" ? true : false,
        remark: values.unitProject_remarks,
      };
      values["unitProject"] = unit;
      console.log("unit", unit);

      let stakeholder = {
        status: values.unitStakeholders_status === "Yes" ? true : false,
        remark: values.unitStakeholders_remarks,
      };
      values["unitStakeholders"] = stakeholder;
      console.log("stakeholder", stakeholder);

      let modification = {
        modifiedBy: values.modification_modifiedBy,
        modifiedRemark: values.modification_modifiedRemark,
      };
      values["modification"] = modification;
      console.log("modification", modification);

      let uptedcity = [
        {
          name: values.city_name,
          linkedUser: values.city_linkedUser,
          website: values.city_website,
          email: values.city_email,
          address: values.city_address,
          isHeadOffice: values.city_isHeadOffice === "Yes" ? true : false,
          drmActivities: values.city_drmActivities_performed,
          numberOfEmployees: values.city_numberOfEmployees_number,
        },
      ];
      values["city"] = uptedcity;
      console.log("uptedcity", uptedcity);
      // dispatch(updateOrganization(values, true, history));
    },
  });


  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          {/* Right profile update and change password */}
          {userProfile && (
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <Card className="card-profile shadow">
                <Row className="justify-content-center">
                  <Col
                    className="order-lg-2"
                    lg="3"
                    style={{ marginLeft: -20 }}
                  >
                    <div className="card-profile-image">
                      <div
                        className="rounded-circle"
                        style={{
                          backgroundImage:
                            'url("' +
                            Config.SERVER_API_URL +
                            Config.PUBLIC_PROF_PIC +
                            userProfile?.profilePicture +
                            '")',
                          backgroundColor: "#bdbdbd",
                        }}
                      >
                        {userProfile?.profilePicture
                          ? ""
                          : userProfile &&
                            userProfile?.firstName &&
                            userProfile?.firstName.length > 0
                            ? userProfile?.firstName.charAt(0).toUpperCase()
                            : ""}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 130, margin: 10 }}>
                  <Col
                    className="d-flex justify-content-between order-xl-2 mb-5 mb-xl-0"
                    style={{ padding: 0 }}
                  >
                    <div></div>
                  </Col>
                </Row>
                <CardBody className="pt-0 pt-md-4">
                  <div className="text-center">
                    <h3> </h3>
                  </div>
                  <div className="text-center">
                    <h3>
                      {Camelize(
                        userProfile?.firstName + " " + userProfile?.lastName
                      )}
                    </h3>
                    <div className="h5 font-weight-300">
                      {userDetails?.user?.email}
                    </div>
                    <br />
                  </div>
                </CardBody>
              </Card>
            </Col>
          )}

          {/* Update profile */}
          <Col
            className="order-xl-1"
            xl="8"
            style={{ display: editSection === "PROFILE" ? "block" : "none" }}
          >
            <Card className="bg-secondary shadow">
              <CardBody>
                <Form
                  role="form"
                  onSubmit={formik.handleSubmit}
                  onReset={formik.handleReset}
                >
                  <div className="heading-title mb-4" style={MbppStyles.Topic}>
                    View Profile
                  </div>

                  <br />
                  <div className="pl-lg-4">
                    <hr className="my-2" />
                    <h6
                      className="heading-small mb-4"
                      style={MbppStyles.SubTopic}
                    >
                      Personal Details
                    </h6>
                    <Row>
                      <Col lg="8">
                        <TextField
                          ullWidth
                          fullWidth
                          id="email"
                          name="email"
                          variant="outlined"
                          label="Email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                          helperText={
                            formik.touched.email && formik.errors.email
                          }
                          className="form-control-alternative form-control-display form-control-css"
                          InputProps={{ readOnly: true, disabled: true }}
                        />
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col lg="6">
                        <TextField
                          ullWidth
                          fullWidth
                          id="firstName"
                          name="firstName"
                          variant="outlined"
                          label="First name"
                          value={formik.values.firstName}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.firstName &&
                            Boolean(formik.errors.firstName)
                          }
                          helperText={
                            formik.touched.firstName && formik.errors.firstName
                          }
                          className={
                            isEdit
                              ? "form-control-alternative form-control-edit"
                              : "form-control-alternative form-control-display"
                          }
                          InputProps={{
                            readOnly: !isEdit,
                            disabled: !isEdit,
                          }}
                        />
                      </Col>

                      <Col lg="6">
                        <TextField
                          ullWidth
                          fullWidth
                          id="lastName"
                          name="lastName"
                          variant="outlined"
                          label="Last Name"
                          value={formik.values.lastName}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.lastName &&
                            Boolean(formik.errors.lastName)
                          }
                          helperText={
                            formik.touched.lastName && formik.errors.lastName
                          }
                          className={
                            isEdit
                              ? "form-control-alternative form-control-edit"
                              : "form-control-alternative form-control-display"
                          }
                          InputProps={{
                            readOnly: !isEdit,
                            disabled: !isEdit,
                          }}
                        />
                      </Col>
                    </Row>
                    <br />

                    {isEdit && (
                      <Row>
                        <Col md="8">
                          <TextField
                            fullWidth
                            id="profilePicture"
                            name="profilePicture"
                            type="file"
                            variant="outlined"
                            label="Profile Picture"
                            multiple={false}
                            onChange={(e) => { }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={
                              formik.touched.profilePicture &&
                              Boolean(formik.errors.profilePicture)
                            }
                            className="form-control-alternative form-control-edit"
                            helperText={
                              formik.touched.profilePicture &&
                              formik.errors.profilePicture
                            }
                          />
                        </Col>
                      </Row>
                    )}
                    <br />

                    <Row>
                      <Col md="8">
                        <TextField
                          select
                          fullWidth
                          id="language"
                          name="language"
                          variant="outlined"
                          label="Language"
                          value={formik.values.language}
                          onChange={formik.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={
                            "form-control-alternative form-control-edit"
                          }
                          error={
                            formik.touched.language &&
                            Boolean(formik.errors.language)
                          }
                          helperText={
                            formik.touched.language && formik.errors.language
                          }
                        >
                          <option key="" value=""></option>
                          {language.map((item, i) =>
                            <option key={item.code} value={item.code}>
                              {item.name}
                            </option>
                          )}
                        </TextField>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md="8">
                        <TextField
                          fullWidth
                          id="emp_designation"
                          name="emp_designation"
                          variant="outlined"
                          label="Designation"
                          value={formik.values.emp_designation}
                          onChange={formik.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={
                            "form-control-alternative form-control-edit"
                          }
                          error={
                            formik.touched.emp_designation &&
                            Boolean(formik.errors.emp_designation)
                          }
                          helperText={
                            formik.touched.emp_designation &&
                            formik.errors.emp_designation
                          }
                        ></TextField>
                      </Col>
                    </Row>
                    <br />

                    <div className="text-left">
                      <Button
                        variant="contained"
                        color="primary"
                        type="button"
                        onClick={(e) => {
                          history.goBack();
                        }}
                      >
                        BACK
                      </Button>
                    </div>
                  </div>
                </Form>
              </CardBody>
            </Card>

            <br />

            <Card
              className="bg-secondary shadow"
              style={{
                display:
                  userDetails?.user?.urole === "GENERAL" ? "block" : "none",
              }}
            >
              <CardBody>
                <Form
                  role="form"
                  onSubmit={formik1.handleSubmit}
                  onReset={formik1.handleReset}
                >
                  <div className="heading-title mb-4" style={MbppStyles.Topic}>
                    Organizational Profile
                  </div>
                  <div className="pl-lg-4">
                    <hr className="my-2" />
                    <h6
                      className="heading-small mb-4"
                      style={MbppStyles.SubTopic}
                    >
                      Organizational Details
                    </h6>
                    <Row>
                      <Col md="8">
                        <TextField
                          fullWidth
                          id="org_country"
                          name="org_country"
                          variant="outlined"
                          label="Country*"
                          value={formik1.values.country}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={
                            "form-control-alternative form-control-edit"
                          }
                          error={
                            formik1.touched.country &&
                            Boolean(formik1.errors.country)
                          }
                          helperText={
                            formik1.touched.country && formik1.errors.country
                          }
                          disabled={true}
                        ></TextField>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md="12">
                        <TextField
                          fullWidth
                          id="organization"
                          name="organization"
                          variant="outlined"
                          label="Name of the Organization*"
                          value={formik1.values.organization}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={
                            "form-control-alternative form-control-edit"
                          }
                          error={
                            formik1.touched.organization &&
                            Boolean(formik1.errors.organization)
                          }
                          helperText={
                            formik1.touched.organization &&
                            formik1.errors.organization
                          }
                          disabled={true}
                        ></TextField>
                      </Col>
                    </Row>
                    <br />

                    <hr className="my-2" />
                    <h6
                      className="heading-small mb-4"
                      style={MbppStyles.SubTopic}
                    >
                      Institutional Details
                    </h6>
                    <Row>
                      <Col md="6">
                        <TextField
                          select
                          fullWidth
                          id="type"
                          name="type"
                          variant="outlined"
                          label="Type of organization*"
                          value={formik1.values.type}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={
                            "form-control-alternative form-control-edit"
                          }
                          error={
                            formik1.touched.type && Boolean(formik1.errors.type)
                          }
                          helperText={
                            formik1.touched.type && formik1.errors.type
                          }
                          disabled={!isOrgOwner}
                        >
                          {emp_types.map((item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                        </TextField>
                      </Col>

                      <Col md="6">
                        <TextField
                          select
                          fullWidth
                          id="geo_area"
                          name="geo_area"
                          variant="outlined"
                          label=" Geographic areas of operations*"
                          value={formik1.values.geographicArea}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={
                            "form-control-alternative form-control-edit"
                          }
                          error={
                            formik1.touched.geographicArea &&
                            Boolean(formik1.errors.geographicArea)
                          }
                          helperText={
                            formik1.touched.geographicArea &&
                            formik1.errors.geographicArea
                          }
                          disabled={!isOrgOwner}
                        >
                          {geo_areas.map((item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                        </TextField>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md="12">
                        <FormControl fullWidth style={MbppStyles.Input}>
                          <label
                            className="form-control-label"
                            htmlFor="input-institute"
                          >
                            Are you legal entity under laws of your country?*
                          </label>
                        </FormControl>
                      </Col>
                      <Col md="3">
                        <TextField
                          select
                          fullWidth
                          id="legalStatus_status"
                          name="legalStatus_status"
                          variant="outlined"
                          label="Answer"
                          value={formik1.values.legalStatus_status}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={
                            "form-control-alternative form-control-edit"
                          }
                          error={
                            formik1.touched.legalStatus_status &&
                            Boolean(formik1.errors.legalStatus_status)
                          }
                          helperText={
                            formik1.touched.legalStatus_status &&
                            formik1.errors.legalStatus_status
                          }
                          disabled={!isOrgOwner}
                        >
                          {console.log(
                            "legalStatus_status",
                            formik1.values.legalStatus_status
                          )}
                          {legal_entitys.map((item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                        </TextField>
                      </Col>

                      <Col md="9">
                        <TextField
                          fullWidth
                          id="legalStatus_mandate"
                          name="legalStatus_mandate"
                          variant="outlined"
                          label="Mandate"
                          multiline
                          rows={4}
                          value={formik1.values.legalStatus_mandate}
                          onChange={formik1.handleChange}
                          className={
                            "form-control-alternative form-control-edit"
                          }
                          error={
                            formik1.touched.legalStatus_mandate &&
                            Boolean(formik1.errors.legalStatus_mandate)
                          }
                          helperText={
                            formik1.touched.legalStatus_mandate &&
                            formik1.errors.legalStatus_mandate
                          }
                          disabled={!isOrgOwner}
                        ></TextField>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md="12">
                        <FormControl fullWidth>
                          <label
                            className="form-control-label"
                            htmlFor="input-institute"
                          >
                            Number of employees*
                          </label>
                        </FormControl>
                      </Col>
                      <Col md="3">
                        <TextField
                          fullWidth
                          id="numberOfOrganizationEmployee_numbers"
                          name="numberOfOrganizationEmployees_number"
                          variant="outlined"
                          label="Answer"
                          value={
                            formik1.values.numberOfOrganizationEmployees_number
                          }
                          onChange={formik1.handleChange}
                          className={
                            "form-control-alternative form-control-edit"
                          }
                          error={
                            formik1.touched
                              .numberOfOrganizationEmployees_number &&
                            Boolean(
                              formik1.errors
                                .numberOfOrganizationEmployees_number
                            )
                          }
                          helperText={
                            formik1.touched
                              .numberOfOrganizationEmployees_number &&
                            formik1.errors.numberOfOrganizationEmployees_number
                          }
                          disabled={!isOrgOwner}
                        ></TextField>
                      </Col>

                      <Col md="9">
                        <TextField
                          fullWidth
                          id="numberOfOrganizationEmployees_remark"
                          name="numberOfOrganizationEmployees_remark"
                          variant="outlined"
                          label="Remark"
                          value={
                            formik1.values.numberOfOrganizationEmployees_remark
                          }
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={
                            "form-control-alternative form-control-edit"
                          }
                          error={
                            formik1.touched
                              .numberOfOrganizationEmployees_remark &&
                            Boolean(
                              formik1.errors
                                .numberOfOrganizationEmployees_remark
                            )
                          }
                          helperText={
                            formik1.touched
                              .numberOfOrganizationEmployees_remark &&
                            formik1.errors.numberOfOrganizationEmployees_remark
                          }
                          disabled={!isCityOwner}
                        ></TextField>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md="8">
                        <FormControl fullWidth style={MbppStyles.Input}>
                          <label
                            className="form-control-label"
                            htmlFor="input-institute"
                          >
                            DRM activities performed*
                          </label>
                          <TextField
                            select
                            fullWidth
                            id="drmActivities"
                            name="drmActivities"
                            variant="outlined"
                            value={formik1.values.drmActivities}
                            onChange={formik1.handleChange}
                            SelectProps={{
                              native: true,
                            }}
                            className={
                              "form-control-alternative form-control-edit"
                            }
                            error={
                              formik1.touched.drmActivities &&
                              Boolean(formik1.errors.drmActivities)
                            }
                            helperText={
                              formik1.touched.drmActivities &&
                              formik1.errors.drmActivities
                            }
                            disabled={!isOrgOwner}
                          >
                            {drm_acts.map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </TextField>
                        </FormControl>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="12">
                        <FormControl fullWidth style={MbppStyles.Input}>
                          <label
                            className="form-control-label"
                            htmlFor="input-institute"
                          >
                            Organization Website
                          </label>
                          <TextField
                            id="emp_address"
                            name="emp_address"
                            variant="outlined"
                            value={formik1.values.website}
                            onChange={formik1.handleChange}
                            className={
                              "form-control-alternative form-control-edit"
                            }
                            error={
                              formik.touched.website &&
                              Boolean(formik.errors.website)
                            }
                            helperText={
                              formik.touched.website && formik.errors.website
                            }
                            disabled={!isOrgOwner}
                          ></TextField>
                        </FormControl>
                      </Col>
                    </Row>

                    <hr className="my-2" />
                    <h6
                      className="heading-small mb-4"
                      style={MbppStyles.SubTopic}
                    >
                      Branch Details
                    </h6>

                    <Row>
                      <Col md="8">
                        <TextField
                          fullWidth
                          id="city_name"
                          name="city_name"
                          variant="outlined"
                          label="City*"
                          value={formik1.values.city_name}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={
                            "form-control-alternative form-control-edit"
                          }
                          error={
                            formik1.touched.city_name &&
                            Boolean(formik1.errors.city_name)
                          }
                          helperText={
                            formik1.touched.city_name &&
                            formik1.errors.city_name
                          }
                          disabled={true}
                        ></TextField>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="12">
                        <FormControl fullWidth>
                          <label
                            className="form-control-label"
                            htmlFor="input-institute"
                          >
                            Does your organization have vision and mission
                            statements?
                          </label>
                        </FormControl>
                      </Col>
                      <Col md="3">
                        <TextField
                          select
                          fullWidth
                          id="visionMission_status"
                          name="visionMission_status"
                          variant="outlined"
                          label="Answer"
                          value={formik1.values.visionMission_status}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={
                            "form-control-alternative form-control-edit"
                          }
                          error={
                            formik1.touched.visionMission_status &&
                            Boolean(formik1.errors.visionMission_status)
                          }
                          helperText={
                            formik1.touched.visionMission_status &&
                            formik1.errors.visionMission_status
                          }
                          disabled={!isCityOwner}
                        >
                          {vision.map((item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                        </TextField>
                      </Col>

                      <Col md="9">
                        <TextField
                          fullWidth
                          id="visionMission_remarks"
                          name="visionMission_remarks"
                          variant="outlined"
                          label="Remark"
                          value={formik1.values.visionMission_remarks}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={
                            "form-control-alternative form-control-edit"
                          }
                          error={
                            formik1.touched.visionMission_remarks &&
                            Boolean(formik1.errors.visionMission_remarks)
                          }
                          helperText={
                            formik1.touched.visionMission_remarks &&
                            formik1.errors.visionMission_remarks
                          }
                          disabled={!isCityOwner}
                        ></TextField>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md="12">
                        <FormControl fullWidth>
                          <label
                            className="form-control-label"
                            htmlFor="input-institute"
                          >
                            Does your organization have a functional
                            organizational structure?
                          </label>
                        </FormControl>
                      </Col>
                      <Col md="3">
                        <TextField
                          select
                          fullWidth
                          id="functionalStructure_status"
                          name="functionalStructure_status"
                          variant="outlined"
                          label="Answer"
                          value={formik1.values.functionalStructure_status}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={
                            "form-control-alternative form-control-edit"
                          }
                          error={
                            formik1.touched.functionalStructure_status &&
                            Boolean(formik1.errors.functionalStructure_status)
                          }
                          helperText={
                            formik1.touched.functionalStructure_status &&
                            formik1.errors.functionalStructure_status
                          }
                          disabled={!isCityOwner}
                        >
                          {functional.map((item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                        </TextField>
                      </Col>

                      <Col md="9">
                        <TextField
                          fullWidth
                          id="functionalStructure_remarks"
                          name="functionalStructure_remarks"
                          variant="outlined"
                          label="Remark"
                          value={formik1.values.functionalStructure_remarks}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={
                            "form-control-alternative form-control-edit"
                          }
                          error={
                            formik1.touched.functionalStructure_remarks &&
                            Boolean(formik1.errors.functionalStructure_remarks)
                          }
                          helperText={
                            formik1.touched.functionalStructure_remarks &&
                            formik1.errors.functionalStructure_remarks
                          }
                          disabled={!isCityOwner}
                        ></TextField>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md="12">
                        <FormControl fullWidth>
                          <label
                            className="form-control-label"
                            htmlFor="input-institute"
                          >
                            Is there a dedicated unit(s) charged with project
                            formulation, Project implementation, Project
                            monitoring and evaluation for preparedness planning
                            and emergency response?
                          </label>
                        </FormControl>
                      </Col>
                      <Col md="3">
                        <TextField
                          select
                          fullWidth
                          id="unitProject_status"
                          name="unitProject_status"
                          variant="outlined"
                          label="Answer"
                          value={formik1.values.unitProject_status}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={
                            "form-control-alternative form-control-edit"
                          }
                          error={
                            formik1.touched.unitProject_status &&
                            Boolean(formik1.errors.unitProject_status)
                          }
                          helperText={
                            formik1.touched.unitProject_status &&
                            formik1.errors.unitProject_status
                          }
                          disabled={!isCityOwner}
                        >
                          {console.log(formik1.values.unitProject_status)}
                          {unit.map((item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                        </TextField>
                      </Col>

                      <Col md="9">
                        <TextField
                          fullWidth
                          id="unitProject_remarks"
                          name="unitProject_remarks"
                          variant="outlined"
                          label="Remark"
                          value={formik1.values.unitProject_remarks}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={
                            "form-control-alternative form-control-edit"
                          }
                          error={
                            formik1.touched.unitProject_remarks &&
                            Boolean(formik1.errors.unitProject_remarks)
                          }
                          helperText={
                            formik1.touched.unitProject_remarks &&
                            formik1.errors.unitProject_remarks
                          }
                          disabled={!isCityOwner}
                        ></TextField>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md="12">
                        <FormControl fullWidth>
                          <label
                            className="form-control-label"
                            htmlFor="input-institute"
                          >
                            Is there a dedicated unit charged with coordinating
                            identified stakeholders for emergency response?
                          </label>
                        </FormControl>
                      </Col>
                      <Col md="3">
                        <TextField
                          select
                          fullWidth
                          id="unitStakeholders_status"
                          name="unitStakeholders_status"
                          variant="outlined"
                          label="Answer"
                          value={formik1.values.unitStakeholders_status}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={
                            "form-control-alternative form-control-edit"
                          }
                          error={
                            formik1.touched.unitStakeholders_status &&
                            Boolean(formik1.errors.unitStakeholders_status)
                          }
                          helperText={
                            formik1.touched.unitStakeholders_status &&
                            formik1.errors.unitStakeholders_status
                          }
                          disabled={!isCityOwner}
                        >
                          {stakeholder.map((item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                        </TextField>
                      </Col>

                      <Col md="9">
                        <TextField
                          fullWidth
                          id="unitStakeholders_remarks"
                          name="unitStakeholders_remarks"
                          variant="outlined"
                          label="Remark"
                          value={formik1.values.unitStakeholders_remarks}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={
                            "form-control-alternative form-control-edit"
                          }
                          error={
                            formik1.touched.unitStakeholders_remarks &&
                            Boolean(formik1.errors.unitStakeholders_remarks)
                          }
                          helperText={
                            formik1.touched.unitStakeholders_remarks &&
                            formik1.errors.unitStakeholders_remarks
                          }
                          disabled={!isCityOwner}
                        ></TextField>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md="12">
                        <FormControl fullWidth style={MbppStyles.Input}>
                          <label
                            className="form-control-label"
                            htmlFor="input-institute"
                          >
                            Number of Employees
                          </label>
                        </FormControl>
                      </Col>
                      <Col md="3">
                        <TextField
                          fullWidth
                          id="city_numberOfEmployees_number"
                          name="city_numberOfEmployees_number"
                          variant="outlined"
                          label="Answer"
                          value={formik1.values.city_numberOfEmployees_number}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={
                            "form-control-alternative form-control-edit"
                          }
                          error={
                            formik1.touched.city_numberOfEmployees_number &&
                            Boolean(
                              formik1.errors.city_numberOfEmployees_number
                            )
                          }
                          helperText={
                            formik1.touched.city_numberOfEmployees_number &&
                            formik1.errors.city_numberOfEmployees_number
                          }
                          disabled={!isCityOwner}
                        ></TextField>
                      </Col>

                      <Col md="9">
                        <TextField
                          fullWidth
                          id="city_numberOfEmployees_remarks"
                          name="city_numberOfEmployees_remarks"
                          variant="outlined"
                          label="Remark"
                          value={formik1.values.city_numberOfEmployees_remarks}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={
                            "form-control-alternative form-control-edit"
                          }
                          error={
                            formik1.touched.city_numberOfEmployees_remarks &&
                            Boolean(
                              formik1.errors.city_numberOfEmployees_remarks
                            )
                          }
                          helperText={
                            formik1.touched.city_numberOfEmployees_remarks &&
                            formik1.errors.city_numberOfEmployees_remarks
                          }
                          disabled={!isCityOwner}
                        ></TextField>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md="12">
                        <FormControl fullWidth style={MbppStyles.Input}>
                          <label
                            className="form-control-label"
                            htmlFor="input-institute"
                          >
                            DRM Activities
                          </label>
                        </FormControl>
                      </Col>
                      <Col md="3">
                        <TextField
                          select
                          fullWidth
                          id="city_drmActivities_performed"
                          name="city_drmActivities_performed"
                          variant="outlined"
                          label="Answer"
                          value={formik1.values.city_drmActivities_performed}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={
                            "form-control-alternative form-control-edit"
                          }
                          error={
                            formik1.touched.city_drmActivities_performed &&
                            Boolean(formik1.errors.city_drmActivities_performed)
                          }
                          helperText={
                            formik1.touched.city_drmActivities_performed &&
                            formik1.errors.city_drmActivities_performed
                          }
                          disabled={!isCityOwner}
                        >
                          {city_drm_acts.map((item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                        </TextField>
                      </Col>

                      <Col md="9">
                        <TextField
                          fullWidth
                          id="city_drmActivities_remarks"
                          name="city_drmActivities_remarks"
                          variant="outlined"
                          label="Remark"
                          value={formik1.values.city_drmActivities_remarks}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={
                            "form-control-alternative form-control-edit"
                          }
                          error={
                            formik1.touched.city_drmActivities_remarks &&
                            Boolean(formik1.errors.city_drmActivities_remarks)
                          }
                          helperText={
                            formik1.touched.city_drmActivities_remarks &&
                            formik1.errors.city_drmActivities_remarks
                          }
                          disabled={!isCityOwner}
                        ></TextField>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md="6">
                        <TextField
                          select
                          fullWidth
                          id="city_isHeadOffice"
                          name="city_isHeadOffice"
                          variant="outlined"
                          label="Is this the head office of your organization?*"
                          value={formik1.values.city_isHeadOffice}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={
                            "form-control-alternative form-control-edit"
                          }
                          error={
                            formik1.touched.city_isHeadOffice &&
                            Boolean(formik1.errors.city_isHeadOffice)
                          }
                          helperText={
                            formik1.touched.city_isHeadOffice &&
                            formik1.errors.city_isHeadOffice
                          }
                          disabled={!isCityOwner}
                        >
                          {headoffice.map((item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                        </TextField>
                      </Col>


                      <Col md="6">
                        <TextField
                          fullWidth
                          id="emp_address"
                          name="emp_address"
                          variant="outlined"
                          label="Head-office/Branch Website"
                          value={formik1.values.city_website}
                          onChange={formik1.handleChange}
                          className={
                            "form-control-alternative form-control-edit"
                          }
                          error={
                            formik1.touched.city_website &&
                            Boolean(formik1.errors.city_website)
                          }
                          helperText={
                            formik1.touched.city_website &&
                            formik1.errors.city_website
                          }
                          disabled={!isCityOwner}
                        ></TextField>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md="12">
                        <TextField
                          fullWidth
                          id="emp_address"
                          name="emp_address"
                          variant="outlined"
                          label="Office Address"
                          value={formik1.values.city_address}
                          multiline
                          rows={4}
                          onChange={formik1.handleChange}
                          className={
                            "form-control-alternative form-control-edit"
                          }
                          error={
                            formik1.touched.city_address &&
                            Boolean(formik1.errors.city_address)
                          }
                          helperText={
                            formik1.touched.city_address &&
                            formik1.errors.city_address
                          }
                          disabled={!isCityOwner}
                        ></TextField>
                      </Col>
                    </Row>

                    <br />
                    <hr className="my-2" />
                    <div className="text-left">
                      <Button
                        variant="contained"
                        color="primary"
                        type="button"
                        onClick={(e) => {
                          history.goBack();
                        }}
                      >
                        BACK
                      </Button>
                    </div>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
