import { Avatar } from '@material-ui/core';
import Config from "config/config.json";
import * as actionType from 'constants/actionTypes';
import { Camelize } from "helper.js";
import React,{useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
// reactstrap components
import {
  Container, DropdownItem, DropdownMenu,
  DropdownToggle,
  Media, Nav, Navbar, UncontrolledDropdown
} from "reactstrap";

const AdminNavbar = (props) => {
  const userDetails = useSelector((state) => state.auth.authData);
  const dispatch = useDispatch();
  const history = useHistory();

  // handle click event of logout
  const logout = () => {
    dispatch({ type: actionType.LOGOUT });
    localStorage.clear();
    history.push('/public/login');
  };


  return (
    <>
      <Navbar className="navbar-top navbar" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {/* {props.brandText} */}
          </Link>

          {userDetails?.user ? (
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                {/* Horizontal top navigation profile */}
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <Avatar
                        className='purple'
                        alt={userDetails?.user.firstname}
                        src={(userDetails?.user.profilepic) ? (Config.SERVER_API_URL + Config.PUBLIC_PROF_PIC + userDetails?.user.profilepic) : null}>
                        {(userDetails && userDetails?.user.firstname && userDetails?.user.firstname.length > 0) ? userDetails?.user.firstname.charAt(0).toUpperCase() : ''}
                      </Avatar>
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {Camelize(userDetails?.user.firstname + ' ' + userDetails?.user.lastname)}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>

                {/* Horizontal top navigation's drop down options */}
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>
                  <DropdownItem to="/registered/user_profile" tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>My profile</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={logout}>
                    <i className="fas fa-power-off" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          ) : null}
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
