const Grid = {
  marginBottom: "20px"
}

const GridEmpty = {
  // marginBottom: "20px"
}

const Input = {
  marginBottom: "20px"
}

const Head = {
  // color: '#525F7F',
  color: '#fdba2b',
  fontWeight: "bold",
}

const Topic = {
  color: '#fdba2b',
  fontWeight: "bold",
}

const SubTopic = {
  color: '#005ae8',
  marginTop: "16px",
  marginBottom: "32px",
  fontWeight: "900!important",
}

const LogoText = {
  color: '#142237',
  fontWeight: "900!important",
}

const Question = {
  marginTop: 10,
  marginBottom: 10,
  fontWeight: '600!important'
}

const QuestionNote = {
  marginTop: 10,
  marginBottom: 10,
  fontStyle: 'italic'
}

const FeedbackError = {
  fontSize: 10,
  color: 'red'
}

const QuickNote = {
  fontSize: 10,
  marginTop: -10,
  marginBottom: 10,
}

const BottomButton = {
  minWidth: '30%',
  marginTop: 40
}

const GeneralButton = {
  minWidth: '30%',
}

export const MbppStyles = {
  Input: Input,
  Grid: Grid,
  GridEmpty: GridEmpty,
  SubTopic: SubTopic,
  Topic: Topic,
  Head: Head,
  LogoText: LogoText,
  Question: Question,
  QuestionNote: QuestionNote,
  FeedbackError: FeedbackError,
  QuickNote: QuickNote,
  BottomButton: BottomButton,
  GeneralButton: GeneralButton,
}

// Got from react native's argonTheme.COLORS
export const GlobalStylesColor = {
  mbppLogoBlue: '#142237',
  mbppPrimary: '#fdba2b',
  mbppThemePrimary: '#fdba2b',
  mbppThemeSecondary: '#005ae8',
  mbppText: '#525f7f',
  mbppLabel: '#fbb61a',
  mbppDisable: '#142237',
  mbppInputEnable: '#525F7F',
  mbppInputDisable: '#ADB5BD',
  mbppIconColor: '#525f7f',
  mbppIconColorLight: '#6d7893',
}