import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { MbppStyles } from 'constants/globalStyles';
import { dateToString } from 'helper'


import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  FormGroup,
  Form,
  Button,
  Row,
  Col,
} from "reactstrap"; // reactstrap components
import { TextField } from '@material-ui/core';
import Lodash from "lodash";

import * as XLSX from 'xlsx';

// core components
import PageHeader from "components/Headers/PageHeader.js";
import { fetchMbpCasesInFull } from "actions/mbp";


const validationSchemaAnalytics = yup.object({
  startday: yup
    .date('Enter start date')
    .required('Start date Required'),
  endday: yup
    .date('Enter end date')
    .required('Start date Required'),
});



const FindCaseIdDirectory = (props) => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [reportConfiguration, setReportConfiguration] = useState(undefined)
  const [xlsxName, setXlsxName] = useState(undefined)


  const caseReports = useSelector((state) => state.mbpCase.CaseReportsFull);
  console.log("CaseReports full 22 -------: ", caseReports);

  const formik = useFormik({
    initialValues: {
      startday: '',
      endday: '',
    },
    validationSchema: validationSchemaAnalytics,
    onSubmit: (values, onSubmitProps) => {
      console.log("formik values: ", values);

      let params = {
        "status": 'complete',
        "startday": values.startday,
        "endday": values.endday,
      }

      console.log("params", params);
      dispatch(fetchMbpCasesInFull(params));
      setXlsxName(`Id-registry_${values.startday}_${values.endday}.xlsx`)
    }
  });

  useEffect(() => {
    // console.log("caseReports -------: ", caseReports);
    if (!caseReports || caseReports.length === 0) return

    // Pre process data
    const cleanedData = []
    Lodash.map(caseReports, function mapAssessment(details) {
      cleanedData.push({
        MOH: details?.moh,
        Ward: details?.ward,
        Index_No: '',
        PHI_No: `${details?.confirmation?.area}/`,
        Name: details?.investigation?.patientRecords?.name,
        Address: details?.investigation?.patientRecords?.address,
        Age: details?.investigation?.patientRecords?.age,
        Gender: details?.confirmation?.patientRecords?.gender,
        Ethnic_Group: details?.investigation?.patientRecords?.ethnicGroup,
        Informed_By: details?.notification?.admission?.institute,
        Informed_As: details?.confirmation?.records?.diseaseNotified,
        Date_Received: dateToString(details?.createdAt),
        Date_Onset: dateToString(details?.confirmation?.dateOfOnset),
        Date_Hospitalization: dateToString(details?.confirmation?.dateOfHospitalization),
        Date_Discharge: dateToString(details?.investigation?.observations?.dateDischarge),
        Date_Confirmed: dateToString(details?.confirmation?.dateOfConfirmation),
        Confirmed_As: Lodash.isArray(details?.confirmation?.records?.diseaseConfirmed) ? details?.confirmation?.records?.diseaseConfirmed[0] : '',
        Contact_No: details?.notification?.patient?.telephoneMobile ?? `${details?.notification?.patient?.telephoneHome}`,
        Epid_No: details.mohWorkflowid,
        MOH_No: details.mohWorkflowid,
        MOH_Register: details?.confirmation?.registrationNo,
        Location: Lodash.isArray(details?.notification?.patient?.location) ? `${details?.notification?.patient?.location[0]?.gps?.lat}, ${details?.notification?.patient?.location[0]?.gps?.lon}` : '',
        Remark: ``,
      })
      return true
    })

    console.log('cleanedData -------: ', cleanedData)
    setReportConfiguration(cleanedData)

  }, [caseReports])


  const exportDataToXlsx = () => {

    let header = [
      "MOH",
      "Ward",
      "Index_No",
      "PHI_No",
      "Name",
      "Address",
      "Age",
      "Gender",
      "Ethnic_Group",
      "Informed_By",
      "Informed_As",
      "Date_Received",
      "Date_Onset",
      "Date_Hospitalization",
      "Date_Discharge",
      "Date_Confirmed",
      "Confirmed_As",
      "Contact_No",
      "Epid_No",
      "MOH_No",
      "MOH_Register",
      "Location",
      "Remark",
    ];
    const ws = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, [header]);
    XLSX.utils.sheet_add_json(ws, reportConfiguration, { origin: 'A2', skipHeader: true });
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    XLSX.writeFile(wb, `${xlsxName}`);
  }

  return (
    <>
      <PageHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Params*/}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Selection Details</h3>
              </CardHeader>
              <Form
                role="form"
                onSubmit={formik.handleSubmit}
                onReset={formik.handleReset}>
                <CardBody>
                  <FormGroup className="mb-3">

                    <Row>
                      <Col xs="1" sm="1" className="px-3 pt-3 text-right">
                        <i className="fas fa-calendar-day" />
                      </Col>
                      <Col xs="10" sm="10">
                        <Row>
                          <Col xs="12" sm="6">
                            <TextField
                              fullWidth
                              type="date"
                              id="startday"
                              name="startday"
                              label="Start Date*"
                              variant="outlined"
                              format={'DD/MM/YYYY'}
                              value={formik.values.startday}
                              onChange={formik.handleChange}
                              style={MbppStyles.Input}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={formik.touched.startday && Boolean(formik.errors.startday)}
                              helperText={formik.touched.startday && formik.errors.startday}
                            />
                          </Col>
                          <Col xs="12" sm="6">
                            <TextField
                              fullWidth
                              type="date"
                              id="endday"
                              name="endday"
                              label="End Date*"
                              variant="outlined"
                              format={'DD/MM/YYYY'}
                              value={formik.values.endday}
                              onChange={formik.handleChange}
                              style={MbppStyles.Input}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={formik.touched.endday && Boolean(formik.errors.endday)}
                              helperText={formik.touched.endday && formik.errors.endday}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                  </FormGroup>
                </CardBody>
                <CardFooter className="py-2 text-right">
                  <Row>
                    <Col sm="12" className="text-right">
                      <Button className="my-2" variant="contained" color="primary" type="reset">
                        Clear
                      </Button>
                      <Button className="my-2" variant="contained" color="primary" type="submit">
                        Visualize
                      </Button>
                    </Col>
                  </Row>
                </CardFooter>
              </Form>
            </Card>
          </div>
        </Row>

        <br />

        <Row>
          <div className="col">
            <Card className="shadow">
              <CardBody style={{ padding: 5 }}>
                <FormGroup className="mb-3">
                  <Row>
                    <Col xs="1" sm="1" className="px-3 pt-3 text-right">
                      <i className="ni ni-align-left-2" />
                    </Col>
                    <Col sm="10" className="pt-3">
                      {xlsxName && (!caseReports || caseReports.length === 0) && (
                        <div>No data. Please try again with a different dates.</div>)}
                      {reportConfiguration && reportConfiguration.length > 1 && (
                        <div>{`${reportConfiguration.length} records available`}</div>
                      )}
                    </Col>
                  </Row>
                </FormGroup>
              </CardBody>

              {reportConfiguration && reportConfiguration.length > 1 && (
                <CardFooter className="py-2 text-right">
                  <Row>
                    <Col sm="12" className="text-right">
                      <Button className="my-2" variant="contained" color="success" onClick={() => { exportDataToXlsx() }}>
                        Download data
                      </Button>
                    </Col>
                  </Row>
                </CardFooter>
              )}

            </Card>
          </div>
        </Row>
      </Container >
    </>
  );
};

export default FindCaseIdDirectory;
