import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import Chart from "chart.js";// javascipt plugin for creating charts
import { Line, Bar } from "react-chartjs-2";// react plugin used to create charts
import { MbppStyles } from 'constants/globalStyles';
import {
  setInitData,
  chartOptions,
  parseOptions,
  getChart1,
  getChart2,
} from "variables/charts.js";// core components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  FormGroup,
  Form,
  Button,
  Row,
  Col,
} from "reactstrap";// reactstrap components
import { TextField, FormControlLabel, Switch, Grid } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { fetchStatSummery } from 'actions/analytics';
import { AnalyticStatus } from "helper.js"
import { basicInformation } from "globalGeoInformation"
import {
  STAT_SUMMERY
} from 'constants/actionTypes';
import Lodash from "lodash";
import Moment from "moment";
import PageHeader from "components/Headers/PageHeader.js";// core components
import { isTemplateLiteralTypeSpan } from "typescript";

const yearOptions_fix = ["2015", "2016", "2017", "2018", "2019", "2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030"];
const monthOptions = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]


const validationSchemaAnalytics = yup.object({
  status: yup.string().required('Status is Required'),
  year: yup.string().required('Year is Required'),
  month: yup.mixed().oneOf(monthOptions).required('Month is Required'),
});


const optionsGrid = {
  scales: {
    xAxes: [
      {
        display: true,
        scaleLabel: {
          display: true,
          labelString: "Dates",
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          color: "#142237",
          zeroLineColor: "#142237",
        },
        ticks: {
          callback: function (value) {
            if (!(value % 1)) {
              return value;
            }
          },
          beginAtZero: true,
          steps: 1,
          stepValue: 1,
          // max: 5.2,
        },
        scaleLabel: {
          display: true,
          labelString: "Case count",
        },
      },
    ],
  },
};


const Analytics = (props) => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [lineChart, setLineChart] = useState({});
  const [showChart, setShowChart] = useState(false);
  const [lastValues, setLastValues] = useState({});
  const [updateCounter, setUpdateCounter] = useState(0);
  const [yearOptions, setYearOptions] = useState([Moment().format('YYYY')]);


  useEffect(() => {

    let years = [];
    for (let index = 2015; index <= Moment().format('YYYY'); index++) {
      years.push(String(index));
    }
    setYearOptions(years);
  }, [])

  // get state Analytics details
  const StatSum = useSelector((state) => state.analytics.StatSum?.stats);

  useEffect(() => {
    console.log("StatSum: ", StatSum);

    if (formik.values.group === 'daily') {
      processDays(StatSum);
    }
    else {
      processMonths(StatSum);
    }

  }, [StatSum, updateCounter])

  const formik = useFormik({
    initialValues: {
      reqtype: STAT_SUMMERY,
      order: 'asc',
      status: AnalyticStatus[3],
      group: 'daily',
      year: Moment().format('YYYY'),
      month: Moment().format('MMMM'),
      moh: monthOptions[0],
    },
    validationSchema: validationSchemaAnalytics,
    onSubmit: (values, onSubmitProps) => {
      // console.log("formik values: ", values);

      let yearMonthDate = Moment(values.year + values.month + "01", "YYYYMMMDD");
      let params = {
        "reqtype": STAT_SUMMERY,
        "startday": yearMonthDate.startOf((formik.values.group === 'daily') ? 'month' : 'year').format('YYYY-MM-DD'),
        "endday": yearMonthDate.endOf((formik.values.group === 'daily') ? 'month' : 'year').format('YYYY-MM-DD'),
        "timeline": formik.values.group === 'daily' ?
          {
            "year": true,
            "month": true,
            "day": true
          }
          :
          {
            "year": true,
            "month": true,
          }
        ,
        "group": {
          "region": {
            "moh": "true"
          }
        },
        "order": values.order,
        "status": values.status.toLowerCase(),
        "selectedMoh": values.moh
      }

      // console.log("params", params);
      let diffWithLast = Lodash.reduce(params, (result, value, key) => Lodash.isEqual(value, lastValues[key]) ? result : result.concat(key), []);
      // console.log("diffWithLast: ", diffWithLast);
      setLastValues(params);
      // Only MOH has been changed, hence no need to bring the data again
      if (diffWithLast.length === 1 && diffWithLast[0] === "selectedMoh") {
        console.log("Local update");
        setUpdateCounter(updateCounter + 1);
      }
      else {
        dispatch(fetchStatSummery(params, history));
      }
      setShowChart(true);
    }
  });


  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const processDays = (StatSum) => {
    if (StatSum && StatSum.length > 0) {
      // console.log("StatSum1: ", StatSum);

      let yearMonthDate = Moment(formik.values.year + formik.values.month + "01", "YYYYMMMDD");
      var mapDailyCount = []

      // console.log("yearMonthDate: ", yearMonthDate);

      StatSum.forEach(item => {
        mapDailyCount = [...mapDailyCount, {
          "year": item._id.timeline.year,
          "month": item._id.timeline.month,
          "day": item._id.timeline.day,
          "moh": item._id.region.moh,
          "count": item.count
        }]
      });

      // console.log("mapDailyCount: ", mapDailyCount);

      var fmtXLabel = []
      var fmtDailyCount = []

      for (let i = 1; i <= yearMonthDate.daysInMonth(); i++) {
        fmtXLabel = [...fmtXLabel, yearMonthDate.format("MMM. ") + i]

        var selItem = [];
        if (formik.values.moh && formik.values.moh.length > 10) {
          // console.log("moh NOT empty ");
          selItem = Lodash.filter(mapDailyCount, { 'day': i, 'moh': formik.values.moh })
        }
        else {
          // console.log("moh empty ");
          selItem = Lodash.filter(mapDailyCount, { 'day': i })
        }

        // console.log("selItem: ", selItem);

        if (selItem && selItem.length > 0) {
          let subTotal = 0;
          selItem.forEach(item => {
            subTotal = subTotal + item.count;
          });
          fmtDailyCount = [...fmtDailyCount, subTotal]
        }
        else {
          fmtDailyCount = [...fmtDailyCount, 0]
        }

      }

      setLineChart({
        xLabels: fmtXLabel,
        yValues: fmtDailyCount
      })

      // console.log("fmtXLabel: ", fmtXLabel);
      // console.log("fmtDailyCount: ", fmtDailyCount);
      // console.log("fmtHideLabel: ", fmtHideLabel);

    }
    else {
      setLineChart({});
    }
  }

  const processMonths = (StatSum) => {
    if (StatSum && StatSum.length > 0) {
      // console.log("StatSum1: ", StatSum);

      var mapDailyCount = []

      // console.log("yearMonthDate: ", yearMonthDate);

      StatSum.forEach(item => {
        mapDailyCount = [...mapDailyCount, {
          "year": item._id.timeline.year,
          "month": item._id.timeline.month,
          "moh": item._id.region.moh,
          "count": item.count
        }]
      });

      // console.log("mapDailyCount: ", mapDailyCount);

      var fmtXLabel = []
      var fmtDailyCount = []

      for (let i = 1; i <= 12; i++) {
        fmtXLabel = [...fmtXLabel, monthOptions[i - 1]]

        var selItem = [];
        if (formik.values.moh && formik.values.moh.length > 10) {
          // console.log("moh NOT empty ");
          selItem = Lodash.filter(mapDailyCount, { 'month': i, 'moh': formik.values.moh })
        }
        else {
          // console.log("moh empty ");
          selItem = Lodash.filter(mapDailyCount, { 'month': i })
        }

        // console.log("selItem: ", selItem);

        if (selItem && selItem.length > 0) {
          let subTotal = 0;
          selItem.forEach(item => {
            subTotal = subTotal + item.count;
          });
          fmtDailyCount = [...fmtDailyCount, subTotal]
        }
        else {
          fmtDailyCount = [...fmtDailyCount, 0]
        }
      }

      setLineChart({
        xLabels: fmtXLabel,
        yValues: fmtDailyCount
      })

      // console.log("fmtXLabel: ", fmtXLabel);
      // console.log("fmtDailyCount: ", fmtDailyCount);
      // console.log("fmtHideLabel: ", fmtHideLabel);

    }
    else {
      setLineChart({});
    }
  }



  return (
    <>
      <PageHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table School */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Selection Details</h3>
              </CardHeader>
              <Form
                role="form"
                onSubmit={formik.handleSubmit}
                onReset={formik.handleReset}>
                <CardBody>
                  <FormGroup className="mb-3">

                    <Row>
                      <Col xs="1" sm="1" className="px-3 pt-3 text-right">
                        <i className="fas fa-calendar-day" />
                      </Col>
                      <Col xs="10" sm="10">
                        <Row>
                          <Col xs="12" sm="6">
                            <TextField
                              select
                              fullWidth
                              id="group"
                              name="group"
                              label="Time Line*"
                              variant="outlined"
                              value={formik.values.group}
                              onChange={formik.handleChange}
                              style={MbppStyles.Input}
                              SelectProps={{
                                native: true,
                              }}
                              error={formik.touched.group && Boolean(formik.errors.group)}
                              helperText={formik.touched.group && formik.errors.group}
                            >
                              <option value='daily'>Month</option>
                              <option value='monthly'>Year</option>
                            </TextField>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs="1" sm="1" className="px-3 pt-3 text-right">
                        <i className="fas fa-calendar-day" />
                      </Col>
                      <Col xs="10" sm="10">
                        <Row>
                          <Col xs="12" sm="6">
                            <TextField
                              select
                              fullWidth
                              id="year"
                              name="year"
                              label="Year*"
                              variant="outlined"
                              value={formik.values.year}
                              onChange={formik.handleChange}
                              style={MbppStyles.Input}
                              SelectProps={{
                                native: true,
                              }}
                              error={formik.touched.year && Boolean(formik.errors.year)}
                              helperText={formik.touched.year && formik.errors.year}
                            >
                              {/* <option value=''></option> */}
                              {yearOptions.map((item) => (
                                <option key={item} value={item}>{item}</option>
                              ))}
                            </TextField>
                          </Col>
                          {formik.values.group === "daily" ?
                            <Col xs="12" sm="6">
                              <TextField
                                select
                                fullWidth
                                id="month"
                                name="month"
                                label="Month*"
                                variant="outlined"
                                value={formik.values.month}
                                onChange={formik.handleChange}
                                style={MbppStyles.Input}
                                SelectProps={{
                                  native: true,
                                }}
                                error={formik.touched.month && Boolean(formik.errors.month)}
                                helperText={formik.touched.month && formik.errors.month}
                              >
                                {/* <option value=''></option> */}
                                {monthOptions.map((item) => (
                                  <option key={item} value={item}>{item}</option>
                                ))}
                              </TextField>
                            </Col>
                            : null
                          }
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs="1" sm="1" className="px-3 pt-3 text-right">
                        <i className="fas fa-info-circle" />
                      </Col>
                      <Col xs="10" sm="10">
                        <Row>
                          <Col xs="12" sm="6">
                            <TextField
                              select
                              fullWidth
                              id="moh"
                              name="moh"
                              label="MOH"
                              variant="outlined"
                              value={formik.values.moh}
                              onChange={formik.handleChange}
                              style={MbppStyles.Input}
                              SelectProps={{
                                native: true,
                              }}
                              error={formik.touched.moh && Boolean(formik.errors.moh)}
                              helperText={formik.touched.moh && formik.errors.moh}
                            >
                              <option value=''></option>
                              {Object.entries(basicInformation.mohIds).map(([key, value]) =>
                              (
                                <option key={key} value={key}>{value}</option>
                              ))}
                            </TextField>
                          </Col>
                          <Col xs="12" sm="6">
                            <TextField
                              select
                              fullWidth
                              id="status"
                              name="status"
                              label="Status*"
                              variant="outlined"
                              value={formik.values.status}
                              onChange={formik.handleChange}
                              style={MbppStyles.Input}
                              SelectProps={{
                                native: true,
                              }}
                              error={formik.touched.status && Boolean(formik.errors.status)}
                              helperText={formik.touched.status && formik.errors.status}
                            >
                              {/* <option value=''></option> */}
                              {AnalyticStatus.map((item) => (
                                <option key={item} value={item}>{item}</option>
                              ))}
                            </TextField>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                  </FormGroup>
                </CardBody>
                <CardFooter className="py-2 text-right">
                  <Row>
                    <Col sm="12" className="text-right">
                      <Button className="my-2" variant="contained" color="primary" type="reset">
                        Clear
                      </Button>
                      <Button className="my-2" variant="contained" color="primary" type="submit">
                        Visualize
                      </Button>
                    </Col>
                  </Row>
                </CardFooter>
              </Form>
            </Card>
          </div>
        </Row>


        {showChart && (
          <>
            <br />
            <Row>
              <div className="col">
                <Card className="bg-gradient-default shadow">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h6 className="text-uppercase text-light ls-1 mb-1">
                          Graph Details
                        </h6>
                        <h2 className="text-white mb-0">{Lodash.isEmpty(lineChart) ? "No Data to Show" : "Case Distribution"}</h2>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {/* Chart */}
                    {Object.keys(lineChart).length > 0 && (
                      <div className="chart">
                        <Line
                          data={{
                            labels: lineChart.xLabels,
                            datasets: [
                              {
                                data: lineChart.yValues,
                                label: "Cases",
                                fill: false,
                                backgroundColor: "#142237",
                                borderColor: "#142237",
                                pointBorderWidth: 5,

                                pointBorderColor: "white",
                                pointStyle: "circle",
                                pointRadius: 1,
                                pointBackgroundColor: "rgb(255, 255, 255)",
                                borderWidth: 0,
                              },
                            ],
                          }}
                          options={optionsGrid}
                          getDatasetAtEvent={(e) => console.log(e)}
                        />
                      </div>
                    )}
                  </CardBody>
                </Card>
              </div>
            </Row>
          </>
        )}
      </Container>
    </>
  );
};

export default Analytics;
