import { Grid } from '@material-ui/core';
import { MbppStyles } from 'constants/globalStyles';
import React from "react";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


// reactstrap components

// reactstrap components
import {
  Card,
  CardBody,
  Col, Form, FormGroup,
  Row,
  Container,
} from "reactstrap";


const PrivacyPolicy = () => {

  return (
    <>
      <div className="main-content" style={{ backgroundColor: '#fdba2b' }}>
        {/* ToDo: explore */}
        {/* <AuthNavbar /> */}
        <div className="header py-6 py-lg-6"
          style={{
            backgroundImage: "url(" + require("../../../assets/img/theme/img_bg.jpg").default + ")",
            backgroundSize: "cover",
            backgroundPosition: "center top",
          }}

        >
          {/* Mask */}
          {/*bg-gradient-yellow*/}
          <span className="mask opacity-2" />
          <Container>
            <div className="header-body text-center mb-5">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100" style={{ marginBottom: '-2px', }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center" >



            <Col lg='10' md='12'>
              <Card className="bg-secondary shadow border-1">
                <CardBody className="px-lg-4 py-lg-4">

                  <div className="heading-title text-center mb-4" style={MbppStyles.Topic}>
                    <span>Privacy Policy</span>
                  </div>
                  <br />
                  <p>
                    Thank you for using the <b>Mo-Buzz+</b> / <b>Mo-Buzz+ Public</b> (“the App”). We are committed to protecting your privacy and providing transparency about how we collect, use, and protect your data. This Privacy Policy outlines the information we collect, why we collect them, and how we use them.
                  </p>


                  <Form role="form">
                    <FormGroup className="mb-3 ">

                      <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                        <Grid item xs={12} sm={12}>
                          <span>
                            <b>
                              1. Data Collection and Usage
                            </b>
                            <p>
                              Data collected from the App are detailed below. All data will only be used for the sole purpose of research.
                            </p>
                            <b>
                              1.1. Personal Information
                            </b>
                            <p>
                              When you register an account with the App, we may collect personal information such as your name, contact number, and professional credentials. This data is used to identify you, manage your account, and provide access to app features.
                            </p>
                            <b>
                              1.2. Reported information
                            </b>
                            <p>
                              Dengue surveillance data entry and case-reporting. For the purpose of documentation, validation, and analysis.
                            </p>
                            <b>
                              1.3 Location Data
                            </b>
                            <p>
                              To support dengue surveillance efforts, the App may request access to your device’s location data. This enables us to provide relevant information and track dengue outbreaks more effectively. You can choose to enable or disable location services through your device’s settings at any time.
                            </p>
                            <p>
                              Mo-Buzz+ Public app, collects location data to enable dengue high risk area notify even when the app is closed or not in use. You can turn on or off this tracking anytime.
                            </p>
                            {/* <b>
                              1.3 Log Data
                            </b>
                            <p>
                              The App collects standard log data, including IP addresses, device information, app usage data, and other statistics. These information are used to improve the App, identify and fix bugs, and enhance user experience.
                            </p> */}

                            <br />
                            <b>
                              2. Purpose of Data Collection
                            </b>
                            <p>
                              We collect and use your data for the following purposes:
                            </p>
                            <b>
                              2.1 Dengue Surveillance
                            </b>
                            <p>
                              Your location data and reports aid in monitoring and managing dengue outbreaks, enabling more effective public health responses.
                            </p>
                            <b>
                              2.2 Account Management
                            </b>
                            <p>
                              Personal information help to verify your identity and manage your account for access to the App’s features.
                            </p>

                            <br />
                            <b>
                              3. Third-Party Service Providers
                            </b>
                            <p>
                              Only dengue-related anonymous data may be shared with Nanyang Technological University for the sole purpose of research. No user data will be shared.
                            </p>

                            <br />
                            <b>
                              4. User Consent
                            </b>
                            <p>
                              By using the Mo-Buzz+ / Mo-Buzz+ Public app, you agree to the collection and use of your data as described in this Privacy Policy. Your consent allows us to provide you with our services and support dengue surveillance efforts effectively.
                            </p>

                            <br />
                            <b>
                              5. Data Security Measures
                            </b>
                            <p>
                              Your data is stored securely, and access is restricted to authorized personnel only. These measures are to protect your information from unauthorized access, disclosure, or misuse.
                            </p>

                            <br />
                            <b>
                              6. Data Retention
                            </b>
                            <p>
                              We retain your personal information for as long as necessary to fulfil the purposes outlined in this Privacy Policy and comply with legal obligations.
                            </p>

                            <br />
                            <b>
                              7. User Rights
                            </b>
                            <p>
                              You have the right to access and review the personal information we hold about you. If any information is inaccurate or incomplete, you can request for corrections. Additionally, you may withdraw your consent, object to processing, and request the deletion or restriction of your data (however, this policy may not apply for the health workers of Colombo Municipal Council).
                            </p>

                            <br />
                            <b>
                              8. Policy Updates
                            </b>
                            <p>
                              We may update this Privacy Policy to reflect changes in our data practices or legal requirements. We will notify you about significant changes through the App or the provided contact information
                            </p>

                            <br />
                            <b>
                              9. Contact Information
                            </b>
                            <p>
                              If you have any questions, concerns, or requests regarding this Privacy Policy or the data we hold about you, please contact us at HealthCommWKWSCI@gmail.com.
                            </p>
                            <p>
                              By using the Mo-Buzz+ / Mo-Buzz+ Public app, you agree to the terms outlines in this Privacy Policy. We are dedicated to supporting public health efforts in dengue surveillance in Colombo while protecting your privacy.
                            </p>

                            <br />

                          </span>
                        </Grid>
                      </Grid>
                    </FormGroup>
                  </Form>
                </CardBody>
              </Card>
              <br />
              <br />
              <br />
              <br />

            </Col>



          </Row>
        </Container>
      </div>

    </>
  );
};

export default PrivacyPolicy;
