// import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Tooltip } from "@material-ui/core";
import PublicOutlinedIcon from "@material-ui/icons/PublicOutlined";
import VpnLockOutlinedIcon from "@material-ui/icons/VpnLockOutlined";
import { fetchMapBuildings, updateMapBuildings } from "actions/map";
import PageHeader from "components/Headers/PageHeader.js"; // core components
import { GlobalStylesColor } from "constants/globalStyles";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Badge, Card, CardHeader, Container, Row, Table } from "reactstrap"; // reactstrap components



const ResourceRow = ({ resource, editClick, statusClick, viewClick, createClick }) => (
  // CATEGORY |	TITLE |	DESCRIPTION |	FILE |	PUBLISHED |	ACTIONS
  <tr>
    <td style={{ whiteSpace: "break-spaces" }}>{resource.category}</td>
    <td style={{ whiteSpace: "break-spaces", color: GlobalStylesColor.mbppThemePrimary }}>{resource.title}</td>
    <td style={{ whiteSpace: "break-spaces" }}>{resource.description}</td>
    <td>
      {resource.isShared ? (
        <Badge color="" className="badge-dot">
          <i className="bg-success" />
          YES
        </Badge>
      ) : (
        <Badge color="" className="badge-dot mr-4">
          <i className="bg-warning" />
          NO
        </Badge>
      )}
    </td>
    <td className="text-right">
      <div className="row">
        <Tooltip title="Edit" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              editClick(e, resource);
            }}
          >
            <i className="far fa-edit" />
          </div>
        </Tooltip>
        <Tooltip title="Change publish status" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              statusClick(e, resource);
            }}
          >
            {resource.isShared ? <PublicOutlinedIcon /> : <VpnLockOutlinedIcon />}
          </div>
        </Tooltip>
        <Tooltip title="View locations" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              viewClick(e, resource);
            }}
          >
            <i className="far fa-eye" />
          </div>
        </Tooltip>
      </div>
    </td>
  </tr>
);

// export function
const MapBuildings = () => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // get forum topics
  const mapAll = useSelector((state) => state.map.buildings);

  useEffect(() => {
    dispatch(fetchMapBuildings());
  }, []);

  const handleStatusClick = (e, resource) => {
    e.preventDefault();
    let params = {
      geoItemId: resource._id,
      isShared: !resource.isShared
    }
    dispatch(updateMapBuildings(params, null));
  };

  const handleViewClick = (e, resource) => {
    e.preventDefault();
    history.push({ pathname: "/registered/map_location_details", state: { mapLocations: resource.markers, title: resource.title } });
  };

  const handleEditClick = (e, resource, type) => {
    e.preventDefault();
    history.push({ pathname: "/registered/map_building_details", state: { map: resource } });
  };

  const handleCreateClick = (e, resource, type) => {
    e.preventDefault();
  };


  return (
    <>
      <PageHeader />

      {/* Page content */}
      <Container className="mt--9" fluid>
        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <div className="mb-xl-0 col-11">
                    <h3 className="mb-0">Categories of Places</h3>
                    <h6 className="mb-0"></h6>
                  </div>
                  <div className="mb-xl-0">
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Category</th>
                    <th scope="col">Title</th>
                    <th scope="col">Description</th>
                    <th scope="col">Published</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr></tr>
                  {mapAll.map((resource, i) => {
                    return <ResourceRow
                      key={i}
                      resource={resource}
                      editClick={handleEditClick}
                      statusClick={handleStatusClick}
                      viewClick={handleViewClick}
                      createClick={handleCreateClick}
                    />;
                  })}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default MapBuildings;
