import {
  Grid, TextField
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { updateMapBuildings } from "actions/map";
import PageHeader from "components/Headers/PageHeader.js";
import { MbppStyles } from "constants/globalStyles";
import { Formik } from "formik";
import Lodash from "lodash";
import React, { useEffect, useRef, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button, Card,
  CardBody,
  CardFooter,
  CardHeader, Col,
  Container, Form, Row
} from "reactstrap"; // reactstrap components
import * as Yup from "yup";



const validateSchema = Yup.object({
  geos: Yup
    .array()
    .of(Yup
      .object({
        key: Yup
          .number("Key is required")
          .required("Key is required"),
        title: Yup
          .string("Title is required")
          .required("Title is required"),
        description: Yup
          .string("Description should be string value"),
        longitude: Yup
          .number("Location longitude should be number")
          .required("Location longitude is required"),
        latitude: Yup
          .number("Location latitude should be number")
          .required("Location latitude is required"),
      })
    ),
});

const SurveyCreateTemplate = (props) => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();

  const [currentGeos, setCurrentGeos] = useState([{ key: "", title: "", description: "", longitude: 0, latitude: 0 }]);
  const [currentMap, setCurrentMap] = useState({});
  const [refreshCounter, setRefreshCounter] = useState(0);

  // To update fomik values
  const formRef = useRef();

  // get the details passed by the parameter to the page. Afterwards, process needed information
  useEffect(() => {
    console.log("---------------> called effect1");

    let map = props.location.state?.map;
    console.log("---------------> called effect1: ", map);
    if (!Lodash.isEmpty(map)) {
      // if (formRef.current) {
      //   formRef.current.setFieldValue("geos", currentGeos, false);
      // }
      setCurrentMap(map);
      if (!Lodash.isEmpty(map.markers)) {

        //pre-process the markers to match with formik
        let updateMarkers = [];
        map.markers.forEach((item) => {
          updateMarkers.push({
            key: item.key,
            title: item.title,
            description: item.description,
            longitude: item.coordinate.longitude,
            latitude: item.coordinate.latitude
          })
        })

        if (formRef.current) {
          setCurrentGeos(updateMarkers);
          formRef.current.setFieldValue("geos", updateMarkers, false);
        }
        setRefreshCounter(refreshCounter + 1);
      }
    }
  }, []);


  // handle change event of comments
  const handleEntryChange = (e, index) => {
    const { name, value } = e.target;
    // console.log("e: ", e);
    console.log("e target: ", e.target);
    // console.log("index: ", index);
    let list = [...currentGeos];
    list[index][name] = value;
    if (formRef.current) {
      setCurrentGeos(list);
      formRef.current.setFieldValue("geos", list, false);
    }
    setRefreshCounter(refreshCounter + 1);
  };

  // handle click event of the Remove button of comment
  const handleEntryRemoveClick = (index) => {
    console.log("index: ", index);
    let list = [...currentGeos];
    list.splice(index, 1);

    if (formRef.current) {
      setCurrentGeos(list);
      formRef.current.setFieldValue("geos", list, false);
    }
    setRefreshCounter(refreshCounter + 1);
  };

  // handle click event of the Add button of comment
  const handleEntryAddClick = () => {
    let list = currentGeos;
    list.push({ key: "", title: "", description: "", longitude: 0, latitude: 0 });
    console.log("add line");

    if (formRef.current) {
      setCurrentGeos(list);
      formRef.current.setFieldValue("geos", list, true);
    }
    setRefreshCounter(refreshCounter + 1);
  };

  return (
    <>
      <PageHeader />

      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* create survey questions */}
        <Row>
          <div className="col">
            <Card className="shadow">
              {/* survey header */}
              <CardHeader className="border-0">
                <div className="row">
                  <div className="mb-xl-0 col-10">
                    <h3 className="mb-0">{props.location.state?.map?.title}</h3>
                    <h6 className="mb-0">Manage geo objects</h6>
                  </div>
                </div>
              </CardHeader>

              {/* question content */}
              <CardBody style={{}}>

                <Formik
                  initialValues={{
                    geos: [
                      {
                        key: "",
                        title: "",
                        description: "",
                        longitude: 0,
                        latitude: 0
                      },
                    ],
                  }}
                  validationSchema={validateSchema}
                  // validateOnChange={false}
                  // validateOnBlur={false}
                  onSubmit={(values, actions) => {
                    console.log("values", values);
                    // actions.resetForm();
                    let updatedMarkers = [];

                    (currentGeos.length > 0) && currentGeos.forEach((item) => {
                      updatedMarkers.push({
                        color: "#FF0000",
                        key: item.key,
                        title: item.title,
                        description: item.description,
                        coordinate: {
                          longitude: item.longitude,
                          latitude: item.latitude
                        }
                      })
                    })

                    // let updatedMap = currentMap;
                    // updatedMap["geoItemId"] = updatedMap._id;
                    // updatedMap["markers"] = updatedMarkers;
                    // console.log("submit values", updatedMap);

                    let updatedMap = {};
                    updatedMap["geoItemId"] = currentMap._id;
                    updatedMap["markers"] = updatedMarkers;
                    console.log("submit values", updatedMap);
                    dispatch(updateMapBuildings(updatedMap, history)); // since entry exist, it's always an update
                  }}
                  innerRef={formRef}
                >
                  {(props) => (
                    <Form
                      id={refreshCounter}
                      role="form"
                      onSubmit={props.handleSubmit}
                      onReset={props.handleReset}
                    >

                      {/* {currentGeos && props.values.geos && props.values.geos.map((x, i) => { */}
                      {currentGeos && currentGeos.map((x, i) => {
                        return (
                          <>
                            <hr className="mt-2" />
                            <Row style={{ /*backgroundColor: (i % 2 === 0) ? "#ADB5BD" : "#fff"*/ }}>
                              <Col lg="1"></Col>
                              <Col lg="11">

                                <Grid
                                  key={i}
                                  container
                                  // spacing={1}
                                  alignItems="center"
                                  style={{ marginBottom: 20 }}
                                >
                                  <Grid item xs={3} sm={3}>
                                    <TextField
                                      fullWidth
                                      label={"Key*"}
                                      name="key" // this is to pass title through event-->e
                                      variant="outlined"
                                      value={x?.key}
                                      onChange={(e) => handleEntryChange(e, i)}
                                      onBlur={(e) => handleEntryChange(e, i)}
                                      style={MbppStyles.Question}
                                      className={"form-control-alternative form-control-edit"}
                                      error={
                                        props.touched &&
                                        props.touched.geos &&
                                        props.touched.geos[i]?.key &&
                                        props.errors.geos &&
                                        Boolean(props.errors.geos[i]?.key)
                                      }
                                      helperText={
                                        props.errors.geos &&
                                          props.touched.geos &&
                                          props.touched.geos[i]?.key
                                          ? props.errors.geos[i]?.key : ""
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={4} sm={4}>
                                    <TextField
                                      fullWidth
                                      label={"Latitude*"}
                                      name="latitude" // this is to pass title through event-->e
                                      variant="outlined"
                                      value={x?.latitude}
                                      onChange={(e) => handleEntryChange(e, i)}
                                      onBlur={(e) => handleEntryChange(e, i)}
                                      style={MbppStyles.Question}
                                      className={"form-control-alternative form-control-edit"}
                                      error={
                                        props.touched &&
                                        props.touched.geos &&
                                        props.touched.geos[i]?.latitude &&
                                        props.errors.geos &&
                                        Boolean(props.errors.geos[i]?.latitude)
                                      }
                                      helperText={
                                        props.errors.geos &&
                                          props.touched.geos &&
                                          props.touched.geos[i]?.latitude
                                          ? props.errors.geos[i]?.latitude : ""
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={4} sm={4}>
                                    <TextField
                                      fullWidth
                                      label={"Longitude*"}
                                      name="longitude" // this is to pass title through event-->e
                                      variant="outlined"
                                      value={x?.longitude}
                                      onChange={(e) => handleEntryChange(e, i)}
                                      onBlur={(e) => handleEntryChange(e, i)}
                                      style={MbppStyles.Question}
                                      className={"form-control-alternative form-control-edit"}
                                      error={
                                        props.touched &&
                                        props.touched.geos &&
                                        props.touched.geos[i]?.longitude &&
                                        props.errors.geos &&
                                        Boolean(props.errors.geos[i]?.longitude)
                                      }
                                      helperText={
                                        props.errors.geos &&
                                          props.touched.geos &&
                                          props.touched.geos[i]?.longitude
                                          ? props.errors.geos[i]?.longitude : ""
                                      }
                                    />
                                  </Grid>


                                  <Grid item xs={5} sm={5}>
                                    <TextField
                                      fullWidth
                                      label={"Title*"}
                                      name="title" // this is to pass title through event-->e
                                      variant="outlined"
                                      value={x?.title}
                                      onChange={(e) => handleEntryChange(e, i)}
                                      onBlur={(e) => handleEntryChange(e, i)}
                                      style={MbppStyles.Question}
                                      className={"form-control-alternative form-control-edit"}
                                      error={
                                        props.errors.geos &&
                                        props.touched.geos &&
                                        props.touched.geos[i]?.title &&
                                        Boolean(props.errors.geos[i]?.title)
                                      }
                                      helperText={
                                        props.errors.geos &&
                                          props.touched.geos &&
                                          props.touched.geos[i]?.title
                                          ? props.errors.geos[i]?.title : ""
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={6} sm={6}>
                                    <TextField
                                      fullWidth
                                      label={"Description"}
                                      name="description" // this is to pass description through event-->e
                                      variant="outlined"
                                      value={x?.description}
                                      onChange={(e) => handleEntryChange(e, i)}
                                      onBlur={(e) => handleEntryChange(e, i)}
                                      style={{ ...MbppStyles.Question }}
                                      className={"form-control-alternative form-control-edit"}
                                      error={
                                        props.errors.geos &&
                                        props.touched.geos &&
                                        props.touched.geos[i]?.description &&
                                        Boolean(props.errors.geos[i]?.description)
                                      }
                                      helperText={
                                        props.errors.geos &&
                                          props.touched.geos &&
                                          props.touched.geos[i]?.description
                                          ? props.errors.geos[i]?.description : ""
                                      }
                                    />
                                  </Grid>


                                  <Grid item xs={1} sm={1}>
                                    {currentGeos.length !== 1 && (
                                      <RemoveCircleOutlineIcon
                                        className="mr10"
                                        onClick={() => handleEntryRemoveClick(i)}
                                      />
                                    )}
                                    {currentGeos.length - 1 === i && (
                                      <AddCircleOutlineIcon
                                        className="ma10"
                                        onClick={handleEntryAddClick}
                                      />
                                    )}
                                  </Grid>

                                </Grid>

                              </Col>
                            </Row>

                          </>
                        );
                      })}


                      <Row>
                        <Col lg="12" className="text-right">
                          {!props.isValid ? (
                            <h6
                              small
                              style={{
                                fontWeight: "bold",
                                marginTop: 40,
                                marginBottom: -20,
                              }}
                            >
                              There are errors! Please correct and retry.
                            </h6>
                          ) : null}
                        </Col>
                      </Row>

                    </Form>
                  )}
                </Formik>
              </CardBody>

              {/* survey footer */}
              <CardFooter className="py-4">
                <Row>
                  <Col lg="6" className="text-left">
                    <Button
                      style={MbppStyles.GeneralButton}
                      color="primary"
                      onClick={() => { history.goBack(); }}>
                      BACK
                    </Button>
                  </Col>
                  <Col lg="6" className="text-right">
                    <Button
                      style={MbppStyles.GeneralButton}
                      color="primary"
                      type="submit"
                      onClick={(e) => {
                        if (formRef.current) {
                          formRef.current.handleSubmit();
                        }
                      }}>
                      UPDATE
                    </Button>
                  </Col>
                </Row>
              </CardFooter>

            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SurveyCreateTemplate;
