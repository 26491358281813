import { Avatar, TextField, Tooltip, CircularProgress, Box } from '@material-ui/core';
import { curdEMaterial, fetchEMaterial } from 'actions/education';
import PageHeader from "components/Headers/PageHeader.js";
import Config from "config/config.json";
import { MbppStyles, GlobalStylesColor } from 'constants/globalStyles';
import { Formik } from "formik";
import { Camelize } from "helper.js";
import Lodash from "lodash";
import React, { useEffect, useRef, useState } from "react";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useDispatch, useSelector } from 'react-redux';
import Pagination from 'react-responsive-pagination';
import { useHistory } from 'react-router-dom';
// reactstrap components
import {
  Badge, Card, CardBody, CardFooter, CardHeader, Col, Container, Form, FormGroup, Input, InputGroup,
  InputGroupAddon, Button,
  InputGroupText, Media, Row, Table
} from "reactstrap";
import * as Yup from "yup";
import Moment from "moment";
import PublicOutlinedIcon from "@material-ui/icons/PublicOutlined";
import VpnLockOutlinedIcon from "@material-ui/icons/VpnLockOutlined";



const reviewSchema = Yup.object({
  // Role: Yup.string()
  //   .required()
  // , Email: Yup.string()
  //   .required()
  //   .min(4)
  //   .max(1000)
  // , File: Yup.object().shape({
  //   name: Yup.string().required()
  // })
});




const EducationalMaterial = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  // show spinner
  const [showSpinner, setShowSpinner] = useState(true);

  // pagination 
  const itemPageSize = 1;
  const [itemCurrent, setItemCurrent] = useState(1);
  const [itemPageContent, setItemPageContent] = useState([]);
  const [itemPageCount, setItemPageCount] = useState(1);
  // filter
  const [txtFilter, setTxtFilter] = useState("");
  const [filterdContent, setFilterdContent] = useState([]);



  useEffect(() => {
    setShowSpinner(true);
    // fetching alldata
    let params = {
      published: true,
      // selectString: "title description"
    }
    dispatch(fetchEMaterial("FETCH_ALL", params, history));
  }, []);


  // get data
  const dataAll = useSelector((state) => state.education.educationMaterials);
  console.log('dataAll: ', dataAll);

  // process content with filter
  useEffect(() => {
    // 1. filter the data form the filter 
    let updatedData = Lodash.filter(dataAll, function (item) {
      return item.published === true;
    });
    setFilterdContent(updatedData)
  }, [dataAll]);

  useEffect(() => {
    // setShowSpinner(false);
  }, [dataAll])

  // process the pagination with page content
  useEffect(() => {
    console.log("filterdContent.length: ", filterdContent.length)
    // Set the pagination count
    let currentPageCount = Math.ceil(filterdContent.length / itemPageSize);
    setItemPageCount(currentPageCount);

    // Set the pagination content 
    if (filterdContent && filterdContent.length > 0) {
      // make sure the page doesn't exceed the array endings
      let startCount = ((itemCurrent - 1) * itemPageSize) < 0 ? 0 : ((itemCurrent - 1) * itemPageSize);
      let endCount = (itemCurrent * itemPageSize) > filterdContent.length ? filterdContent.length : (itemCurrent * itemPageSize);

      let updatedArray = Lodash.slice(filterdContent, startCount, endCount);
      if (updatedArray && updatedArray.length > 0) {
        setItemPageContent(updatedArray);
      }
      else {
        setItemPageContent([])
      }
    }
    else {
      setItemPageContent([])
    }
  }, [itemCurrent, filterdContent]);



  const DataTableRow = ({ dataProps }) => (
    <>
      <Row>
        <Col><div dangerouslySetInnerHTML={{ __html: dataProps.paragraph ? dataProps.paragraph : null }} style={{ whiteSpace: "break-spaces" }} /></Col>
      </Row>
      <Row>
        <Col lg="12" style={{ alignItems: "center", textAlign: "center" }}>{dataProps.imageUrl ? <img lg="12" alt="paraimage" style={{ maxWidth: 500, width: "100%", alignSelf: "center" }} src={Config.SERVER_API_URL + "uploads/resource/" + dataProps.imageUrl} /> : null}</Col>
      </Row>
      <Row>
        <Col>{(dataProps.hyperLink && dataProps.hyperLink.link) ? <a className="font-weight-bold ml-1" href={dataProps.hyperLink.link} target="_blank" rel="noreferrer" >{dataProps.hyperLink.tag}</a> : null}</Col>
      </Row>
      <br />
    </>
  );


  return (
    <>

      <PageHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>

        <Row>
          <Col className="order-xl-1">

            <Card className="shadow">

              {itemPageContent && (itemPageContent.length > 0) && itemPageContent.map((props, i) => {
                console.log(i, props);
                return (
                  <>

                    <CardHeader className="border-0">
                      <Row>
                        <Col md="12">
                          <h3 className="">{props.title}</h3>
                          <h6 className="">{props.description}</h6>
                        </Col>
                      </Row>
                    </CardHeader>


                    <CardBody>

                      {props.articles && (props.articles.length > 0) && (props.articles).map((article, i) => {
                        return (
                          <DataTableRow
                            key={i}
                            dataProps={article}
                          />
                        );
                      })}

                    </CardBody>

                  </>
                );
              })}


              {/* pagination */}
              <CardFooter>
                <Row>
                  <Col lg="12">
                    <Pagination
                      current={itemCurrent}
                      total={itemPageCount}
                      onPageChange={(current) => { setItemCurrent(current); }}
                    />
                  </Col>
                </Row>
              </CardFooter>
            </Card>

          </Col>
        </Row>

      </Container>
    </>
  );
};

export default EducationalMaterial;
