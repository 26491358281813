import {
  RESOURCE_CREATE,
  RESOURCE_UPDATE,
  RESOURCE_FETCH_ALL,
  RESOURCE_DELETE
} from 'constants/actionTypes';

const initState = {
  resourceAll: [],
  updateResource: [],
}

const resourceReducer = (Resource = initState, action) => {
  switch (action.type) {
    // Get all the languages
    case RESOURCE_FETCH_ALL:
      return { ...Resource, resourceAll: action.payload };
    case RESOURCE_UPDATE:
      return { ...Resource, updateResource: action.payload };
    case RESOURCE_DELETE:
      return { ...Resource, deleteResource: action.payload };
    default:
      return Resource;
  }
};

export default resourceReducer;