import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import PageHeader from "components/Headers/PageHeader.js"; // core components
import { MbppStyles } from "constants/globalStyles";
import Lodash from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Row } from "reactstrap"; // reactstrap components


const Maps = (props) => {
  const history = useHistory();

  const defaultLocation = {
    lng: 79.87095918506384,
    lat: 6.950403586944603
  }

  const [mapLocations, setMapLocations] = useState(defaultLocation);

  const handleOnLoad = (map) => {
    if(!map){
      return;
    }

    const bounds = new window.google.maps.LatLngBounds();
    ([
      {
        "longitude": 79.87507905811071,
        "latitude": 6.98635658496617
      },
      {
        "longitude": 79.88915529102087,
        "latitude": 6.872865268023713
      },
      {
        "longitude": 79.81740083545446,
        "latitude": 6.925354137254142
      },
      {
        "longitude": 79.91009797900915,
        "latitude": 6.914788664110432
      }
    ]).forEach((coordinate) => bounds.extend({ lat: coordinate.latitude, lng: coordinate.longitude }));
    map.fitBounds(bounds);
  };

  return (
    <>
      <PageHeader />

      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow border-0">

              <CardHeader className="border-0">
                <div className="row">
                  <div className="mb-xl-0 col-10">
                    <h3 className="mb-0">Find Location Coordinates</h3>
                    <h6 className="mb-0">Drag the marker to the position. And click on it.</h6>
                    <h4 className="mb-0">{"Current location: " + mapLocations.lat + "(lat), " + mapLocations.lng + "(lng)"}</h4>
                  </div>
                </div>
              </CardHeader>

              <div
                style={{ height: `600px` }}
                className="map-canvas"
                id="map-canvas"
              >
                <GoogleMap
                  onLoad={handleOnLoad}
                  onClick={() => { }}
                  // center={new window.google.maps.LatLng(defaultLocation.lat, defaultLocation.lng)}
                  mapContainerStyle={{ width: "100%", height: "100%" }}
                >

                  <Marker
                    key={0}
                    position={mapLocations}
                    draggable={true}
                    onDragEnd={(coord, index) => {
                      const { latLng } = coord;
                      setMapLocations({ lat: latLng.lat(), lng: latLng.lng() });
                    }}
                  >
                  </Marker>

                </GoogleMap>

              </div>

              <CardFooter className="py-4">
                <Row>
                  <Col lg="6" className="text-left">
                    <Button
                      style={MbppStyles.GeneralButton}
                      color="primary"
                      onClick={() => { history.goBack(); }}>
                      BACK
                    </Button>
                  </Col>
                  <Col lg="6" className="text-right">
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Maps;
