import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import Chart from "chart.js";// javascipt plugin for creating charts
import { Line, Bar, Pie } from "react-chartjs-2";// react plugin used to create charts
import { MbppStyles } from 'constants/globalStyles';
import {
  setInitData,
  chartOptions,
  parseOptions,
  getChart1,
  getChart2,
} from "variables/charts.js";// core components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  FormGroup,
  Form,
  Button,
  Row,
  Col,
} from "reactstrap";// reactstrap components
import { TextField, FormControlLabel, Switch, Grid } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { fetchStatSummery } from 'actions/analytics';
import { RoundValue } from "helper.js"
import { basicInformation } from "globalGeoInformation"
import {
  STAT_SUMMERY
} from 'constants/actionTypes';
import Lodash from "lodash";
import Moment from "moment";
import PageHeader from "components/Headers/PageHeader.js";// core components
import { isTemplateLiteralTypeSpan } from "typescript";
import { fetchStatBreedingSites } from "actions/analytics";

const graphColors = ['#d5fa66', '#b0fa66', '#8bfa66', '#66fa66', '#66fa8b', '#66fab0', '#66fad5', '#66fafa', '#66d5fa', '#66b0fa', '#668bfa', '#6666fa', '#8b66fa', '#b066fa', '#d566fa', '#fa66fa', '#fa66d5', '#fa66b0', '#fa668b', '#fa6666', '#570606', '#571a06', '#572e06', '#574306', '#575706', '#435706', '#2e5706', '#1a5706', '#065706', '#06571a', '#06572e', '#065743', '#065757', '#064357', '#062e57', '#061a57', '#060657', '#1a0657', '#2e0657', '#430657', '#570657', '#570643', '#57062e', '#57061a', '#570606', '#a182db', '#8e69d4', '#7b50cd', '#6837c5', '#551ebe', '#4205b7', '#3b05a5'];


const validationSchemaAnalytics = yup.object({
  startday: yup
    .date('Enter start date')
    .required('Start date Required'),
  endday: yup
    .date('Enter end date')
    .required('Start date Required'),
});


const optionsGrid = {
  legend: {
    display: true,
    position: 'bottom'
  }
};


const AnalyticsBreedingSites = (props) => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [pieChart, setPieChart] = useState({});
  const [showChart, setShowChart] = useState(false);

  useEffect(() => {
    // dispatch(fetchStatBreedingSites());
  }, [])

  // get state Analytics details
  const StatBreedingSites = useSelector((state) => state.analytics.StatBreedingSites);

  const formik = useFormik({
    initialValues: {
      startday: '',
      endday: '',
    },
    validationSchema: validationSchemaAnalytics,
    onSubmit: (values, onSubmitProps) => {
      console.log("formik values: ", values);

      let params = {
        "startDay": values.startday,
        "endDay": values.endday,
      }

      console.log("params", params);
      dispatch(fetchStatBreedingSites(params));
      setShowChart(true);
    }
  });


  useEffect(() => {
    console.log("StatBreedingSites: ", StatBreedingSites);

    if (StatBreedingSites && StatBreedingSites.length > 0) {
      // console.log("StatSum1: ", StatBreedingSites);
      let pieData = [];
      let kI = 0;
      let pieTotal = 0;
      let pieLabels = [];
      let pieValues = [];
      let pieColor = [];

      StatBreedingSites.forEach((item) => {
        pieData = [...pieData,
        {
          name: (item._id.region.ward === '' || item._id.region.ward == null) ? "No Region" : basicInformation.wardIds[item._id.region.ward],
          count: item.count,
          color: graphColors[kI],
        }
        ];

        kI = (kI < (graphColors.length - 1)) ? kI + 1 : 0;
        pieTotal = pieTotal + item.count;
      });

      // post process data to fit in to stack/multi bar chart
      pieData.forEach((item, i) => {
        pieLabels.push(item.name + " [" + RoundValue(item.count * 100 / pieTotal) + "%]");
        pieValues.push(item.count);
        pieColor.push(item.color);
      });

      setPieChart({
        labels: pieLabels,
        datasets: [
          {
            label: 'Reports',
            data: pieValues,
            backgroundColor: pieColor,
            borderColor: pieColor,
            borderWidth: 1,
          },
        ],
      })
      console.log('stack graph data: ', pieChart);

    }
    else {
      setPieChart({});
    }

  }, [StatBreedingSites])


  return (
    <>
      <PageHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>

        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Selection Details</h3>
              </CardHeader>
              <Form
                role="form"
                onSubmit={formik.handleSubmit}
                onReset={formik.handleReset}>
                <CardBody>
                  <FormGroup className="mb-3">

                    <Row>
                      <Col xs="1" sm="1" className="px-3 pt-3 text-right">
                        <i className="fas fa-calendar-day" />
                      </Col>
                      <Col xs="10" sm="10">
                        <Row>
                          <Col xs="12" sm="6">
                            <TextField
                              fullWidth
                              type="date"
                              id="startday"
                              name="startday"
                              label="Start Date*"
                              variant="outlined"
                              format={'DD/MM/YYYY'}
                              value={formik.values.startday}
                              onChange={formik.handleChange}
                              style={MbppStyles.Input}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={formik.touched.startday && Boolean(formik.errors.startday)}
                              helperText={formik.touched.startday && formik.errors.startday}
                            />
                          </Col>
                          <Col xs="12" sm="6">
                            <TextField
                              fullWidth
                              type="date"
                              id="endday"
                              name="endday"
                              label="End Date*"
                              variant="outlined"
                              format={'DD/MM/YYYY'}
                              value={formik.values.endday}
                              onChange={formik.handleChange}
                              style={MbppStyles.Input}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={formik.touched.endday && Boolean(formik.errors.endday)}
                              helperText={formik.touched.endday && formik.errors.endday}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                  </FormGroup>
                </CardBody>
                <CardFooter className="py-2 text-right">
                  <Row>
                    <Col sm="12" className="text-right">
                      <Button className="my-2" variant="contained" color="primary" type="reset">
                        Clear
                      </Button>
                      <Button className="my-2" variant="contained" color="primary" type="submit">
                        Visualize
                      </Button>
                    </Col>
                  </Row>
                </CardFooter>
              </Form>
            </Card>
          </div>
        </Row>

        {showChart && (
          <>
            <br />
            <Row>
              <div className="col">
                <Card className="bg-gradient-default shadow">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h6 className="text-uppercase text-light ls-1 mb-1">
                          Graph Details
                        </h6>
                        <h2 className="text-white mb-0">{Lodash.isEmpty(pieChart) ? "No Data to Show" : "Breeding Sites Among Wards"}</h2>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {/* Chart */}
                    {Object.keys(pieChart).length > 0 && (
                      <div className="chart">
                        <Pie
                          data={pieChart}
                          options={optionsGrid}
                        />
                      </div>
                    )}
                  </CardBody>
                </Card>
              </div>
            </Row>
          </>
        )}
      </Container>
    </>
  );
};

export default AnalyticsBreedingSites;
