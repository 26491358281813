import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import Chart from "chart.js"; // javascipt plugin for creating charts
import { Line, Bar } from "react-chartjs-2"; // react plugin used to create charts
import { MbppStyles } from 'constants/globalStyles';
import {
  setInitData,
  chartOptions,
  parseOptions,
  getChart1,
  getChart2,
} from "variables/charts.js"; // core components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  FormGroup,
  Form,
  Button,
  Row,
  Col,
} from "reactstrap"; // reactstrap components
import { TextField, FormControlLabel, Switch, Grid } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { basicInformation } from "globalGeoInformation"
import Lodash from "lodash";
import Moment from "moment";

// core components
import PageHeader from "components/Headers/PageHeader.js";
import { isTemplateLiteralTypeSpan } from "typescript";
import { fetchStatReports } from "actions/analytics";

const graphColors = ['#81eaea', '#019494', '#016a6a', '#a182db', '#551ebe', '#2e0480', '#69e567', '#05bf02', '#037f01', '#fed680', '#fdba2b', '#b1821e'];


const validationSchemaAnalytics = yup.object({
  startday: yup
    .date('Enter start date')
    .required('Start date Required'),
  endday: yup
    .date('Enter end date')
    .required('Start date Required'),
});


const optionsGrid = {
  legend: {
    display: true,
    position: 'bottom'
  },
  scales: {
    xAxes: [
      {
        display: true,
        scaleLabel: {
          display: false,
          labelString: "Report status",
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          color: "#142237",
          zeroLineColor: "#142237",
        },
        ticks: {
          callback: function (value) {
            if (!(value % 1)) {
              return value;
            }
          },
          beginAtZero: true,
          steps: 1,
          stepValue: 1,
          // max: 5.2,
        },
        scaleLabel: {
          display: true,
          labelString: "Report count",
        },
      },
    ],
  },
};


const AnalyticsReports = (props) => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [barChart, setBarChart] = useState({});
  const [showChart, setShowChart] = useState(false);

  const options = {
    scales: {
      y: {
        stacked: true,
        ticks: {
          beginAtZero: true
        }
      },
      x: {
        stacked: true
      }
    }
  };

  // const data = {
  //   labels: ['1', '2', '3', '4', '5', '6'],
  //   datasets: [
  //     {
  //       label: '# of Red Votes',
  //       data: [12, 19, 3, 5, 2, 3],
  //       backgroundColor: 'rgb(255, 99, 132)',
  //     },
  //     {
  //       label: '# of Blue Votes',
  //       data: [2, 3, 20, 5, 1, 4],
  //       backgroundColor: 'rgb(54, 162, 235)',
  //     },
  //     {
  //       label: '# of Green Votes',
  //       data: [3, 10, 13, 15, 22, 30],
  //       backgroundColor: 'rgb(75, 192, 192)',
  //     },
  //   ],
  // };

  useEffect(() => {
    // dispatch(fetchStatReports());
  }, [])

  // get state Analytics details
  const StatReports = useSelector((state) => state.analytics.StatReports);

  const formik = useFormik({
    initialValues: {
      startday: '',
      endday: '',
    },
    validationSchema: validationSchemaAnalytics,
    onSubmit: (values, onSubmitProps) => {
      console.log("formik values: ", values);

      let params = {
        "startDay": values.startday,
        "endDay": values.endday,
      }

      console.log("params", params);
      dispatch(fetchStatReports(params));
      setShowChart(true);
    }
  });

  useEffect(() => {
    console.log("StatReports: ", StatReports);

    if (StatReports && StatReports.length > 0) {
      console.log("StatSum1: ", StatReports);

      let kI = 0;
      let barLabel = ['Open', 'Closed'];
      let barLegend = [];
      let barData = [[], []];
      let barColor = [];

      let mohs = basicInformation.mohIds;
      mohs[null] = "No region"; // There should not be null values, instead it should be other.

      Object.keys(mohs).forEach((key, i) => {
        // console.log('@@@@> ' + i + ' @@> ' + key);

        // Get the data for each MOH and Other
        let mohData = Lodash.filter(StatReports, { _id: { region: { moh: key ? key : null } } })
        // console.log('######> ' + i + ' ##> ' + key + ' ##> ', JSON.stringify(mohData))

        if (!Lodash.isEmpty(mohData)) {
          // If data is available, populate 1-D arrays first
          barLegend = [...barLegend, mohs[key]];
          barColor = [...barColor, graphColors[kI++]];

          // Populate multi-D array for data (for each status)
          for (let i = 0; i < barLabel.length; i++) {
            let statusData = Lodash.find(mohData, { _id: { status: barLabel[i].toLowerCase() } });
            barData[i].push(Lodash.isEmpty(statusData) ? 0 : statusData.count);
          }
        }
      });

      let params = {
        labels: barLabel,
        legend: barLegend,
        barColors: barColor,
        data: barData
      };
      console.log('params: ', params);

      // post process data to fit in to stack/multi bar chart
      setBarChart({
        labels: barLabel,
        datasets: barLegend.map((item, i) => {
          return {
            label: barLegend[i],
            data: [barData[0][i], barData[1][i]],
            backgroundColor: barColor[i],
          }
        })
      })
      console.log('stack graph data: ', barChart);

    }
    else {
      setBarChart({});
    }

  }, [StatReports])




  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  return (
    <>
      <PageHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Params*/}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Selection Details</h3>
              </CardHeader>
              <Form
                role="form"
                onSubmit={formik.handleSubmit}
                onReset={formik.handleReset}>
                <CardBody>
                  <FormGroup className="mb-3">

                    <Row>
                      <Col xs="1" sm="1" className="px-3 pt-3 text-right">
                        <i className="fas fa-calendar-day" />
                      </Col>
                      <Col xs="10" sm="10">
                        <Row>
                          <Col xs="12" sm="6">
                            <TextField
                              fullWidth
                              type="date"
                              id="startday"
                              name="startday"
                              label="Start Date*"
                              variant="outlined"
                              format={'DD/MM/YYYY'}
                              value={formik.values.startday}
                              onChange={formik.handleChange}
                              style={MbppStyles.Input}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={formik.touched.startday && Boolean(formik.errors.startday)}
                              helperText={formik.touched.startday && formik.errors.startday}
                            />
                          </Col>
                          <Col xs="12" sm="6">
                            <TextField
                              fullWidth
                              type="date"
                              id="endday"
                              name="endday"
                              label="End Date*"
                              variant="outlined"
                              format={'DD/MM/YYYY'}
                              value={formik.values.endday}
                              onChange={formik.handleChange}
                              style={MbppStyles.Input}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={formik.touched.endday && Boolean(formik.errors.endday)}
                              helperText={formik.touched.endday && formik.errors.endday}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                  </FormGroup>
                </CardBody>
                <CardFooter className="py-2 text-right">
                  <Row>
                    <Col sm="12" className="text-right">
                      <Button className="my-2" variant="contained" color="primary" type="reset">
                        Clear
                      </Button>
                      <Button className="my-2" variant="contained" color="primary" type="submit">
                        Visualize
                      </Button>
                    </Col>
                  </Row>
                </CardFooter>
              </Form>
            </Card>
          </div>
        </Row>


        {showChart && (
          <>
            <br />
            <Row>
              <div className="col">
                <Card className="bg-gradient-default shadow">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h6 className="text-uppercase text-light ls-1 mb-1">
                          Graph Details
                        </h6>
                        <h2 className="text-white mb-0">{Lodash.isEmpty(barChart) ? "No Data to Show" : "Case Distribution with Status"}</h2>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {/* Chart */}
                    {Object.keys(barChart).length > 0 && (
                      <div className="chart">
                        <Bar
                          data={{
                            labels: barChart.labels,
                            datasets: barChart.datasets
                          }}
                          options={optionsGrid}
                          getDatasetAtEvent={(e) => console.log(e)}
                        />
                      </div>
                    )}

                    {/* <div className="chart">
                      <Bar
                        data={{
                          labels: ['1', '2', '3', '4', '5', '6'],
                          datasets: [
                            {
                              label: '# of Red Votes',
                              data: [12, 19, 3, 5, 2, 3],
                              backgroundColor: 'rgb(255, 99, 132)',
                            },
                            {
                              label: '# of Blue Votes',
                              data: [2, 3, 20, 5, 1, 4],
                              backgroundColor: 'rgb(54, 162, 235)',
                            },
                            {
                              label: '# of Green Votes',
                              data: [3, 10, 13, 15, 22, 30],
                              backgroundColor: 'rgb(75, 192, 192)',
                            },
                          ],
                        }}
                        options={options}
                      />
                    </div> */}
                  </CardBody>
                </Card>
              </div>
            </Row>
          </>
        )}
      </Container>
    </>
  );
};

export default AnalyticsReports;
