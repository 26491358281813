import { Grid } from '@material-ui/core';
import { MbppStyles } from 'constants/globalStyles';
import React from "react";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


// reactstrap components

// reactstrap components
import {
  Card,
  CardBody,
  Col, Form, FormGroup,
  Row,
  Container,
} from "reactstrap";


const DataPolicy = () => {

  return (
    <>
      <div className="main-content" style={{ backgroundColor: '#fdba2b' }}>
        {/* ToDo: explore */}
        {/* <AuthNavbar /> */}
        <div className="header py-6 py-lg-6"
          style={{
            backgroundImage: "url(" + require("../../../assets/img/theme/img_bg.jpg").default + ")",
            backgroundSize: "cover",
            backgroundPosition: "center top",
          }}

        >
          {/* Mask */}
          {/*bg-gradient-yellow*/}
          <span className="mask opacity-2" />
          <Container>
            <div className="header-body text-center mb-5">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100" style={{ marginBottom: '-2px', }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center" >



            <Col lg='10' md='12'>
              <Card className="bg-secondary shadow border-1">
                <CardBody className="px-lg-4 py-lg-4">

                  <div className="heading-title text-center mb-4" style={MbppStyles.Topic}>
                    <span>Data Policy</span>
                  </div>
                  <br />
                  <p>
                    Thank you for using the <b>Mo-Buzz+ Public</b> (“the App”). We are committed to protecting your privacy and providing transparency about how we collect, use, and protect your data.
                    Please refer <b><a href="privacy_policy" target="_blank" style={{ color: '#142237' }}>privacy policy</a></b> for more detail. Please refer below information regarding the account and content delete.
                  </p>

                  <Form role="form">
                    <FormGroup className="mb-3 ">

                      <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                        <Grid item xs={12} sm={12}>
                          <span>
                            <b>
                              1. Account and content delete request
                            </b>
                            <p>
                              Please send a delete request from the registered email which was used for the login, to <b><a href="mailto:healthcommwkwsci@gmail.com" target="_blank" style={{ color: '#142237' }}>healthcommwkwsci@gmail.com</a></b>.
                              Please make sure that you have the recovery code prompt to you in registration or during a password reset.
                              This may use for verification as a part of the delete confirmation process.
                            </p>
                            <b>
                              1.1. Delete content
                            </b>
                            <p>
                              User account with profile information, Breedingsite complaints and asked questions will be removed (including images, if any) with the account.
                            </p>
                            <b>
                              1.2. Delete process
                            </b>
                            <p>
                              Account and content will be deleted with in 90 days from the date of delete confirmation.
                            </p>

                            <br />
                            <b>
                              2. Account and content delete using the app
                            </b>
                            <p>
                              Users can delete the account after login to the app. All the data (mentioned in the 1.1) will be deleted with the account.
                            </p>

                            <br />

                          </span>
                        </Grid>
                      </Grid>
                    </FormGroup>
                  </Form>
                </CardBody>
              </Card>
              <br />
              <br />
              <br />
              <br />

            </Col>



          </Row>
        </Container>
      </div>

    </>
  );
};

export default DataPolicy;
