import axios from 'axios';
import config from '../config/config'

const API = axios.create({ baseURL: config.SERVER_API_URL });
// const API = axios.create({ baseURL: 'http://localhost:3010/' });
// const API = axios.create({ baseURL: 'https://srilanka.mobuzzplus.com/mbpp/' });

API.interceptors.request.use((req) => {
  if (localStorage.getItem('userDetails')) {
    req.headers["x-access-token"] = `${JSON.parse(localStorage.getItem('userDetails')).token}`;
  }

  return req;
});
// AUTH
export const signIn = (formData) => API.post('/api/user/auth', formData);
export const signUp = (formData) => API.post('/api/user/register', formData);
export const signupadmin = (formData) => API.post('/api/user/register', formData);
export const signupalreadyregister = (formData) => API.post('/api/user/registerUserAndUpdateOrganization', formData);

// user
export const fetchProfile = () => API.post('/api/user/profile/find');
export const fetchAllProfiles = () => API.get('/api/user/all');
export const updateProfile = (formData) => API.post('/api/user/profile/update', formData);
export const updateUser = (formData) => API.post('/api/user/profile/updateUser', formData);
export const uploadProfileImage = (formData) => API.post('/api/uploads/saveImageProfile', formData);
export const changePassword = (formData) => API.post('/api/user/auth/change_password', formData);
export const changeStatus = (formData) => API.post('/api/user/status', formData);
export const createPreRegister = (formData) => API.post('/api/user/preRegister', formData);
export const fetchPreRegisterAll = (formData) => API.post('/api/user/findPreRegister', formData);
export const updatePreRegister = (formData) => API.post('/api/user/updatePreRegister', formData);
export const deletePreRegister = (formData) => API.post('/api/user/deletePreRegister', formData);
export const resetPassword = (formData) => API.post('/api/user/reset', formData);

// contact
export const fetchContact = () => API.get('/api/contact/getcontacts');
export const fetchContactById = (formData) => API.post('/api/contact/getcontactbyid', formData);
export const createContact = (formData) => API.post('/api/contact/createcontact', formData);
export const updateContact = (formData) => API.put('/api/contact/updatecontact', formData);

// education
export const createEMaterial = (formData) => API.post('/api/educationM/create', formData);
export const updateEMaterial = (formData) => API.post('/api/educationM/update', formData);
export const deleteEMaterial = (formData) => API.post('/api/educationM/delete', formData);
export const fetchEMaterial = (formData) => API.post('/api/educationM/find', formData);
export const fetchPublicEMaterial = (formData) => API.post('/api/educationM/findForPublic', formData);
// education topics
export const fetchEduTopics = () => API.get('/api/education/gettopics');
export const fetchEduTopicById = (formData) => API.post('/api/education/gettopicbyid', formData);
export const createEduTopic = (formData) => API.post('/api/education/createtopic', formData);
export const updateEduTopic = (formData) => API.put('/api/education/updatetopic', formData);
// education sub topics
export const fetchEduSubTopics = (formData) => API.post('/api/education/getsubtopics', formData);
export const fetchEduSubTopicById = (formData) => API.post('/api/education/getsubtopicbyid', formData);
export const createEduSubTopic = (formData) => API.post('/api/education/createsubtopic', formData);
export const updateEduSubTopic = (formData) => API.put('/api/education/updatesubtopic', formData);
// education Articles
export const fetchEduArt = () => API.get('/api/education/getarticles');
export const fetchEduArtbyTopics = (formData) => API.post('/api/education/getarticlebytopic', formData);
export const fetchEduArtbyId = (formData) => API.post('/api/education/getarticlebyid', formData);
export const createEduArt = (formData) => API.post('/api/education/createarticle', formData);
export const updateEduArt = (formData) => API.put('/api/education/updatearticle', formData);

// forum
// forum topics
export const fetchFrmTopics = () => API.get('/api/forum/gettopics');
export const fetchFrmTopicById = (formData) => API.post('/api/forum/gettopicbyid', formData);
export const createFrmTopic = (formData) => API.post('/api/forum/createtopic', formData);
export const updateFrmTopic = (formData) => API.put('/api/forum/updatetopic', formData);
export const deleteFrmTopic = (formData) => API.post('/api/forum/deletetopic', formData);
// forum sub topics
export const fetchFrmSubTopics = (formData) => API.post('/api/forum/getsubtopics', formData);
export const fetchFrmSubTopicById = (formData) => API.post('/api/forum/getsubtopicbyid', formData);
export const createFrmSubTopic = (formData) => API.post('/api/forum/createsubtopic', formData);
export const updateFrmSubTopic = (formData) => API.put('/api/forum/updatesubtopic', formData);
export const deleteFrmSubTopic = (formData) => API.post('/api/forum/deletesubtopic', formData);
// forum Questions
export const fetchFrmQus = () => API.get('/api/forum/getquestions');
export const fetchFrmQusbyTopics = (formData) => API.post('/api/forum/getquestionsbysubtopic', formData);
export const fetchFrmQusbyId = (formData) => API.post('/api/forum/getquestionbyid', formData);
export const createFrmQus = (formData) => API.post('/api/forum/createquestion', formData);
export const updateFrmQus = (formData) => API.put('/api/forum/updatequestion', formData);
export const deleteFrmQus = (formData) => API.put('/api/forum/deletequestionanswer', formData);
// forum Answers
export const fetchFrmAns = (formData) => API.post('/api/forum/getanswers', formData);
export const fetchFrmAnsById = (formData) => API.post('/api/forum/getanswerbyid', formData);
export const createFrmAns = (formData) => API.post('/api/forum/createanswer', formData);
export const updateFrmAns = (formData) => API.put('/api/forum/updateanswer', formData);
export const deleteFrmAns = (formData) => API.put('/api/forum/deletequestionanswer', formData); //same as delete question
// new questions
export const fetchNewQuestionsByOptions = (formData) => API.post('/api/forum/findnewquestion', formData);
export const fetchNewQuestionAnswerById = (formData) => API.post('/api/forum/findnewquestionanswer', formData);

// Breeding
export const fetchBreedingComplainById = (formData) => API.post('/api/breedingsite/complain/find', formData);
export const fetchBreedingComplainByUser = (formData) => API.post('/api/breedingsite/complain/findByUser', formData);
export const fetchBreedingComplainLocation = (formData) => API.post('/api/breedingsite/complain/findLocation', formData);
export const fetchBreedingComplainByRegionStatus = (formData) => API.post('/api/breedingsite/complain/findRegionAndStatus', formData);
export const fetchBreedingAllSites = (formData) => API.post('/api/breedingsite/complain/findRegion', formData);
export const fetchBreedingByOptions = (formData) => API.post('/api/breedingsite/complain/findByOptions', formData);
export const createBreedingComplain = (formData) => API.post('/api/breedingsite/complain/new', formData);
export const updateBreedingComplain = (formData) => API.post('/api/breedingsite/complain/update', formData);
export const deleteBreedingComplain = (formData) => API.post('/api/breedingsite/complain/deleteSoft', formData);
export const uploadBreedingComplainImage = (formData) => API.post('/api/uploads/saveImageBreedingsite', formData);

// Calendar
export const fetchCalendarEventAll = () => API.get('/api/calendar/event/find');
export const fetchCalendarEventByUser = (formData) => API.post('/api/calendar/event/find', formData); // post find is findByUser
export const fetchCalendarEventPub = (formData) => API.post('/api/calendar/event/public', formData);
export const createCalendarEvent = (formData) => API.post('/api/calendar/event/new', formData);
export const updateCalendarEvent = (formData) => API.post('/api/calendar/event/update', formData);
export const deleteCalendarEvent = (formData) => API.post('/api/calendar/event/delete', formData);

// Analytics
export const fetchStatSummery = (formData) => API.post('/api/analytic/cases', formData);
export const fetchStatReports = (formData) => API.post('/api/analytic/breedingsites/all', formData);
export const fetchStatBreedingSites = (formData) => API.post('/api/analytic/breedingsites/status', formData);
export const fetchStatBreedingSitesDate = (formData) => API.post('/api/analytic/breedingsites/statusDate', formData);
export const fetchPatientLocations = (formData) => API.post('/api/analytic/locations', formData);
export const fetchDashStat = (formData) => API.post('/api/analytic/dashStat', formData);

// Maps
export const fetchMapBuildings = (formData) => API.post('/api/maps/buildings', formData);
export const updateMapBuildings = (formData) => API.post('/api/maps/items/updateById', formData);
export const fetchMapDangerZones = (formData) => API.post('/api/maps/dangerzones/public', formData);


//////////////////////////////////////////

// user
export const fetchProfileOrg = () => API.post('/api/user/profile/findUser');
export const fetchProfileOrgAsAdmin = (formData) => API.post('/api/user/profile/findUserAsAdmin', formData);
// export const updateUser = (formData) => API.post('/api/user/profile/updateUser', formData);
export const fetchUserTemplateAll = (formData) => API.post('/api/user/userNew', formData);
export const fetchUserPreRegisterAll = (formData) => API.post('/api/user/userNew', formData);


// Resource
export const fetchResources = (formData) => API.post('/api/resource/findAll', formData);
export const createResource = (formData) => API.post('/api/resource/create', formData);
export const updateResource = (formData) => API.post('/api/resource/update', formData);
export const uploadResource = (formData) => API.post('/api/uploads/saveResource', formData);
export const deleteResource = (formData) => API.post('/api/resource/deleteById', formData);

// Reports
export const fetchReportAnswerAll = (formData) => API.post('/api/report/answer/all', formData);
export const fetchReportAnswersByUser = (formData) => API.post('/api/report/answer/byUser', formData);
export const fetchReportAnswerByCountry = (formData) => API.post('/api/report/answer/summaryByCountry', formData);

// Notification
export const sendNotification = (formData) => API.post('/api/notification/pushCustom', formData);
export const fetchNotificationPublic = (formData) => API.post('/api/notification/public', formData);

// /////////////////////////

// Mbp Cases
export const fetchMbpCasesInDetail = (formData) => API.post('/api/analytic/mbp/cases/finddetail', formData);
export const fetchMbpCasesInFull = (formData) => API.post('/api/analytic/mbp/cases/findfull', formData);
export const createMbpPatient = (formData) => API.post('/api/analytic/mbp/patient', formData);
export const createMbpNotification = (formData) => API.post('/api/analytic/mbp/notification', formData);
export const createMbpCase = (formData) => API.post('/api/analytic/mbp/case', formData);
export const createMbpComment = (formData) => API.post('/api/analytic/mbp/comment', formData);


// Case template
export const showCaseTemplate = `${config.SERVER_API_URL}uploads/template/Densys_Template.xls`