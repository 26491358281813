import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { MbppStyles } from 'constants/globalStyles';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  FormGroup,
  Form,
  Button,
  Row,
  Col
} from "reactstrap";

import { TextField, FormControlLabel, Switch, Grid } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { fetchFrmTopics, fetchFrmQus, createFrmQus, updateFrmQus } from 'actions/forum';

import { FILE_IMG_SIZE, SUPPORTED_IMG_FORMATS } from "helper.js"

// core components
import PageHeader from "components/Headers/PageHeader.js";

const validationSchemaQuestion = yup.object({
  question: yup
    .string('Enter Question text')
    .max(1000, 'Question should be of maximum 1000 characters length')
    .required('Question is required'),
  description: yup
    .string('Enter Question description')
    .min(3, 'Description should be of minimum 3 characters length')
    .max(500, 'Description should be of maximum 500 characters length'),
  // image: yup
  //   .array()
  //   .of(yup
  //     .mixed()
  //     .nullable(true)
  //     .test(
  //       "fileSize",
  //       "File too large",
  //       function (value) { return value ? FILE_IMG_SIZE >= value.size : true }
  //     )
  //     .test(
  //       "fileFormat",
  //       "Unsupported Format",
  //       function (value) { return value ? SUPPORTED_IMG_FORMATS.includes(value.type) : true }
  //     )
  //   ),
  // keyWord: yup
  //   .array()
  //   .of(yup
  //     .string('Enter Question keyWord')
  //   ),
  // status: yup
  //   .boolean('Enter Question Status')

});

const ForumQuestionFrom = (props) => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  // set state topic id
  const [topicId, setTopicId] = useState(null);
  // set state topic
  const [topic, setTopic] = useState(null);
  // set state topic description
  const [topicDescription, setTopicDescription] = useState(null);
  // set state sub topic id
  const [subTopicId, setSubTopicId] = useState(null);
  // set state sub topic
  const [subTopic, setSubTopic] = useState(null);
  // set state sub topic description
  const [subTopicDescription, setSubTopicDescription] = useState(null);
  // get topic details
  const selectFrmTopic = (state) => {
    const itemArray = state.forum.frmAllTopics?.filter((item) => {
      return item._id === props.location.state?.frmTopicId
    });
    if (itemArray && itemArray[0]) {
      return itemArray[0]
    }
  }
  const topicDetails = useSelector(selectFrmTopic);
  // get sub topic details
  const selectFrmSubTopic = (topicDetails) => {
    const itemArray = topicDetails?.subTopic?.length > 0 && topicDetails?.subTopic?.filter((item) => {
      return item._id === props.location.state?.frmSubTopicId
    });
    if (itemArray && itemArray[0]) {
      return itemArray[0]
    }
  }
  const subTopicDetails = selectFrmSubTopic(topicDetails);
  // get Question details
  const selectFrmQuestion = (state) => {
    const itemArray = state.forum.frmAllQus?.filter((item) => {
      return item._id === props.location.state?.frmQuestionId
    });
    if (itemArray && itemArray[0]) {
      return itemArray[0]
    }
  }
  const frmQuestion = useSelector(selectFrmQuestion);

  useEffect(() => {
    // fetching forum topics
    dispatch(fetchFrmTopics());
    // fetching forum Questions
    dispatch(fetchFrmQus());
  }, []);

  useEffect(() => {
    if (topicDetails && topicDetails._id) {
      setTopicId(topicDetails._id);
      setTopic(topicDetails.topic);
      setTopicDescription(topicDetails.description);
    }
    if (subTopicDetails && subTopicDetails._id) {
      setSubTopicId(subTopicDetails._id);
      formik.values.subTopicId = subTopicDetails._id;
      setSubTopic(subTopicDetails.subTopic);
      setSubTopicDescription(subTopicDetails.description);
    }
    if (frmQuestion && frmQuestion._id) {
      formik.values.forumQuestionId = frmQuestion._id;
      formik.values.subTopicId = frmQuestion.subTopic;
      formik.values.question = frmQuestion.question;
      formik.values.keyWord = frmQuestion.keyWord;
      formik.values.description = frmQuestion.description;
      formik.values.status = frmQuestion.status;
      setImage((frmQuestion.image && frmQuestion.image.length !== 0) ? frmQuestion.image : ['']);
    }
  }, [topicDetails, subTopicDetails, frmQuestion]);


  // set state image list
  const [listImage, setImage] = useState(['']);

  // handle change event of the Image list
  const handleImageChange = (e, index) => {
    const list = [...listImage];
    let reader = new FileReader();
    formik.values.image[index] = e.target.files[0];
    reader.addEventListener("load", function (e) {
      list[index] = e.target.result;
    });
    reader.readAsDataURL(e.target.files[0]);
    setImage(list);
  };

  // handle click event of the Remove button of Image
  const handleRemoveImageClick = (index) => {
    const list = [...listImage];
    list.splice(index, 1);
    setImage(list);
  };

  // handle click event of the Add button of Image
  const handleAddImageClick = () => {
    setImage([...listImage, '']);
  };

  const handleKeyWordChange = (e) => {
    formik.values.keyWord = (e.target.value.split(',')).filter(el => { return el !== null && el !== '' });
  }

  const formik = useFormik({
    initialValues: {
      subTopicId: '',
      forumQuestionId: '',
      question: '',
      image: [],
      keyWord: [],
      description: '',
      status: true,
      redirect: '/registered/faq_management'
    },
    validationSchema: validationSchemaQuestion,
    onSubmit: (values, onSubmitProps) => {
      values.image = listImage.filter(el => { return el !== null && el !== '' });
      if (values.subTopicId) {
        if (values.forumQuestionId) {
          let pathParam = {
            path: "/registered/faq_management",
            state: { frmTopicId: props.location.state?.frmTopicId, frmSubTopicId: props.location.state?.frmSubTopicId, frmQuestionId: props.location.state?.frmQuestionId}
            }
            let formData = { forumQuestionId: values.forumQuestionId, description: values.description, question: values.question};
          dispatch(updateFrmQus(formData, history, pathParam));
        }
        else {
          let pathParam = {
            path: "/registered/faq_management",
            state: {frmTopicId: props.location.state?.frmTopicId, frmSubTopicId: props.location.state?.frmSubTopicId, frmQuestionId: props.location.state?.frmQuestionId}
            }
            let formData = { subTopicId:props.location.state?.frmSubTopicId, forumQuestionId: values.forumQuestionId, description: values.description, question: values.question};
          dispatch(createFrmQus(formData, history, pathParam));
        }
      }
      onSubmitProps.setSubmitting(false);
      setImage(['']);
      document.getElementById('image').value = '';
      document.getElementById('keyWord').value = '';
      onSubmitProps.resetForm();
    },
    onReset: () => {
      setImage(['']);
      document.getElementById('image').value = '';
      document.getElementById('keyWord').value = '';
    },
  });

  return (
    <>
      <PageHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table School */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Question Details</h3>
              </CardHeader>
              <Form role="form" onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <CardBody>
                  <FormGroup className="mb-3">

                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-question"></i>
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          id="question"
                          name="question"
                          label="Question"
                          variant="outlined"
                          value={formik.values.question}
                          onChange={formik.handleChange}
                          multiline rows={4}
                          error={formik.touched.question && Boolean(formik.errors.question)}
                          helperText={formik.touched.question && formik.errors.question}
                        />
                      </Grid>
                    </Grid>

                    {/* <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-question"></i>
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <CKEditor
                          editor={ClassicEditor}
                          data={formik.values.question || ''}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            formik.values.question = data;
                          }}
                          config={{
                            ckfinder: {
                              uploadUrl:
                                `/uploadImage`
                            }
                          }}
                        />
                        <TextField
                          fullWidth
                          className="mt--4"
                          style={{ marginTop: '-2rem !important' }}
                          InputProps={{ readOnly: true, disabled: true }}
                          error={formik.touched.question && Boolean(formik.errors.question)}
                          helperText={formik.touched.question && formik.errors.question}
                        />
                      </Grid>
                    </Grid> */}

                    {/* {listImage.length > 0 && listImage.map((x, i) => {
                      return (
                        <Grid key={i} container spacing={1} alignItems="center">
                          <Grid item xs={1} sm={1} className="px-3 text-right">
                            <i className="far fa-images" />
                          </Grid>
                          <Grid item xs={10} sm={10}>
                            <TextField
                              fullWidth
                              id="image"
                              name="image"
                              type="file"
                              label={"Article Image #" + (1 + i)}
                              name='image'
                              variant="outlined"
                              multiple={false}
                              onChange={e => handleImageChange(e, i)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={(formik.errors.image && formik.touched.image) && (formik.touched.image[i]) && Boolean((formik.errors.image[i]))}
                              helperText={(formik.errors.image && formik.touched.image) && (formik.touched.image[i]) ? (formik.errors.image[i]) : ''}
                            />
                          </Grid>
                          <Grid item xs={1} sm={1} >
                            {listImage.length !== 1 && listImage.length - 1 === i && <RemoveCircleOutlineIcon className="mr10" onClick={() => handleRemoveImageClick(i)} />}
                            {(listImage.length - 1 === i && !(listImage.length > 4)) && <AddCircleOutlineIcon className="ma10" onClick={handleAddImageClick} />}
                          </Grid>
                        </Grid>
                      );
                    })} */}
                    {/* <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-key" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          id="keyWord"
                          name="keyWord"
                          label="Keywords"
                          variant="outlined"
                          value={formik.values.keyWord.length > 0 ? formik.values.keyWord.toString() : null}
                          onChange={e => handleKeyWordChange(e)}
                          multiline rows={4}
                          error={formik.touched.keyWord && Boolean(formik.errors.keyWord)}
                          helperText={formik.touched.keyWord && formik.errors.keyWord}
                        />
                      </Grid>
                    </Grid> */}
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-align-left" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          id="description"
                          name="description"
                          label="Description"
                          variant="outlined"
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          multiline rows={4}
                          error={formik.touched.description && Boolean(formik.errors.description)}
                          helperText={formik.touched.description && formik.errors.description}
                        />
                      </Grid>
                    </Grid>
                    {/* <Grid container spacing={1} alignItems="center" style={ChsStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-toggle-off" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <FormControlLabel
                          value="status"
                          control={
                            <Switch
                              checked={formik.values.status}
                              onChange={formik.handleChange}
                              color="primary"
                              name="status"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                              error={formik.touched.status && Boolean(formik.errors.status)}
                              helperText={formik.touched.status && formik.errors.status}
                            />}
                          label="Status"
                          labelPlacement="start"
                        />
                      </Grid>
                    </Grid> */}
                  </FormGroup>
                </CardBody>
                
                <CardFooter className="py-4">
                  <Row>
                    <Col lg="6" className="text-left">
                      <Button
                        style={MbppStyles.GeneralButton}
                        color="primary"
                        onClick={() => {
                          history.goBack();
                        }}>
                        BACK
                      </Button>
                    </Col>
                    <Col lg="6" className="text-right">
                      <Button
                        style={MbppStyles.GeneralButton}
                        color="primary"
                        type="reset">
                        RESET
                      </Button>
                      <Button
                        style={MbppStyles.GeneralButton}
                        color="primary"
                        type="submit">
                        {(formik.values.forumQuestionId) ? 'UPDATE' : 'SAVE'}
                      </Button>
                    </Col>
                  </Row>
                </CardFooter>

              </Form>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ForumQuestionFrom;
