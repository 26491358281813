import {
  EDU_TOPIC_FETCH_ALL,
  EDU_TOPIC_FETCH_ONE,
  EDU_TOPIC_CREATE,
  EDU_TOPIC_UPDATE,
  EDU_SUB_TOPIC_FETCH_ALL,
  EDU_SUB_TOPIC_FETCH_ONE,
  EDU_SUB_TOPIC_CREATE,
  EDU_SUB_TOPIC_UPDATE,
  EDU_ART_FETCH_ONE,
  EDU_ART_FETCH_ALL,
  EDU_ART_TOPIC_FETCH_ALL,
  EDU_ART_CREATE,
  EDU_ART_UPDATE,
  //////////////
  EDUCATIONAL_MATERIALS,
  SHOW_LOADING
} from 'constants/actionTypes';
import * as api from '../api/index.js';
import { confirmAlert } from 'react-confirm-alert';
import '../assets/css/react-confirm-alert-custom.css';
import Lodash from "lodash";
import { Avatar, TextField, Tooltip, CircularProgress, Box } from '@material-ui/core';


// education Topics
export const fetchEduTopics = () => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchEduTopics();
    if (data?.status === 'success') {
      dispatch({ type: EDU_TOPIC_FETCH_ALL, payload: data?.data });
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error);
    alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const fetchEduTopicById = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchEduTopicById(formData);
    if (data?.status === 'success') {
      dispatch({ type: EDU_TOPIC_FETCH_ONE, payload: data?.data });
      formData.redirect && router.push(formData.redirect);
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error);
    alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const createEduTopic = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.createEduTopic(formData);
    dispatch({ type: EDU_TOPIC_CREATE, payload: data });
    if (data?.status === 'success') {
      alert(data?.message);
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error)
    alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const updateEduTopic = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.updateEduTopic(formData);
    dispatch({ type: EDU_TOPIC_UPDATE, payload: data?.data });
    if (data?.status === 'success') {
      alert(data?.message);
      formData.redirect && router.push(formData.redirect);
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error)
    alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// education Sub Topics
export const fetchEduSubTopics = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchEduSubTopics(formData);
    if (data?.status === 'success') {
      dispatch({ type: EDU_SUB_TOPIC_FETCH_ALL, payload: data?.data });
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error);
    alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const fetchEduSubTopicById = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchEduSubTopicById(formData);
    if (data?.status === 'success') {
      dispatch({ type: EDU_SUB_TOPIC_FETCH_ONE, payload: data?.data });
      formData.redirect && router.push(formData.redirect);
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error);
    alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const createEduSubTopic = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.createEduSubTopic(formData);
    dispatch({ type: EDU_SUB_TOPIC_CREATE, payload: data });
    if (data?.status === 'success') {
      alert(data?.message);
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error)
    alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const updateEduSubTopic = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.updateEduSubTopic(formData);
    dispatch({ type: EDU_SUB_TOPIC_UPDATE, payload: data });
    if (data?.status === 'success') {
      alert(data?.message);
      formData.redirect && router.push(formData.redirect);
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error)
    alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// Education articles
export const fetchEduArt = () => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchEduArt();
    if (data?.status === 'success') {
      dispatch({ type: EDU_ART_FETCH_ALL, payload: data?.data });
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error);
    alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const fetchEduArtbyTopics = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchEduArtbyTopics(formData);
    if (data?.status === 'success') {
      dispatch({ type: EDU_ART_TOPIC_FETCH_ALL, payload: data?.data });
      formData.redirect && router.push(formData.redirect);
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error);
    alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const fetchEduArtbyId = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchEduArtbyId(formData);
    if (data?.status === 'success') {
      dispatch({ type: EDU_ART_FETCH_ONE, payload: data?.data });
      formData.redirect && router.push(formData.redirect);
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error);
    alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const createEduArt = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.createEduArt(formData);
    dispatch({ type: EDU_ART_CREATE, payload: data });
    if (data?.status === 'success') {
      alert(data?.message);
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error)
    alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const updateEduArt = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.updateEduArt(formData);
    dispatch({ type: EDU_ART_UPDATE, payload: data });
    if (data?.status === 'success') {
      alert(data?.message);
      formData.redirect && router.push(formData.redirect);
    }
    if (data?.status === 'error') {
      console.log(data);
      alert(data?.message);
    }
  } catch (error) {
    console.log(error)
    alert(error);
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

//////////////////////////


export const curdEMaterial = (action, formData, history) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    let response = null;
    if (action === "CREATE") response = await api.createEMaterial(formData);
    else if (action === "UPDATE") response = await api.updateEMaterial(formData);
    else if (action === "DELETE") response = await api.deleteEMaterial(formData);

    const { data } = response;
    console.log("data: ", data);

    if (data?.status === 'success') {
      confirmAlert({
        message: data?.message, buttons: [{
          label: 'Ok',
          onClick: () => {
            let params = {
              selectString: "title published publishedOn order keyWords"
            }
            dispatch(fetchEMaterial("FETCH_ALL", params, history));
            if (!Lodash.isEmpty(history)) history.goBack();
          }
        }]
      });
    }
    else if (data?.status === 'error') {
      confirmAlert({ message: data?.message, buttons: [{ label: 'Ok', onClick: () => { } }] });
    }
    else {
      // should not come here. do nothing.
    }
  } catch (error) {
    console.log("catch error", error)
    confirmAlert({ message: error?.response?.data?.message, buttons: [{ label: 'Ok', onClick: () => { } }] });
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const fetchEMaterial = (action, formData, history) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });

    let response = null;
    if (action === "FETCH_ALL") response = await api.fetchEMaterial(formData);
    else if (action === "FETCH_PUBLIC") response = await api.fetchPublicEMaterial(formData);

    const { data } = response;
    console.log("data: ", data);

    if (data?.status === 'success') {
      // confirmAlert({ message: data?.message, buttons: [{ label: 'Ok', onClick: () => { } }] });
      dispatch({ type: EDUCATIONAL_MATERIALS, payload: data?.data });
    }
    else if (data?.status === 'error') {
      confirmAlert({ message: data?.message, buttons: [{ label: 'Ok', onClick: () => { } }] });
    }
    else {
      // should not come here. do nothing.
    }
  } catch (error) {
    console.log("catch error", error)
    confirmAlert({ message: error?.response?.data?.message, buttons: [{ label: 'Ok', onClick: () => { } }] });
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

