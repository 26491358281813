import {
    STAT_SUMMERY,
    STAT_SUMMERY_TOTAL,
    STAT_SUMMERY_CON_MON_FETCH,
    STAT_SUMMERY_CON_WEK_FETCH,
    STAT_SUMMERY_REP_FETCH,
    STAT_BREEDING_SITES,
    STAT_BREEDING_SITES_DATE,
    STAT_DASHBOARD,
    STAT_REPORTS
  } from 'constants/actionTypes';

  const initState = {
    statSum: [],
    statSumTotal: [],
    StatSumConMon: [],
    StatSumConWek: [],
    StatSumRpt: [],
    StatBreedingSites:[],
    StatBreedingSitesDate:[],
    StatReports:[],
    StatDashboard:{},
  }
  
  const analyticsReducer =(analytics = initState, action) => {
    switch (action.type) {
      // stat summery 2
        case STAT_SUMMERY:
            return {...analytics, StatSum: action.payload};
        case STAT_SUMMERY_TOTAL:
            return {...analytics, statSumTotal: action.payload};
        case STAT_SUMMERY_CON_MON_FETCH:
            return {...analytics, StatSumConMon: action.payload};
        case STAT_SUMMERY_CON_WEK_FETCH:
            return {...analytics, StatSumConWek: action.payload};
        case STAT_SUMMERY_REP_FETCH:
            return {...analytics, StatSumRpt: action.payload};
        case STAT_BREEDING_SITES:
            return {...analytics, StatBreedingSites: action.payload};
        case STAT_BREEDING_SITES_DATE:
            return {...analytics, StatBreedingSitesDate: action.payload};
        case STAT_REPORTS:
            return {...analytics, StatReports: action.payload};
        case STAT_DASHBOARD:
            return {...analytics, StatDashboard: action.payload};
        default:
            return analytics;
    }
  };
  
  export default analyticsReducer;