import { Checkbox, Chip, FormControl, InputAdornment, ListItemIcon, ListItemText, MenuItem, Select, TextField } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { changePassword, fetchProfile, updateProfile } from 'actions/user';
import UserHeader from "components/Headers/UserHeader.js"; // core components
import Config from "config/config.json";
import { MbppStyles } from 'constants/globalStyles';
import { useFormik } from 'formik';
import { basicInformation } from "globalGeoInformation.js";
import { affiliation_types, ages, Camelize, CryptoPassword, emp_types, genders, phoneRegExp, titles, useMultiSelectStyles } from "helper.js";
import Lodash from "lodash";
import React, { useEffect, useState } from 'react';
// import { useAlert } from 'react-alert'
import Resizer from "react-image-file-resizer";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card, CardBody, Col, Container, Form, Row
} from "reactstrap";
import * as yup from 'yup';


const validationSchema = yup.object({
  firstname: yup
    .string('Enter your first name')
    .max(255, 'First name should be of maximum 255 characters length')
    .required('First name is required'),
  lastname: yup
    .string('Enter your last name')
    .max(255, 'Last name should be of maximum 255 characters length')
    .required('Last name is required'),
  // profilepic: yup
  //   .mixed()
  //   .test(
  //     "fileSize",
  //     "File too large",
  //     function (value) { return value ? FILE_IMG_SIZE >= value.size : true }
  //   )
  //   .test(
  //     "fileFormat",
  //     "Unsupported Format",
  //     function (value) { return value ? SUPPORTED_IMG_FORMATS.includes(value.type) : true }
  //   ),
  // coverpic: yup
  //   .mixed()
  //   .test(
  //     "fileSize",
  //     "File too large",
  //     function (value) { return value ? FILE_IMG_SIZE >= value.size : true }
  //   )
  //   .test(
  //     "fileFormat",
  //     "Unsupported Format",
  //     function (value) { return value ? SUPPORTED_IMG_FORMATS.includes(value.type) : true }
  //   ),
  age: yup
    .string('Select your Age range')
    .required('Age is required'),
  gender: yup
    .string('Select your gender')
    .required('Gender is required'),
  title: yup
    .string('Select your title'),
  telephone: yup
    .array()
    .of(yup
      .string('Enter your phone number').matches(phoneRegExp, 'Phone number is not valid')
    ),
  address: yup
    .string('Enter your address')
    .min(3, 'Address should be of minimum 3 characters length')
    .max(500, 'Address should be of maximum 500 characters length'),
  emp_type: yup
    .string('Select your employment type'),
  emp_institute: yup
    .string('Enter your work place')
    .min(3, 'Work place should be of minimum 3 characters length')
    .max(255, 'Work place should be of maximum 255 characters length'),
  emp_department: yup
    .string('Enter your department')
    .min(3, 'Department should be of minimum 3 characters length')
    .max(255, 'Department should be of maximum 255 characters length'),
  emp_telephone: yup
    .string('Enter employer phone number')
    .matches(phoneRegExp, 'Phone number is not valid'),
  emp_email: yup
    .string('Enter employer email')
    .email('Enter a valid email'),
  emp_address: yup
    .string('Enter employer address')
    .min(3, 'Address should be of minimum 3 characters length')
    .max(500, 'Address should be of maximum 500 characters length'),
  emp_designation: yup
    .string('Enter your designation')
    .min(3, 'Designation should be of minimum 3 characters length')
    .max(255, 'Department should be of maximum 255 characters length'),
  emp_regno: yup
    .string('Enter employer registration number')
    .min(3, 'Registration number should be of minimum 3 characters length')
    .max(255, 'Registration number should be of maximum 255 characters length'),
  referenceNo: yup
    .string('Enter employer reference number')
    .min(3, 'Reference number should be of minimum 3 characters length')
    .max(255, 'Reference number should be of maximum 255 characters length'),
  notificationNo: yup
    .string('Enter employer notification number')
    .min(3, 'Notification number should be of minimum 3 characters length')
    .max(255, 'Notification number should be of maximum 255 characters length'),
  emp_region_name: yup
    .string('Enter employer Region name')
    .min(3, 'Region name should be of minimum 3 characters length')
    .max(255, 'Region name should be of maximum 255 characters length'),
  emp_region_code: yup
    .string('Enter employer region code')
    .min(3, 'Region code should be of minimum 3 characters length')
    .max(255, 'Region code should be of maximum 255 characters length'),
  emp_region_wards: yup
    .array()
    .of(yup
      .string('Enter your region ward')
    ),
  emp_region_mohs: yup
    .array()
    .of(yup
      .string('Enter your region mohs')
    ),
  affiliation: yup
    .string('Enter your affiliation')
    .min(3, 'Affiliation should be of minimum 3 characters length')
    .max(255, 'Affiliation should be of maximum 255 characters length'),
});


const validationSchemaChangePassword = yup.object({
  currentPassword: yup
    .string('Enter your current password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Current password is required'),
  password: yup
    .string('Enter your new password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('New password is required'),
  confirmPassword: yup
    .string('New passwords must match')
    .test('passwords-match', 'New passwords must match', function (value) { return this.parent.password === value }),
});


const Profile = () => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  // set state contact number list
  const [contactList, setContactList] = useState([{ index: 0, contactno: "" }]);

  // multi select
  const classes = useMultiSelectStyles();
  const [mohsSelected, setMohsSelected] = useState([]);
  const [wardsSelected, setWardsSelected] = useState([]);
  const isMohsAllSelected = (Object.entries(basicInformation.mohIds)).length > 0 && mohsSelected.length === (Object.entries(basicInformation.mohIds)).length;
  const isWardsAllSelected = (Object.entries(basicInformation.wardIds)).length > 0 && wardsSelected.length === (Object.entries(basicInformation.wardIds)).length;

  // set state edit
  const [isEdit, setIsEdit] = useState(true);
  // Show edit profile or change password
  const [editSection, setEditSection] = useState("WELCOME");

  useEffect(() => {
    dispatch(fetchProfile());
  }, [])

  // set state userDetails
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));

  // get state user profile
  const userProfile = useSelector((state) => state.user.userProfile.profile);


  useEffect(() => {
    setEditSection("WELCOME");
    console.log(">>>>>>>>>>>>>>>>> ", userDetails);
    console.log(">>>>>>>>>>>>>>>>> ", userProfile);

    if (userProfile && Object.keys(userProfile).length > 0) {

      formik.values.userId = userDetails?.user.id || '';
      formik.values.profileId = userProfile?._id || '';
      formik.values.firstname = userProfile?.firstname || '';
      formik.values.lastname = userProfile?.lastname || '';
      formik.values.email = userDetails?.user.email || '';
      formik.values.age = userProfile?.age || '';
      formik.values.gender = userProfile?.gender || '';
      formik.values.title = userProfile?.title || '';
      formik.values.telephone = userProfile?.telephone || '';
      formik.values.address = userProfile?.address || '';
      formik.values.description = userProfile?.description || '';
      formik.values.profilepic = userProfile?.profilepic || '';
      formik.values.profilethumbnail = userProfile?.profilethumbnail || '';
      formik.values.newProfilePicture = false;
      formik.values.emp_type = userProfile?.employment?.type || '';
      formik.values.emp_job = userProfile?.employment?.job || '';
      formik.values.emp_institute = userProfile?.employment?.institute || '';
      formik.values.emp_department = userProfile?.employment?.department || '';
      formik.values.emp_telephone = userProfile?.employment?.telephone || '';
      formik.values.emp_email = userProfile?.employment?.email || '';
      formik.values.emp_address = userProfile?.employment?.address || '';
      formik.values.emp_designation = userProfile?.employment?.designation || '';
      formik.values.emp_regno = userProfile?.employment?.regno || '';
      formik.values.referenceNo = userProfile?.employment?.referenceNo || '';
      formik.values.notificationNo = userProfile?.employment?.notificationNo || '';
      formik.values.emp_region_name = userProfile?.employment?.region.name || '';
      formik.values.emp_region_code = userProfile?.employment?.region.code || '';
      formik.values.emp_region_wards = userProfile?.employment?.region.wards || [];
      formik.values.emp_region_mohs = userProfile?.employment?.region.mohs || [];
      formik.values.affiliation = userProfile?.affiliation || '';

      // Geographical information
      if (userProfile?.employment?.region.wards) {
        console.log('init ward list: ', wardsSelected)
        setWardsSelected(userProfile?.employment?.region.wards)
        console.log('init ward list: ', wardsSelected)
      }
      if (userProfile?.employment?.region.mohs) {
        setMohsSelected(userProfile?.employment?.region.mohs)
        console.log(mohsSelected)
      }

      // Contact information
      if (userProfile?.telephone?.length > 0) {
        const list = [...contactList];
        for (const [key, value] of Object.entries(userProfile?.telephone)) {
          list[key]['contactno'] = value;
        }
        setContactList(list);
      }
    }
  }, [userProfile]);


  // handle click event of togle Edit
  const switchMode = (e) => {
    e.preventDefault();
    // setIsEdit((prevIsSignup) => !prevIsSignup);
    // setIsEdit(true);
    setEditSection("PROFILE");
  };

  // handle change event of the profilepic
  const handleFileChange = async (e) => {
    // Resize the image to generate thumbnail
    let image = null;
    try {
      const file = e.target.files[0];
      image = await resizeImage(file);
      console.log(image);
      formik.values.profilepic = file;
      formik.values.profilethumbnail = image;
      formik.values.newProfilePicture = true;
    } catch (err) {
      console.log(err);
    }
    // dispatch(uploadProfileImage(formik.values.profilepic, formik.values.profilethumbnail, history));
  };

  const resizeImage = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        200,
        200,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
        200,
        200
      );
    });

  // handle change event of the contact number
  const handleContactChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...contactList];
    list[index][name] = value;
    setContactList(list);
    formik.values.telephone = ((Object.keys(contactList).map((key) => contactList[key]['contactno'])).filter(el => { return el !== null && el !== '' }))
  };

  // handle click event of the Remove button of contact number
  const handleRemoveClick = index => {
    const list = [...contactList];
    list.splice(index, 1);
    setContactList(list);
  };

  // handle click event of the Add button of contact number
  const handleAddClick = () => {
    setContactList([...contactList, { index: contactList.length, contactno: "" }]);
  };

  const handleMohMultiSelectChange = (event) => {

    const value = event.target.value;
    let updatedMohs = [];
    if (value[value.length - 1] === "all") {
      updatedMohs = (mohsSelected.length === (Object.entries(basicInformation.mohIds)).length ? [] : Object.keys(basicInformation.mohIds));
    }
    else {
      updatedMohs = value;
    }
    setMohsSelected(updatedMohs);
    formik.values.emp_region_mohs = updatedMohs;
    console.log('selected moh items', updatedMohs);
  }

  const handleWardMultiSelectChange = (event) => {

    const value = event.target.value;
    let updatedWards = [];
    if (value[value.length - 1] === "all") {
      updatedWards = (wardsSelected.length === (Object.entries(basicInformation.wardIds)).length ? [] : Object.keys(basicInformation.wardIds));
    }
    else {
      updatedWards = value;
    }
    setWardsSelected(updatedWards);
    formik.values.emp_region_wards = updatedWards;
    console.log('selected ward items', updatedWards);
  }

  const alert = null; //useAlert();

  const formik = useFormik({
    initialValues: {
      userId: '',
      profileId: '',
      email: '',
      firstname: '',
      lastname: '',
      age: '',
      gender: '',
      title: '',
      profilepic: '',
      profilethumbnail: '',
      newProfilePicture: false,
      coverpic: '',
      telephone: '',
      address: '',
      description: '',
      emp_type: '',
      emp_job: '',
      emp_institute: '',
      emp_department: '',
      emp_telephone: '',
      emp_email: '',
      emp_address: '',
      emp_designation: '',
      emp_regno: '',
      emp_referenceNo: '',
      emp_notificationNo: '',
      emp_region_name: '',
      emp_region_code: '',
      emp_region_wards: null,
      emp_region_mohs: null
    },
    validationSchema: validationSchema,
    onSubmit: (values, onSubmitProps) => {

      console.log('Update', values);
      dispatch(updateProfile(values, alert, true, history));
    }
  });


  const formikChangePassword = useFormik({
    initialValues: {
      currentPassword: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchemaChangePassword,
    onSubmit: (values, onSubmitProps) => {
      console.log('Change password with values: ', values)
      values.oldpassword = CryptoPassword(values.currentPassword);
      values.newpassword = CryptoPassword(values.password);
      dispatch(changePassword(values, alert, history));
      onSubmitProps.resetForm();
    }
  });

  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>

          {/* Left profile summary */}
          {userProfile && (<Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
              <Row className="justify-content-center">

                <Col className="order-lg-2" lg="3" style={{ marginLeft: -20 }}>
                  <div className="card-profile-image">

                    <div className="rounded-circle"
                      style={{ backgroundImage: 'url("' + Config.SERVER_API_URL + Config.PUBLIC_PROF_PIC + userProfile?.profilepic + '")', backgroundColor: "#bdbdbd" }}>
                      {(userProfile?.profilepic) ? "" : userProfile?.firstname.charAt(0).toUpperCase()}
                    </div>

                  </div>
                </Col>

              </Row>
              <Row style={{ paddingTop: 130, margin: 10, }}>

                <Col className="d-flex justify-content-between order-xl-2 mb-5 mb-xl-0" style={{ padding: 0 }}>
                  <div></div>
                  <Button
                    className="mr-4"
                    color="primary"
                    href="#pablo"
                    onClick={(e) => { switchMode(e) }}
                    size="sm"
                    // style={{ minWidth: 130 }}
                    style={{ minWidth: 100, paddingTop: 3, paddingBottom: 3 }}
                  >
                    Update Profile
                  </Button>
                  <Button
                    className="float-right"
                    color="primary"
                    href="#pablo"
                    onClick={(e) => { setEditSection("PASSWORD"); }}
                    size="sm"
                    style={{ minWidth: 100, paddingTop: 3, paddingBottom: 3 }}
                  >
                    Change Password
                  </Button>
                  <div></div>
                </Col>

              </Row>
              <CardBody className="pt-0 pt-md-4">
                <div className="text-center"><h3> </h3></div>
                <div className="text-center">
                  <h3>
                    {Camelize(userProfile?.title + '. ' + userProfile?.firstname + ' ' + userProfile?.lastname)}
                  </h3>
                  <div className="h5 font-weight-300">
                    {contactList[0].contactno + ' | ' + userDetails?.user?.email}
                  </div>
                  <div className="h4 font-weight-300">
                    {userProfile?.address}
                  </div>
                  <br />

                  <div className="h4 font-weight-300 form-check-label">
                    {userProfile?.employment?.type}
                  </div>
                  <div>
                    {userProfile?.employment?.designation}
                  </div>
                  <br />

                  <div className="h5 font-weight-300 form-check-label">
                    Institute
                  </div>
                  <div>
                    {userProfile?.employment?.institute}
                  </div>
                  <br />

                  <div className="h5 font-weight-300 form-check-label">
                    Department
                  </div>
                  <div>
                    {userProfile?.employment?.department}
                  </div>
                  <br />

                  <div className="h5 font-weight-300 form-check-label">
                    Working Address
                  </div>
                  <div>
                    {userProfile?.employment?.address}
                  </div>
                  <br />

                  <div className="h5 font-weight-300 form-check-label">
                    Designation
                  </div>
                  <div>
                    {userProfile?.employment?.designation}
                  </div>
                  <br />

                  <div className="h5 font-weight-300 form-check-label">
                    MOH Details
                  </div>
                  <div>
                    {userProfile?.employment?.region?.mohs && userProfile?.employment?.region?.mohs.map((moh) => {
                      return basicInformation.mohIds[moh] + ', '
                    })}
                  </div>
                  <br />

                  <div className="h5 font-weight-300 form-check-label">
                    Ward Details
                  </div>
                  <div>
                    {userProfile?.employment?.region?.wards && userProfile?.employment?.region?.wards.map((ward) => {
                      return basicInformation.wardIds[ward] + ', '
                    })}
                  </div>
                  <br />
                </div>
              </CardBody>
            </Card>
          </Col>)}


          {/* Right profile update and change password */}

          {/* Welcome message */}
          <Col className="order-xl-1" xl="8" style={{ display: editSection === "WELCOME" ? 'block' : 'none', }}>
            {/* <Card className="bg-secondary shadow" style={{ backgroundImage: "url(" + require("../../../assets/img/theme/img_bg.jpg").default + ")", backgroundPosition: "center", backgroundSize: "cover", }}> */}
            <Card className="bg-secondary shadow">

              <CardBody className="d-flex align-items-center">
                <Row>
                  <Col lg="10" md="10">
                    <h1 className="display-2" style={MbppStyles.Head}>
                      Hello {Lodash.isEmpty(userProfile?.title) ? "" : (userProfile?.title ? (userProfile?.title + '. ') : '') + Camelize(userProfile?.firstname + ' ' + userProfile?.lastname)}
                    </h1>
                    <p className="mt-0 mb-5" style={MbppStyles.SubTopic}>
                      This is your profile page. You can update your details or change the password.
                    </p>
                  </Col>
                </Row>
              </CardBody>

            </Card>
          </Col>

          {/* Change password */}
          <Col className="order-xl-1" xl="8" style={{ display: editSection === "PASSWORD" ? 'block' : 'none' }}>
            <Card className="bg-secondary shadow">

              <CardBody>
                <Form
                  role="form"
                  onSubmit={formikChangePassword.handleSubmit}
                  onReset={formikChangePassword.handleReset}>

                  <div className="heading-title mb-4" style={MbppStyles.Topic}>
                    Change password
                  </div>

                  <br />
                  <hr className="my-2" />
                  <Row>
                    <Col lg="6">
                      <FormControl fullWidth>
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          Current Password*
                        </label>
                        <TextField style={MbppStyles.Input}
                          fullWidth
                          id="currentPassword"
                          name="currentPassword"
                          variant="outlined"
                          type={'password'}
                          value={formikChangePassword.values.currentPassword}
                          onChange={formikChangePassword.handleChange}
                          error={formikChangePassword.touched.currentPassword && Boolean(formikChangePassword.errors.currentPassword)}
                          helperText={formikChangePassword.touched.currentPassword && formikChangePassword.errors.currentPassword}
                          className={"form-control-alternative form-control-edit"}
                        />
                      </FormControl>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <FormControl fullWidth>
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          New Password*
                        </label>
                        <TextField style={MbppStyles.Input}
                          fullWidth
                          id="password"
                          name="password"
                          variant="outlined"
                          type={'password'}
                          value={formikChangePassword.values.password}
                          onChange={formikChangePassword.handleChange}
                          error={formikChangePassword.touched.password && Boolean(formikChangePassword.errors.password)}
                          helperText={formikChangePassword.touched.password && formikChangePassword.errors.password}
                          className={"form-control-alternative form-control-edit"}
                        />
                      </FormControl>
                    </Col>
                    <Col lg="6">
                      <FormControl fullWidth>
                        <label
                          className="form-control-label"
                          htmlFor="input-last-name"
                        >
                          Retype New Password*
                        </label>
                        <TextField style={MbppStyles.Input}
                          fullWidth
                          id="confirmPassword"
                          name="confirmPassword"
                          variant="outlined"
                          type={'password'}
                          value={formikChangePassword.values.confirmPassword}
                          onChange={formikChangePassword.handleChange}
                          error={formikChangePassword.touched.confirmPassword && Boolean(formikChangePassword.errors.confirmPassword)}
                          helperText={formikChangePassword.touched.confirmPassword && formikChangePassword.errors.confirmPassword}
                          className={"form-control-alternative form-control-edit"}
                        />
                      </FormControl>
                    </Col>
                  </Row>

                  <br />
                  <div className="text-center">
                    <Button variant="contained" color="primary" type="reset">
                      RESET
                    </Button>
                    <Button variant="contained" color="primary" type="submit">
                      CHANGE
                    </Button>
                  </div>

                </Form>
              </CardBody>

            </Card>
          </Col>

          {/* Update profile */}
          <Col className="order-xl-1" xl="8" style={{ display: editSection === "PROFILE" ? 'block' : 'none' }}>
            <Card className="bg-secondary shadow">

              <CardBody>
                <Form
                  role="form"
                  onSubmit={formik.handleSubmit}
                  onReset={formik.handleReset}>

                  <div className="heading-title mb-4" style={MbppStyles.Topic}>
                    Update Profile
                  </div>

                  <br />
                  <hr className="my-2" />
                  <h6 className="heading-small mb-4" style={MbppStyles.SubTopic}>
                    Personal Details
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormControl fullWidth>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email address*
                          </label>
                          <TextField style={MbppStyles.Input}
                            fullWidth
                            id="email"
                            name="email"
                            variant="outlined"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                            className="form-control-alternative form-control-display form-control-css"
                            InputProps={{ readOnly: true, disabled: true }}
                          />
                        </FormControl>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormControl fullWidth>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            First Name*
                          </label>
                          <TextField style={MbppStyles.Input}
                            fullWidth
                            id="firstname"
                            name="firstname"
                            variant="outlined"
                            value={formik.values.firstname}
                            onChange={formik.handleChange}
                            error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                            helperText={formik.touched.firstname && formik.errors.firstname}
                            className={isEdit ? "form-control-alternative form-control-edit" : "form-control-alternative form-control-display"}
                            InputProps={{ readOnly: !isEdit, disabled: !isEdit }}
                          />
                        </FormControl>
                      </Col>
                      <Col lg="6">
                        <FormControl fullWidth>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Last Name
                          </label>
                          <TextField style={MbppStyles.Input}
                            fullWidth
                            id="lastname"
                            name="lastname"
                            variant="outlined"
                            value={formik.values.lastname}
                            onChange={formik.handleChange}
                            error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                            helperText={formik.touched.lastname && formik.errors.lastname}
                            className={isEdit ? "form-control-alternative form-control-edit" : "form-control-alternative form-control-display"}
                            InputProps={{ readOnly: !isEdit, disabled: !isEdit }}
                          />
                        </FormControl>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormControl fullWidth>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Salutation*
                          </label>
                          <TextField style={MbppStyles.Input}
                            select
                            fullWidth
                            id="title"
                            name="title"
                            variant="outlined"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            SelectProps={{
                              native: true,
                            }}
                            error={formik.touched.title && Boolean(formik.errors.title)}
                            helperText={formik.touched.title && formik.errors.title}
                            className={isEdit ? "form-control-alternative form-control-edit" : "form-control-alternative form-control-display"}
                            InputProps={{ readOnly: !isEdit, disabled: !isEdit }}
                          >
                            {titles.map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </TextField>
                        </FormControl>
                      </Col>
                      <Col lg="4">
                        <FormControl fullWidth>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Gender*
                          </label>
                          <TextField style={MbppStyles.Input}
                            select
                            fullWidth
                            id="gender"
                            name="gender"
                            variant="outlined"
                            value={formik.values.gender}
                            onChange={formik.handleChange}
                            SelectProps={{
                              native: true,
                            }}
                            error={formik.touched.gender && Boolean(formik.errors.gender)}
                            helperText={formik.touched.gender && formik.errors.gender}
                            className="form-control-alternative form-control-display form-control-css"
                            InputProps={{ readOnly: true, disabled: true }}
                          >
                            {/* <option value=''></option> */}
                            {genders.map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </TextField>
                        </FormControl>
                      </Col>
                      <Col lg="4">
                        <FormControl fullWidth>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Age
                          </label>
                          <TextField style={MbppStyles.Input}
                            select
                            fullWidth
                            id="age"
                            name="age"
                            variant="outlined"
                            value={formik.values.age}
                            onChange={formik.handleChange}
                            SelectProps={{
                              native: true,
                            }}
                            error={formik.touched.age && Boolean(formik.errors.age)}
                            helperText={formik.touched.age && formik.errors.age}
                            className="form-control-alternative form-control-display form-control-css"
                            InputProps={{ readOnly: true, disabled: true }}
                          >
                            {/* <option value=''></option> */}
                            {ages.map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </TextField>
                        </FormControl>
                      </Col>
                    </Row>
                    {isEdit &&
                      (<Row>
                        <Col md="8">
                          <FormControl fullWidth style={MbppStyles.Input}>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Profile Picture
                            </label>
                            <TextField
                              fullWidth
                              id="profilepic"
                              name="profilepic"
                              type="file"
                              variant="outlined"
                              multiple={false}
                              onChange={handleFileChange}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={formik.touched.profilepic && Boolean(formik.errors.profilepic)}
                              className="form-control-alternative form-control-edit"
                              helperText={formik.touched.profilepic && formik.errors.profilepic}
                            />
                            {/* <p className="small">
                              If the picture is not square shape, image may stretch to fit.
                            </p> */}
                          </FormControl>
                        </Col>
                      </Row>)
                    }
                  </div>

                  {/* Address */}
                  <div className="pl-lg-4">
                    <Row>
                      {contactList.map((x, i) => {
                        return (

                          <Col lg="4" key={i}>
                            <FormControl fullWidth>
                              <label
                                className="form-control-label"
                                htmlFor="input-address"
                              >
                                {/* {'Contact Number #' + (1 + i)} */}
                                {'Contact Number'}
                              </label>
                              <TextField style={MbppStyles.Input}
                                fullWidth
                                id="contactno"
                                name="contactno"
                                // label={'Contact Number #'+ (1 + i)}
                                variant="outlined"
                                value={x.contactno}
                                onChange={e => handleContactChange(e, i)}
                                error={formik.touched.telephone && Boolean(formik.errors.telephone)}
                                helperText={formik.touched.telephone && formik.errors.telephone}
                                InputProps={!isEdit ? {
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {contactList.length !== 1 && <RemoveCircleOutlineIcon className="mr10" onClick={() => handleRemoveClick(i)} />}
                                      {(contactList.length - 1 === i && !(contactList.length > 4)) && <AddCircleOutlineIcon className="ma10" onClick={handleAddClick} />}
                                    </InputAdornment>
                                  ),
                                } : null}
                                className={isEdit ? "form-control-alternative form-control-edit" : "form-control-alternative form-control-display"}
                                InputProps={{ readOnly: !isEdit, disabled: !isEdit }}
                              />
                            </FormControl>
                          </Col>
                        );
                      })}
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormControl fullWidth>
                          <label
                            className="form-control-label"
                            htmlFor="input-address"
                          >
                            Resident Address*
                          </label>
                          <TextField style={MbppStyles.Input}
                            fullWidth
                            id="address"
                            name="address"
                            variant="outlined"
                            value={formik.values.address}
                            onChange={formik.handleChange}
                            multiline rows={2}
                            error={formik.touched.address && Boolean(formik.errors.address)}
                            helperText={formik.touched.address && formik.errors.address}
                            className={isEdit ? "form-control-alternative form-control-edit" : "form-control-alternative form-control-display"}
                            InputProps={{ readOnly: !isEdit, disabled: !isEdit }}
                          />
                        </FormControl>
                      </Col>
                    </Row>
                  </div>

                  <br />
                  <hr className="my-2" />
                  <h6 className="heading-small mb-4" style={MbppStyles.SubTopic}>
                    Occupation Details
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormControl fullWidth>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Workplace Category*
                          </label>
                          <TextField style={MbppStyles.Input}
                            select
                            fullWidth
                            id="emp_type"
                            name="emp_type"
                            variant="outlined"
                            value={formik.values.emp_type}
                            onChange={formik.handleChange}
                            SelectProps={{
                              native: true,
                            }}
                            error={formik.touched.emp_type && Boolean(formik.errors.emp_type)}
                            helperText={formik.touched.emp_type && formik.errors.emp_type}
                            className={isEdit ? "form-control-alternative form-control-edit" : "form-control-alternative form-control-display"}
                            InputProps={{ readOnly: !isEdit, disabled: !isEdit }}
                          >
                            {/* <option value=''></option> */}
                            {emp_types.map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </TextField>
                        </FormControl>
                      </Col>
                      <Col lg="6">
                        <FormControl fullWidth>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Working Institute
                          </label>
                          <TextField style={MbppStyles.Input}
                            fullWidth
                            id="emp_institute"
                            name="emp_institute"
                            variant="outlined"
                            value={formik.values.emp_institute}
                            onChange={formik.handleChange}
                            error={formik.touched.emp_institute && Boolean(formik.errors.emp_institute)}
                            helperText={formik.touched.emp_institute && formik.errors.emp_institute}
                            className={isEdit ? "form-control-alternative form-control-edit" : "form-control-alternative form-control-display"}
                            InputProps={{ readOnly: !isEdit, disabled: !isEdit }}
                          />
                        </FormControl>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormControl fullWidth>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Working Department
                          </label>
                          <TextField style={MbppStyles.Input}
                            fullWidth
                            id="emp_department"
                            name="emp_department"
                            variant="outlined"
                            value={formik.values.emp_department}
                            onChange={formik.handleChange}
                            error={formik.touched.emp_department && Boolean(formik.errors.emp_department)}
                            helperText={formik.touched.emp_department && formik.errors.emp_department}
                            className={isEdit ? "form-control-alternative form-control-edit" : "form-control-alternative form-control-display"}
                            InputProps={{ readOnly: !isEdit, disabled: !isEdit }}
                          />
                        </FormControl>
                      </Col>
                      <Col lg="6">
                        <FormControl fullWidth>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Working Designation
                          </label>
                          <TextField style={MbppStyles.Input}
                            fullWidth
                            id="emp_designation"
                            name="emp_designation"
                            variant="outlined"
                            value={formik.values.emp_designation}
                            onChange={formik.handleChange}
                            error={formik.touched.emp_designation && Boolean(formik.errors.emp_designation)}
                            helperText={formik.touched.emp_designation && formik.errors.emp_designation}
                            className={isEdit ? "form-control-alternative form-control-edit" : "form-control-alternative form-control-display"}
                            InputProps={{ readOnly: !isEdit, disabled: !isEdit }}
                          />
                        </FormControl>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="12">
                        <FormControl fullWidth>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Workplace Address*
                          </label>
                          <TextField style={MbppStyles.Input}
                            fullWidth
                            id="emp_address"
                            name="emp_address"
                            variant="outlined"
                            multiline rows={2}
                            value={formik.values.emp_address}
                            onChange={formik.handleChange}
                            error={formik.touched.emp_address && Boolean(formik.errors.emp_address)}
                            helperText={formik.touched.emp_address && formik.errors.emp_address}
                            className={isEdit ? "form-control-alternative form-control-edit" : "form-control-alternative form-control-display"}
                            InputProps={{ readOnly: !isEdit, disabled: !isEdit }}
                          />
                        </FormControl>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormControl fullWidth>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Affiliation Type*
                          </label>
                          <TextField style={MbppStyles.Input}
                            select
                            fullWidth
                            id="affiliation"
                            name="affiliation"
                            variant="outlined"
                            value={formik.values.affiliation}
                            onChange={formik.handleChange}
                            SelectProps={{
                              native: true,
                            }}
                            error={formik.touched.affiliation && Boolean(formik.errors.affiliation)}
                            helperText={formik.touched.affiliation && formik.errors.affiliation}
                            className={isEdit ? "form-control-alternative form-control-edit" : "form-control-alternative form-control-display"}
                            InputProps={{ readOnly: !isEdit, disabled: !isEdit }}
                          >
                            {/* <option value=''></option> */}
                            {affiliation_types.map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </TextField>
                        </FormControl>
                      </Col>
                    </Row>
                  </div>

                  <br />
                  <hr className="my-2" />
                  <h6 className="heading-small mb-4" style={MbppStyles.SubTopic}>
                    Geographic Details
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormControl fullWidth disabled={!isEdit}>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Wards
                          </label>
                          <Select style={MbppStyles.Input}
                            fullWidth
                            labelId="mutiple-select-label"
                            multiple
                            variant="outlined"
                            value={wardsSelected}
                            onChange={handleWardMultiSelectChange}
                            renderValue={(wardsSelected) => (
                              <div style={{ display: 'flex', flexWrap: 'wrap', }}>
                                {(wardsSelected).map((key) => (
                                  <Chip key={basicInformation.wardIds[key]} label={basicInformation.wardIds[key]} style={{ margin: 2 }} />
                                ))}
                              </div>
                            )}
                            error={formik.touched.emp_region_wards && Boolean(formik.errors.emp_region_wards)}
                            helperText={formik.touched.emp_region_wards && formik.errors.emp_region_wards}
                            className={isEdit ? "form-control-alternative form-control-edit" : "form-control-alternative form-control-display"}
                            InputProps={{ readOnly: !isEdit, disabled: !isEdit }}
                          >
                            <MenuItem
                              value="all"
                              className={{
                                root: isWardsAllSelected ? classes.selectedAll : ""
                              }}
                            >
                              <ListItemIcon>
                                <Checkbox
                                  className={{ indeterminate: classes.indeterminateColor }}
                                  checked={isWardsAllSelected}
                                  indeterminate={
                                    wardsSelected.length > 0 && wardsSelected.length < Object.entries(basicInformation.wardIds).length
                                  }
                                />
                              </ListItemIcon>
                              <ListItemText
                                className={{ primary: classes.selectAllText }}
                                primary="Select All"
                              />
                            </MenuItem>
                            {Object.entries(basicInformation.wardIds).map(([key, value]) =>
                            (
                              <MenuItem key={key} value={key}>
                                <ListItemIcon>
                                  <Checkbox checked={wardsSelected && wardsSelected.indexOf(key) > -1} />
                                </ListItemIcon>
                                <ListItemText primary={value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Col>
                      <Col lg="6">
                        <FormControl fullWidth disabled={!isEdit}>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            MOHS
                          </label>
                          <Select style={MbppStyles.Input}
                            fullWidth
                            labelId="mutiple-select-label"
                            multiple
                            variant="outlined"
                            value={mohsSelected}
                            onChange={handleMohMultiSelectChange}
                            renderValue={(mohsSelected) => (
                              <div style={{ display: 'flex', flexWrap: 'wrap', }}>
                                {(mohsSelected).map((key) => (
                                  <Chip key={basicInformation.mohIds[key]} label={basicInformation.mohIds[key]} style={{ margin: 2 }} />
                                ))}
                              </div>
                            )}
                            error={formik.touched.emp_region_mohs && Boolean(formik.errors.emp_region_mohs)}
                            helperText={formik.touched.emp_region_mohs && formik.errors.emp_region_mohs}
                            className={isEdit ? "form-control-alternative form-control-edit" : "form-control-alternative form-control-display"}
                            InputProps={{ readOnly: !isEdit, disabled: !isEdit }}
                          >
                            <MenuItem
                              value="all"
                              className={{
                                root: isMohsAllSelected ? classes.selectedAll : ""
                              }}
                            >
                              <ListItemIcon>
                                <Checkbox
                                  className={{ indeterminate: classes.indeterminateColor }}
                                  checked={isMohsAllSelected}
                                  indeterminate={
                                    mohsSelected.length > 0 && mohsSelected.length < Object.entries(basicInformation.mohIds).length
                                  }
                                />
                              </ListItemIcon>
                              <ListItemText
                                className={{ primary: classes.selectAllText }}
                                primary="Select All"
                              />
                            </MenuItem>
                            {Object.entries(basicInformation.mohIds).map(([key, value]) =>
                            (
                              <MenuItem key={key} value={key}>
                                <ListItemIcon>
                                  <Checkbox checked={mohsSelected && mohsSelected.indexOf(key) > -1} />
                                </ListItemIcon>
                                <ListItemText primary={value} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Col>
                    </Row>

                    <p className="small mb-4">
                      * are mandatory fields
                    </p>
                  </div>

                  <br />

                  <div className="text-center">
                    <Button variant="contained" color="primary" type="reset">
                      RESET
                    </Button>
                    <Button variant="contained" color="primary" type="submit">
                      UPDATE
                    </Button>
                  </div>
                </Form>
              </CardBody>

            </Card>
          </Col>

        </Row>
      </Container>
    </>
  );
};

export default Profile;