import { Avatar, TextField, Tooltip, CircularProgress, Box } from '@material-ui/core';
import { curdEMaterial, fetchEMaterial } from 'actions/education';
import PageHeader from "components/Headers/PageHeader.js";
import Config from "config/config.json";
import { MbppStyles, GlobalStylesColor } from 'constants/globalStyles';
import { Formik } from "formik";
import { Camelize } from "helper.js";
import Lodash from "lodash";
import React, { useEffect, useRef, useState } from "react";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useDispatch, useSelector } from 'react-redux';
import Pagination from 'react-responsive-pagination';
import { useHistory } from 'react-router-dom';
// reactstrap components
import {
  Badge, Card, CardBody, CardFooter, CardHeader, Col, Container, Form, FormGroup, Input, InputGroup,
  InputGroupAddon, Button,
  InputGroupText, Media, Row, Table
} from "reactstrap";
import * as Yup from "yup";
import Moment from "moment";
import PublicOutlinedIcon from "@material-ui/icons/PublicOutlined";
import VpnLockOutlinedIcon from "@material-ui/icons/VpnLockOutlined";



const reviewSchema = Yup.object({
  // Role: Yup.string()
  //   .required()
  // , Email: Yup.string()
  //   .required()
  //   .min(4)
  //   .max(1000)
  // , File: Yup.object().shape({
  //   name: Yup.string().required()
  // })
});




const EducationalMaterial = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  // show spinner
  const [showSpinner, setShowSpinner] = useState(true);

  // pagination 
  const itemPageSize = Config.ITEM_PAGE_SIZE;
  const [itemCurrent, setItemCurrent] = useState(1);
  const [itemPageContent, setItemPageContent] = useState([]);
  const [itemPageCount, setItemPageCount] = useState(1);
  // filter
  const [txtFilter, setTxtFilter] = useState("");
  const [filterdContent, setFilterdContent] = useState([]);



  useEffect(() => {
    setShowSpinner(true);
    // fetching alldata
    let params = {
      selectString: "title published publishedOn order keyWords"
    }
    dispatch(fetchEMaterial("FETCH_ALL", params, history));
  }, []);


  // get data
  const dataAll = useSelector((state) => state.education.educationMaterials);
  console.log('dataAll: ', dataAll);

  // process content with filter
  useEffect(() => {
    // 1. filter the data form the filter 
    let updatedData = Lodash.filter(dataAll, function (item) {
      return item.title && item.title.indexOf(txtFilter) > -1;
    });
    setFilterdContent(updatedData)
  }, [dataAll, txtFilter]);

  useEffect(() => {
    // setShowSpinner(false);
  }, [dataAll])

  // process the pagination with page content
  useEffect(() => {
    console.log("filterdContent.length: ", filterdContent.length)
    // Set the pagination count
    let currentPageCount = Math.ceil(filterdContent.length / itemPageSize);
    setItemPageCount(currentPageCount);

    // Set the pagination content 
    if (filterdContent && filterdContent.length > 0) {
      // make sure the page doesn't exceed the array endings
      let startCount = ((itemCurrent - 1) * itemPageSize) < 0 ? 0 : ((itemCurrent - 1) * itemPageSize);
      let endCount = (itemCurrent * itemPageSize) > filterdContent.length ? filterdContent.length : (itemCurrent * itemPageSize);

      let updatedArray = Lodash.slice(filterdContent, startCount, endCount);
      if (updatedArray && updatedArray.length > 0) {
        setItemPageContent(updatedArray);
      }
      else {
        setItemPageContent([])
      }
    }
    else {
      setItemPageContent([])
    }
  }, [itemCurrent, filterdContent]);



  const DataTableRow = ({ dataProps, editClick, deleteClick, statusClick }) => (
    <tr>

      <td>{dataProps.order}</td>
      <th>{dataProps.title}</th>
      <td>{Moment(dataProps.publishedOn).format('YYYY-MMM-DD')}</td>
      <td>
        {dataProps.published ?
          <Badge color="" className="badge-dot">
            <i className="bg-success" />
            Published
          </Badge>
          :
          <Badge color="" className="badge-dot mr-4">
            <i className="bg-warning" />
            Unpublished
          </Badge>
        }
      </td>
      <td className="text-right">
        <div className="row">
          <Tooltip title="Edit" arrow>
            <div className="navbar-toggler"
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                editClick(e, dataProps._id)
              }}>
              <i className="far fa-edit" />
            </div>
          </Tooltip>
          <Tooltip title="Change publish status" arrow>
            <div
              className="navbar-toggler"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                statusClick(e, dataProps._id, dataProps.published);
              }}
            >
              {dataProps.published ? <PublicOutlinedIcon /> : <VpnLockOutlinedIcon />}
            </div>
          </Tooltip>
          <Tooltip title="Delete" arrow>
            <div className="navbar-toggler"
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                deleteClick(e, dataProps._id)
              }}>
              <i className="far fa-times-circle" />
            </div>
          </Tooltip>
        </div>
      </td>

    </tr>
  );


  const handleCreateNewClick = (e) => {
    e.preventDefault();
    history.push({ pathname: '/registered/educational_material_details', state: { materialId: null } })
  };

  const handleEditClick = (e, id) => {
    e.preventDefault();
    history.push({ pathname: '/registered/educational_material_details', state: { materialId: id } })
  };

  const handleDeleteClick = (e, id) => {
    e.preventDefault();
    dispatch(curdEMaterial("DELETE", { materialId: id }, history));
  };

  const handleStatusClick = (e, id, published) => {
    e.preventDefault();
    let params = {
      materialId: id,
      published: !published
    }
    dispatch(curdEMaterial("UPDATE", params, null));
  };


  return (
    <>
      {/* {showSpinner ?
        <div className="react-confirm-alert-overlay">
          <CircularProgress />
        </div> : null
      } */}

      <PageHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>

        <Row>
          <Col className="order-xl-1">

            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col md="5">
                    <h3 className="mb-0">Educational Materials</h3>
                  </Col>
                  <Col md="6">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <InputGroup className="input-group-alternative" >
                        <InputGroupAddon addonType="prepend" >
                          <InputGroupText>
                            <i className="ni ni-zoom-split-in" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="form-control-alternative"
                          placeholder="By Title"
                          type="text"
                          onChange={(e) => {
                            console.log(e.target.value);
                            setTxtFilter(e.target.value);
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md="1">
                    <Tooltip title="Add new article" arrow>
                      <div
                        className="navbar-toggler"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          handleCreateNewClick(e);
                        }}
                      >
                        <i className="fas fa-plus-circle" style={{ fontSize: 30, color: GlobalStylesColor.mbppThemePrimary }} />
                      </div>
                    </Tooltip>
                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    {/* <th scope="col" /> */}
                    <th scope="col">Order (&darr;)</th>
                    <th scope="col">Title</th>
                    <th scope="col">Modified On</th>
                    <th scope="col">Published</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {itemPageContent && (itemPageContent.length > 0) && itemPageContent.map((props, i) => {
                    console.log(i, props);
                    return (
                      <DataTableRow
                        key={i}
                        dataProps={props}
                        editClick={handleEditClick}
                        deleteClick={handleDeleteClick}
                        statusClick={handleStatusClick}
                      />
                    );
                  })}
                </tbody>
              </Table>

              {/* pagination */}
              <CardFooter>
                <Row>
                  <Col lg="12">
                    <Pagination
                      current={itemCurrent}
                      total={itemPageCount}
                      onPageChange={(current) => { setItemCurrent(current); }}
                    />
                  </Col>
                </Row>
              </CardFooter>
            </Card>

          </Col>
        </Row>

      </Container>
    </>
  );
};

export default EducationalMaterial;
