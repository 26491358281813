import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { MbppStyles } from 'constants/globalStyles';
import Moment from "moment";
import Lodash from "lodash";
import Config from "config/config.json";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  FormGroup,
  Form,
  Button,
  Row,
  Col,
  Table,
} from "reactstrap";

import { TextField, FormControlLabel, Switch, Grid } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import { fetchBreedingAllSites, fetchBreedingComplainById, createBreedingComplain, updateBreedingComplain, uploadBreedingComplainImage } from 'actions/breeding';

import { BreedingComplainActions, BreedingComplainStatus, FILE_IMG_SIZE, SUPPORTED_IMG_FORMATS } from "helper.js"

import { basicInformation, cmcRegionBoundaries } from "globalGeoInformation"

// core components
import PageHeader from "components/Headers/PageHeader.js";

const validationSchemaComplain = yup.object({
  // email: yup
  //   .string('Enter your email')
  //   .email('Enter a valid email'),
  // date: yup
  //   .date('Enter complain date')
  //   .required('Date complain Required'),
  // place: yup
  //   .string('Enter complain place'),
  // title: yup
  //   .string('Enter complain title')
  //   .min(3, 'Title should be of minimum 3 characters length')
  //   .max(255, 'Title should be of maximum 500 characters length'),
  // description: yup
  //   .string('Enter complain description')
  //   .min(3, 'Description should be of minimum 3 characters length')
  //   .max(500, 'Description should be of maximum 500 characters length'),
  // severity: yup
  //   .string('Enter complain title')
  //   .min(3, 'Title should be of minimum 3 characters length')
  //   .max(255, 'Title should be of maximum 500 characters length'),
  // address: yup
  //   .string('Enter complain address')
  //   .min(3, 'Address should be of minimum 3 characters length')
  //   .max(500, 'Address should be of maximum 500 characters length'),
  // region: yup
  //   .object({
  //     // coordinates: yup
  //     //   .object({
  //     //     longitude: yup
  //     //       .number('Enter complain region longitude'),
  //     //     latitude: yup
  //     //       .number('Enter complain region latitude'),
  //     //   }),
  //     // name: yup
  //     //   .string('Enter complain region name')
  //     //   .min(3, 'Region name should be of minimum 3 characters length')
  //     //   .max(255, 'Region name should be of maximum 500 characters length'),
  //     // code: yup
  //     //   .string('Enter complain region code')
  //     //   .min(3, 'Region code should be of minimum 3 characters length')
  //     //   .max(255, 'Region code should be of maximum 500 characters length'),
  //     // ward: yup
  //     //   .string('Enter complain region ward'),
  //     // moh: yup
  //     //   .string('Enter complain region MOH'),
  //   }),
  // pictures: yup
  //   .array()
  //   .of(yup
  //     .object({
  //       thumbnail: yup
  //         .mixed()
  //         .nullable(true)
  //         .test(
  //           "fileSize",
  //           "File too large",
  //           function (value) { return value ? FILE_IMG_SIZE >= value.size : true }
  //         )
  //         .test(
  //           "fileFormat",
  //           "Unsupported Format",
  //           function (value) { return value ? SUPPORTED_IMG_FORMATS.includes(value.type) : true }
  //         ),
  //       image: yup
  //         .mixed()
  //         .nullable(true)
  //         .test(
  //           "fileSize",
  //           "File too large",
  //           function (value) { return value ? FILE_IMG_SIZE >= value.size : true }
  //         )
  //         .test(
  //           "fileFormat",
  //           "Unsupported Format",
  //           function (value) { return value ? SUPPORTED_IMG_FORMATS.includes(value.type) : true }
  //         ),
  //     })
  //   ),
  // changes: yup
  //   .array()
  //   .of(yup
  //     .object({
  //       date: yup
  //         .date('Enter change date'),
  //       reason: yup
  //         .string('Enter reason for changes')
  //         .min(3, 'Reason should be of minimum 3 characters length')
  //         .max(500, 'Reason should be of maximum 255 characters length'),
  //     })
  //   ),
  region_ward: yup
    .string('Select report ward')
    .required('Select report ward'),
  region_moh: yup
    .string('Select report MOH')
    .required('Select report MOH'),
  action_date: yup
    .date('Enter change date')
    .required('Enter change date'),
  action_action: yup
    .mixed().oneOf(BreedingComplainActions)
    .required('Select the action'),
  action_message: yup
    .string('Enter action message')
    .required('Enter action message')
    .min(3, 'Message should be of minimum 3 characters length')
    .max(500, 'Message should be of maximum 500 characters length'),
  // actions: yup
  //   .array()
  //   .of(yup
  //     .object({
  //       date: yup
  //         .date('Enter change date')
  //         .required('Enter change date'),
  //       takenBy: yup
  //         .string('Enter who took the action'),
  //       action: yup
  //         .mixed().oneOf(BreedingComplainActions),
  //       message: yup
  //         .string('Enter action message')
  //         .min(3, 'Message should be of minimum 3 characters length')
  //         .max(500, 'Message should be of maximum 500 characters length'),
  //     })
  // ),
  status: yup
    .mixed().oneOf(BreedingComplainStatus)
    .required('Select the new status'),
});

const BreedingComplainFrom = (props) => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const date_options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };

  // set state image list
  const [listImage, setImage] = useState(['']);
  // set state action list
  const [listAction, setAction] = useState([{ 'date': '', 'takenBy': '', 'action': '', 'message': '' }]);
  const [isDeleted, setIsDeleted] = useState(true);
  const [refreshCounter, setRefreshCounter] = useState(0);

  // get state breeding complains
  const selectedBreedingCompalain = props.location.state?.breedingId
  const breedingComplain = useSelector((state) => state.breedingsite.breedingSiteComplaintsById);
  console.log(breedingComplain)

  useEffect(() => {
    if (selectedBreedingCompalain)
      // fetching breeding site complains
      dispatch(fetchBreedingComplainById({ _id: selectedBreedingCompalain }, history))
  }, [selectedBreedingCompalain]);

  useEffect(() => {
    if (breedingComplain && Object.keys(breedingComplain).length > 0 && breedingComplain._id) {
      formik.values._id = breedingComplain?._id ?? '';
      formik.values.email = breedingComplain?.createdBy?.email ?? '';
      formik.values.date = Moment(breedingComplain?.date).format("YYYY-MM-DD");
      formik.values.place = breedingComplain?.place ?? '';
      formik.values.title = breedingComplain?.title ?? '';
      formik.values.description = breedingComplain?.description ?? '';
      formik.values.severity = breedingComplain?.severity ?? '';
      formik.values.address = breedingComplain?.address ?? '';
      formik.values.region = breedingComplain?.region ?? '';
      formik.values.region_ward = breedingComplain?.region?.ward ?? ''; //? breedingComplain.region?.ward : "";
      formik.values.region_moh = breedingComplain.region?.moh ?? '' //? breedingComplain.region?.moh : "";
      formik.values.pictures = breedingComplain?.pictures ?? [];
      formik.values.actions = breedingComplain?.actions ?? '';
      formik.values.status = breedingComplain?.status ?? '';
      setAction(breedingComplain?.actions);
      setIsDeleted(breedingComplain?.deleted);

      let currentUser = localStorage.getItem('userDetails');
      let currentJUser = JSON.parse(currentUser);
      console.log("j user: ", currentJUser);
      if (currentJUser && !Lodash.isEmpty(currentJUser)) {
        formik.values.action_takenby = currentJUser.user.email;
        formik.values.action_takenby_id = currentJUser.user.id;
      }

      setRefreshCounter(refreshCounter + 1);
    }
    console.log(formik.values)
  }, [breedingComplain]);

  // handle image uplaod
  const handleImageUpload = (e) => {
    e.preventDefault()
    dispatch(uploadBreedingComplainImage({ files: formik.values.pictures.image }, history));
  }
  // handle change event of the Image list
  const handleImageChange = (e, index) => {
    const list = [...listImage];
    let reader = new FileReader();
    // formik.values.pictures[index].image = e.target.files[0];
    reader.addEventListener("load", function (e) {
      list[index] = e.target.result;
    });
    reader.readAsDataURL(e.target.files[0]);
    setImage(list);
  };

  // handle click event of the Remove button of Image
  const handleRemoveImageClick = (index) => {
    const list = [...listImage];
    list.splice(index, 1);
    setImage(list);
  };

  // handle click event of the Add button of Image
  const handleAddImageClick = () => {
    setImage([...listImage, '']);
  };
  // // set state changes list
  // const [listChanges, setChanges] = useState([{date:'', reason:''}]);
  // // handle change event of the changes list
  // const handleListChangesChange = (e, index) => {
  //   const { name, value } = e.target;
  //   const list = [...listChanges];
  //   list[index][name] = value;
  //   setChanges(list);
  //   formik.values.changes =  listChanges;
  // };

  // // handle click event of the Remove button of changes
  // const handleRemoveListChangesClick = (index) => {

  //   const list = [...listChanges];
  //   list.splice(index, 1);
  //   setChanges(list);
  // };

  // // handle click event of the Add button of changes
  // const handleAddListChangesClick = () => {
  //   setChanges([...listChanges, {date:'', reason:''}]);
  // };
  // handle change event of the Image list
  const handleListActionChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...listAction];
    list[index][name] = value;
    setAction(list);
    formik.values.action = listAction;
  };

  // handle click event of the Remove button of action
  const handleRemoveListActionClick = (index) => {

    const list = [...listAction];
    list.splice(index, 1);
    setAction(list);
  };

  // handle click event of the Add button of action
  const handleAddListActionClick = () => {
    setAction([...listAction, { date: '', takenBy: '', action: '', message: '' }]);
  };


  const handleWardChange = (e) => {
    formik.handleChange(e);
    let selectedWard = e.target.value;
    console.log("selectedWard: ", selectedWard);
    let selectedMoh = Lodash.find(cmcRegionBoundaries, { _id: selectedWard });
    if (selectedMoh && !Lodash.isEmpty(selectedMoh)) {
      console.log("selectedMoh: ", selectedMoh);
      console.log("selectedMoh: ", selectedMoh.moh);
      formik.values.region_moh = selectedMoh.moh;
    }
  }

  const formik = useFormik({
    initialValues: {
      _id: '',
      email: '',
      date: '',
      place: '',
      title: '',
      description: '',
      severity: '',
      address: '',
      region:
      {
        coordinates:
        {
          longitude: '',
          latitude: '',
        },
        name: '',
        code: '',
        ward: '',
        moh: '',
      },
      // ward: '',
      // moh: '',
      pictures:
        [
          {
            thumbnail: '',
            image: '',
          }
        ],
      region_ward: '',
      region_moh: '',
      action_date: '',
      action_action: '',
      action_takenby: '',
      action_takenby_id: '',
      action_message: '',
      actions:
        [
          {
            date: '',
            takenBy: '',
            action: '',
            message: '',
          }
        ],
      status: ''
    },
    validationSchema: validationSchemaComplain,
    onSubmit: (values, onSubmitProps) => {
      console.log("####################> 1", values);

      values["region"]["moh"] = values.region_moh;
      values["region"]["ward"] = values.region_ward;

      let actionList = [];

      for (const item of values["actions"]) {
        actionList.push({
          action: item.action,
          date: item.date,
          message: item.message,
          takenBy: item.takenBy._id
        })
      }

      actionList.push({
        action: values.action_action,
        date: values.action_date,
        message: values.action_message,
        takenBy: values.action_takenby_id
      })

      let params = {
        _id: values._id,
        region: values.region,
        status: values.status,
        actions: actionList,
        searchParams: props.location.state?.searchParams
      }

      console.log("####################> 2", params);
      dispatch(updateBreedingComplain(params, history));
      onSubmitProps.setSubmitting(false);
    },
    onReset: () => {
      // selectedBreedingCompalain = null;
      // setImage(['']);
      // setAction([{ 'date': '', 'takenBy': '', 'action': '', 'message': '' }]);
      // document.getElementById('image').value = '';
    },
  });

  return (
    <>
      <PageHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table School */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Breeding Site Reports</h3>
              </CardHeader>
              <Form
                id={refreshCounter}
                role="form"
                onSubmit={formik.handleSubmit}
                onReset={formik.handleReset}>
                <CardBody>
                  <FormGroup className="mb-3">

                    <hr className="my-2" />
                    <h6
                      className="heading-small mb-4"
                      style={MbppStyles.SubTopic}
                    >
                      Report Details
                    </h6>

                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="ni ni-email-83" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          id="email"
                          name="email"
                          label="Email"
                          variant="outlined"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          error={formik.touched.email && Boolean(formik.errors.email)}
                          helperText={formik.touched.email && formik.errors.email}
                          disabled={true}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-calendar-day" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          type="date"
                          id="date"
                          name="date"
                          label="Date"
                          variant="outlined"
                          format={'DD/MM/YYYY'}
                          value={formik.values.date}
                          onChange={formik.handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={formik.touched.date && Boolean(formik.errors.date)}
                          helperText={formik.touched.date && formik.errors.date}
                          disabled={true}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-location-arrow" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          id="place"
                          name="place"
                          label="place"
                          variant="outlined"
                          value={formik.values.place}
                          onChange={formik.handleChange}
                          error={formik.touched.place && Boolean(formik.errors.place)}
                          helperText={formik.touched.place && formik.errors.place}
                          disabled={true}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-heading" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          id="title"
                          name="title"
                          label="Title"
                          variant="outlined"
                          value={formik.values.title}
                          onChange={formik.handleChange}
                          error={formik.touched.title && Boolean(formik.errors.title)}
                          helperText={formik.touched.title && formik.errors.title}
                          disabled={true}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="ni ni-align-left-2" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          id="description"
                          name="description"
                          label="Description"
                          variant="outlined"
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          multiline 
                          minRows={4}
                          error={formik.touched.description && Boolean(formik.errors.description)}
                          helperText={formik.touched.description && formik.errors.description}
                          disabled={true}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-info-circle" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          id="severity"
                          name="severity"
                          label="Severity"
                          variant="outlined"
                          value={formik.values.severity}
                          onChange={formik.handleChange}
                          error={formik.touched.severity && Boolean(formik.errors.severity)}
                          helperText={formik.touched.severity && formik.errors.severity}
                          disabled={true}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-address-card" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          id="address"
                          name="address"
                          label="Address"
                          variant="outlined"
                          value={formik.values.address}
                          onChange={formik.handleChange}
                          multiline 
                          minRows={4}
                          error={formik.touched.address && Boolean(formik.errors.address)}
                          helperText={formik.touched.address && formik.errors.address}
                          disabled={true}
                        />
                      </Grid>
                    </Grid>
                  </FormGroup>

                  <br />
                  <FormGroup className="mb-3">

                    <hr className="my-2" />
                    <h6
                      className="heading-small mb-4"
                      style={MbppStyles.SubTopic}
                    >
                      Geographical Details
                    </h6>

                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-map-marked-alt" />
                      </Grid>
                      <Grid item xs={5} sm={5}>
                        <TextField
                          fullWidth
                          id="longitude"
                          name="longitude"
                          label="Longitude"
                          variant="outlined"
                          value={`${formik.values.region?.coordinates?.longitude}`}
                          onChange={formik.handleChange}
                          error={formik.touched.longitude && Boolean(formik.errors.longitude)}
                          helperText={formik.touched.longitude && formik.errors.longitude}
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={5} sm={5}>
                        <TextField
                          fullWidth
                          id="latitude"
                          name="latitude"
                          label="Latitude"
                          variant="outlined"
                          value={`${formik.values.region?.coordinates?.latitude}`}
                          onChange={formik.handleChange}
                          error={formik.touched.latitude && Boolean(formik.errors.latitude)}
                          helperText={formik.touched.latitude && formik.errors.latitude}
                          disabled={true}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        {/* <i className="fas fa-map-marked" /> */}
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          id="name"
                          name="name"
                          label="Resolved Name"
                          variant="outlined"
                          value={formik.values.region.name}
                          onChange={formik.handleChange}
                          error={formik.touched.name && Boolean(formik.errors.name)}
                          helperText={formik.touched.name && formik.errors.name}
                          disabled={true}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        {/* <i className="fas fa-map-marked" /> */}
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          id="code"
                          name="code"
                          label="Resolved Code"
                          variant="outlined"
                          value={formik.values.region.code}
                          onChange={formik.handleChange}
                          error={formik.touched.code && Boolean(formik.errors.code)}
                          helperText={formik.touched.code && formik.errors.code}
                          disabled={true}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        {/* <i className="fas fa-map-marked" /> */}
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          select
                          fullWidth
                          id="region_ward"
                          name="region_ward"
                          label="Ward"
                          variant="outlined"
                          value={formik.values.region_ward}
                          // onChange={formik.handleChange}
                          onChange={(e) => handleWardChange(e)}
                          SelectProps={{ native: true, }}
                          error={formik.touched.region_ward && Boolean(formik.errors.region_ward)}
                          helperText={formik.touched.region_ward && formik.errors.region_ward}
                          disabled={isDeleted}
                        >
                          <option value=''></option>
                          {Object.entries(basicInformation.wardIds).map(([key, value]) => (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                    {/* <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-map-marked" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          select
                          fullWidth
                          id="ward"
                          name="ward"
                          label="Ward"
                          variant="outlined"
                          value={formik.values.region.ward}
                          onChange={formik.handleChange}
                          SelectProps={{ native: true, }}
                          error={formik.touched.ward && Boolean(formik.errors.ward)}
                          helperText={formik.touched.ward && formik.errors.ward}
                        >
                          <option value=''></option>
                          {Object.entries(basicInformation.wardIds).map(([key, value]) => (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid> */}
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        {/* <i className="fas fa-map-marked" /> */}
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          select
                          fullWidth
                          id="region_moh"
                          name="region_moh"
                          label="MOH"
                          variant="outlined"
                          value={formik.values.region_moh}
                          onChange={formik.handleChange}
                          SelectProps={{ native: true, }}
                          error={formik.touched.region_moh && Boolean(formik.errors.region_moh)}
                          helperText={formik.touched.region_moh && formik.errors.region_moh}
                          disabled={isDeleted}
                        >
                          <option value=''></option>
                          {Object.entries(basicInformation.mohIds).map(([key, value]) =>
                          (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </FormGroup>

                  <br />
                  <FormGroup className="mb-3">

                    <hr className="my-2" />
                    <h6
                      className="heading-small mb-4"
                      style={MbppStyles.SubTopic}
                    >
                      Pictures
                    </h6>

                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="far fa-images" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        {/* <div className="row"> */}
                        {(formik.values.pictures).length > 0 && (formik.values.pictures).map((x, i) => {
                          return (
                            (x && x !== null && x.thumbnail) ?
                              <a
                                key={i}
                                target="_blank"
                                rel="noreferrer"
                                href={Config.SERVER_API_URL + "uploads/breedingsite/" + x.image}
                              >
                                <img
                                  style={{ width: 200, height: 200, margin: 5 }}
                                  alt={"image " + i}
                                  src={Config.SERVER_API_URL + "uploads/breedingsite/" + x.thumbnail}
                                />
                              </a>
                              : null
                          );
                        })}
                        {/* </div> */}
                      </Grid>
                    </Grid>
                  </FormGroup>

                  {/* <FormGroup className="mb-3">

                    <hr className="my-2" />
                    <h6
                      className="heading-small mb-4"
                      style={MbppStyles.SubTopic}
                    >
                      Pictures
                    </h6>

                    {listImage.length > 0 && listImage.map((x, i) => {
                      return (
                        <Grid key={i} container spacing={1} alignItems="center">
                          <Grid item xs={1} sm={1} className="px-3 text-right">
                            <i className="far fa-images" />
                          </Grid>
                          <Grid item xs={10} sm={10}>
                            <TextField
                              fullWidth
                              id="image"
                              name="image"
                              type="file"
                              label={"Image #" + (1 + i)}
                              name='image'
                              variant="outlined"
                              multiple={false}
                              onChange={e => handleImageChange(e, i)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={(formik.errors.image && formik.touched.image) && (formik.touched.image[i]) && Boolean((formik.errors.image[i]))}
                              helperText={(formik.errors.image && formik.touched.image) && (formik.touched.image[i]) ? (formik.errors.image[i]) : ''}
                            />
                          </Grid>
                          <Grid item xs={1} sm={1} >
                            {listImage.length !== 1 && listImage.length - 1 === i && <RemoveCircleOutlineIcon className="mr10" onClick={() => handleRemoveImageClick(i)} />}
                            {(listImage.length - 1 === i && !(listImage.length > 5)) && <AddCircleOutlineIcon className="ma10" onClick={handleAddImageClick} />}
                          </Grid>
                        </Grid>
                      );
                    })}
                    <Button
                      color="primary"
                      size="md"
                      className="m-3"
                      type="button"
                      onChange={e => handleImageUpload(e)}
                    >
                      Upload Images
                    </Button>
                  </FormGroup> */}

                  <br />
                  <FormGroup className="mb-3">

                    <hr className="my-2" />
                    <h6
                      className="heading-small mb-4"
                      style={MbppStyles.SubTopic}
                    >
                      Action History
                    </h6>


                    <Table className="align-items-center table-flush" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Date</th>
                          <th scope="col">Action By</th>
                          <th scope="col">Action</th>
                          <th scope="col">Message</th>
                        </tr>
                      </thead>
                      <tbody>
                        {listAction.length > 0 && listAction.map((x, i) => {
                          return (
                            <tr key={i}>
                              <td>{new Date(x.date).toLocaleDateString(undefined, date_options)}</td>
                              <td>{x.takenBy.email}</td>
                              <td>{x.action}</td>
                              <td style={{ 'whiteSpace': 'break-spaces' }}>{x.message}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>

                  </FormGroup>

                  <br />
                  <FormGroup className="mb-3" style={{ display: isDeleted ? 'none' : 'block' }}>
                    <hr className="my-2" />
                    <h6
                      className="heading-small mb-4"
                      style={MbppStyles.SubTopic}
                    >
                      New Action Details
                    </h6>

                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-calendar-day" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          type="date"
                          id="action_date"
                          name="action_date"
                          label="Date"
                          variant="outlined"
                          format={'DD/MM/YYYY'}
                          InputLabelProps={{ shrink: true, }}
                          value={formik.values.action_date}
                          onChange={formik.handleChange}
                          error={formik.touched.action_date && Boolean(formik.errors.action_date)}
                          helperText={formik.touched.action_date && formik.errors.action_date}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-user" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          id="action_takenby"
                          name="action_takenby"
                          label="Action by"
                          variant="outlined"
                          value={formik.values.action_takenby}
                          onChange={formik.handleChange}
                          error={formik.touched.action_takenby && Boolean(formik.errors.action_takenby)}
                          helperText={formik.touched.action_takenby && formik.errors.action_takenby}
                          disabled={true}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-walking" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          select
                          fullWidth
                          id="action_action"
                          name="action_action"
                          label="Action"
                          variant="outlined"
                          SelectProps={{ native: true, }}
                          value={formik.values.action_action}
                          onChange={formik.handleChange}
                          error={formik.touched.action_action && Boolean(formik.errors.action_action)}
                          helperText={formik.touched.action_action && formik.errors.action_action}
                        >
                          <option value=''></option>
                          {BreedingComplainActions.map((value) => (
                            <option key={value} value={value}>
                              {value}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="ni ni-align-left-2" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          id="action_message"
                          name="action_message"
                          label="Message"
                          variant="outlined"
                          multiline 
                          minRows={4}
                          value={formik.values.action_message}
                          onChange={formik.handleChange}
                          error={formik.touched.action_message && Boolean(formik.errors.action_message)}
                          helperText={formik.touched.action_message && formik.errors.action_message}
                        />
                      </Grid>
                    </Grid>
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-info" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          select
                          fullWidth
                          id="status"
                          name="status"
                          label="Report's New Status"
                          variant="outlined"
                          value={formik.values.status}
                          onChange={formik.handleChange}
                          SelectProps={{ native: true, }}
                          error={formik.touched.status && Boolean(formik.errors.status)}
                          helperText={formik.touched.status && formik.errors.status}
                          disabled={isDeleted}
                        >
                          <option value=''></option>
                          {BreedingComplainStatus.map((value) => (
                            <option key={value} value={value}>
                              {value}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </FormGroup>

                </CardBody>

                <CardFooter className="py-4">
                  <Row>
                    <Col lg="6" className="text-left">
                      <Button
                        style={MbppStyles.GeneralButton}
                        color="primary"
                        onClick={() => {
                          history.goBack();
                        }}>
                        BACK
                      </Button>
                    </Col>
                    <Col lg="6" className="text-right">
                      {/* <Button
                        style={MbppStyles.GeneralButton}
                        color="primary"
                        type="reset"
                        onClick={() => { }}>
                        RESET
                      </Button> */}
                      {isDeleted ? null
                        :
                        <Button
                          style={MbppStyles.GeneralButton}
                          color="primary"
                          type="submit"
                          onClick={() => { }}>
                          {(formik.values._id) ? 'UPDATE' : 'SAVE'}
                        </Button>
                      }
                    </Col>
                  </Row>
                </CardFooter>

              </Form>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default BreedingComplainFrom;
